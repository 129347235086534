import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppVersion } from 'src/appRedux/actions';
import IntlMessages from 'src/util/IntlMessages';
import { LoadingOutlined } from '@ant-design/icons';

const Version = () => {
  const dispatch = useDispatch();
  const { version } = useSelector(
    ({ common }) => common,
  );

  React.useEffect(() => {
    dispatch(getAppVersion());
  }, []);

  return (
    <div className="gx-m-2">
      {version.loading
        && (
          <div>
            <p>
              <LoadingOutlined />
              {version.version ? <IntlMessages id="common.syncVersion" /> : <IntlMessages id="common.loadingVersion" />}
            </p>
          </div>
        )}
      {version.version && (
        <p style={{ userSelect: 'text' }}>
          <IntlMessages id="common.version-be" />
          {version.version}
        </p>
      )}
      <p style={{ userSelect: 'text' }}>
        v
        {process.env.REACT_APP_VERSION}
      </p>
      <p>{version.error && version.error}</p>
    </div>
  );
};

export default Version;
