/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbtack, faHome, faVolumeUp, faMicrophone,
  faExclamationTriangle, faCamera, faBan,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button, Modal, Select, Tooltip,
} from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  pan,
  stop,
  setNightView,
  pinHomePosition,
  zoomStop,
  zoomIn,
  zoomOut,
  goToHomeBookmark,
  startListen,
  stopListen,
  startTalk,
  stopTalk,
  startNotify,
  stopNotifyPlayAlert,
  endNotifyActivity,
  fetchAllReasons,
  setAudioVideoBlocked,
  setAudioVideoRequest, setAudioVideoSuccess,
} from '@actions/iObserverActions/ObservationActions';

import {
  getSelectedEndpointObservation,
  getListenReasons,
  getTalkReasons,
  getNotifyReasons,
  getTransferId,
  getAudioVideo,
} from '@selectors/iObserverSelectors';

import {
  PAN_DIRECTIONS,
  ZOOM_DIRECTIONS,
} from '@constants/Settings';
import { layoutNames } from '@constants/LayoutSettings';
import { debounce } from 'lodash';
import Draggable from 'react-draggable';
import IntlMessages from '../util/IntlMessages';

import nightModeImg from '../assets/images/24-nightmode.png';

const { Option } = Select;
const REASON_TYPES = {
  LISTEN: 'listen',
  TALK: 'talk',
  NOTIFY: 'notify',
};
export class CameraControls extends React.PureComponent {
  handleDeviceChange = debounce((devices) => this.handleDeviceChangeDebounced(devices), 500);

  constructor(props) {
    super(props);

    this.move = this.move.bind(this);
    this.stopMotion = this.stopMotion.bind(this);
    this.zoom = this.zoom.bind(this);
    this.zoomStop = this.zoomStop.bind(this);
    this.goToHomeBookmark = this.goToHomeBookmark.bind(this);
    this.pinHomePosition = this.pinHomePosition.bind(this);
    this.getMachineName = this.getMachineName.bind(this);
    this.setView = this.setView.bind(this);
    this.startListen = this.startListen.bind(this);
    this.startTalk = this.startTalk.bind(this);
    this.startNotify = this.startNotify.bind(this);
    this.stopNotify = this.stopNotify.bind(this);
    this.handleListen = this.handleListen.bind(this);
    this.handleTalk = this.handleTalk.bind(this);
    this.handleNotify = this.handleNotify.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setSelectedReason = this.setSelectedReason.bind(this);
    this.onPermissionStatusChange = this.onPermissionStatusChange.bind(this);
    this.handleCloseShowPinHomePositionModal = this.handleCloseShowPinHomePositionModal.bind(this);
    this.onDeviceChange = this.onDeviceChange.bind(this);

    const { selectedEndpoint, fetchAllReasonsProp } = this.props;
    this.selectedEndpointObservation = selectedEndpoint;
    fetchAllReasonsProp();
    this.state = {
      showReasonsModal: false,
      showPinHomePositionModal: false,
      reasonType: '',
      reasons: [],
      selectedReason: '',
      modalBounds: {
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      },
    };

    this.keyFlag = false;
  }

  componentDidMount() {
    // document.addEventListener('keydown', this.onKeyPressed);
    // document.addEventListener('keyup', this.onKeyRelease);
    this.onPermissionStatusChange();
    navigator.mediaDevices.ondevicechange = () => {
      this.onDeviceChange();
    };
    this.initCurrentDevices();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedEndpoint,
    } = this.props;
    if (prevProps.selectedEndpoint && prevProps.selectedEndpoint.id
      && ((selectedEndpoint && selectedEndpoint.id !== prevProps.selectedEndpoint.id)
        || !selectedEndpoint)) {
      this.handleSelectedEndpointChanged(selectedEndpoint, true);
    } else if ((!prevProps.selectedEndpoint && selectedEndpoint && selectedEndpoint.id)
      || (selectedEndpoint && prevProps.selectedEndpoint
        && selectedEndpoint.listen !== prevProps.selectedEndpoint.listen)
      || (selectedEndpoint && prevProps.selectedEndpoint
        && selectedEndpoint.talk !== prevProps.selectedEndpoint.talk)
      || (selectedEndpoint && prevProps.selectedEndpoint
        && selectedEndpoint.nightView !== prevProps.selectedEndpoint.nightView)
      || (selectedEndpoint && prevProps.selectedEndpoint
        && selectedEndpoint.playAlert !== prevProps.selectedEndpoint.playAlert)
    ) {
      this.selectedEndpointObservation = selectedEndpoint;
    }
  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.onKeyPressed);
    // document.removeEventListener('keyup', this.onKeyRelease);
  }

  // onKeyPressed = (event) => {
  //   if (event.shiftKey && event.keyCode === 38 && !this.keyFlag) {
  //     this.keyFlag = true;
  //     this.move(PAN_DIRECTIONS.UP);
  //   }
  //   if (event.shiftKey && event.keyCode === 40 && !this.keyFlag) {
  //     this.keyFlag = true;
  //     this.move(PAN_DIRECTIONS.DOWN);
  //   }
  //   if (event.shiftKey && event.keyCode === 37 && !this.keyFlag) {
  //     this.keyFlag = true;
  //     this.move(PAN_DIRECTIONS.LEFT);
  //   }
  //   if (event.shiftKey && event.keyCode === 39 && !this.keyFlag) {
  //     this.keyFlag = true;
  //     this.move(PAN_DIRECTIONS.RIGHT);
  //   }
  //   if (event.shiftKey && event.keyCode === 107 && !this.keyFlag) {
  //     this.keyFlag = true;
  //     this.zoom(ZOOM_DIRECTIONS.IN);
  //   }
  //   if (event.shiftKey && event.keyCode === 109 && !this.keyFlag) {
  //     this.keyFlag = true;
  //     this.zoom(ZOOM_DIRECTIONS.OUT);
  //   }
  //   if (event.shiftKey && event.keyCode === 72) {
  //     this.goToHomeBookmark();
  //   }
  //   if (event.shiftKey && event.keyCode === 68) {
  //     this.handleNotify(event);
  //   }
  // }

  // onKeyRelease = (event) => {
  //   if (event.keyCode === 107 || event.keyCode === 109) {
  //     this.keyFlag = false;
  //     this.zoomStop();
  //     return;
  //   }
  //   if (event.keyCode === 37 || event.keyCode === 38
  //     || event.keyCode === 39 || event.keyCode === 40) {
  //     this.keyFlag = false;
  //     this.stopMotion();
  //   }
  // }

  onPermissionStatusChange() {
    navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
      const permission = permissionStatus;
      const { setAudioVideoBlockedProp, setAudioVideoSuccessProp, audioVideo } = this.props;
      setAudioVideoBlockedProp({
        isMicBlocked: this.checkDevicePermissions(permissionStatus),
        isVideoBlocked: null,
      });

      permission.onchange = () => {
        setAudioVideoBlockedProp({
          isMicBlocked: this.checkDevicePermissions(permissionStatus),
          isVideoBlocked: null,
        });
        setAudioVideoSuccessProp({
          deviceChanged: !audioVideo.deviceChanged,
        });
      };
    }).catch((e) => console.log(e));
    navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
      const permission = permissionStatus;
      const { setAudioVideoBlockedProp, setAudioVideoSuccessProp, audioVideo } = this.props;
      setAudioVideoBlockedProp({
        isMicBlocked: null,
        isVideoBlocked: this.checkDevicePermissions(permissionStatus),
      });
      permission.onchange = () => {
        setAudioVideoBlockedProp({
          isMicBlocked: null,
          isVideoBlocked: this.checkDevicePermissions(permissionStatus),
        });
        setAudioVideoSuccessProp({
          deviceChanged: !audioVideo.deviceChanged,
        });
      };
    }).catch((e) => console.log(e));
  }

  async onDeviceChange() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log(devices);
    this.handleDeviceChange(devices);
  }

  getMachineName() {
    let machineName = null;
    if (this.selectedEndpointObservation && this.selectedEndpointObservation.machine_name) {
      machineName = this.selectedEndpointObservation.machine_name;
    }
    return machineName;
  }

  setSelectedReason(reason) {
    if (reason) {
      this.setState({ selectedReason: reason });
    }
  }

  setView() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { selectedEndpoint, setNightViewProp } = this.props;

      const newValue = (typeof selectedEndpoint.nightView === 'string')
        ? selectedEndpoint.nightView !== 'true' : !selectedEndpoint.nightView;
      setNightViewProp(machine_name, newValue);
    }
  }

  handleDeviceChangeDebounced = (devices) => {
    const { audioVideo, intl } = this.props;
    const { audio, video, speaker } = audioVideo;
    let hasSameVideo = null;
    let hasSameAudio = null;
    let hasSameSpeaker = null;

    let videoToSet = null;
    let audioToSet = null;
    let speakerToSet = null;

    let audioAdded = false;
    let videoAdded = false;
    let speakerAdded = false;

    this.audio = devices.filter((x) => x.kind === 'audioinput');
    this.video = devices.filter((x) => x.kind === 'videoinput');
    this.speakers = devices.filter((x) => x.kind === 'audiooutput');

    if ((this.oldAudioDevices !== null && this.oldAudioDevices.length === this.audio.length)
      && (this.oldVideoDevices !== null && this.oldVideoDevices.length === this.video.length)
      && (this.oldSpeakerDevices !== null && this.oldSpeakerDevices[1]?.label !== 'Dummy Output'
        && this.oldSpeakerDevices.length === this.speakers.length)) {
      return;
    }
    if (this.oldAudioDevices.length !== this.audio.length) {
      if (this.oldAudioDevices.length < this.audio.length) {
        const newDevice = this.audio.filter(({ deviceId: id1 }) => !this.oldAudioDevices.some(
          ({ deviceId: id2 }) => id2 === id1,
        ));
        const newDeviceToSet = newDevice[0];
        audioToSet = newDeviceToSet;
        audioAdded = true;
      }
      this.oldAudioDevices = this.audio;
    }
    if (this.oldVideoDevices.length !== this.video.length) {
      if (this.oldVideoDevices.length < this.video.length) {
        const newDevice = this.video.filter(({ deviceId: id1 }) => !this.oldVideoDevices.some(
          ({ deviceId: id2 }) => id2 === id1,
        ));
        const newDeviceToSet = newDevice[0];
        videoToSet = newDeviceToSet;
        videoAdded = true;
      }
      this.oldVideoDevices = this.video;
    }
    if (this.oldSpeakerDevices.length !== this.speakers.length
      || this.speakers[1]?.label === 'Dummy Output' || this.oldSpeakerDevices[1]?.label === 'Dummy Output') {
      if (this.oldSpeakerDevices.length < this.speakers.length || this.oldSpeakerDevices[1]?.label === 'Dummy Output') {
        const newDevice = this.speakers.filter(({ deviceId: id1 }) => !this.oldSpeakerDevices.some(
          ({ deviceId: id2 }) => id2 === id1,
        ));
        const newDeviceToSet = newDevice[0];
        speakerToSet = newDeviceToSet;
        speakerAdded = true;
      }
      this.oldSpeakerDevices = this.speakers;
    }

    if (video) {
      hasSameVideo = devices.find((localdevice) => localdevice.kind === 'videoinput' && localdevice.label === video);
    }
    if (audio) {
      hasSameAudio = devices.find((localdevice) => localdevice.kind === 'audioinput' && localdevice.label === audio);
    }
    if (speaker) {
      hasSameSpeaker = devices.find((localdevice) => localdevice.kind === 'audiooutput' && localdevice.label === speaker);
    }

    if (hasSameAudio && !audioAdded) {
      audioToSet = hasSameAudio;
    } else if (!hasSameAudio && !audioAdded) {
      [audioToSet] = this.audio;
    }
    if (hasSameSpeaker && !speakerAdded) {
      speakerToSet = hasSameSpeaker;
    } else if (!hasSameSpeaker && !speakerAdded) {
      [speakerToSet] = this.speakers;
    }
    if (hasSameVideo && !videoAdded) {
      videoToSet = hasSameVideo;
    } else if (!hasSameVideo && !videoAdded) {
      [videoToSet] = this.video;
    }
    const { setAudioVideo } = this.props;
    if (this.speakers[1] !== undefined && this.speakers[1].label === 'Dummy Output') {
      speakerToSet = {
        deviceId: '',
        label: intl.formatMessage({ id: 'common.msg.noSpeakers' }),
      };
    }
    setAudioVideo({
      audio: audioToSet?.label ? audioToSet.label : intl.formatMessage({ id: 'common.msg.noAudio' }),
      video: videoToSet?.label ? videoToSet.label : intl.formatMessage({ id: 'common.msg.noCamera' }),
      speaker: speakerToSet?.label ? speakerToSet.label : intl.formatMessage({ id: 'common.msg.noSpeakers' }),
      speakerId: speakerToSet?.deviceId ? speakerToSet.deviceId : '',
      isMicBlocked: audioVideo.isMicBlocked,
      isVideoBlocked: audioVideo.isVideoBlocked,
      deviceChanged: audioVideo.deviceChanged !== undefined ? !audioVideo.deviceChanged : false,
    });
  };

  initCurrentDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.oldAudioDevices = devices.filter((x) => x.kind === 'audioinput');
    this.oldVideoDevices = devices.filter((x) => x.kind === 'videoinput');
    this.oldSpeakerDevices = devices.filter((x) => x.kind === 'audiooutput');
  }

  checkDevicePermissions = (permissionStatus) => permissionStatus.state === 'denied';

  handleSelectedEndpointChanged = (selectedEndpoint, isChanged) => {
    if (this.selectedEndpointObservation
      && selectedEndpoint !== this.selectedEndpointObservation) {
      const { listen, talk, playAlert } = this.selectedEndpointObservation;
      if (listen) {
        this.handleListen();
        this.selectedEndpointObservation.listen = false;
      } else if (talk) {
        this.handleTalk();
        this.selectedEndpointObservation.talk = false;
      } else if (playAlert) {
        this.handleNotify();
        this.selectedEndpointObservation.playAlert = false;
      } else {
        this.selectedEndpointObservation = selectedEndpoint;
        this.setState({
          showReasonsModal: false,
          reasonType: '',
          reasons: [],
          selectedReason: '',
        });
      }

      if (isChanged && (listen || talk || playAlert)) {
        if (listen) {
          const { listenReasons, stopListenProp } = this.props;
          stopListenProp(this.getMachineName(), '', false, !!listenReasons.length);
        }
        if (talk) {
          const { talkReasons, stopTalkProp } = this.props;
          stopTalkProp(this.getMachineName(), '', false, !!talkReasons.length);
        }
        if (playAlert) {
          const { notifyReasons, endNotifyActivityProp } = this.props;
          this.stopNotify();
          endNotifyActivityProp(this.getMachineName(), '', false, !!notifyReasons.length);
        }
      }
    } else {
      this.setState({
        showReasonsModal: false,
        reasonType: '',
        reasons: [],
        selectedReason: '',
      });
    }
  }

  getIntervationType = () => {
    const { intl } = this.props;
    const { reasonType } = this.state;
    let reason;
    switch (reasonType) {
      case REASON_TYPES.LISTEN:
      default:
        reason = intl.formatMessage({ id: 'btn.listen' });
        break;
      case REASON_TYPES.NOTIFY:
        reason = intl.formatMessage({ id: 'btn.notify' });
        break;
      case REASON_TYPES.TALK:
        reason = intl.formatMessage({ id: 'btn.talk' });
        break;
    }
    return reason.toUpperCase();
  };

  getBedName = () => {
    const { intl } = this.props;
    let name = '';
    if (this.selectedEndpointObservation.patientInfo) {
      name = this.selectedEndpointObservation.patientInfo.bed_name
        ? this.selectedEndpointObservation.patientInfo.bed_name : intl.formatMessage({ id: 'patient.bedName' });
    }
    return name.toUpperCase();
  };

  handleCloseShowPinHomePositionModal() {
    this.setState({ showPinHomePositionModal: false });
  }

  goToHomeBookmark() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { goToHomeBookmarkProp } = this.props;
      goToHomeBookmarkProp(machine_name);
    }
  }

  startListen() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { talkReasons, startListenProp } = this.props;
      const reason = talkReasons[0].code;
      startListenProp(machine_name, reason, true);
    }
  }

  startTalk() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { talkReasons, startTalkProp } = this.props;
      const reason = talkReasons[0].code;
      startTalkProp(machine_name, reason, true);
    }
  }

  startNotify() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { talkReasons, startNotifyProp } = this.props;

      const reason = talkReasons[0].code;
      startNotifyProp(machine_name, reason, true);
    }
  }

  stopNotify() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { stopNotifyPlayAlertProp } = this.props;
      stopNotifyPlayAlertProp(machine_name);
    }
  }

  handleListen(event) {
    if (this.selectedEndpointObservation) {
      const { listen } = this.selectedEndpointObservation;
      if (listen === true) {
        const { listenReasons, stopListenProp } = this.props;
        if (listenReasons.length) {
          this.setState({
            showReasonsModal: true,
            reasons: listenReasons || [],
            reasonType: REASON_TYPES.LISTEN,
            selectedReason: listenReasons[0].code,
          });
        }
        if (event) {
          stopListenProp(this.getMachineName(), '', false, !!listenReasons.length);
        }
      } else if (listen === false) {
        this.startListen();
      }
    }
  }

  handleTalk(event) {
    if (this.selectedEndpointObservation) {
      const { talk } = this.selectedEndpointObservation;
      const { talkReasons, stopTalkProp } = this.props;
      if (talk === true) {
        if (talkReasons.length) {
          this.setState({
            showReasonsModal: true,
            reasons: talkReasons || [],
            reasonType: REASON_TYPES.TALK,
            selectedReason: talkReasons[0].code,
          });
        }
        if (event) {
          stopTalkProp(this.getMachineName(), '', false, !!talkReasons.length);
        }
      } else if (talk === false) {
        this.startTalk();
      }
    }
  }

  handleNotify(event) {
    const { notifyReasons, endNotifyActivityProp } = this.props;

    if (this.selectedEndpointObservation) {
      const { playAlert } = this.selectedEndpointObservation;
      if (playAlert === true) {
        if (notifyReasons.length) {
          this.setState({
            showReasonsModal: true,
            reasons: notifyReasons || [],
            reasonType: REASON_TYPES.NOTIFY,
            selectedReason: notifyReasons[0].code,
          });
          if (event) {
            this.stopNotify();
            endNotifyActivityProp(this.getMachineName(), '', false, !!notifyReasons.length);
          }
        }
      } else if (playAlert === false) {
        this.startNotify();
      }
    }
  }

  handleCloseModal() {
    const {
      selectedReason, reasonType, reasons,
    } = this.state;
    const { selectedEndpoint } = this.props;
    const machine_name = this.getMachineName();
    if (machine_name && selectedReason && reasonType
      && reasons.length && this.selectedEndpointObservation) {
      const { endNotifyActivityProp, stopTalkProp, stopListenProp } = this.props;
      switch (reasonType) {
        case REASON_TYPES.LISTEN:
          stopListenProp(machine_name, selectedReason, false);
          break;
        case REASON_TYPES.TALK:
          stopTalkProp(machine_name, selectedReason, false);
          break;
        case REASON_TYPES.NOTIFY:
          endNotifyActivityProp(machine_name, selectedReason, false);
          break;
        default:
          break;
      }
    }
    this.handleSelectedEndpointChanged(selectedEndpoint);
  }

  zoomStop() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { zoomStopProp } = this.props;
      zoomStopProp(machine_name);
    }
  }

  zoom(action) {
    const machine_name = this.getMachineName();
    const { zoomInProp, zoomOutProp } = this.props;
    if (machine_name) {
      if (action === ZOOM_DIRECTIONS.IN) {
        zoomInProp(machine_name);
      } else if (action === ZOOM_DIRECTIONS.OUT) {
        zoomOutProp(machine_name);
      }
    }
  }

  stopMotion() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { stopProp } = this.props;
      stopProp(machine_name);
    }
  }

  move(direction) {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { panProp } = this.props;
      panProp(machine_name, direction);
    }
  }

  pinHomePosition() {
    const machine_name = this.getMachineName();
    if (machine_name) {
      const { pinHomePositionProp } = this.props;
      pinHomePositionProp(machine_name);
      this.setState({ showPinHomePositionModal: true });
    }
  }

  onDragStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = this.draggleRef?.current?.getBoundingClientRect();
    this.setState({
      modalBounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  };

  renderSelectReason() {
    const { reasons, reasonType } = this.state;
    if (reasons.length) {
      let options = [];
      if (reasonType && reasons.length) {
        options = reasons.map((el) => <Option key={el.code} value={el.code}>{el.text}</Option>);
      }
      return (
        <Select
          defaultValue={reasons[0].code}
          onChange={(value) => { this.setSelectedReason(value); }}
        >
          {options}
        </Select>
      );
    }
    return null;
  }

  render() {
    const { showReasonsModal, showPinHomePositionModal, modalBounds } = this.state;
    const {
      selectedEndpoint, transferId, intl, audioVideo, history, selectedLayout,
    } = this.props;
    const classNameListenTalk = 'gx-mb-0 footer-btn btn-footer-text';
    const classNameListen = `${classNameListenTalk}${selectedEndpoint && selectedEndpoint.listen ? ' listenTalk' : ''}`;
    const classNameTalk = `${classNameListenTalk}${selectedEndpoint && selectedEndpoint.talk ? ' listenTalk' : ''}`;

    return (
      <>
        <div className="left-side-part">
          <div className="camera-indicator">
            {audioVideo && (
              <>
                {audioVideo?.isVideoBlocked && (
                  <Tooltip title={intl.formatMessage({ id: 'cameraLabelBlocked' })}>
                    <span className="camera-indicator-blocked fa-layers fa-fw">
                      <FontAwesomeIcon icon={faCamera} transform="shrink-6" className="inactive blocked gx-mx-2" />
                      <FontAwesomeIcon icon={faBan} className="inactive red gx-mx-2" />
                    </span>
                  </Tooltip>
                )}
                {!audioVideo.isVideoBlocked && audioVideo.video === intl.formatMessage({ id: 'common.msg.noCamera' }) && (
                  <Tooltip title={intl.formatMessage({ id: 'cameraLabelInactive' })}>
                    <Button type="text" onClick={() => history.push('/app/my-camera')}>
                      <FontAwesomeIcon icon={faCamera} className="inactive gx-mx-2" />
                    </Button>
                  </Tooltip>
                )}
                {!audioVideo.isVideoBlocked && audioVideo.video !== intl.formatMessage({ id: 'common.msg.noCamera' }) && (
                  <Tooltip title={intl.formatMessage({ id: 'cameraLabelActive' })}>
                    <Button type="text" onClick={() => history.push('/app/my-camera')}>
                      <FontAwesomeIcon icon={faCamera} className="gx-mx-2" />
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </div>
          <div className="camera-controls">
            <Button
              className="gx-mb-0 footer-btn btn-footer-text"
              size="medium"
              title={intl.formatMessage({ id: 'btn.nightMode' })}
              onClick={this.setView}
            >
              <img
                src={nightModeImg}
                className="gx-mx-2"
                alt="Night Mode"
              />
              <span>
                <IntlMessages id="btn.nightMode" />
              </span>
            </Button>
            <Button
              className="gx-mb-0 footer-btn btn-footer-text"
              size="medium"
              title={intl.formatMessage({ id: 'btn.home' })}
              onClick={this.goToHomeBookmark}
            >
              <FontAwesomeIcon icon={faHome} className="gx-mx-2" />
              <span>
                <IntlMessages id="btn.home" />
              </span>
            </Button>
            <Button
              className="gx-mb-0 footer-btn btn-footer-text"
              size="medium"
              title={intl.formatMessage({ id: 'btn.pin' })}
              onClick={this.pinHomePosition}
            >
              <FontAwesomeIcon icon={faThumbtack} className="gx-mx-2" />
              <span>
                <IntlMessages className="btn-footer-text" id="btn.pin" />
              </span>
            </Button>
          </div>
        </div>

        <div className="joystick-controls">
          <Button
            className="gx-m-0 gx-mb-0 footer-btn zoom-in-btn"
            size="medium"
            onMouseDown={() => { this.zoom(ZOOM_DIRECTIONS.IN); }}
            onMouseUp={() => { this.zoomStop(); }}
          >
            &nbsp;
          </Button>
          <div className="gx-m-1" id="camera_joystick">
            <a
              id="move_up"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.UP); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_up_right"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.UPRIGHT); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_up_left"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.UPLEFT); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_right"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.RIGHT); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_left"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.LEFT); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_down"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.DOWN); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_down_right"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.DOWNRIGHT); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
            <a
              id="move_down_left"
              className="nav_btn"
              href="#"
              onMouseDown={() => { this.move(PAN_DIRECTIONS.DOWNLEFT); }}
              onMouseUp={() => { this.stopMotion(); }}
            />
          </div>
          <Button
            className="gx-m-0 gx-mb-0 footer-btn zoom-out-btn"
            size="medium"
            onMouseDown={() => { this.zoom(ZOOM_DIRECTIONS.OUT); }}
            onMouseUp={() => { this.zoomStop(); }}
          >
            &nbsp;
          </Button>
        </div>
        {/* RIGHT BUTTONS SECTION */}
        <div className="right-side-part">
          {/* LISTEN BUTTON */}
          <Button
            className={classNameListen}
            size="medium"
            title={intl.formatMessage({ id: 'btn.listen' })}
            onClick={this.handleListen}
            disabled={audioVideo.speaker === 'No Speakers'}
          >
            {audioVideo.speaker === 'No Speakers' ? (
              <span className="mic-indicator-blocked">
                <FontAwesomeIcon icon={faVolumeUp} className="gx-mx-2" />
                <FontAwesomeIcon icon={faBan} className="inactive red gx-mx-2" />
              </span>
            )
              : <FontAwesomeIcon icon={faVolumeUp} className="gx-mx-2" />}
            <span>
              <IntlMessages id="btn.listen" />
            </span>
          </Button>
          {/* TALK BUTTON */}
          {audioVideo && (
            <Button
              className={classNameTalk}
              size="medium"
              title={intl.formatMessage({ id: 'btn.talk' })}
              onClick={this.handleTalk}
              disabled={audioVideo.isMicBlocked || audioVideo.audio === intl.formatMessage({ id: 'common.msg.noAudio' })}
            >
              {audioVideo.isMicBlocked && (
                <span className="mic-indicator-blocked">
                  <FontAwesomeIcon icon={faMicrophone} className="gx-mx-2" />
                  <FontAwesomeIcon icon={faBan} className="inactive red gx-mx-2" />
                </span>
              )}
              {!audioVideo.isMicBlocked && audioVideo.audio === intl.formatMessage({ id: 'common.msg.noAudio' }) && (
                <span className="mic-indicator-blocked">
                  <FontAwesomeIcon icon={faMicrophone} className="gx-mx-2" />
                  <FontAwesomeIcon icon={faBan} className="inactive red gx-mx-2" />
                </span>
              )}
              {!audioVideo.isMicBlocked && audioVideo.audio !== intl.formatMessage({ id: 'common.msg.noAudio' }) && (
                <FontAwesomeIcon icon={faMicrophone} className="gx-mx-2" />
              )}
              <span>
                <IntlMessages id="btn.talk" />
              </span>
            </Button>
          )}
          <Button
            className="gx-mb-0 footer-alarm-btn btn-footer-text"
            size="medium"
            title={intl.formatMessage({ id: 'btn.notify' })}
            onClick={this.handleNotify}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="gx-mx-2" />
            <span>
              <IntlMessages id="btn.notify" />
            </span>
          </Button>
          {
            transferId !== null && transferId > -1
              ? (
                <div className="waiting-transfer-label">
                  <IntlMessages id="sessoin.waiting_for_transfer_2" />
                </div>
              )
              : null
          }
        </div>

        {showReasonsModal && (
          <Modal
            centered={selectedLayout !== layoutNames.DEFAULT_LAYOUT}
            style={selectedLayout === layoutNames.DEFAULT_LAYOUT ? { top: 30, marginRight: '50%', cursor: 'move' } : { cursor: 'move' }}
            visible={showReasonsModal}
            title={intl.formatMessage({ id: 'cameraControls.selectInterventionReason' })}
            closable={false}
            onOk={this.handleCloseModal}
            // onCancel={this.handleCloseModal}
            footer={[
              <Button
                key="submit"
                type="primary"
                className="buttons-modal"
                loading={false}
                onClick={this.handleCloseModal}
              >
                <IntlMessages id="common.submit" />
              </Button>,
            ]}
            modalRender={(modal) => (
              <Draggable
                bounds={modalBounds}
                onStart={(event, uiData) => this.onDragStart(event, uiData)}
              >
                <div ref={this.draggleRef}>{modal}</div>
              </Draggable>
            )}
          >
            <p>
              {intl.formatMessage({ id: 'cameraControls.intervationType' })}
              <strong>{this.getIntervationType()}</strong>
            </p>
            <p>
              {intl.formatMessage({ id: 'cameraControls.bedName' })}
              <strong>{this.getBedName()}</strong>
            </p>
            {this.renderSelectReason()}
          </Modal>
        )}

        {/* END TRANSFER SESSION MODAL */}
        <Modal
          visible={showPinHomePositionModal}
          onOk={this.handleCloseShowPinHomePositionModal}
          // onCancel={this.handleCloseShowPinHomePositionModal}
          title={intl.formatMessage({ id: 'cameraControls.home_position' })}
          footer={[
            <Button
              className="buttons-modal"
              key="submit"
              type="primary"
              loading={false}
              onClick={this.handleCloseShowPinHomePositionModal}
            >
              <IntlMessages id="sessoin.ok" />
            </Button>,
          ]}
        >
          <div>{intl.formatMessage({ id: 'cameraControls.home_position_has_been_sent' })}</div>
        </Modal>
      </>
    );
  }
}

CameraControls.propTypes = {
  intl: PropTypes.shape().isRequired,
  selectedEndpoint: PropTypes.shape(),
  panProp: PropTypes.func.isRequired,
  stopProp: PropTypes.func.isRequired,
  pinHomePositionProp: PropTypes.func.isRequired,
  zoomStopProp: PropTypes.func.isRequired,
  zoomInProp: PropTypes.func.isRequired,
  zoomOutProp: PropTypes.func.isRequired,
  setNightViewProp: PropTypes.func.isRequired,
  goToHomeBookmarkProp: PropTypes.func.isRequired,
  startListenProp: PropTypes.func.isRequired,
  stopListenProp: PropTypes.func.isRequired,
  startTalkProp: PropTypes.func.isRequired,
  stopTalkProp: PropTypes.func.isRequired,
  startNotifyProp: PropTypes.func.isRequired,
  stopNotifyPlayAlertProp: PropTypes.func.isRequired,
  endNotifyActivityProp: PropTypes.func.isRequired,
  fetchAllReasonsProp: PropTypes.func.isRequired,
  listenReasons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  talkReasons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  notifyReasons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  transferId: PropTypes.number.isRequired,
  audioVideo: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  setAudioVideoBlockedProp: PropTypes.func.isRequired,
  setAudioVideo: PropTypes.func.isRequired,
  setAudioVideoSuccessProp: PropTypes.func.isRequired,
  selectedLayout: PropTypes.string.isRequired,
};
CameraControls.defaultProps = {
  selectedEndpoint: {},
};

const mapStateToProps = (state) => {
  const selectedEndpoint = getSelectedEndpointObservation(state);
  const listenReasons = getListenReasons(state);
  const talkReasons = getTalkReasons(state);
  const notifyReasons = getNotifyReasons(state);
  const transferId = getTransferId(state);
  const { monitoringLayout } = state;
  const { selectedLayout } = monitoringLayout;
  return {
    selectedEndpoint,
    listenReasons,
    talkReasons,
    notifyReasons,
    transferId,
    audioVideo: getAudioVideo(state),
    selectedLayout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  panProp: (machine_name, direction) => dispatch(pan(machine_name, direction)),
  stopProp: (machine_name) => dispatch(stop(machine_name)),
  pinHomePositionProp: (machine_name) => dispatch(pinHomePosition(machine_name)),
  zoomStopProp: (machine_name) => dispatch(zoomStop(machine_name)),
  zoomInProp: (machine_name) => dispatch(zoomIn(machine_name)),
  zoomOutProp: (machine_name) => dispatch(zoomOut(machine_name)),
  setNightViewProp: (machine_name, toSet) => dispatch(setNightView(machine_name, toSet)),
  goToHomeBookmarkProp: (machine_name) => dispatch(goToHomeBookmark(machine_name)),
  startListenProp: (machine_name, reason, toSet) => dispatch(
    startListen(machine_name, reason, toSet),
  ),
  stopListenProp: (machine_name, reason, toSet, setOnly) => dispatch(
    stopListen(machine_name, reason, toSet, setOnly),
  ),
  startTalkProp: (machine_name, reason, toSet) => dispatch(startTalk(machine_name, reason, toSet)),
  stopTalkProp: (machine_name, reason, toSet, setOnly) => dispatch(
    stopTalk(machine_name, reason, toSet, setOnly),
  ),
  startNotifyProp: (machine_name, reason, toSet) => dispatch(
    startNotify(machine_name, reason, toSet),
  ),
  stopNotifyPlayAlertProp: (machine_name) => dispatch(stopNotifyPlayAlert(machine_name)),
  endNotifyActivityProp: (machine_name, reason, toSet, setOnly) => dispatch(
    endNotifyActivity(machine_name, reason, toSet, setOnly),
  ),
  fetchAllReasonsProp: () => dispatch(fetchAllReasons()),
  setAudioVideoBlockedProp: (state) => dispatch(setAudioVideoBlocked(state)),
  setAudioVideo: (params) => dispatch(setAudioVideoRequest(params)),
  setAudioVideoSuccessProp: (params) => dispatch(setAudioVideoSuccess(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(CameraControls)));
