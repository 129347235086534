import React from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import EndpointVideo from '../EndpointVideo';

const Layout6RoomsSymmetric = (props) => {
  const {
    endpointsData, selectEndpoint, selectedEndpoint, refreshCamera, makeCall, callbacks,
  } = props;

  return (
    <>
      <Row className="row-height-large video-thumbs-holder">
        <Col span={12} className="video-height video-thumb">
          <div className="video-placeholder-six-symmetric">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[0] && endpointsData[0].stream}
              mediaId={endpointsData[0] && endpointsData[0].mediaId}
              endpointData={endpointsData[0] && endpointsData[0].data}
              endpointStats={endpointsData[0] && endpointsData[0].stats}
              hasCall={!!(endpointsData[0] && endpointsData[0].dialedInPopup)}
              index={0}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
        <Col span={12} className="video-height video-thumb">
          <div className="video-placeholder-six-symmetric">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[1] && endpointsData[1].stream}
              mediaId={endpointsData[1] && endpointsData[1].mediaId}
              endpointData={endpointsData[1] && endpointsData[1].data}
              endpointStats={endpointsData[1] && endpointsData[1].stats}
              hasCall={!!(endpointsData[1] && endpointsData[1].dialedInPopup)}
              index={1}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
      </Row>
      <Row className="row-small-video-s video-thumbs-holder">
        <Col span={6} className="video-thumb video-height padding-right-col">
          <div className="video-placeholder-six-symmetric">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[2] && endpointsData[2].stream}
              mediaId={endpointsData[2] && endpointsData[2].mediaId}
              endpointData={endpointsData[2] && endpointsData[2].data}
              endpointStats={endpointsData[2] && endpointsData[2].stats}
              hasCall={!!(endpointsData[2] && endpointsData[2].dialedInPopup)}
              index={2}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
        <Col span={6} className="video-thumb video-height padding-right-col">
          <div className="video-placeholder-six-symmetric">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[3] && endpointsData[3].stream}
              mediaId={endpointsData[3] && endpointsData[3].mediaId}
              endpointData={endpointsData[3] && endpointsData[3].data}
              endpointStats={endpointsData[3] && endpointsData[3].stats}
              hasCall={!!(endpointsData[3] && endpointsData[3].dialedInPopup)}
              index={3}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
        <Col span={6} className="video-thumb video-height padding-right-col">
          <div className="video-placeholder-six-symmetric">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[4] && endpointsData[4].stream}
              mediaId={endpointsData[4] && endpointsData[4].mediaId}
              endpointData={endpointsData[4] && endpointsData[4].data}
              endpointStats={endpointsData[4] && endpointsData[4].stats}
              hasCall={!!(endpointsData[4] && endpointsData[4].dialedInPopup)}
              index={4}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
        <Col span={6} className="video-thumb video-height">
          <div className="video-placeholder-six-symmetric">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[5] && endpointsData[5].stream}
              mediaId={endpointsData[5] && endpointsData[5].mediaId}
              endpointData={endpointsData[5] && endpointsData[5].data}
              endpointStats={endpointsData[5] && endpointsData[5].stats}
              hasCall={!!(endpointsData[5] && endpointsData[5].dialedInPopup)}
              index={5}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
Layout6RoomsSymmetric.defaultProps = {
  endpointsData: [],
  selectedEndpoint: null,
  selectEndpoint: null,
  refreshCamera: null,
  makeCall: null,
  callbacks: null,
};

Layout6RoomsSymmetric.propTypes = {
  selectEndpoint: PropTypes.func,
  selectedEndpoint: PropTypes.shape(),
  endpointsData: PropTypes.arrayOf(PropTypes.shape()),
  refreshCamera: PropTypes.func,
  makeCall: PropTypes.func,
  callbacks: PropTypes.shape(),
};

export { Layout6RoomsSymmetric as Layout6RoomsSymmetricTest };
export default injectIntl(withRouter(Layout6RoomsSymmetric));
