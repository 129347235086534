import React from 'react';
import {
  Row, Col, Button, Tooltip,
} from 'antd';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
  CheckSquareOutlined, SyncOutlined, WarningOutlined, LoadingOutlined, CloseCircleOutlined,
} from '@ant-design/icons';
import IntlMessages from 'src/util/IntlMessages';

export default function SelectedCustomer(props) {
  if (!props) {
    return null;
  }
  const {
    value, onClick, isOffline, isLoading, onRefreshClick, intl, isStreamDisconnected,
  } = props;
  const colSpanName = (isOffline || isStreamDisconnected) && !isLoading ? 15 : 17;
  const cnCustomerName = `word-breaker current-customer gx-py-2 ${isOffline ? 'offline' : ''}`;

  return (
    <div className="word-breaker">
      <Row className="current-customer">
        {isLoading && (
          <Col sm={2} md={2} lg={2} xl={2} className="status-icon-holder">
            <Tooltip title={intl.formatMessage({ id: 'customers.checkingStatus' })}>
              <LoadingOutlined style={{ height: '16px' }} />
            </Tooltip>
          </Col>
        )}
        {!isOffline && isStreamDisconnected && !isLoading && (
          <Col sm={2} md={2} lg={2} xl={2} className="status-icon-holder disconnected">
            <Tooltip title={intl.formatMessage({ id: 'customers.statusMediaDisconnected' })}>
              <WarningOutlined />
            </Tooltip>
          </Col>
        )}
        {isOffline && !isLoading && (
          <Col sm={2} md={2} lg={2} xl={2} className="status-icon-holder offline">
            <Tooltip title={intl.formatMessage({ id: 'customers.statusOffline' })}>
              <CloseCircleOutlined />
            </Tooltip>
          </Col>
        )}
        {!isOffline && !isLoading && !isStreamDisconnected && (
          <Col sm={2} md={2} lg={2} xl={2} className="status-icon-holder online">
            <Tooltip title={intl.formatMessage({ id: 'customers.statusOnline' })}>
              <CheckSquareOutlined />
            </Tooltip>
          </Col>
        )}
        <Col sm={colSpanName} md={colSpanName} lg={colSpanName} xl={colSpanName}>
          <p className={cnCustomerName}>{value}</p>
        </Col>
        {(isOffline || isStreamDisconnected) && !isLoading && (
          <Col sm={2} md={2} lg={2} xl={2}>
            <Tooltip title={intl.formatMessage({ id: 'btn.checkStatus.tooltip' })}>
              <Button className="current-customer-btn gx-px-2" onClick={onRefreshClick}>
                <SyncOutlined />
              </Button>
            </Tooltip>
          </Col>
        )}
        <div>
          <Button className="discharge-btn gx-px-2" onClick={onClick}>
            <IntlMessages id="customers.discharge" />
          </Button>
        </div>
      </Row>
    </div>
  );
}
SelectedCustomer.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  isOffline: PropTypes.bool,
  isLoading: PropTypes.bool,
  isStreamDisconnected: PropTypes.bool,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

SelectedCustomer.defaultProps = {
  isOffline: false,
  isLoading: false,
  isStreamDisconnected: false,
};
