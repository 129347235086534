import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'antd';
import { Button } from 'antd/lib/radio';
import PropTypes from 'prop-types';

const CustomModalNotifications = ({
  visible, title, message, onOk, onCancel, onClose, okBtnText, cancelBtnText, modalStyle,
  modalClass,
}) => (
  visible && (
    <div className={`custom-modal-container ${modalClass || ''}`} style={modalStyle}>
      <Row>
        <p className="custom-modal-title">{title}</p>
        {!!onClose && (
          <FontAwesomeIcon icon={faTimes} className="custom-modal-icon" onClick={onClose} />
        )}
      </Row>
      <div className="custom-modal-message">{message}</div>
      <div className="custom-modal-footer">
        <Button className="custom-modal-btn-primary" onClick={onOk}>{okBtnText}</Button>
        <Button onClick={onCancel}>{cancelBtnText}</Button>
      </div>
    </div>
  )
);
CustomModalNotifications.defaultProps = {
  modalStyle: {},
  modalClass: '',
  onClose: null,
};

CustomModalNotifications.propTypes = {
  title: PropTypes.shape().isRequired,
  message: PropTypes.shape().isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  okBtnText: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  modalStyle: PropTypes.shape(),
  modalClass: PropTypes.string,
};

export default CustomModalNotifications;
