import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SecondsCounter extends PureComponent {
  constructor(props) {
    super(props);

    this.timer = 0;

    this.state = {
      seconds: 0,
      minutes: 0,
      hours: 0,
    };
  }

  componentDidMount() {
    this.calculateData();
  }

  componentDidUpdate(prevProps) {
    const { startDateTime } = this.props;

    if (prevProps && prevProps.startDateTime !== startDateTime) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.calculateData();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  calculateData = () => {
    const { startDateTime } = this.props;

    const diffInSeconds = Math.floor((Date.now() - startDateTime) / 1000);

    const seconds = diffInSeconds % 60;
    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(diffInSeconds / 3600);

    this.setState({ seconds, minutes, hours });

    this.timer = setTimeout(this.calculateData, 1000);
  }

  tick = () => {
    let { seconds, minutes, hours } = this.state;

    if (seconds < 60) {
      seconds += 1;
    } else {
      seconds = 0;
      if (minutes < 60) {
        minutes += 1;
      } else {
        minutes = 0;
        hours += 1;
      }
    }
    this.setState({ seconds, minutes, hours });
    this.timer = setTimeout(this.tick, 1000);
  };

  render() {
    const { hours, minutes, seconds } = this.state;

    return (
      <span>
        {hours > 0 ? `${hours}:` : ''}
        {minutes < 10 ? `0${minutes}` : minutes}
        :
        {seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  }
}

SecondsCounter.propTypes = {
  startDateTime: PropTypes.number.isRequired,
};
export default SecondsCounter;
