import React from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import PropTypes from 'prop-types';
import {
  logout,
} from '@actions/iObserverActions/GlobalActions';
import IntlMessages from '../../util/IntlMessages';

const { Header } = Layout;
const logo = require('@assets/images/c-logo.png');

const CustomTopbar = (props) => {
  const dispatch = useDispatch();
  const { username } = props;
  return (
    <Header>
      <div className="gx-d-flex gx-flex-row panels">
        <div className="logo"><img className="max-width" alt="Logo" src={logo} /></div>
        <div className="second-section">
          <div className="gx-py-3 email-space">
            <span className="ellipsis-email">
              <UserOutlined />
              {username}
            </span>
          </div>
          <div style={{ margin: '10px 0' }}>
            <button
              type="button"
              className="logout-btn"
              onClick={() => dispatch(logout())}
            >
              <IntlMessages id="app.userAuth.logout" />
            </button>
          </div>
        </div>
      </div>
    </Header>
  );
};

CustomTopbar.propTypes = {
  username: PropTypes.string,
};

CustomTopbar.defaultProps = {
  username: '',
};

export default CustomTopbar;
