import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';
import {
  applyChangesSuccess,
  cleanUpDataOnLogout,
} from '@actions/iObserverActions/GlobalActions';

import {
  getAdminId,
  getObservationSessionId,
  getAdmittedEndpoints,
  getRemovedEndpoints,
  getChangedEndpoints,
  getAppliedEndpoints,
} from '@selectors/iObserverSelectors';

import {
  APPLY_CHANGES,
  END_OBSERVATION_SESSION_REQUEST,
  LOGOUT,
  CLEAN_UP_SESSION_AFTER_TRANSFER,
  START_OBSERVATIONS,
  FETCH_IOBSERVER_CONTROLER_SETTINGS_REQUEST,
  GET_BE_APP_VERSION,
} from '@constants/iObserverActionTypes';

import {
  startRoomObservation,
  // endRoomObservation,
  updateRoomObservation,
  startObservationSessionSuccess,
  cleanUpSession,
  endRoomObservationSuccess,
} from '@actions/iObserverActions/ObservationActions';

import {
  CONTROL_COMMANDS,
  PARAMS_PER_COMMAND,
} from '@constants/Settings';

import { rest } from '@api/iObserverApi';

import {
  checkVersion, fetchAppVersionResult, fetchError, fetchIObserverControllerSettingsSuccess,
} from '@actions/Common';
import WebRTCManager from '../../../util/WebRTC/WebRTCManager';

// adding tests object for unit testing private functions
const tests = {
  startObservations: null,
  endRoomObservation: null,
  endObservations: null,
  updateObservations: null,
  applyChanges: null,
  endObservationSession: null,
  cleanUpStoredSession: null,
  logout: null,
  fetchIObserverControllerSettings: null,
  getAppVersion: null,
};

function* startObservations() {
  try {
    const admittedEndpoints = yield select(getAdmittedEndpoints);
    const arrAE = Object.values(admittedEndpoints);

    // const adminId = yield select(getAdminId);
    if (arrAE.length) {
      // the wait value is taken from the prev. version source code
      // setTimeout(() => {
      //   arrAE.forEach((item) => {
      //     WebRTCManager.directLink(`camera_${item.machine_name}`,
      //      `${adminId}`, { calibrateMotion: false });
      //   });
      // }, 1800);

      const startObservationActions = yield arrAE.map((item) => put(startRoomObservation(item)));
      yield all([...startObservationActions]);
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* endRoomObservation(data) {
  try {
    const { machine_name, id } = data;
    const params = {
      machine_name,
    };
    yield console.log('Start "end observation" for: ', machine_name);
    yield call(rest.endRoomObservation, params);

    const stopPlayAlertParams = {
      machine_name,
      command: CONTROL_COMMANDS.STOP_PLAY_ALERT,
      params_p: PARAMS_PER_COMMAND.stop_play_alert,
    };
    try {
      // To be redone when API Changes/Stabilizes from current Proxy Method
      yield call(rest.sendCommandByMachineName, stopPlayAlertParams);
    } catch (e) {
      console.warn('[Error] [send_command] sendCommandByMachineName endRoomObservation failed',
        JSON.stringify(stopPlayAlertParams), e);
    }

    const admin_id = yield select(getAdminId);
    const endObserverParams = {
      machine_name,
      command: CONTROL_COMMANDS.END_OBSERVER,
      params_p: { admin_id: admin_id },
    };
    try {
      // To be redone when API Changes/Stabilizes from current Proxy Method
      yield call(rest.sendCommandByMachineName, endObserverParams);
    } catch (e) {
      console.warn('[Error] [send_command] endRoomObservation sendCommandByMachineName failed',
        JSON.stringify(endObserverParams), e);
    }

    yield put(endRoomObservationSuccess(id));
    yield console.log('end observation for: ', machine_name);
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* endObservations(adminId) {
  try {
    const removedEndpoints = yield select(getRemovedEndpoints);
    const arrRE = Object.values(removedEndpoints);
    if (arrRE.length) {
      const endObservationActions = yield arrRE.map((item) => {
        WebRTCManager.directUnlink(`camera_${item.machine_name}`, adminId, { calibrateMotion: false });
        return call(endRoomObservation, item);
      });
      yield all([...endObservationActions]);
      // WebRTCManager.closeUserSession();
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* updateObservations() {
  try {
    const changedEndpoints = yield select(getChangedEndpoints);

    if (changedEndpoints.length) {
      const updateObservationActions = yield changedEndpoints
        .map((item) => put(updateRoomObservation(item)));
      yield all([...updateObservationActions]);
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* applyChanges() {
  try {
    const admin_id = yield select(getAdminId);
    const sessId = yield select(getObservationSessionId);

    if (!sessId) {
      // 1 START SESSION
      WebRTCManager.closeUserSession();
      const sessionResponse = yield call(rest.startObservationSession, admin_id);
      yield put(startObservationSessionSuccess(sessionResponse));

      // 2 START OBSERVATION FOR ALL THE ADMITTED ENDPOINTS
      yield call(startObservations, admin_id);

      setTimeout(() => {
        WebRTCManager.login(`${admin_id}`);
      }, 1200);
    } else {
      // 1 END OBSERVATION SESSIONS
      const endObs = call(endObservations, admin_id);
      // 2 START OBSERVATION SESSIONS
      const startObs = call(startObservations, admin_id);
      // 3 UPDATE OBSERVATION SESSIONS
      const updateObs = call(updateObservations);
      // execute all in parallel
      yield all([endObs, startObs, updateObs]);
    }
    yield put(applyChangesSuccess({ status: 'updated' }));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* endObservationSession() {
  try {
    const applyiedEndpoints = yield select(getAppliedEndpoints);
    const removedEndpoints = yield select(getRemovedEndpoints);
    const arrAE = Object.values({ ...applyiedEndpoints, ...removedEndpoints });
    const adminId = yield select(getAdminId);

    if (arrAE.length && adminId) {
      const endObservationActions = yield arrAE.map((item) => {
        WebRTCManager.directUnlink(`camera_${item.machine_name}`, adminId, { calibrateMotion: false });
        return call(endRoomObservation, item);
      });
      yield all([...endObservationActions]);
    }
    WebRTCManager.closeUserSession();
    yield put(cleanUpSession());
    yield console.log('FINISH "END SESSION"');
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* cleanUpStoredSession() {
  try {
    const applyiedEndpoints = yield select(getAppliedEndpoints);
    const removedEndpoints = yield select(getRemovedEndpoints);
    const arrAE = Object.values({ ...applyiedEndpoints, ...removedEndpoints });
    const adminId = yield select(getAdminId);

    if (arrAE.length && adminId) {
      const endObservationActions = yield arrAE.map((item) => (
        WebRTCManager.directUnlink(`camera_${item.machine_name}`, adminId, { calibrateMotion: false })
        // return call(endRoomObservation, item);
      ));
      yield all([...endObservationActions]);
      WebRTCManager.closeUserSession();
      yield put(cleanUpSession());
      yield console.log('FINISH "CLEAN UP SESSION"');
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* logout() {
  try {
    yield call(endObservationSession);
    yield put(cleanUpDataOnLogout());
    yield console.log('FINAL LOGOUT STEP ............');
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* fetchIObserverControllerSettings() {
  try {
    const response = yield call(rest.getIObserverControllerSettings);
    if (response) {
      yield put(fetchIObserverControllerSettingsSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getAppVersion() {
  try {
    yield put(checkVersion());
    const response = yield call(rest.getAppVersion);
    if (response) {
      yield put(fetchAppVersionResult(response));
    } else {
      yield put(fetchAppVersionResult({ error: 'Fetching version failed.' }));
    }
  } catch (error) {
    yield put(fetchAppVersionResult({ error }));
  }
}

export function* actionsWatcher() {
  yield takeEvery(APPLY_CHANGES, applyChanges);
  yield takeEvery(END_OBSERVATION_SESSION_REQUEST, endObservationSession);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(CLEAN_UP_SESSION_AFTER_TRANSFER, cleanUpStoredSession);
  yield takeEvery(START_OBSERVATIONS, startObservations);
  yield takeEvery(FETCH_IOBSERVER_CONTROLER_SETTINGS_REQUEST, fetchIObserverControllerSettings);
  yield takeEvery(GET_BE_APP_VERSION, getAppVersion);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
  ]);
}

if (process.env.NODE_ENV === 'test') {
  tests.startObservations = startObservations;
  tests.endRoomObservation = endRoomObservation;
  tests.endObservations = endObservations;
  tests.updateObservations = updateObservations;
  tests.applyChanges = applyChanges;
  tests.endObservationSession = endObservationSession;
  tests.cleanUpStoredSession = cleanUpStoredSession;
  tests.logout = logout;
  tests.fetchIObserverControllerSettings = fetchIObserverControllerSettings;
  tests.getAppVersion = getAppVersion;
}

export { tests };
