/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Select,
  Row,
  Col,
  Button,
  Input,
  Divider,
} from 'antd';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { layoutNames } from '@constants/LayoutSettings';
import {
  INIT_ENDPOINT,
} from '@constants/Settings';

import {
  getSelectedEndpoints,
  getPatientRisksList,
  getObservationRisksList,
  getSelectedEndpointObservation,
  getPatientRoomStatusList,
} from '@selectors/iObserverSelectors';

import {
  changeEndpointProp,
  fetchPatientRisksList,
  fetchObservationRisksList,
  fetchPatientRoomStatusList,
  fetchPatientRoomStatusListSuccess,
  removeRoomStatus,
} from '@actions/iObserverActions/EndpointActions';
import {
  applyChanges,
} from '@actions/iObserverActions/GlobalActions';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import lowRiskImg from '../assets/images/22x22-indicator-green.png';
import medRiskImg from '../assets/images/22x22-indicator-yellow.png';
import hiRiskImg from '../assets/images/22x22-indicator-red.png';
import IntlMessages from '../util/IntlMessages';
import { setSelectedObservation } from '../appRedux/actions';

const { Option } = Select;

export class Patient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeEndpoint = this.onChangeEndpoint.bind(this);
    this.onChangeEndpointProp = this.onChangeEndpointProp.bind(this);
    this.renderEndpointsList = this.renderEndpointsList.bind(this);
    this.setSelectedEndpoint = this.setSelectedEndpoint.bind(this);
    this.renderRiskPriority = this.renderRiskPriority.bind(this);
    this.hasSelectedEndpoint = this.hasSelectedEndpoint.bind(this);

    this.state = this.hasSelectedEndpoint()
      ? {
        ...this.props.selectedEndpoint.patientInfo,
        customRoomStatus: '',
        roomStatusError: '',
      } : {
        ...INIT_ENDPOINT.patientInfo,
        customRoomStatus: '',
        roomStatusError: '',
      };
    // console.log('CALL RISKS');
    this.props.fetchPatientRisksList();
    this.props.fetchObservationRisksList();
    this.props.fetchPatientRoomStatusList();

    this.applyBtnPatient = React.createRef();

    this.throttleHandleChangeFns = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props
      && prevProps
      && this.props.selectedEndpoint
      && prevProps.selectedEndpoint
      && this.props.selectedEndpoint.id
      && this.props.selectedEndpoint.id !== prevProps.selectedEndpoint.id) {
      this.setSelectedEndpoint();
    }
  }

  onChangeEndpoint(machineName) {
    const { selectEndpointId: select } = this.props;
    select(machineName);
  }

  onChangeEndpointProp(value, propName) {
    const { selectedEndpoint } = this.props;
    if (this.state[propName] !== undefined) {
      this.setState({ [propName]: value });
    }

    if (!this.throttleHandleChangeFns[propName]) {
      this.throttleHandleChangeFns[propName] = debounce((params) => {
        const { changeEndpointProperty } = this.props;
        changeEndpointProperty(params);
      }, 500);
    }
    this.throttleHandleChangeFns[propName]({
      propName,
      value,
      endpointId: selectedEndpoint && selectedEndpoint.id,
    });
  }

  setSelectedEndpoint() {
    if (this.hasSelectedEndpoint()) {
      this.setState({
        ...this.props.selectedEndpoint.patientInfo,
        ...this.props.selectedEndpoint.patientInfo.dirtyProps,
        id: this.props.selectedEndpoint.id,
      }, () => console.log('UPDATED STATE: ', this.state));
    }
  }

  applyBtnPatientHandler = () => {
    if (this.applyBtnPatient.current) {
      this.applyBtnPatient.current.disabled = true;
      setTimeout(() => {
        if (this.applyBtnPatient.current) {
          this.applyBtnPatient.current.disabled = false;
        }
      }, 2000);
    }
    const { applyChanges: apply } = this.props;
    console.log('CLICK ON APPLY BUTTON');
    apply();
  }

  onRoomStatusChange = (event) => {
    this.setState({
      customRoomStatus: event.target.value,
      roomStatusError: '',
    });
  };

  addRoomStatus = () => {
    const { roomStatusList, fetchPatientRoomStatusListSuccessProp } = this.props;
    const { customRoomStatus } = this.state;
    const statusExists = roomStatusList.some((status) => status.text === customRoomStatus);
    if (customRoomStatus === '') {
      return;
    }
    if (!statusExists) {
      const roomStatus = [
        {
          code: customRoomStatus,
          text: customRoomStatus,
          removable: true,
        },
      ];
      fetchPatientRoomStatusListSuccessProp(roomStatus);
      this.setState({
        customRoomStatus: '',
      });
    } else {
      this.setState({
        roomStatusError: 'This status already exists!',
      });
    }
  };

  removeRoomStatus = (e, roomStatus) => {
    const { removeRoomStatusProp } = this.props;
    const { patient_room_status } = this.state;
    e.stopPropagation();
    removeRoomStatusProp(roomStatus);
    if (patient_room_status === roomStatus) {
      this.onChangeEndpointProp('', 'patient_room_status');
    }
  }

  loadPatientOptions() {
    const options = [];
    for (let i = 0; i < this.props.observationRisks.length; i += 1) {
      const { code, text } = this.props.observationRisks[i];
      options.push(<Option key={code} value={code}>{text}</Option>);
    }
    return options;
  }

  renderRiskIcon = (riskPriority) => {
    let imgData = lowRiskImg;
    switch (riskPriority) {
      case 2:
        imgData = medRiskImg;
        break;
      case 3:
        imgData = hiRiskImg;
        break;
      default:
        break;
    }

    return (<img style={{ marginRight: '7px' }} src={imgData} alt={riskPriority ? riskPriority.text : 'risk'} />);
  }

  sortEndpoints = (endpoints) => {
    const arr = Object.values(endpoints);
    if (arr.length > 1) {
      arr.sort((a, b) => (a.ordering_idx > b.ordering_idx ? 1 : -1));
    }
    return arr;
  }

  hasSelectedEndpoint() {
    const { selectedEndpoint } = this.props;

    return selectedEndpoint && Object.keys(selectedEndpoint).length;
  }

  renderRiskPriority() {
    const optionRiskL = [];
    for (let i = 0; i < this.props.patientRisks.length; i += 1) {
      const { code, text } = this.props.patientRisks[i];
      optionRiskL.push(
        <Option key={code} value={code}>
          <div className="patient-risk-priority-container">
            {this.renderRiskIcon(code)}
            {text}
          </div>
        </Option>,
      );
    }
    return optionRiskL;
  }

  renderRoomStatusList() {
    const roomStatusRiskList = [];
    for (let i = 0; i < this.props.roomStatusList.length; i += 1) {
      const { code, text } = this.props.roomStatusList[i];
      roomStatusRiskList.push(
        <Option key={code} value={code}>
          <div className="room-status-option-container">
            <IntlMessages defaultMessage={text} id={`patient.stickers.${code}`} />
            {this.props.roomStatusList[i]?.removable
              && <CloseOutlined className="remove-room-status" onClick={(e) => this.removeRoomStatus(e, text)} />}
          </div>
        </Option>,
      );
    }
    return roomStatusRiskList;
  }

  renderEndpointsList() {
    const { selectedEndpoint, selectedEndpoints, intl } = this.props;

    const endpoints = this.sortEndpoints(selectedEndpoints).map(
      (e) => <Option key={e.id} value={e.machine_name}>{e.name}</Option>,
    );

    console.log('Select Patient: ', selectedEndpoints);
    console.log('Selected Patient: ', selectedEndpoint);
    const selEndpoint = selectedEndpoint && selectedEndpoint.machine_name;

    return (
      <Select
        placeholder={intl.formatMessage({ id: 'patient.selectPatient' })}
        disabled={!selectedEndpoints || !selectedEndpoints.length}
        value={selEndpoint}
        className="patient-select"
        onChange={(option) => { this.onChangeEndpoint(option); }}
        getPopupContainer={() => document.getElementById('inner-wrapper-patient')}
      >
        {endpoints}
      </Select>
    );
  }

  render() {
    const { selectedLayout } = this.props;
    const { roomStatusError } = this.state;
    const hasSelEndpoint = this.hasSelectedEndpoint();
    return (
      <div className="gx-mx-0 view-wrapper gx-m-2">
        <div id="inner-wrapper-patient" className="inner-wrapper-patient">
          <h1 className="inner-heading gx-m-2">
            <IntlMessages id="common.heading.patient" />
          </h1>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 12 : 24} className="gx-px-2">
              <Row className="row-style" gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {this.renderEndpointsList()}
                </Col>
              </Row>
              {/* BED NAME */}
              <Row className="row-style" gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    onChange={(event) => { this.onChangeEndpointProp(event.target.value, 'bed_name'); }}
                    className="patient-input"
                    placeholder={this.props.intl.formatMessage({ id: 'patient.bedName' })}
                    value={this.state.bed_name}
                    disabled={!hasSelEndpoint}
                  />
                </Col>
              </Row>
              {/* PATIENT NAME */}
              <Row className="row-style" gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    className="patient-input"
                    placeholder={this.props.intl.formatMessage({ id: 'patient.name' })}
                    onChange={(event) => { this.onChangeEndpointProp(event.target.value, 'patient_name'); }}
                    value={this.state.patient_name}
                    disabled={!hasSelEndpoint}
                  />
                </Col>
              </Row>
              {/* NOTES */}
              <Row className="row-style" gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    className="patient-input"
                    placeholder={this.props.intl.formatMessage({ id: 'patient.notes' })}
                    onChange={(event) => { this.onChangeEndpointProp(event.target.value, 'notes'); }}
                    value={this.state.notes}
                    disabled={!hasSelEndpoint}
                  />
                </Col>
              </Row>
            </Col>
            {/* PROFILE PROPS */}
            <Col xs={24} sm={24} md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 12 : 24} className="gx-px-2">
              <Row className="row-style" gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h3>
                    <IntlMessages id="common.heading.profile" />
                  </h3>
                </Col>
              </Row>
              {/* RISK GRADE */}
              <Row className="row-style" gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Select
                    className="risk"
                    placeholder={this.props.intl.formatMessage({ id: 'patient.risk_level' })}
                    value={this.state.risk_priority}
                    onChange={(selection) => { this.onChangeEndpointProp(selection, 'risk_priority'); }}
                    disabled={!hasSelEndpoint}
                    getPopupContainer={() => document.getElementById('inner-wrapper-patient')}
                  >
                    {this.renderRiskPriority()}
                  </Select>
                </Col>
              </Row>
              {/* RISK TYPE */}
              <Row className="row-style" gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>
                    <IntlMessages id="patient.obs_reasons" />
                  </p>
                  <Select
                    mode="multiple"
                    className="risks-list"
                    placeholder={this.props.intl.formatMessage({ id: 'common.select.please' })}
                    onChange={(selection) => { this.onChangeEndpointProp(selection, 'risk_type'); }}
                    value={this.state.risk_type}
                    disabled={!hasSelEndpoint}
                    getPopupContainer={() => document.getElementById('inner-wrapper-patient')}
                  >
                    {this.loadPatientOptions()}
                  </Select>
                </Col>
              </Row>
              <Row gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h3 className="room-status-title">
                    <IntlMessages id="common.heading.roomStatus" />
                  </h3>
                </Col>
              </Row>
              {/* ROOM STATUS */}
              <Row gutter={[16, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="room-status-container">
                    <Select
                      className="room-status-select"
                      placeholder={this.props.intl.formatMessage({ id: 'patient.roomReasons' })}
                      value={this.state.patient_room_status}
                      onChange={(selection) => { this.onChangeEndpointProp(selection, 'patient_room_status'); }}
                      disabled={!hasSelEndpoint}
                      getPopupContainer={() => document.getElementById('inner-wrapper-patient')}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={this.state.customRoomStatus} onChange={this.onRoomStatusChange} />
                            <button
                              className="patient-add-room-status-btn"
                              type="button"
                              onClick={this.addRoomStatus}
                            >
                              <PlusOutlined />
                              <IntlMessages id="patient.addStatus" />
                            </button>
                          </div>
                          {roomStatusError && <p className="patient-room-status-error">{roomStatusError}</p>}
                        </div>
                      )}
                    >
                      {this.renderRoomStatusList()}
                    </Select>
                    <Button
                      className="btn-customer room-status-clear-btn"
                      onClick={() => this.onChangeEndpointProp('', 'patient_room_status')}
                    >
                      <IntlMessages id="patient.clear" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="gx-d-flex gx-justify-content-end btn-customer-panel">
          <Button
            className="btn-customer btn-patient"
            onClick={this.applyBtnPatientHandler}
            ref={this.applyBtnPatient}
            id="applyBtnPatient"
          >
            <IntlMessages id="customers.btnApply" />
          </Button>
        </div>
      </div>
    );
  }
}

Patient.propTypes = {
  intl: PropTypes.shape().isRequired,
  selectedEndpoints: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  patientRisks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  observationRisks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchPatientRisksList: PropTypes.func.isRequired,
  fetchObservationRisksList: PropTypes.func.isRequired,
  fetchPatientRoomStatusList: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.shape(),
  applyChanges: PropTypes.func.isRequired,
  selectEndpointId: PropTypes.func.isRequired,
  selectedLayout: PropTypes.string.isRequired,
  changeEndpointProperty: PropTypes.func.isRequired,
  roomStatusList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchPatientRoomStatusListSuccessProp: PropTypes.func.isRequired,
  removeRoomStatusProp: PropTypes.func.isRequired,
};

Patient.defaultProps = {
  selectedEndpoint: null,
};

export const mapStateToProps = (state) => {
  const patientRisks = getPatientRisksList(state);
  const observationRisks = getObservationRisksList(state);
  const selectedEndpoint = getSelectedEndpointObservation(state);
  const roomStatusList = getPatientRoomStatusList(state);
  const { selectedLayout } = state.monitoringLayout;

  const selected = getSelectedEndpoints(state);

  const arrSelected = [];
  Object.values(selected).forEach((e) => arrSelected.push(e));

  return {
    selectedEndpoints: [...arrSelected],
    selectedEndpoint,
    patientRisks,
    observationRisks,
    selectedLayout,
    roomStatusList,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  selectEndpointId: (machineName) => dispatch(setSelectedObservation(machineName)),
  changeEndpointProperty: (params) => dispatch(changeEndpointProp(params)),
  fetchPatientRisksList: () => dispatch(fetchPatientRisksList()),
  fetchObservationRisksList: () => dispatch(fetchObservationRisksList()),
  fetchPatientRoomStatusList: () => dispatch(fetchPatientRoomStatusList()),
  fetchPatientRoomStatusListSuccessProp:
    (value) => dispatch(fetchPatientRoomStatusListSuccess(value)),
  applyChanges: () => dispatch(applyChanges()),
  removeRoomStatusProp: (roomStatus) => dispatch(removeRoomStatus(roomStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Patient)));
