import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter, } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Col, Row } from 'antd';
import CustomTopbar from '@containers/Topbar/CustomTopbar';
import Customers from '@routes/Customers';
import Patient from '@routes/Patient';
import Alert from '@routes/Alert';
import Layouts from '@routes/Layouts';
import Session from '@routes/Session';
import MyCamera from '@routes/MyCamera';
import InnerLayoutWrapper from '@components/layouts/InnerLayoutWrapper';
import Reports from '@routes/Reports';
import { getAudioVideoRequest } from '@actions/iObserverActions/ObservationActions';
import { fetchIObserverControllerSettings } from '@actions/Common';
import { getIObserverControllerWsAddresses } from '@selectors/commonSelectors';
import WebRTCManager from '@util/WebRTC/WebRTCManager';
import { layoutNames } from '@constants/LayoutSettings';
import Version from './Version';

export class App extends React.Component {
  componentDidMount() {
    const {
      getAudioVideo,
      getIObserverControllerSettings,
    } = this.props;
    if (getAudioVideo) {
      getAudioVideo();
      getIObserverControllerSettings();
    }
    document.addEventListener('keydown', this.attachKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { iObserverControllerWsAddresses } = this.props;
    if (!prevProps.iObserverControllerWsAddresses && iObserverControllerWsAddresses) {
      WebRTCManager.connect(iObserverControllerWsAddresses);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.attachKeyDown);
  }

  getColSpan(container) {
    let span;
    const { selectedLayout, location, navCollapsed } = this.props;

    if (selectedLayout === layoutNames.DEFAULT_LAYOUT || navCollapsed) {
      span = container === 'layout' ? 24 : 0;
    } else if (selectedLayout === layoutNames.LAYOUT_3_ROOMS_LEFT
      || selectedLayout === layoutNames.LAYOUT_3_ROOMS_RIGHT) {
      span = container === 'layout' ? 24 : 0;
    } else if (selectedLayout === layoutNames.LAYOUT_6_ROOMS_LEFT
      || selectedLayout === layoutNames.LAYOUT_6_ROOMS_RIGHT
      || selectedLayout === layoutNames.LAYOUT_6_ROOMS_SYMMETRIC
      || selectedLayout === layoutNames.LAYOUT_6_ROOMS_EQUAL) {
      span = container === 'layout' ? 24 : 0;
    } else if (selectedLayout === layoutNames.LAYOUT_12_ROOMS_LEFT
      || selectedLayout === layoutNames.LAYOUT_12_ROOMS_EQUAL) {
      span = container === 'layout' ? 24 : 0;
    } else if (location.pathname === '/app/layouts') {
      span = container === 'layout' ? 16 : 8;
    } else if (location.pathname !== '/app/layouts') {
      span = container === 'layout' ? 16 : 8;
    }

    return span;
  }

  attachKeyDown = (zEvent) => {
    if ((zEvent.ctrlKey || zEvent.metaKey) && zEvent.altKey && (zEvent.code === 'KeyK' || zEvent.code === 'Keyk')) {
      const { selectedLayout } = this.props;
      const element = document.getElementById(selectedLayout === layoutNames.DEFAULT_LAYOUT ? 'default-inner-wrapper' : 'main-content');
      try {
        if (element.requestFullscreen) {
          element.requestFullscreen().catch(() => {
            console.log('[kiosk] fullscreen');
          });
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen().catch((e) => {
            console.log('[kiosk] fullscreen', e);
          });
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      } catch (error) {
        console.warn('[kiosk] fullscreen', error);
      }
    }
  }

  render() {
    const {
      navCollapsed, profile,
    } = this.props;
    const { username } = profile || {};
    return (
      <div className="gx-main-content-wrapper">
        <Row className="central-view-wrapper">
          <Col className="routeContentCol" span={this.getColSpan('main')}>
            <CustomTopbar username={username} />
            <div className="main-content-holder">
              <Switch>
                <Route exact path="/">
                  <Redirect to="app/customers" />
                </Route>
                <Route exact path="/app">
                  <Redirect to="customers" />
                </Route>
                <Route path="app/customers" component={Customers} />
                <Route path="app/patient" component={Patient} />
                <Route path="app/alert" component={Alert} />
                <Route path="app/layouts" component={Layouts} />
                <Route path="app/session" component={Session} />
                <Route path="app/my-camera" component={MyCamera} />
                <Route path="app/reports" component={Reports} />
                <Route path="app/version" component={Version} />
              </Switch>
            </div>
          </Col>
          <Col id="default-inner-wrapper" span={this.getColSpan('layout')} className="default-inner-wrapper">
            <InnerLayoutWrapper navCollapsed={navCollapsed} />
          </Col>
        </Row>
      </div>
    );
  }
}

App.defaultProps = {
};

App.propTypes = {
  profile: PropTypes.shape().isRequired,
  selectedLayout: PropTypes.string.isRequired,
  navCollapsed: PropTypes.bool.isRequired,
  getAudioVideo: PropTypes.func.isRequired,
  getIObserverControllerSettings: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  iObserverControllerWsAddresses: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    monitoringLayout,
    settings,
    profile,
    observation,
  } = state;

  return {
    audioVideo: observation.audioVideo,
    selectedLayout: monitoringLayout.selectedLayout,
    navCollapsed: settings.navCollapsed,
    profile: profile.profile,
    iObserverControllerWsAddresses: getIObserverControllerWsAddresses(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  getIObserverControllerSettings: () => dispatch(fetchIObserverControllerSettings()),
  getAudioVideo: () => dispatch(getAudioVideoRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(App)));
