import React from 'react';
import {
  Row, Col, Button,
} from 'antd';
import { dashboardUrl } from '@util/config';
import IntlMessages from '@util/IntlMessages';

const Reports = () => {
  const jwt = localStorage.getItem('accessToken');

  const writeUrlOnConsole = () => {
    console.log(`${dashboardUrl() + jwt}`);
  };

  return (
    <div className="gx-m-3">
      <div className="inner-wrapper">
        <h1 className="inner-heading">
          <IntlMessages id="iobReports.iobReport" />
        </h1>
        <Row>
          <Col sm={24} md={24} lg={24} xl={24}>
            <Button
              className="dashboard-btn gx-py-1"
              href={dashboardUrl() + jwt}
              target="_blank"
              onClick={writeUrlOnConsole}
            >
              <IntlMessages id="iobReports.btnLaunchDashboard" />
            </Button>
            <p className="txt-iobserver">
              <IntlMessages id="iobReports.txtViewReporting" />
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default React.memo(Reports);
