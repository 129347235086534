import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onNavStyleChange, toggleCollapsedSideNav } from '@actions/Setting';
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '@constants/ThemeSetting';
import PropTypes from 'prop-types';

const SidebarLogo = (props) => {
  const dispatch = useDispatch();
  const { themeType, navCollapsed, navStyle } = useSelector(({ settings }) => settings);

  const { intl } = props;
  return (
    <div className="gx-layout-sider-header">
      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR)
        ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            role="button"
            className="gx-linebar"
            tabIndex="-1"
            onClick={() => {
              if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          >
            <span className="menu-logo">{intl.formatMessage({ id: 'menu' })}</span>
            <i
              className={`icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'chevron-right' : 'chevron-left'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
            />
          </div>
        ) : null}
    </div>
  );
};

SidebarLogo.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default SidebarLogo;
