import {
  CUSTOMERS_FETCH_DATA,
  CUSTOMERS_FETCH_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
} from '@constants/iObserverActionTypes';

/**
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const customersOnFetchData = (page, sorting, filter) => ({
  type: CUSTOMERS_FETCH_DATA,
  page,
  sorting,
  filter,
});

/**
 * @param  {Object} data
 * @return {Object}
 */
export const customersFetchDataSuccess = (data) => ({
  type: CUSTOMERS_FETCH_SUCCESS,
  data,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchCustomer = (payload) => ({
  type: FETCH_CUSTOMER_REQUEST,
  id: payload.id,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchCustomerSuccess = (payload) => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload,
});
