import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ENDPOINT_STATUS_OFFLINE } from '@constants/EndpointAvailabilityStatusTypes';
import { connect } from 'react-redux';
import { getAudioVideo } from 'src/appRedux/selectors/iObserverSelectors';
import IntlMessages from 'src/util/IntlMessages';
import EndpointVideoTitle from './EndpointVideoTitle/EndpointVideoTitle';
import EndpointVideoElement from './EndpointVideoElement/EndpointVideoElement';
import SecondsCounter from './SecondsCounter/SecondsCounter';

const DELAY_DROP_OVER_SELF_TIMEOUT = 10000; // 10 sec

class EndpointVideo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { dragOver: false, errorMessage: null };
    this.delayDragOverOffTimer = 0;
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    this.setErrorMessage();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      endpointData, stream, intl, endpointStats,
    } = this.props;

    if (endpointData && (!prevProps || !prevProps.endpointData
      || prevProps.endpointData.machine_name !== endpointData.machine_name)) {
      this.setErrorMessage();
    } else if (endpointData) {
      if (endpointData.availabilityStatus !== prevProps.endpointData.availabilityStatus
        && endpointData.availabilityStatus === ENDPOINT_STATUS_OFFLINE) {
        this.updateErrorMessage(intl.formatMessage({ id: 'warnings.endpointOfflineDetected' }));
      } else if (endpointData.mediaType !== prevProps.endpointData.mediaType
        && endpointData.mediaType !== 'audioVideo') {
        this.updateErrorMessage(intl.formatMessage({ id: 'warnings.endpointMediaNotConnected' }));
      } else if (!stream && prevProps && prevProps.stream) {
        this.updateErrorMessage(intl.formatMessage({ id: 'warnings.endpointConnecting' }));
      } else if (prevState && prevState.errorMessage
        && !(endpointData.availabilityStatus === ENDPOINT_STATUS_OFFLINE
          || endpointData.mediaType !== 'audioVideo' || !stream)) {
        this.updateErrorMessage(null);
      }
    }

    if (!stream && endpointData && endpointData.activityStatus
      && !endpointData.activityStatus.loading
      && (prevProps && prevProps.endpointData && prevProps.endpointData.activityStatus
        && prevProps.endpointData.activityStatus.loading)) {
      this.checkEndpointStatus(endpointData.activityStatus);
    }

    if (stream && endpointStats && endpointStats.result) {
      const prevLowFpsStartTime = prevProps && prevProps.endpointStats
        && prevProps.endpointStats.result && prevProps.endpointStats.result.lowFpsStartTime;
      const prevLowResolutionStartTime = prevProps && prevProps.endpointStats
        && prevProps.endpointStats.result && prevProps.endpointStats.result.lowResolutionStartTime;

      if (endpointStats.result.lowFpsStartTime !== prevLowFpsStartTime) {
        this.setupLowFpsStartTimer(endpointStats.result.lowFpsStartTime);
      } else if (endpointStats.result.lowResolutionStartTime !== prevLowResolutionStartTime) {
        this.updateErrorMessage(endpointStats.result.lowResolutionStartTime
          ? intl.formatMessage({ id: 'warnings.endpointLowResolution' }) : null);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayDragOverOffTimer);
    this.delayDragOverOffTimer = 0;
  }

  setErrorMessage = () => {
    const {
      endpointData, stream, endpointStats, intl,
    } = this.props;

    this.updateErrorMessage(null);
    if (endpointData && endpointData.availabilityStatus === ENDPOINT_STATUS_OFFLINE) {
      this.updateErrorMessage(intl.formatMessage({ id: 'warnings.endpointOfflineDetected' }));
    } else if (endpointData && endpointData.mediaType !== 'audioVideo') {
      this.updateErrorMessage(intl.formatMessage({ id: 'warnings.endpointMediaNotConnected' }));
    } else if (endpointData && !stream) {
      this.checkEndpointStatus(endpointData.activityStatus);
    } else if (stream && endpointStats && endpointStats.result) {
      if (endpointStats.result.lowFpsStartTime) {
        this.setupLowFpsStartTimer(endpointStats.result.lowFpsStartTime);
      } else if (endpointStats.result.lowResolutionStartTime) {
        this.updateErrorMessage(intl.formatMessage({ id: 'warnings.endpointLowResolution' }));
      }
    }
  };

  onKeyPressed = (event) => {
    if (event.charCode === 13) {
      this.selectEndpoint();
    }
  }

  setupLowFpsStartTimer = (lowFpsStartTime) => {
    const { intl } = this.props;

    this.updateErrorMessage(lowFpsStartTime
      ? intl.formatMessage({ id: 'warnings.endpointLowFrameRate' }) : null);
  }

  checkEndpointStatus = (activityStatus) => {
    const { intl } = this.props;

    if (!activityStatus) {
      this.setState({ errorMessage: intl.formatMessage({ id: 'warnings.endpointConnecting' }) });
      return;
    }

    if (activityStatus.version === undefined) {
      this.setState({ errorMessage: intl.formatMessage({ id: 'warnings.endpointFailure' }) });
    } else {
      this.setState({ errorMessage: intl.formatMessage({ id: 'warnings.endpointDisconnected' }) });
    }
  }

  updateErrorMessage = (errorMessage) => {
    this.setState({ errorMessage });
  };

  goToPoint = (x, y, zoom) => {
    const { callbacks, endpointData } = this.props;
    if (endpointData && callbacks && callbacks.goToPoint) {
      callbacks.goToPoint(endpointData.machine_name, x, y, zoom);
    }
  }

  zoom = (stop, isZoomIn) => {
    const { callbacks, endpointData } = this.props;
    if (endpointData && callbacks && callbacks.zoom) {
      callbacks.zoom(endpointData.machine_name, stop, isZoomIn);
    }
  }

  onDragStart = (e) => {
    const { index, disableDnd } = this.props;
    console.log('[dnd] onDragStart', e.target.className, index, disableDnd);

    if (disableDnd) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    e.dataTransfer.effectAllowed = 'copy';
    // e.dataTransfer.dropEffect = 'copy';
    e.dataTransfer.dropEffect = 'none';
    e.dataTransfer.setData('text/plain', index);
    e.dataTransfer.setData('className', e.target.className);
    // e.stopPropagation();
    return false;
  };

  onDragEnter = (e) => {
    if (e.dataTransfer.types.includes('classname')) {
      this.setDragOver(true);
      e.dataTransfer.dropEffect = 'copy';
    }
  };

  onDragLeave = () => {
    this.setDragOver(false);
  };

  onDragEnd = () => {
    this.setDragOver(false);
  };

  onDragOver = (e) => {
    e.preventDefault();
    const { disableDnd } = this.props;
    if (disableDnd) {
      e.dataTransfer.dropEffect = 'none';
      e.stopPropagation();
    } else {
      this.onDragEnter(e);
    }
    return false;
  }

  setDragOver = (dragOverToSet, toDelayOff) => {
    if (this.delayDragOverOffTimer) {
      if (!dragOverToSet) {
        return;
      }

      clearTimeout(this.delayDragOverOffTimer);
      this.delayDragOverOffTimer = 0;
    }

    if (dragOverToSet && toDelayOff) {
      this.delayDragOverOffTimer = setTimeout(() => {
        this.setState({ dragOver: false });
      }, DELAY_DROP_OVER_SELF_TIMEOUT);
      this.setState({ dragOver: true });
      return;
    }
    const { dragOver } = this.state;
    if (dragOver !== dragOverToSet) {
      this.setState({ dragOver: dragOverToSet });
    }
  }

  onDrop = (e) => {
    const {
      endpointData, callbacks, index, disableDnd,
    } = this.props;
    if (disableDnd) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    const className = e.dataTransfer.getData('className');
    console.log('[dnd] onDrop', e, endpointData, index);

    if (callbacks.swapEndpointsData && className.includes('endpoint-video')) {
      const from = parseInt(e.dataTransfer.getData('text'), 10);
      if (from === index) {
        this.setDragOver(true, true);
        e.stopPropagation();
        return false;
      }
      callbacks.swapEndpointsData(from, index);
    }
    this.onDragLeave();
    e.stopPropagation();
    return false;
  }

  selectEndpoint(pinned = false) {
    const { selectEndpoint, endpointData, selectedEndpoint } = this.props;
    if (endpointData && selectEndpoint) {
      if (!pinned) {
        if (!selectedEndpoint || selectedEndpoint.machine_name !== endpointData.machine_name) {
          selectEndpoint(endpointData.machine_name);
        }
      } else {
        selectEndpoint(endpointData.machine_name, true);
      }
    }
  }

  render() {
    const {
      endpointData, mediaId, selectedEndpoint, stream, refreshCamera, makeCall, hasCall, disableDnd,
      endpointStats, speakerId,
    } = this.props;
    const { dragOver, errorMessage } = this.state;
    const isSelected = endpointData && selectedEndpoint
      && selectedEndpoint.machine_name === endpointData.machine_name;
    let className = `endpoint-video${isSelected ? ' selected' : ''}`;
    const isListen = endpointData && endpointData.listen;
    const isTalk = endpointData && endpointData.talk;
    if (isListen || isTalk) {
      className += ' listen-talk';
    }
    if (endpointData && endpointData.playAlert) {
      className += ' alert';
    }
    if (dragOver && !disableDnd) {
      className += ' dragOver';
    }
    const errorStartTime = !!errorMessage && stream && endpointStats && endpointStats.result
      && endpointStats.result.lowFpsStartTime;

    return (
      <div
        role="button"
        tabIndex="-1"
        className={className}
        onClick={() => this.selectEndpoint()}
        onKeyPress={(e) => this.onKeyPressed(e)}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragEnd}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        {!!(endpointData && mediaId)
          && (
            <>
              <div className="endpoint-video-title-container" ref={this.titleRef}>
                <EndpointVideoTitle
                  isSelected={isSelected || disableDnd}
                  endpointData={endpointData}
                  refreshCamera={refreshCamera}
                  makeCall={makeCall}
                  hasCall={!!hasCall}
                  onDragStart={this.onDragStart}
                  draggable={!disableDnd}
                  selectPinnedEndpoint={() => this.selectEndpoint(true)}
                  titleWidth={this.titleRef.current ? this.titleRef.current.clientWidth : 0}
                />
              </div>

              <EndpointVideoElement
                stream={stream}
                goToPoint={this.goToPoint}
                zoom={this.zoom}
                muted={!(isListen || isTalk)}
                speakerId={speakerId}
              />
              {endpointData.patientInfo.patient_room_status
                && (
                  <div className="room-status-sticker">
                    <p className="room-status-sticker-text">
                      <IntlMessages
                        id={`patient.stickers.${endpointData.patientInfo.patient_room_status}`}
                        defaultMessage={endpointData.patientInfo.patient_room_status}
                      />
                    </p>
                  </div>
                )}
              {!!errorMessage && (
                <div className="endpoint-video-error">
                  {errorMessage}
                  {!!errorStartTime && (
                    <SecondsCounter startDateTime={errorStartTime} />
                  )}
                </div>
              )}
            </>
          )}
      </div>
    );
  }
}

EndpointVideo.defaultProps = {
  endpointData: null,
  endpointStats: null,
  stream: null,
  mediaId: null,
  selectedEndpoint: null,
  selectEndpoint: null,
  hasCall: false,
  callbacks: {},
  disableDnd: false,
  index: -1,
  speakerId: '',
};

EndpointVideo.propTypes = {
  endpointData: PropTypes.shape({
    machine_name: PropTypes.string,
    listen: PropTypes.bool,
    talk: PropTypes.bool,
    playAlert: PropTypes.bool,
    activityStatus: PropTypes.shape({ loading: PropTypes.bool }),
    availabilityStatus: PropTypes.string,
    mediaType: PropTypes.string,
    patientInfo: PropTypes.shape({ patient_room_status: PropTypes.string }),
  }),
  endpointStats: PropTypes.shape({
    result: PropTypes.shape(),
  }),
  stream: PropTypes.shape({ id: PropTypes.string }),
  mediaId: PropTypes.string,
  selectEndpoint: PropTypes.func,
  selectedEndpoint: PropTypes.shape(),
  refreshCamera: PropTypes.func.isRequired,
  makeCall: PropTypes.func.isRequired,
  hasCall: PropTypes.bool,
  callbacks: PropTypes.shape(),
  disableDnd: PropTypes.bool,
  index: PropTypes.number,
  intl: PropTypes.shape().isRequired,
  speakerId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  speakerId: getAudioVideo(state).speakerId,
});

export { EndpointVideo as EndpointVideoTest };
export default connect(mapStateToProps)(injectIntl(EndpointVideo));
