export const LISTINGS_PAGE_SIZE = 20;
export const INPUT_DEBOUNCE_MILLSEC = 400;
export const SEARCH_MIN_CHAR_LENGTH = 3;

export const ACCESS_TOKEN_PARAM = 'access_token';
export const REFRESH_TOKEN_PARAM = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const CURRENT_USER_KEY = 'ownUserProfile';
export const IMPERSONATE_TOKEN = 'impersonate_token';
export const INVITE_TOKEN = 'invite_token';

export const HTTP_ERROR_MESSAGES = {
  400: 'bad_request',
  403: 'forbidden',
  404: 'not_found',
  409: 'already_exists',
  500: 'server_error',
  502: 'bad_gateway',
  503: 'service_not_available',
  504: 'gateway_timeout',
};

export const FILTER_PAGE_SIZE = 10000;

export const ENDPOINT_STATUSES = {
  ADMITTED: 'admitted',
  APPLIED: 'applied',
  REMOVED: 'removed',
};
export const INIT_ENDPOINT = {
  id: '',
  machine_name: '',
  name: '',
  status: '',
  selected: false,
  nightView: false,
  listen: false,
  talk: false,
  playAlert: false,
  activityStatus: null,
  availabilityStatus: null,
  mediaType: null,
  patientInfo: {
    dirty: false,
    dirtyProps: {},
    bed_name: '',
    patient_name: '',
    notes: '',
    risk_priority: '',
    risk_type: [],
    notification_contacts: [],
    alertSelectedNumber: '',
    alertSelectedType: '',
    patient_room_status: '',
  },
  ordering_idx: null,
};

export const CONTROL_COMMANDS = {
  GO_TO_BOOKMARK: 'go_to_bookmark',
  START_PLAY_ALERT: 'play_alert',
  STOP_PLAY_ALERT: 'stop_play_alert',
  START_OBSERVER: 'start_observer',
  END_OBSERVER: 'end_observer',
  PAN: 'pan',
  STOP: 'stop',
  ZOOM: 'zoom',
  NIGHT_VIEW: 'night_view',
  GO_TO_POINT: 'go_to_point', // double click on video
  SET_AS_BOOKMARK: 'set_as_bookmark',
  REATTACH_VIDEO: 'reattach_video',
  TRANSFER_INFO: 'transfer_info',
  REQUEST_TRANSFER_INFO: 'request_transfer_info',
};

export const PARAMS_PER_COMMAND = {
  go_to_bookmark: {
    bookmark: { bookmark: 'esitter_base' },
    home: { bookmark: 'esitter_home' },
  },
  stop: null,
  stop_play_alert: {
    esitter: 'yes',
  },
  pan: {
    dir: {
      up: { dir: 'up' },
      upright: { dir: 'upright' },
      right: { dir: 'right' },
      downright: { dir: 'downright' },
      down: { dir: 'down' },
      downleft: { dir: 'downleft' },
      left: { dir: 'left' },
      upleft: { dir: 'upleft' },
    },
  },
  zoom: {
    tele: { dir: 'tele' }, // zoom in
    wide: { dir: 'wide' }, // zoom out
    stop: { dir: 'stop' }, // stop zoom

  },
  night_view: {
    on: { on: 'true' },
    off: { on: 'false' },
  },
  go_to_point: {
    zoom_val_false: {
      x_val: '', // -0.194169943767799,
      y_val: '', // 0.0520573246326282,
      zoom_val: 'false',
    },
    zoom_val_true: {
      x_val: '', // -0.194169943767799,
      y_val: '', // 0.0520573246326282,
      zoom_val: 'true',
    },
  },
  set_as_bookmark: {
    b_name: 'esitter_base',
  },

};

export const PAN_DIRECTIONS = {
  UP: 'up',
  UPRIGHT: 'upright',
  RIGHT: 'right',
  DOWNRIGHT: 'downright',
  DOWN: 'down',
  DOWNLEFT: 'downleft',
  LEFT: 'left',
  UPLEFT: 'upleft',
};

export const ZOOM_DIRECTIONS = {
  IN: 'in',
  OUT: 'out',
};

export const OBSERVATION_ACTIVITY = {
  TYPES: {
    LISTEN: 'Listen',
    TALK: 'Talk',
    ALERT: 'Alert',
    VIDEO_CALL: 'VideoCall',
  },
  PARAMS: {
    current_admin_id: '',
    machine_name: '',
    a_type: '',
    reason: '',
  },
  REASONS: {
    1: 'Patient attempting to get out of bed/chair or out of bed/chair',
    2: 'Accidental button push',
    3: 'Patient / visitor program education',
    4: '',
    5: '',
  },
};

export const SESSION_MESSAGES = {
  SESSION_BEGIN: 'SESSION_BEGIN',
  SESSION_END: 'SESSION_END',
  SESSION_IMPORTED: 'SESSION_IMPORTED',
};

/**
 * Time in ms for the user to be notified before the session expires
 * @type {number}
 */
export const SESSION_EXPIRATION_TIME_ADVANCE = 10 * 60 * 1000; // 10 min
