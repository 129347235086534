/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import {
  Select, Row, Col, Button,
  Input, Tooltip,
} from 'antd';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { layoutNames } from '@constants/LayoutSettings';

import {
  INIT_ENDPOINT,
} from '@constants/Settings';

import { getSelectedEndpoints, getSelectedEndpointObservation } from '@selectors/iObserverSelectors';

import { changeEndpointProp } from '@actions/iObserverActions/EndpointActions';

import { PlusSquareFilled } from '@ant-design/icons';
import { applyChanges, setSelectedObservation } from '../appRedux/actions';
import IntlMessages from '../util/IntlMessages';

const { Option } = Select;

const alertTypeOptions = [{
  id: 'alert.doctor',
  type: 'provider',
}, {
  id: 'alert.nurse',
  type: 'nurse',
}, {
  id: 'alert.support',
  type: 'supportStaff',
}];
const numberRegex = /^\d*$/;
export class Alert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeEndpoint = this.onChangeEndpoint.bind(this);
    this.onChangeEndpointProp = this.onChangeEndpointProp.bind(this);
    this.renderEndpointsList = this.renderEndpointsList.bind(this);
    this.setSelectedEndpoint = this.setSelectedEndpoint.bind(this);
    this.hasSelectedEndpoint = this.hasSelectedEndpoint.bind(this);
    this.state = this.hasSelectedEndpoint() ? {
      ...this.props.selectedEndpoint.patientInfo,
      phoneError: '',
    } : {
      ...INIT_ENDPOINT.patientInfo,
      phoneError: '',
    };

    this.applyBtnAlert = React.createRef();
    this.throttleHandleChangeFns = {};
  }

  componentDidMount() {
    const { alertSelectedNumber } = this.state;
    this.onChangeEndpointProp(alertSelectedNumber, 'alertSelectedNumber');
  }

  componentDidUpdate(prevProps) {
    if (this.props
      && prevProps
      && this.props.selectedEndpoint
      && prevProps.selectedEndpoint
      && this.props.selectedEndpoint.id
      && this.props.selectedEndpoint.id !== prevProps.selectedEndpoint.id) {
      this.setSelectedEndpoint();
    }
  }

  onChangeEndpoint(machineName) {
    const { selectEndpoint } = this.props;
    selectEndpoint(machineName);
  }

  onChangeEndpointProp(value, propName) {
    const { selectedEndpoint } = this.props;
    if (propName === 'alertSelectedNumber' && !numberRegex.test(value)) {
      this.forceUpdate();
      return;
    }

    if (this.state[propName] !== undefined) {
      this.setState({ [propName]: value });
    }

    if (!this.throttleHandleChangeFns[propName]) {
      this.throttleHandleChangeFns[propName] = debounce((params) => {
        const { changeEndpointProperty } = this.props;
        changeEndpointProperty(params);
      }, 500);
    }
    this.throttleHandleChangeFns[propName]({
      propName,
      value,
      endpointId: selectedEndpoint && selectedEndpoint.id,
      skipDirty: propName === 'alertSelectedNumber' || propName === 'alertSelectedType',
    });
  }

  setSelectedEndpoint() {
    if (this.hasSelectedEndpoint()) {
      this.setState({ ...this.props.selectedEndpoint.patientInfo, id: this.props.selectedEndpoint.id }, () => console.log('UPDATED STATE: ', this.state));
    }
  }

  formatPhoneNumber = (str) => {
    const cleaned = `${str}`.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return null;
  };

  checkForDuplicates = (phoneNumber) => {
    const { notification_contacts } = this.state;
    return notification_contacts.some((contact) => contact.phone_number === phoneNumber);
  }

  applyBtnAlertHandler = () => {
    if (this.applyBtnAlert.current) {
      this.applyBtnAlert.current.disabled = true;
      setTimeout(() => {
        if (this.applyBtnAlert.current) {
          this.applyBtnAlert.current.disabled = false;
        }
      }, 2000);
    }
    const { applyChangesProp } = this.props;
    applyChangesProp();
  }

  getMedicianTitleByType = (type, intl) => {
    const option = alertTypeOptions.find((opt) => opt.type === type);

    if (option) {
      return intl.formatMessage({ id: option.id });
    }

    return intl.formatMessage({ id: alertTypeOptions[0] });
  }

  loadNotificationOptions = (intl) => (
    alertTypeOptions.map((option) => (
      <Option key={option.type} value={option.type}>{intl.formatMessage({ id: option.id })}</Option>
    ))
  );

  addNumber = () => {
    const { changeEndpointProperty, selectedEndpoint, intl } = this.props;
    const { alertSelectedType, alertSelectedNumber, notification_contacts } = this.state;
    const formatedPhoneNumber = this.formatPhoneNumber(alertSelectedNumber);
    if (this.checkForDuplicates(formatedPhoneNumber)) {
      this.setState({ phoneError: intl.formatMessage({ id: 'alert.phoneExists' }) });
      return;
    }
    const value = notification_contacts || [];
    const endpointId = selectedEndpoint && selectedEndpoint.id;
    value.push({
      role_type: alertSelectedType || alertTypeOptions[0].type,
      phone_number: formatedPhoneNumber,
    });
    changeEndpointProperty({
      propName: 'notification_contacts',
      value,
      endpointId,
    });
    changeEndpointProperty({
      propName: 'alertSelectedNumber', value: '', endpointId,
    });
    this.setState({ alertSelectedNumber: '', notification_contacts: value });
  }

  removeNumber = (index) => {
    const { changeEndpointProperty, selectedEndpoint } = this.props;

    const { notification_contacts } = this.state;
    const value = notification_contacts.slice();
    value.splice(index, 1);
    const endpointId = selectedEndpoint && selectedEndpoint.id;
    changeEndpointProperty({
      propName: 'notification_contacts', value, endpointId,
    });
    this.setState({ notification_contacts: value });
  }

  handleKeyDown = (e) => {
    const { alertSelectedNumber } = this.state;
    if (e.keyCode === 13 && alertSelectedNumber.length === 10) {
      this.addNumber();
    }
  };

  hasSelectedEndpoint() {
    return this.props.selectedEndpoint && Object.keys(this.props.selectedEndpoint).length;
  }

  renderEndpointsList() {
    const { selectedEndpoint, selectedEndpoints, intl } = this.props;

    const endpoints = selectedEndpoints.sort(
      (a, b) => a.name.localeCompare(b.name),
    ).map(
      (e) => <Option key={e.id} value={e.machine_name}>{e.name}</Option>,
    );
    console.log('Alert:', this.props.selectedEndpoints);
    const selEndpoint = selectedEndpoint && selectedEndpoint.machine_name;

    return (
      <Select
        placeholder={intl.formatMessage({ id: 'patient.selectPatient' })}
        disabled={!selectedEndpoints || !selectedEndpoints.length}
        value={selEndpoint}
        className="patient-select"
        onChange={(option) => { this.onChangeEndpoint(option); }}
        getPopupContainer={() => document.getElementById('inner-wrapper-alert')}
      >
        {endpoints}
      </Select>
    );
  }

  renderNotificationNumbers = () => {
    const hasSelEndpoint = this.hasSelectedEndpoint();
    console.log(hasSelEndpoint);
    if (!hasSelEndpoint) {
      return null;
    }
    const { intl } = this.props;
    const { notification_contacts } = this.state;

    if (!notification_contacts) {
      return null;
    }

    return (
      notification_contacts.map((contactNumber, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={contactNumber.phone_number + index}
          style={{ paddingBottom: '6px' }}
          className="gx-flex-row gx-px-2 gx-justify-content-lg-between gx-align-items-center">
          <span>
            {this.getMedicianTitleByType(contactNumber.role_type, intl)}
            :
            {' '}
            {this.formatPhoneNumber(contactNumber.phone_number)}
          </span>
          <Button
            disabled={!hasSelEndpoint}
            className="discharge-btn current-customer-btn gx-px-3"
            onClick={() => this.removeNumber(index)}
          >
            <strong><IntlMessages id="alert.delete" /></strong>
          </Button>
        </div>
      ))
    );
  }

  renderTextNotificationNumbersAdd = () => {
    const hasSelEndpoint = this.hasSelectedEndpoint();
    console.log(hasSelEndpoint);
    if (!hasSelEndpoint) {
      return null;
    }

    const { intl } = this.props;
    const { alertSelectedType, alertSelectedNumber, phoneError } = this.state;

    return (
      <div id="alert-wrapper" className="gx-d-flex gx-align-items-center gx-justify-content-sm-between">
        <Input.Group compact>
          <Select
            disabled={!hasSelEndpoint}
            className="notification-title-select"
            onChange={(option) => { this.onChangeEndpointProp(option, 'alertSelectedType'); }}
            value={alertSelectedType || alertTypeOptions[0].type}
            getPopupContainer={() => document.getElementById('inner-wrapper-alert')}
          >
            {this.loadNotificationOptions(intl)}
          </Select>
          <div className="alert-phone-input-container" style={{ width: '60%' }}>
            <Input
              style={{ width: '100%' }}
              maxLength={10}
              onFocus={() => this.setState({ phoneError: '' })}
              placeholder={this.props.intl.formatMessage({ id: 'alert.text_notification' })}
              onChange={(event) => { this.onChangeEndpointProp(event.target.value, 'alertSelectedNumber'); }}
              onKeyDown={(e) => this.handleKeyDown(e)}
              value={alertSelectedNumber}
              disabled={!hasSelEndpoint}
            />
            {phoneError && <p className="alert-phone-error">{phoneError}</p>}
          </div>
        </Input.Group>
        <Tooltip title={intl.formatMessage({ id: 'btn.add.tooltip' })}>
          <Button
            disabled={!hasSelEndpoint || !alertSelectedNumber || alertSelectedNumber.length !== 10}
            className="current-customer-btn green gx-px-1"
            onClick={this.addNumber}
          >
            <PlusSquareFilled style={{ fontSize: '18px' }} />
          </Button>
        </Tooltip>
      </div>
    );
  }

  render() {
    const { selectedLayout } = this.props;

    return (
      <div className="gx-mx-0 view-wrapper gx-m-2">
        <div id="inner-wrapper-alert" className="inner-wrapper-alert">
          <h1 className="inner-heading gx-m-2">
            <IntlMessages id="alert.titleTxt" />
          </h1>
          <Row>
            <Col sm={24} md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 12 : 24}>
              <Row className="row-style" gutter={[16, 36]}>
                <Col sm={24} md={24} lg={24} xl={24}>
                  <div className="gx-mx-2">
                    {this.renderEndpointsList()}
                  </div>
                </Col>
              </Row>
              <Row className="row-style" gutter={[16, 36]}>
                <Col sm={24} md={24} lg={24} xl={24}>
                  <div className="gx-mx-2">
                    <p className="row-style txt-title-input"><IntlMessages id="alert.titleSend" /></p>
                    {this.renderTextNotificationNumbersAdd()}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 'col-customers2' : 'col-customers'}
              sm={24}
              md={24}
              lg={24}
              xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 12 : 24}
            >
              <h2 className="text-title-list"><IntlMessages id="alert.notificationsList" /></h2>
              <hr className="line" />
              <div className={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 'word-breaker customers-list' : 'word-breaker long-customers-list'}>
                {this.renderNotificationNumbers()}
              </div>
            </Col>
          </Row>
        </div>
        <div className="gx-d-flex gx-justify-content-end btn-customer-panel">
          <Button className="btn-customer btn-alert" onClick={() => { this.applyBtnAlertHandler(); }} ref={this.applyBtnAlert} id="applyBtnAlert"><IntlMessages id="customers.btnApply" /></Button>
        </div>
        {/* <Row gutter={16}>
          <Col sm={24} md={24} lg={24} xl={24}>
            <div className="gx-d-flex gx-justify-content-end gx-mt-2">
              <Button className="btn-customer" onClick={() => { this.applyBtnAlertHandler(); }} ref={this.applyBtnAlert} id="applyBtnAlert"><IntlMessages id="customers.btnApply" /></Button>
            </div>
          </Col>
        </Row> */}
      </div>
    );
  }
}

Alert.propTypes = {
  intl: PropTypes.shape().isRequired,
  selectedLayout: PropTypes.string.isRequired,
  selectedEndpoints: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => {
  const selectedEndpoint = getSelectedEndpointObservation(state);
  const arrSelected = Object.values(getSelectedEndpoints(state));
  const { selectedLayout } = state.monitoringLayout;

  return {
    selectedEndpoints: [...arrSelected],
    selectedEndpoint: { ...selectedEndpoint },
    selectedLayout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectEndpoint: (machineName) => dispatch(setSelectedObservation(machineName)),
  changeEndpointProperty: (params) => dispatch(changeEndpointProp(params)),
  applyChangesProp: () => dispatch(applyChanges()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Alert)));
