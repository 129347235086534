import { cloneDeep } from 'lodash';
import {
  ENDPOINTS_FETCH_SUCCESS,
  ENDPOINTS_FETCH_DATA,
  FETCH_ENDPOINT_SUCCESS,
  ADMIT_ENDPOINT,
  REMOVE_ENDPOINT,
  CHANGE_ENDPOINT_PROP,
  SELECT_ADMITTED_ENDPOINT,
  FETCH_PATIENT_RISKS_LIST_SUCCESS,
  FETCH_OBSERVATION_RISKS_LIST_SUCCESS,
  START_ROOM_OBSERVATION_SUCCESS,
  END_ROOM_OBSERVATION_SUCCESS,
  UPDATE_ROOM_OBSERVATION_SUCCESS,
  APPLY_CHANGES_SUCCESS,
  SELECT_OBSERVATION_ENDPOINT,
  UPDATE_NIGHT_VIEW,
  SET_TALK,
  SET_LISTEN,
  SET_NOTIFY,
  CLEAN_UP_DATA_ON_LOGOUT,
  LOAD_IMPORTED_ENDPOINTS,
  UPDATE_ENDPOINT_DETAILS,
  REFRESH_CAMERA_ACTION,
  GET_ENDPOINT_STATUS_SUCCESS,
  CHECK_ENDPOINT_RESULT_ACTION,
  CHECK_ENDPOINT_ACTION, UPDATE_ENDPOINT_MEDIA_TYPE, FETCH_PATIENT_ROOM_STATUS_LIST_SUCCESS,
  REMOVE_ROOM_STATUS,
} from '@constants/iObserverActionTypes';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,

  ENDPOINT_STATUSES,
  INIT_ENDPOINT,
} from '@constants/Settings';
import { ENDPOINT_STATUS_CHECKING } from '@constants/EndpointAvailabilityStatusTypes';
import WebRTCManager from '@util/WebRTC/WebRTCManager';

const INIT_ENDPOINTS_STATE = {
  loading: false,
  list: [],
  page: {},
  endpointRisksList: [],
  endpointObservationRisksList: [],
  selectedEndpoint: null,
  selectedEndpoints: {},
  roomStatusList: [],
};

/**
 * Executed when changes are Applied Successfully
 *
 * @param  {object} state
 * @returns {object}
 */
function onApplyChangesSuccess(state) {
  const newState = {
    selectedEndpoints: { ...state.selectedEndpoints },
  };

  Object.keys(newState.selectedEndpoints).forEach((key) => {
    if (newState.selectedEndpoints[key].status === ENDPOINT_STATUSES.ADMITTED) {
      newState.selectedEndpoints[key].status = ENDPOINT_STATUSES.APPLIED;
    }
  });

  return { ...state, ...newState };
}

/**
 * On Endpoints Fetch Success
 *
 * @param  {object} state
 * @param  {array} list
 *
 * @returns {object}
 */
function onEndpointsFetchSuccess(state, list) {
  const newState = { list, loading: false };

  return { ...state, ...newState };
}

/**
 * On Endpoint Fetch Success
 *
 * @param  {object} state
 * @param  {object} selectedEndpoint
 *
 * @returns {object}
 */
function onEndpointFetchSuccess(state, selectedEndpoint) {
  const newState = { selectedEndpoint, loading: false };

  return { ...state, ...newState };
}

/**
 * On Admit Endpoint
 *
 * @param  {object} state
 * @param  {object} admittedEndpoint
 *
 * @returns {object}
 */
function onAdmitEndpoint(state, admittedEndpoints) {
  let newEndpoint;
  let endpointsLen;
  let selectedEndpoints = { ...state.selectedEndpoints };

  admittedEndpoints.forEach((admittedEndpoint) => {
    const existingEndpoint = selectedEndpoints[admittedEndpoint.id];
    if (existingEndpoint) {
      newEndpoint = { ...cloneDeep(selectedEndpoints[admittedEndpoint.id]) };
      endpointsLen = Object.keys(selectedEndpoints).length - 1;
    } else {
      newEndpoint = { ...cloneDeep(INIT_ENDPOINT), ...admittedEndpoint };
      endpointsLen = Object.keys(selectedEndpoints).length;
    }

    newEndpoint.ordering_idx = endpointsLen || 0;
    newEndpoint.status = newEndpoint.status === ENDPOINT_STATUSES.REMOVED
      ? ENDPOINT_STATUSES.APPLIED : ENDPOINT_STATUSES.ADMITTED;

    selectedEndpoints = {
      ...selectedEndpoints,
      [admittedEndpoint.id]: newEndpoint,
    };
  });
  return { ...state, ...{ selectedEndpoints } };
}

/**
 * Set proper ordering index for selected endpoints
 *
 * @param  {object} endpoints
 */
function sortSetEndpointsIndex(endpoints) {
  const arr = Object.values(endpoints);
  if (arr.length > 1) {
    arr.sort((a, b) => {
      const aIdx = a.ordering_idx;
      const bIdx = b.ordering_idx;
      // eslint-disable-next-line no-nested-ternary
      return aIdx > bIdx ? 1 : (aIdx < bIdx ? -1 : 0);
    });
    arr.forEach((endp, idx) => {
      if (endpoints[endp.id]) {
        // eslint-disable-next-line no-param-reassign
        endp.ordering_idx = idx;
      }
    });
  }
}
/**
 * On Remove Endpoint
 *
 * @param  {object} state
 * @param  {string} toRemoveId
 *
 * @returns {object}
 */
function onRemoveEndpoint(state, toRemoveId) {
  const toRemoveEndpoint = state.selectedEndpoints[toRemoveId];

  if (!toRemoveEndpoint) {
    return state;
  }

  const clonedEndpoints = cloneDeep(state.selectedEndpoints);
  let selEndpoint = state.selectedEndpoint;
  if (toRemoveEndpoint.status === ENDPOINT_STATUSES.APPLIED) {
    clonedEndpoints[toRemoveId].status = ENDPOINT_STATUSES.REMOVED;
    if (toRemoveId === state.selectedEndpoint) {
      selEndpoint = null;
    }
  } else {
    delete clonedEndpoints[toRemoveId];
  }
  sortSetEndpointsIndex(clonedEndpoints);

  const newState = {
    selectedEndpoints: { ...clonedEndpoints },
    selectedEndpoint: selEndpoint,
  };

  return { ...state, ...newState };
}

/**
 * On Change Endpoint Prop
 *
 * @param  {object} state
 * @param  {object} options
 * @param  {string} options.propName
 * @param  {string} options.endpointId
 * @param  {*} options.value
 * @param  {boolean} options.skipDirty
 *
 * @returns {object}
 */
function onChangeEndpointProp(state, {
  propName, value, endpointId, skipDirty = false,
}) {
  const endpointToChange = state.selectedEndpoints[endpointId];

  if (!endpointToChange) {
    return state;
  }

  const clonedPatientInfo = cloneDeep(endpointToChange.patientInfo);

  if (!skipDirty) {
    clonedPatientInfo.dirtyProps[propName] = value;
    clonedPatientInfo.dirty = true;
  } else {
    clonedPatientInfo[propName] = value;
  }
  const updatedEndpoint = { ...endpointToChange, patientInfo: clonedPatientInfo };
  const newState = {
    selectedEndpoints: { ...state.selectedEndpoints, [endpointId]: updatedEndpoint },
  };

  return { ...state, ...newState };
}

/**
 * On Start Room Observation Success
 *
 * @param  {object} state
 * @param  {string} roomId
 *
 * @returns {object}
 */
function onStartRoomObservationSuccess(state, roomId) {
  if (!roomId) {
    return state;
  }

  const currentEndpoints = cloneDeep(state.selectedEndpoints);
  const currEndpoint = currentEndpoints[roomId];

  if (currEndpoint) {
    currEndpoint.dirty = false;
    currEndpoint.dirtyProps = [];
    currEndpoint.status = ENDPOINT_STATUSES.APPLIED;

    if (currEndpoint.patientInfo) {
      currEndpoint.patientInfo.dirty = false;

      Object.keys(currEndpoint.patientInfo.dirtyProps).forEach((key) => {
        currEndpoint.patientInfo[key] = currEndpoint.patientInfo.dirtyProps[key];
      });

      currEndpoint.patientInfo.dirtyProps = {};
    }
  }

  const newSelectedEndpoints = { ...currentEndpoints, [roomId]: currEndpoint };
  const newState = { ...state, selectedEndpoints: { ...newSelectedEndpoints } };

  return { ...state, ...newState };
}

/**
 * On End Room Observation Success
 *
 * @param  {object} state
 * @param  {string} roomId
 *
 * @returns {object}
 */
function onEndRoomObservationSuccess(state, roomId) {
  if (!roomId) {
    return state;
  }

  const selectedEndpoints = cloneDeep(state.selectedEndpoints);

  if (selectedEndpoints[roomId]) {
    delete selectedEndpoints[roomId];
  }

  return { ...state, ...{ selectedEndpoints } };
}

/**
 * On Update Room Observation Success
 *
 * @param  {object} state
 * @param  {string} roomId
 *
 * @returns {object}
 */
function onUpdateRoomObservationSuccess(state, roomId) {
  if (!roomId) {
    return state;
  }

  const selectedEndpoints = cloneDeep(state.selectedEndpoints);
  const currEndpoint = selectedEndpoints[roomId];

  if (currEndpoint) {
    currEndpoint.dirty = false;
    currEndpoint.dirtyProps = [];
    currEndpoint.status = ENDPOINT_STATUSES.APPLIED;

    if (currEndpoint.patientInfo) {
      currEndpoint.patientInfo.dirty = false;

      Object.keys(currEndpoint.patientInfo.dirtyProps).forEach((key) => {
        currEndpoint.patientInfo[key] = currEndpoint.patientInfo.dirtyProps[key];
      });

      currEndpoint.patientInfo.dirtyProps = {};
    }
  }

  return { ...state, ...{ selectedEndpoints } };
}

/**
 * On Update Room Observation Success
 *
 * @param  {object} state
 * @param  {string} machine_name
 *
 * @returns {object}
 */
function onSelectObservationEndpoint(state, machine_name) {
  if (!machine_name) {
    return state;
  }

  const selectedEndpoints = cloneDeep(state.selectedEndpoints);
  Object.keys(selectedEndpoints).forEach((key) => {
    selectedEndpoints[key].selected = selectedEndpoints[key].machine_name === machine_name;
  });

  return { ...state, ...{ selectedEndpoints } };
}

/**
 * On Update listen, talk, playAlert, refresh or nightView
 *
 * @param  {object} state
 * @param  {string} machine_name
 * @param  {string} propName
 * @param  {string} toSet
 *
 * @returns {object}
 */
function onSetListenTalkNotifyNightView(state, machine_name, propName, toSet) {
  if (!machine_name) {
    return state;
  }

  const selectedEndpoints = cloneDeep(state.selectedEndpoints);
  Object.keys(selectedEndpoints).forEach((key) => {
    if (selectedEndpoints[key] && selectedEndpoints[key].machine_name === machine_name) {
      selectedEndpoints[key][propName] = toSet;
    }
  });

  return { ...state, ...{ selectedEndpoints } };
}

function onUpdateEndpointDetails(state, machine_name, details) {
  if (!machine_name) {
    return state;
  }
  const selectedEndpoints = cloneDeep(state.selectedEndpoints);
  Object.keys(selectedEndpoints).forEach((key) => {
    if (selectedEndpoints[key] && selectedEndpoints[key].machine_name === machine_name) {
      selectedEndpoints[key].details = details;
    }
  });

  return { ...state, ...{ selectedEndpoints } };
}

/**
 * On Load Imported from transferred session Endpoints
 *
 * @param  {object} state
 * @param  {object} payload
 *
 * @returns {object}
 */
function onLoadImportedEndpoints(state, payload) {
  const transfer_info = payload;
  const admittedEndpoints = {};
  if (transfer_info && transfer_info.length) {
    transfer_info.forEach((endpoint) => {
      const newEndpoint = { ...cloneDeep(INIT_ENDPOINT) };
      newEndpoint.id = endpoint.id;
      newEndpoint.name = endpoint.name || '';
      newEndpoint.machine_name = endpoint.machine_name;
      newEndpoint.patientInfo.bed_name = endpoint.bed_name;
      newEndpoint.patientInfo.notes = endpoint.notes;
      newEndpoint.patientInfo.patient_name = endpoint.patient_name;
      newEndpoint.patientInfo.risk_priority = endpoint.priority;
      newEndpoint.patientInfo.risk_type = endpoint.profile;
      newEndpoint.patientInfo.notification_contacts = endpoint.notification_contacts;
      newEndpoint.patientInfo.patient_room_status = endpoint.patient_room_status;
      newEndpoint.status = ENDPOINT_STATUSES.ADMITTED;
      newEndpoint.ordering_idx = endpoint.ordering_idx;

      newEndpoint.mediaType = WebRTCManager.lookupNodeInfo(
        `camera_${endpoint.machine_name}`,
      ).mediaType;

      admittedEndpoints[newEndpoint.id] = { ...newEndpoint };
    });
  }
  const selectedEndpoints = { ...admittedEndpoints };

  return { ...state, ...{ selectedEndpoints } };
}

/**
 * On get endpoint status
 *
 * @param  {object} state
 * @param  {object} payload
 *
 * @returns {object}
 */
function onGetEndpointStatus(state, payload) {
  const selectedEndpoints = cloneDeep(state.selectedEndpoints);
  const { id, response } = payload;
  const endpoint = selectedEndpoints[id];
  if (endpoint) {
    endpoint.activityStatus = response;
  }
  return { ...state, ...{ selectedEndpoints } };
}

/**
 * Handle check endpoint result
 *
 * @param {object} state
 * @param {object} payload
 */
function onCheckEndpointResult(state, payload) {
  console.log('onCheckEndpointResult', state, payload);
  const selectedEndpoints = cloneDeep(state.selectedEndpoints);
  const { machine_name, availabilityStatus } = payload;

  Object.keys(selectedEndpoints).some((key) => {
    const selectedEndpoint = selectedEndpoints[key];
    if (selectedEndpoint && selectedEndpoint.machine_name === machine_name) {
      selectedEndpoint.availabilityStatus = availabilityStatus;
      return true;
    }
    return false;
  });

  return { ...state, ...{ selectedEndpoints } };
}

function onFetchPatientRoomStatusSuccess(state, payload) {
  const newRoomStatusList = [
    ...state.roomStatusList,
    ...payload,
  ];
  const uniqueRoomList = Array.from(new Set(newRoomStatusList.map((status) => status.text)))
    .map((text) => newRoomStatusList.find((status) => status.text === text));
  return { ...state, roomStatusList: [...uniqueRoomList] };
}

function removeRoomStatus(state, payload) {
  const newRoomStatusList = state.roomStatusList.filter((status) => status.text !== payload);
  return { ...state, roomStatusList: newRoomStatusList };
}

function onUpdateEndpointMediaType(state, payload) {
  console.log('onUpdateEndpointMediaType', state, payload);

  const { endpointId, mediaType } = payload;
  if (state.selectedEndpoints[endpointId]) {
    const selectedEndpoints = cloneDeep(state.selectedEndpoints);

    selectedEndpoints[endpointId].mediaType = mediaType;
    return { ...state, ...{ selectedEndpoints } };
  }

  return state;
}

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = cloneDeep(INIT_ENDPOINTS_STATE), action) => {
  let newState = null;
  switch (action.type) {
    // When the full flow is implemented "APPLY_CHANGES" action
    // should be removed and we should listen only for "APPLY_CHANGES_SUCCESS"
    case APPLY_CHANGES_SUCCESS:
      return onApplyChangesSuccess(state);

    case ENDPOINTS_FETCH_SUCCESS:
      return onEndpointsFetchSuccess(state, { ...action.data.list });

    case ENDPOINTS_FETCH_DATA:
      return { ...state, ...{ loading: true } };

    case FETCH_ENDPOINT_SUCCESS:
      return onEndpointFetchSuccess(state, { ...action.payload });

    case ADMIT_ENDPOINT:
      return onAdmitEndpoint(state, [...action.payload]);

    case REMOVE_ENDPOINT:
      return onRemoveEndpoint(state, action.payload.id);

    case CHANGE_ENDPOINT_PROP:
      return onChangeEndpointProp(state, action.payload);

    case SELECT_ADMITTED_ENDPOINT:
      newState = state.selectedEndpoints[action.payload.id]
        ? { selectedEndpoint: action.payload.id }
        : {};

      return { ...state, ...newState };

    case REMOVE_ROOM_STATUS:
      return removeRoomStatus(state, action.payload);

    case FETCH_PATIENT_ROOM_STATUS_LIST_SUCCESS:
      return onFetchPatientRoomStatusSuccess(state, action.payload);

    case FETCH_PATIENT_RISKS_LIST_SUCCESS:
      newState = { endpointRisksList: [...action.payload] };

      return { ...state, ...newState };

    case FETCH_OBSERVATION_RISKS_LIST_SUCCESS:
      newState = { endpointObservationRisksList: [...action.payload] };

      return { ...state, ...newState };

    case START_ROOM_OBSERVATION_SUCCESS:
      return onStartRoomObservationSuccess(state, action.payload.id);

    case END_ROOM_OBSERVATION_SUCCESS:
      return onEndRoomObservationSuccess(state, action.payload.id);

    case UPDATE_ROOM_OBSERVATION_SUCCESS:
      return onUpdateRoomObservationSuccess(state, action.payload.id);

    case SELECT_OBSERVATION_ENDPOINT:
      return onSelectObservationEndpoint(state, action.payload.machine_name);

    case UPDATE_NIGHT_VIEW:
      return onSetListenTalkNotifyNightView(state, action.payload.machine_name, 'nightView', action.payload.toSet);

    case SET_TALK:
      return onSetListenTalkNotifyNightView(state, action.payload.machine_name, 'talk', action.payload.toSet);

    case SET_LISTEN:
      return onSetListenTalkNotifyNightView(state, action.payload.machine_name, 'listen', action.payload.toSet);

    case SET_NOTIFY:
      return onSetListenTalkNotifyNightView(state, action.payload.machine_name, 'playAlert', action.payload.toSet);

    case REFRESH_CAMERA_ACTION:
      return onSetListenTalkNotifyNightView(state, action.payload.machine_name, 'refreshTime', action.payload.toSet);

    case CLEAN_UP_DATA_ON_LOGOUT:
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      // console.log('process.env.REACT_APP_SSO_LOGOUT_REDIRECT_URL: ',
      // process.env.REACT_APP_SSO_LOGOUT_REDIRECT_URL);
      window.location.href = process.env.REACT_APP_SSO_LOGOUT_REDIRECT_URL;
      return {
        ...cloneDeep(INIT_ENDPOINTS_STATE),
      };

    case UPDATE_ENDPOINT_DETAILS:
      return onUpdateEndpointDetails(state, action.payload.machine_name, action.payload.details);

    case LOAD_IMPORTED_ENDPOINTS:
      return onLoadImportedEndpoints(state, action.payload);

    case GET_ENDPOINT_STATUS_SUCCESS:
      return onGetEndpointStatus(state, action.payload);

    case CHECK_ENDPOINT_ACTION:
      return onCheckEndpointResult(state, {
        machine_name: action.payload.machine_name,
        availabilityStatus: ENDPOINT_STATUS_CHECKING,
      });

    case CHECK_ENDPOINT_RESULT_ACTION:
      return onCheckEndpointResult(state, action.payload);
    case UPDATE_ENDPOINT_MEDIA_TYPE:
      return onUpdateEndpointMediaType(state, action.payload);

    default:
      return state;
  }
};

const tests = {};
if (process.env.NODE_ENV === 'test') {
  tests.onApplyChangesSuccess = onApplyChangesSuccess;
  tests.onEndpointsFetchSuccess = onEndpointsFetchSuccess;
  tests.onEndpointFetchSuccess = onEndpointFetchSuccess;
  tests.onAdmitEndpoint = onAdmitEndpoint;
  tests.sortSetEndpointsIndex = sortSetEndpointsIndex;
  tests.onRemoveEndpoint = onRemoveEndpoint;
  tests.onChangeEndpointProp = onChangeEndpointProp;
  tests.onStartRoomObservationSuccess = onStartRoomObservationSuccess;
  tests.onEndRoomObservationSuccess = onEndRoomObservationSuccess;
  tests.onUpdateRoomObservationSuccess = onUpdateRoomObservationSuccess;
  tests.onSelectObservationEndpoint = onSelectObservationEndpoint;
  tests.onSetListenTalkNotifyNightView = onSetListenTalkNotifyNightView;
  tests.onUpdateEndpointDetails = onUpdateEndpointDetails;
  tests.onLoadImportedEndpoints = onLoadImportedEndpoints;
  tests.onGetEndpointStatus = onGetEndpointStatus;
  tests.onCheckEndpointResult = onCheckEndpointResult;
  tests.onUpdateEndpointMediaType = onUpdateEndpointMediaType;
}

export { tests };
