import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/Endpoints';
import { FILTER_PAGE_SIZE } from '@constants/Settings';

export const auth = {
  /**
   * Refreshes the auth token
   * @param aToken {string}
   * @param rToken {string}
   * @returns {Promise<Response>}
   */
  async refreshAccessToken(aToken, rToken) {
    const url = `${ENDPOINTS.AUTH.REFRESH_TOKEN}?accessToken=${aToken}&refreshToken=${rToken}`;
    return RestManager.request(url, 'POST');
  },
  async logout(aToken, rToken) {
    const url = `${ENDPOINTS.AUTH.LOGOUT}`;
    const body = { access_token: aToken, refresh_token: rToken };
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

};

export const rest = {
  /**
   * @return {Object}
   */
  async getProfileInfo() {
    const url = `${ENDPOINTS.API.FETCH_PROFILE}`;
    return RestManager.request(url);
  },
  /**
   * @param  {string} key
   * @param  {string} filter
   * @return {Object}
   */
  async getFilterOptions(key, filter = '') {
    let url = '';
    switch (key) {
      case 'organization':
        url = `${ENDPOINTS.API.FILTER.organization}&size=${FILTER_PAGE_SIZE}`;
        console.log('url: ', url);
        return RestManager.request(url);
      case 'customer':
        return RestManager.request(`${ENDPOINTS.API.FILTER.customer}&size=${FILTER_PAGE_SIZE}${filter ? `&organization.id=${filter}` : ''}`);
      case 'facility':
        return RestManager.request(`${ENDPOINTS.API.FILTER.facility}&size=${FILTER_PAGE_SIZE}&customer.id=${filter}`);
      case 'unit':
        return RestManager.request(`${ENDPOINTS.API.FILTER.unit}&size=${FILTER_PAGE_SIZE}&facility.id=${filter}`);
      case 'endpoint':
        return RestManager.request(`${ENDPOINTS.API.FILTER.endpoint}&size=${FILTER_PAGE_SIZE}&unit.id=${filter}`);

      default:
        // should never get here
        return Promise.reject(new Error('key not recognized'));
    }
  },

  /**
   * @param  {string} id
   * @return {Object}
   */
  async getCustomerById(id) {
    return RestManager.request(`${ENDPOINTS.API.CUSTOMER.fetchById}/${id}`);
  },

  /**
   *
   * @param {Object} parameters for page size, sorting and filtering
   * @param {Array} sorting
   */
  async getCustomers({ page, sorting, filter }) {
    const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
    const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
    return RestManager.request(
      `${ENDPOINTS.API.CUSTOMER.fetchCustomers}?page=${page || 0
      }${sortingQueryString}${filterQueryString}`,
    );
  },

  /**
   * @param  {string} id
   * @return {Object}
   */
  async getEndpointById(id) {
    return RestManager.request(`${ENDPOINTS.API.ENDPOINT.fetchById}/${id}`);
  },

  /**
   *
   * @param {Object} parameters for page size, sorting and filtering
   * @param {Array} sorting
   * @param {Array} filter
   */
  async getEndpoints({ page, sorting, filter }) {
    const filterQueryString = filter && filter.length ? `&${filter.join('&')}` : '';
    const sortingQueryString = sorting && sorting.length ? `&sort=${sorting.join('&sort=')}` : '';
    return RestManager.request(
      `${ENDPOINTS.API.ENDPOINT.fetchEndpoints}?page=${page || 0
      }${sortingQueryString}${filterQueryString}`,
    );
  },

  async getEndpointRisksList() {
    const url = `${ENDPOINTS.API.ENDPOINT.fetchEndpointRisks}`;
    return RestManager.request(url);
  },

  async getObservationRisksList() {
    const url = `${ENDPOINTS.API.ENDPOINT.fetchObservationRisks}`;
    return RestManager.request(url);
  },

  async fetchEndpointDetails(machine_name) {
    const url = `${ENDPOINTS.API.ENDPOINT.fetchEndpointDetails}`;

    return RestManager.requestWithoutQueryParams(url, 'POST', { machine_name });
  },

  async esitterToggle(params) {
    const url = `${ENDPOINTS.API.ENDPOINT.esitterToggle}`;
    const body = { ...params };
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async startObservationSession(admin_id, transferred_session = null) {
    const body = { admin_id };
    if (transferred_session) {
      body.transferred_session = transferred_session;
    }
    const url = `${ENDPOINTS.API.OBSERVATION.startSession}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },
  async startRoomObservation(params) {
    const body = { ...params };
    const url = `${ENDPOINTS.API.OBSERVATION.startRoomObservation}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },
  async endRoomObservation(params) {
    const body = { ...params };
    const url = `${ENDPOINTS.API.OBSERVATION.endRoomObservation}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },
  async updateRoomObservation(params) {
    const body = { ...params };
    const url = `${ENDPOINTS.API.OBSERVATION.updateRoomObservation}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async sendCommandByMachineName(params) {
    const body = { ...params };
    const url = `${ENDPOINTS.API.OBSERVATION.sendCommandByMachineName}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async sendRefreshCamera(params) {
    const body = { ...params };
    const url = `${ENDPOINTS.API.OBSERVATION.refreshCameraObservation}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async startObservationActivity(params) {
    const body = params;
    const url = `${ENDPOINTS.API.OBSERVATION.startObservationActivity}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
    // return await RestManager.formDataReq(url, 'POST', body);
  },
  async endObservationActivity(params) {
    const body = { ...params };
    const url = `${ENDPOINTS.API.OBSERVATION.endObservationActivity}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async fetchListenReasons() {
    const url = `${ENDPOINTS.API.OBSERVATION.fetchListenReasons}`;
    return RestManager.request(url);
  },

  async fetchTalkReasons() {
    const url = `${ENDPOINTS.API.OBSERVATION.fetchTalkReasons}`;
    return RestManager.request(url);
  },

  async fetchNotifyReasons() {
    const url = `${ENDPOINTS.API.OBSERVATION.fetchAlertReasons}`;
    return RestManager.request(url);
  },

  async fetchRoomStatusList() {
    const roomStatusList = [
      {
        code: 'sticker1',
        text: 'Out of Room',
      },
      {
        code: 'sticker2',
        text: 'At Nurse’s Station',
      },
    ];
    return Promise.resolve(roomStatusList);
  },

  async transferSession(body) {
    const url = `${ENDPOINTS.API.OBSERVATION.transferSession}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async cancelTransferSession(body) {
    const url = `${ENDPOINTS.API.OBSERVATION.cancelTransferSession}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async sendCommandByUser(body) {
    const url = `${ENDPOINTS.API.OBSERVATION.sendCommandByUser}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async getTransfers() {
    const url = `${ENDPOINTS.API.OBSERVATION.getTransfers}`;
    return RestManager.request(url);
  },

  async takeOverSession(body) {
    const url = `${ENDPOINTS.API.OBSERVATION.takeOverSession}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async getAudioVideo() {
    const url = `${ENDPOINTS.API.OBSERVATION.audioVideo}`;
    return RestManager.request(url);
  },

  async setAudioVideo(body) {
    const url = `${ENDPOINTS.API.OBSERVATION.audioVideo}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async getIObserverControllerSettings() {
    const url = `${ENDPOINTS.API.SETTINGS.iObserverControllerSettings}`;
    return RestManager.request(url);
  },
  async getEndpointStatus(endpointId) {
    const url = `${ENDPOINTS.API.ENDPOINT.getStatus}/${endpointId}/status`;
    return RestManager.request(url);
  },

  async sendAlertTextMessage(data) {
    const body = { ...data };
    const url = `${ENDPOINTS.API.OBSERVATION.textMessage}`;
    return RestManager.requestWithoutQueryParams(url, 'POST', body);
  },

  async getAppVersion() {
    const url = `${ENDPOINTS.API.VERSION.fetchVersion}`;
    return RestManager.request(url);
  },
};
