import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Layout } from 'antd';

import { toggleCollapsedSideNav, updateWindowWidth } from '@actions/Setting';
import { layoutNames } from '@constants/LayoutSettings';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from '@constants/ThemeSetting';
import SidebarCnt from './SidebarContent';

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();

  const {
    themeType, navCollapsed, navStyle,
  } = useSelector(({ settings }) => settings);

  const {
    selectedLayout,
  } = useSelector(({ monitoringLayout }) => monitoringLayout);

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  let drawerStyle = 'gx-collapsed-sidebar';

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = '';
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar gx-mini-custom-sidebar';
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = 'gx-custom-sidebar';
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar';
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = 'gx-collapsed-sidebar';
  }
  // DO NOT HIDE SIDE BAR IOB-299
  const widthToUse = TAB_SIZE + 1;
  const collapsed = widthToUse >= TAB_SIZE
    && (navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR);
  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={collapsed}
      theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
      collapsible
    >
      {
        navStyle === NAV_STYLE_DRAWER || widthToUse < TAB_SIZE
          ? (
            <Drawer
              className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
              placement="left"
              closable={false}
              onClose={onToggleCollapsedNav}
              visible={navCollapsed}
            >
              <SidebarCnt hideIcons={collapsed && selectedLayout !== layoutNames.DEFAULT_LAYOUT} />
            </Drawer>
          )
          : (
            <SidebarCnt
              hideIcons={collapsed && selectedLayout !== layoutNames.DEFAULT_LAYOUT}
            />
          )
      }
    </Sider>
  );
};
export default Sidebar;
