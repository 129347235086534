const WebRTCUtils = {
  // Can be overriden if we want to log over the socket
  socketLog: () => { },

  /**
   * Sets Logging method over the web socket
   *
   * @param  {Function} socketLogMethod
   * @returns {void}
   */
  setSocketLogMethod: (socketLogMethod) => {
    WebRTCUtils.socketLog = socketLogMethod;
  },

  /**
   * Debug
   *
   * @param  {string} nodeId
   * @param  {string} command
   *
   * @return {void}
   */
  debug(nodeId, command) {
    this.socketLog('debug', nodeId, command);
  },

  /**
   * Log Message
   *
   * @param  {*}    first [description]
   * @param  {...[*]} rest  [description]
   *
   * @returns {void}
   */
  logMessage: (first, ...rest) => {
    const d = new Date();
    const pad = (n) => n.toString().padStart(2, '0');
    const now = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;

    if (typeof first === 'string') {
      // eslint-disable-next-line no-console
      console.debug(`[iObserverController] ${now} ${first}`, ...rest);
      WebRTCUtils.socketLog('log', first, ...rest);
    } else {
      // eslint-disable-next-line no-console
      console.debug('[iObserverController]', now, first, ...rest);
      // eslint-disable-next-line no-console
      console.trace();
      WebRTCUtils.socketLog('log', JSON.stringify(first), ...rest);
    }
  },

  /**
   * Format Media Constraints
   *
   * @param  {string}  audioDeviceId
   * @param  {string}  videoDeviceId
   * @param  {Boolean} is_safari
   *
   * @returns {object}
   */
  formatMediaConstraints: (audioDeviceId, videoDeviceId, is_safari = false) => {
    WebRTCUtils.logMessage(`getUserMedia constraints derived from ${JSON.stringify(audioDeviceId)} audio and ${JSON.stringify(videoDeviceId)} video`);
    const constraints = {};

    if (audioDeviceId != null) {
      if (audioDeviceId) {
        constraints.audio = { deviceId: { exact: audioDeviceId } };
      } else {
        constraints.audio = true;
      }
    }

    if (videoDeviceId != null && videoDeviceId.toUpperCase() !== 'NO CAMERA') {
      if (videoDeviceId) {
        if (is_safari) {
          constraints.video = { deviceId: { exact: videoDeviceId } };
        } else {
          constraints.video = {
            deviceId: { exact: videoDeviceId },
            width: 1280,
            height: 720,
            frameRate: { ideal: 30, max: 30 },
          };
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (is_safari) {
          constraints.video = true;
        } else {
          constraints.video = {
            width: 1280,
            height: 720,
            frameRate: { ideal: 30, max: 30 },
          };
        }
      }
    }

    return constraints;
  },

  /**
   * Determine stream type
   *
   * @param  {object} stream
   * @return {string}
   */
  getMediaType: (stream) => {
    if (!stream) {
      WebRTCUtils.logMessage('*** missing stream parameter to getMediaType');
      return 'none';
    }

    if (!stream.getAudioTracks || !stream.getVideoTracks) {
      WebRTCUtils.logMessage(`*** broken stream ID ${stream?.id} missing required properties`);
      return 'none';
    }

    const audioTracks = stream.getAudioTracks().filter((track) => track.readyState === 'live');
    const audioId = audioTracks.length > 0 ? audioTracks[0].id : null;
    const videoTracks = stream.getVideoTracks().filter((track) => track.readyState === 'live');
    const videoId = videoTracks.length > 0 ? videoTracks[0].id : null;

    if (audioId && videoId) {
      return 'audioVideo';
    }

    if (videoId) {
      return 'videoOnly';
    }

    if (audioId) {
      return 'audioOnly';
    }

    return 'none';
  },

  /**
   * Infer Stream By PeerId
   *
   * @param  {object} stream      [description]
   * @param  {string} peerId      [description]
   * @param  {Map} remoteNodes [description]
   *
   * @returns {object}             [description]
   */
  inferStreamByPeerId: (stream, peerId, remoteNodes) => {
    let nodeInfo = remoteNodes.get(peerId);

    if (nodeInfo) {
      if (nodeInfo.streamId) {
        WebRTCUtils.logMessage(`inferring ${peerId} stream ID is now ${stream.id}, replacing ${nodeInfo.streamId}`);
      } else {
        WebRTCUtils.logMessage(`inferring ${peerId} stream ID is ${stream.id}`);
      }

      nodeInfo.streamId = stream.id;
    } else {
      nodeInfo = {
        connected: true,
        up: true,
        mediaType: WebRTCUtils.getMediaType(stream),
        streamId: stream.id,
        callType: 'none',
      };
      remoteNodes.set(peerId, nodeInfo);
      WebRTCUtils.logMessage(`inferring ${peerId} remote node info ${JSON.stringify(nodeInfo)}`);
    }

    return nodeInfo;
  },

  /**
   * Find media sink
   *
   * @param  {string} mediaId    [description]
   * @param  {string} sinkId     [description]
   * @param  {Set} mediaSinks [description]
   *
   * @returns {[type]}            [description]
   */
  hasMediaSink: (mediaId, sinkId, mediaSinks) => mediaSinks.has(`${mediaId}:${sinkId}`),

  getPeerConnectionConstraints: () => {
    const constraints = {};

    if (typeof (DO_NOT_USE_QOS) === 'undefined') {
      constraints.optional = [{ googDscp: true }];
    }

    return constraints;
  },

  /**
   * Restrict Bandwidth
   *
   * @param  {string} sdp       [description]
   * @param  {object} streaming [description]
   *
   * @returns {string}           [description]
   */
  restrictBandwidth: (sdp, streaming = {}) => {
    // eslint-disable-next-line no-param-reassign
    streaming.maxKbps = 1000;
    const modifier = 'AS';

    if (sdp.indexOf(`b=${modifier}:`) === -1) {
      // insert b= after c= line.
      // eslint-disable-next-line no-param-reassign
      sdp = sdp.replace(
        /c=IN (.*)\r\n/g,
        `c=IN $1\r\nb=${modifier}:${streaming.maxKbps}\r\n`,
      );
    } else {
      // eslint-disable-next-line no-param-reassign
      sdp = sdp.replace(
        new RegExp(`b=${modifier}:.*\r\n`, 'g'),
        `b=${modifier}:${streaming.maxKbps}\r\n`,
      );
    }

    return sdp;
  },

  /**
   * Dequeue Candidates
   *
   * @param  {string} peerId
   * @param  {string} peer
   *
   * @returns {void}
   */
  async dequeueCandidates(peerId, peer) {
    const num = peer.candidates.length;
    if (num > 0) {
      WebRTCUtils.logMessage(`applying ${num} queued ICE candidates for ${peerId}`);

      // eslint-disable-next-line no-restricted-syntax
      for (const candidate of peer.candidates) {
        // eslint-disable-next-line no-await-in-loop
        await peer.conn.addIceCandidate(new RTCIceCandidate(candidate));
      }

      // eslint-disable-next-line no-param-reassign
      peer.candidates = [];
    }
  },
};

export default WebRTCUtils;
