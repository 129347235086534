import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import {
  Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';
import { matchPath } from 'react-router';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { setInitUrl } from '@actions/Auth';
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from '@actions/Setting';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '@constants/ThemeSetting';

import {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_PARAM,
  REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_PARAM,
} from '@constants/Settings';
import AppLocale from 'src/lngProvider';
import { refreshSessionTimeSetup } from 'src/appRedux/actions';
import MainApp from './MainApp';
import AutoMonitorRoom from '../../routes/AutoMonitorRoom';

const App = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  const qParams = new URLSearchParams(history.location.search);
  const hParams = new URLSearchParams(String(history.location.hash).replace('#', '?'));

  const newAccessToken = qParams.get(ACCESS_TOKEN_PARAM) || hParams.get(ACCESS_TOKEN_PARAM);
  const newRefreshToken = qParams.get(REFRESH_TOKEN_PARAM) || hParams.get(REFRESH_TOKEN_PARAM);
  const isProd = process.env.NODE_ENV === 'production' && !window.location.hostname.includes('deviobserver');
  const dispatch = useDispatch();

  if ((!accessToken || !refreshToken) && (!newAccessToken || !newRefreshToken)) {
    const matchAutoMonitor = matchPath(location.pathname, {
      path: '/automonitor/:pid/:nodeId',
      exact: true,
      strict: true,
    });

    if (!matchAutoMonitor || !matchAutoMonitor.isExact) {
      window.location.href = process.env.REACT_APP_SSO_REDIRECT_URL;
    }
  } else if (newAccessToken && newRefreshToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
    dispatch(refreshSessionTimeSetup());
  }

  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings,
  );
  const { initURL } = useSelector(({ auth }) => auth);

  // useEffect(() => {
  //   let link = document.createElement('link');
  //   link.type = 'text/css';
  //   link.rel = 'stylesheet';
  //   link.href = '/css/style.css';
  //   link.className = 'gx-style';
  //   document.body.appendChild(link);
  // });

  const setLayoutType = (layoutTyp) => {
    if (layoutTyp === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutTyp === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutTyp === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setJACss = () => {
    // adding / removing css class fixes for Arabic and Japanese language layout
    switch (locale.locale) {
      case 'ar': { // arabic
        // removing css class fixes for japanese
        document.body.classList.remove('ja-full-layout');

        document.body.classList.add('ar-full-layout');
        break;
      }
      default: {
        document.body.classList.remove('ja-full-layout', 'ar-full-layout');
        break;
      }
    }
  };

  const setNavStyle = (naviStyle) => {
    if (
      naviStyle === NAV_STYLE_DEFAULT_HORIZONTAL
      || naviStyle === NAV_STYLE_DARK_HORIZONTAL
      || naviStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL
      || naviStyle === NAV_STYLE_ABOVE_HEADER
      || naviStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
    setJACss();
  });

  const currentAppLocale = AppLocale[locale.locale];

  const flattenMessages = (nestedMessages, prefix = '') => Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={flattenMessages(currentAppLocale.messages)}
      >
        <Switch>
          {!isProd
            && <Route exact path="/automonitor/:pid/:nodeId" component={AutoMonitorRoom} />}
          <Route path={`${match.url}`} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
