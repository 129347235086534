import {
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
  SESSION_EXPIRATION_TIME_SETUP,
  SESSION_EXPIRATION_WARNING_FLAG,
  SESSION_REFRESH,
  SESSION_REFRESH_DATA,
} from '@constants/iObserverActionTypes';

export const getProfile = () => ({
  type: FETCH_OWN_PROFILE_REQUEST,
});
export const getProfileSuccess = (payload = {}) => ({
  type: FETCH_OWN_PROFILE_SUCCESS,
  payload,
});

export const refreshSessionToken = () => ({
  type: SESSION_REFRESH,
});
export const refreshSessionTimeSetup = () => ({
  type: SESSION_EXPIRATION_TIME_SETUP,
});
export const setSessionExpirationWarning = (expirationWarningFlag) => ({
  type: SESSION_EXPIRATION_WARNING_FLAG,
  payload: {
    expirationWarningFlag,
  },
});
export const refreshSessionTokenData = (expirationData = { expirationTime: null }) => ({
  type: SESSION_REFRESH_DATA,
  payload: {
    ...expirationData,
  },
});
