import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Popover, Button } from 'antd';
import { injectIntl } from 'react-intl';
import lowRiskImg from '../../../assets/images/22x22-indicator-green.png';
import medRiskImg from '../../../assets/images/22x22-indicator-yellow.png';
import hiRiskImg from '../../../assets/images/22x22-indicator-red.png';
import switchImg from '../../../assets/images/36x36-switch.png';
import callImg from '../../../assets/images/36x36-call-blue.png';
import callActiveImg from '../../../assets/images/36x36-call.png';
import notesImg from '../../../assets/images/36x36-notes-blue.png';
import notesActiveImg from '../../../assets/images/36x36-notes-green.png';

const REFRESH_DISABLE_TIMEOUT = 60000; // 1 minute

class EndpointVideoTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showNotes: false, disableRefresh: false, endpointLabel: '' };

    this.disableRefreshTimer = 0;
  }

  componentDidMount() {
    this.checkRefreshTime();

    this.updateEndpointLabel();
  }

  componentDidUpdate(prevProps) {
    this.checkRefreshTime(prevProps);

    const { endpointData } = this.props;
    if (!prevProps.endpointData || (endpointData && !endpointData.patientInfo.dirty)
      || (endpointData && endpointData.id !== prevProps.endpointData.id)) {
      this.updateEndpointLabel();
    }
  }

  componentWillUnmount() {
    if (this.disableRefreshTimer) {
      clearTimeout(this.disableRefreshTimer);
      this.disableRefreshTimer = 0;
    }
  }

  static renderRiskIcon(riskPriority) {
    let imgData = lowRiskImg;
    switch (riskPriority) {
      case 2:
        imgData = medRiskImg;
        break;
      case 3:
        imgData = hiRiskImg;
        break;
      default:
        break;
    }

    return (<img src={imgData} alt={riskPriority ? riskPriority.text : 'risk'} />);
  }

  checkRefreshTime = (prevProps) => {
    const { endpointData } = this.props;
    const { disableRefresh } = this.state;

    if (endpointData && endpointData.refreshTime
      && (!prevProps || !prevProps.endpointData || !prevProps.endpointData.refreshTime
        || endpointData.refreshTime !== prevProps.endpointData.refreshTime)) {
      const currentTime = new Date().getTime();
      const diff = (parseInt(endpointData.refreshTime, 10) + REFRESH_DISABLE_TIMEOUT)
        - currentTime;
      if (diff > 0 && diff <= REFRESH_DISABLE_TIMEOUT) {
        this.setState({ disableRefresh: true });

        if (this.disableRefreshTimer) {
          clearTimeout(this.disableRefreshTimer);
        }

        this.disableRefreshTimer = setTimeout(() => {
          this.setState({ disableRefresh: false });
          this.disableRefreshTimer = 0;
        }, diff);
      } else if (disableRefresh) {
        this.setState({ disableRefresh: false });
      }
    } else if (disableRefresh && !(endpointData && endpointData.refreshTime)) {
      this.setState({ disableRefresh: false });
    }
  }

  toggleNotes = (e) => {
    const { showNotes } = this.state;
    this.setState({ showNotes: !showNotes });
    e.stopPropagation();
  }

  onMakeCall = (e) => {
    const { endpointData, makeCall } = this.props;
    makeCall(endpointData.machine_name);
    e.stopPropagation();
  }

  onRefreshClick = (e) => {
    const { endpointData, refreshCamera } = this.props;
    refreshCamera(endpointData.machine_name);
    e.stopPropagation();
  }

  onKeyPressed = (event) => {
    if (event.charCode === 13) {
      const { selectPinnedEndpoint } = this.props;
      selectPinnedEndpoint();
    }
  }

  updateEndpointLabel() {
    const { endpointData, intl } = this.props;
    let name = '';

    if (endpointData.patientInfo) {
      name += endpointData.patientInfo.bed_name
        ? endpointData.patientInfo.bed_name : intl.formatMessage({ id: 'patient.bedName' });
      name += ' / ';
      name += endpointData.patientInfo.patient_name
        ? endpointData.patientInfo.patient_name : intl.formatMessage({ id: 'patient.name' });
      name += ' / ';
      if (endpointData.patientInfo.risk_type.length !== 0) {
        endpointData.patientInfo.risk_type.forEach((riskType, index) => {
          if (index > 0) {
            name += ', ';
          }
          name += riskType;
        });
      } else { name += intl.formatMessage({ id: 'patient.riskType' }); }
      name += ' / ';
    }
    if (endpointData.name) {
      name += endpointData.name;
    }

    const { endpointLabel } = this.state;
    if (endpointLabel !== name) {
      this.setState({ endpointLabel: name });
    }
  }

  renderEndpointInfo() {
    const { endpointData, draggable } = this.props;
    const { endpointLabel } = this.state;

    const className = `endpoint-video-title-info${draggable ? ' drag' : ''}`;
    return (
      <div className={className}>
        <Tooltip title={endpointLabel}>
          {EndpointVideoTitle.renderRiskIcon(endpointData.patientInfo.risk_priority)}
          <span>
            {endpointLabel}
          </span>
        </Tooltip>
      </div>
    );
  }

  renderButtons() {
    const {
      endpointData, intl, hasCall, isSelected, titleWidth,
    } = this.props;
    const { showNotes, disableRefresh } = this.state;

    return (
      <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
        {!!isSelected
          && (
            <Tooltip title={intl.formatMessage({ id: 'btn.refresh.tooltip' })}>
              <Button
                shape="circle"
                disabled={disableRefresh}
                className="title-button"
                onClick={this.onRefreshClick}
                aria-label={intl.formatMessage({ id: 'btn.refresh.tooltip' })}
              >
                <img src={switchImg} alt={intl.formatMessage({ id: 'btn.refresh.tooltip' })} />
              </Button>
            </Tooltip>
          )}
        {!!endpointData.patientInfo.notes && !!isSelected
          && (
            <Tooltip title={intl.formatMessage({ id: 'btn.notes.tooltip' })}>
              <Popover
                overlayStyle={{ wordWrap: 'break-word', width: `${titleWidth - 70}px` }}
                title={intl.formatMessage({ id: 'btn.notes.tooltip' })}
                content={endpointData.patientInfo.notes}
                trigger="click"
                visible={showNotes}
                overlayClassName="endpoint-video-notes-container"
                arrowPointAtCenter
                placement="bottomRight"
              >
                <Button
                  shape="circle"
                  className="title-button"
                  onClick={this.toggleNotes}
                  aria-label={intl.formatMessage({ id: 'btn.notes.tooltip' })}
                >
                  <img
                    src={showNotes ? notesActiveImg : notesImg}
                    alt={intl.formatMessage({ id: 'btn.notes.tooltip' })}
                  />
                </Button>
              </Popover>
            </Tooltip>
          )}
        {(!!isSelected || hasCall)
          && (
            <Tooltip title={intl.formatMessage({ id: 'btn.call.tooltip' })}>
              <Button
                shape="circle"
                className="title-button"
                onClick={this.onMakeCall}
                aria-label={intl.formatMessage({ id: 'btn.call.tooltip' })}
              >
                <img
                  src={hasCall ? callActiveImg : callImg}
                  alt={intl.formatMessage({ id: 'btn.call.tooltip' })}
                />
              </Button>
            </Tooltip>
          )}
      </div>
    );
  }

  render() {
    const { onDragStart, draggable, selectPinnedEndpoint } = this.props;

    return (
      <div
        role="button"
        tabIndex="-1"
        className="endpoint-video-title"
        draggable={draggable}
        onDragStart={onDragStart}
        onClick={selectPinnedEndpoint}
        onKeyPress={(e) => this.onKeyPressed(e)}
      >
        {this.renderEndpointInfo()}
        {this.renderButtons()}
      </div>
    );
  }
}
EndpointVideoTitle.defaultProps = {
  isSelected: false,
  hasCall: false,
  draggable: false,
};

EndpointVideoTitle.propTypes = {
  // endpointData: PropTypes.shape(
  //   {
  //     machine_name: PropTypes.string,
  //     status: PropTypes.string,
  //     patientInfo: PropTypes.shape,
  //     name: PropTypes.string,
  //   },
  // ).isRequired,
  endpointData: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool,
  intl: PropTypes.shape().isRequired,
  refreshCamera: PropTypes.func.isRequired,
  makeCall: PropTypes.func.isRequired,
  hasCall: PropTypes.bool,
  onDragStart: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  selectPinnedEndpoint: PropTypes.func.isRequired,
  titleWidth: PropTypes.number.isRequired,
};
export { EndpointVideoTitle as EndpointVideoTitleTest };
export default injectIntl(EndpointVideoTitle);
