import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import CustomTopbar from '@containers/Topbar/CustomTopbar';
import Customers from '@routes/Customers';
import Patient from '@routes/Patient';
import Alert from '@routes/Alert';
import Layouts from '@routes/Layouts';
import Session from '@routes/Session';
import MyCamera from '@routes/MyCamera';
import Reports from '@routes/Reports';
import { ENDPOINT_STATUSES } from '@constants/Settings';
import EndpointVideo from '../EndpointVideo';
import Version from '../../routes/Version';

const routePaths = {
  customers: '/app/customers',
  patient: '/app/patient',
  alert: '/app/alert',
  layouts: '/app/layouts',
  session: '/app/session',
  myCamera: '/app/my-camera',
  reports: '/app/reports',
  version: '/app/version',
};

class DefaultLayout extends React.PureComponent {
  renderThumbs() {
    const {
      endpointsData, selectEndpoint, selectedEndpoint, refreshCamera, makeCall, callbacks,
    } = this.props;
    const cols = [];
    const { chosenEndpoints } = this.props;
    let cellsCounter = Math.max(Object.keys(chosenEndpoints).length, endpointsData.length);
    if (cellsCounter <= 4) {
      cellsCounter = 4;
    } else if (cellsCounter > 4 && cellsCounter <= 6) {
      cellsCounter = 6;
    } else if (cellsCounter > 6) {
      cellsCounter = 12;
    }
    const className = `video-thumb${cellsCounter === 4 || cellsCounter === 6 ? ' video-container' : ''}`;
    const span = cellsCounter === 4 ? 24 / 4 : 24 / 6;

    for (let i = 0; i < cellsCounter; i += 1) {
      cols.push(
        <Col span={span} key={`thumb-${i}`} className={className}>
          <div className="video-placeholder">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[i] && endpointsData[i].stream}
              mediaId={endpointsData[i] && endpointsData[i].mediaId}
              endpointData={endpointsData[i] && endpointsData[i].data}
              endpointStats={endpointsData[i] && endpointsData[i].stats}
              hasCall={!!(endpointsData[i] && endpointsData[i].dialedInPopup)}
              index={i}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>,
      );
    }

    return cols;
  }

  renderProperRoute() {
    const { location } = this.props;

    switch (location.pathname) {
      case routePaths.customers:
        return <Customers />;
      case routePaths.patient:
        return <Patient />;
      case routePaths.alert:
        return <Alert />;
      case routePaths.layouts:
        return <Layouts />;
      case routePaths.session:
        return <Session />;
      case routePaths.myCamera:
        return <MyCamera />;
      case routePaths.reports:
        return <Reports />;
      case routePaths.version:
        return <Version />;
      default:
        return <Redirect to="/app/customers" />;
    }
  }

  render() {
    const {
      profile, bigVideoData, selectEndpoint, selectedEndpoint, refreshCamera, makeCall,
      callbacks,
    } = this.props;
    const { username } = profile || {};

    return (
      <>
        <Row className="default-upper-container">
          <Col span={12} className="default-inner-wrapper">
            <CustomTopbar username={username} />
            {this.renderProperRoute()}
          </Col>
          <Col span={12} className="main-video-holder default-inner-wrapper default-video-box">
            <div className="video-placeholder">
              {!!bigVideoData
                && (
                  <EndpointVideo
                    selectedEndpoint={selectedEndpoint}
                    selectEndpoint={selectEndpoint}
                    stream={bigVideoData.stream}
                    mediaId={bigVideoData.mediaId}
                    endpointData={bigVideoData.data}
                    endpointStats={bigVideoData.stats}
                    hasCall={!!bigVideoData.dialedInPopup}
                    refreshCamera={refreshCamera}
                    makeCall={makeCall}
                    callbacks={callbacks}
                    disableDnd
                  />
                )}
            </div>
          </Col>
        </Row>
        <Row className="video-thumbs-holder default-lower-container">
          {this.renderThumbs()}
        </Row>
      </>
    );
  }
}

DefaultLayout.propTypes = {
  location: PropTypes.shape().isRequired,
  profile: PropTypes.shape().isRequired,
  selectEndpoint: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.shape(),
  endpointsData: PropTypes.arrayOf(PropTypes.shape()),
  bigVideoData: PropTypes.shape(),
  chosenEndpoints: PropTypes.shape().isRequired,
  refreshCamera: PropTypes.func.isRequired,
  makeCall: PropTypes.func,
  callbacks: PropTypes.shape(),
};

DefaultLayout.defaultProps = {
  bigVideoData: null,
  endpointsData: [],
  selectedEndpoint: null,
  makeCall: null,
  callbacks: null,
};

const mapStateToProps = (store) => {
  const { monitoringLayout, profile, endpoints } = store;
  const { selectedEndpoints = {} } = endpoints;
  const chosenEndpoints = {};

  Object.keys(selectedEndpoints).forEach((key) => {
    if (selectedEndpoints[key].status === ENDPOINT_STATUSES.ADMITTED
      || selectedEndpoints[key].status === ENDPOINT_STATUSES.APPLIED) {
      chosenEndpoints[selectedEndpoints[key].machine_name] = selectedEndpoints[key];
    }
  });

  return {
    selectedLayout: monitoringLayout.selectedLayout,
    profile: profile.profile,
    chosenEndpoints,
  };
};
export { DefaultLayout as DefaultLayoutTest };
export default connect(mapStateToProps, null)(injectIntl(withRouter(DefaultLayout)));
