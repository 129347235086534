function ActionCableType() {
  (() => {
    (() => {
      const { slice } = [];

      this.ActionCable = {
        INTERNAL: {
          message_types: {
            welcome: 'welcome',
            ping: 'ping',
            confirmation: 'confirm_subscription',
            rejection: 'reject_subscription',
          },
          default_mount_path: '/cable',
          protocols: ['actioncable-v1-json', 'actioncable-unsupported'],
        },
        WebSocket: window.WebSocket,
        logger: window.console,
        createConsumer(url) {
          let ref;
          if (url == null) {
            ref = this.getConfig('url');
            // eslint-disable-next-line no-param-reassign
            url = ref != null ? ref : this.INTERNAL.default_mount_path;
          }
          // eslint-disable-next-line no-use-before-define
          return new ActionCable.Consumer(this.createWebSocketURL(url));
        },
        getConfig(name) {
          const element = document.head.querySelector(`meta[name='action-cable-${name}']`);
          // eslint-disable-next-line no-void
          return element != null ? element.getAttribute('content') : void 0;
        },
        createWebSocketURL(url) {
          let a;
          if (url && !/^wss?:/i.test(url)) {
            a = document.createElement('a');
            a.href = url;
            a.protocol = a.protocol.replace('http', 'ws');
            return a.href;
          }
          return url;
        },
        startDebugging() {
          this.debugging = true;
        },
        stopDebugging() {
          this.debugging = null;
        },
        log() {
          let ref;
          // eslint-disable-next-line prefer-rest-params
          const messages = arguments.length >= 1 ? slice.call(arguments, 0) : [];
          if (this.debugging) {
            messages.push(Date.now());
            (ref = this.logger).log.apply(ref, ['[ActionCable]'].concat(slice.call(messages)));
          }
        },
      };
    }).call(this);
  }).call(this);

  const { ActionCable } = this;

  (() => {
    ((...rest) => {
      const bind = function (fn, me) { return function () { return fn.apply(me, rest); }; };

      ActionCable.ConnectionMonitor = (() => {
        let clamp; let now; let
          secondsSince;

        function ConnectionMonitor(connection) {
          this.connection = connection;
          this.visibilityDidChange = bind(this.visibilityDidChange, this);
          this.reconnectAttempts = 0;
        }
        ConnectionMonitor.pollInterval = {
          min: 3,
          max: 30,
        };

        ConnectionMonitor.staleThreshold = 6;

        ConnectionMonitor.prototype.start = function () {
          if (!this.isRunning()) {
            this.startedAt = now();
            delete this.stoppedAt;
            this.startPolling();
            document.addEventListener('visibilitychange', this.visibilityDidChange);
            return ActionCable.log(`ConnectionMonitor started. pollInterval = ${this.getPollInterval()} ms`);
          }
          return undefined;
        };

        ConnectionMonitor.prototype.stop = function () {
          if (this.isRunning()) {
            this.stoppedAt = now();
            this.stopPolling();
            document.removeEventListener('visibilitychange', this.visibilityDidChange);
            return ActionCable.log('ConnectionMonitor stopped');
          }
          return undefined;
        };

        ConnectionMonitor.prototype.isRunning = function () {
          return (this.startedAt != null) && (this.stoppedAt == null);
        };

        ConnectionMonitor.prototype.recordPing = function () {
          this.pingedAt = now();
        };

        ConnectionMonitor.prototype.recordConnect = function () {
          this.reconnectAttempts = 0;
          this.recordPing();
          delete this.disconnectedAt;
          return ActionCable.log('ConnectionMonitor recorded connect');
        };

        ConnectionMonitor.prototype.recordDisconnect = function () {
          this.disconnectedAt = now();
          return ActionCable.log('ConnectionMonitor recorded disconnect');
        };

        ConnectionMonitor.prototype.startPolling = function () {
          this.stopPolling();
          return this.poll();
        };

        ConnectionMonitor.prototype.stopPolling = function () {
          return clearTimeout(this.pollTimeout);
        };

        ConnectionMonitor.prototype.poll = function () {
          this.pollTimeout = setTimeout((function (_this) {
            return function () {
              _this.reconnectIfStale();
              return _this.poll();
            };
          }(this)), this.getPollInterval());
          return this.pollTimeout;
        };

        ConnectionMonitor.prototype.getPollInterval = function () {
          const ref = this.constructor.pollInterval;
          const { min } = ref;
          const { max } = ref;
          const interval = 5 * Math.log(this.reconnectAttempts + 1);
          return Math.round(clamp(interval, min, max) * 1000);
        };

        ConnectionMonitor.prototype.reconnectIfStale = function () {
          if (this.connectionIsStale()) {
            ActionCable.log(`ConnectionMonitor detected stale connection. reconnectAttempts = ${this.reconnectAttempts}, pollInterval = ${this.getPollInterval()} ms, time disconnected = ${secondsSince(this.disconnectedAt)} s, stale threshold = ${this.constructor.staleThreshold} s`);
            this.reconnectAttempts += 1;
            if (this.disconnectedRecently()) {
              return ActionCable.log('ConnectionMonitor skipping reopening recent disconnect');
            }
            ActionCable.log('ConnectionMonitor reopening');
            return this.connection.reopen();
          }
          return undefined;
        };

        ConnectionMonitor.prototype.connectionIsStale = function () {
          const ref = this.pingedAt;
          return secondsSince(ref != null ? ref : this.startedAt) > this.constructor.staleThreshold;
        };

        ConnectionMonitor.prototype.disconnectedRecently = function () {
          return this.disconnectedAt
            && secondsSince(this.disconnectedAt) < this.constructor.staleThreshold;
        };

        ConnectionMonitor.prototype.visibilityDidChange = function () {
          if (document.visibilityState === 'visible') {
            return setTimeout((function (_this) {
              return function () {
                if (_this.connectionIsStale() || !_this.connection.isOpen()) {
                  ActionCable.log(`ConnectionMonitor reopening stale connection on visibilitychange. visbilityState = ${document.visibilityState}`);
                  return _this.connection.reopen();
                }
                return undefined;
              };
            }(this)), 200);
          }
          return undefined;
        };

        now = function () {
          return new Date().getTime();
        };

        secondsSince = function (time) {
          return (now() - time) / 1000;
        };

        clamp = function (number, min, max) {
          return Math.max(min, Math.min(max, number));
        };

        return ConnectionMonitor;
      })();
    }).call(this);
    (() => {
      let supportedProtocols = [];
      // let unsupportedProtocol = undefined;
      const { slice } = [];
      // eslint-disable-next-line prefer-rest-params
      const bind = function (fn, me) { return function () { return fn.apply(me, arguments); }; };
      // eslint-disable-next-line no-plusplus
      const { indexOf } = [];

      const ref = ActionCable.INTERNAL;
      const { message_types } = ref;
      const { protocols } = ref;

      // supportedProtocols = 2 <= protocols.length
      // ? slice.call(protocols, 0, i = protocols.length - 1)
      // : (i = 0, []), unsupportedProtocol = protocols[i++];
      if (protocols.length >= 2) {
        supportedProtocols = slice.call(protocols, 0, protocols.length - 1);
      }
      // else {
      //   supportedProtocols = [];
      //   // unsupportedProtocol = protocols[i++];
      // }
      ActionCable.Connection = (() => {
        function Connection(consumer) {
          this.consumer = consumer;
          this.open = bind(this.open, this);
          this.subscriptions = this.consumer.subscriptions;
          this.monitor = new ActionCable.ConnectionMonitor(this);
          this.disconnected = true;
        }
        Connection.reopenDelay = 500;

        Connection.prototype.send = function (data) {
          if (this.isOpen()) {
            this.webSocket.send(JSON.stringify(data));
            return true;
          }
          return false;
        };

        Connection.prototype.open = function () {
          if (this.isActive()) {
            ActionCable.log(`Attempted to open WebSocket, but existing socket is ${this.getState()}`);
            return false;
          }
          ActionCable.log(`Opening WebSocket, current state is ${this.getState()}, subprotocols: ${protocols}`);
          if (this.webSocket != null) {
            this.uninstallEventHandlers();
          }
          this.webSocket = new ActionCable.WebSocket(this.consumer.url, protocols);
          this.installEventHandlers();
          this.monitor.start();
          return true;
        };

        Connection.prototype.close = function (arg) {
          let ref1;
          const { allowReconnect } = arg != null ? arg : {
            allowReconnect: true,
          };
          if (!allowReconnect) {
            this.monitor.stop();
          }
          if (this.isActive()) {
            ref1 = this.webSocket;
            // eslint-disable-next-line no-void
            return ref1 != null ? ref1.close() : void 0;
          }
          return undefined;
        };

        Connection.prototype.reopen = function () {
          let error;
          ActionCable.log(`Reopening WebSocket, current state is ${this.getState()}`);
          if (this.isActive()) {
            try {
              return this.close();
            } catch (error1) {
              error = error1;
              return ActionCable.log('Failed to reopen WebSocket', error);
            } finally {
              ActionCable.log(`Reopening WebSocket in ${this.constructor.reopenDelay}ms`);
              setTimeout(this.open, this.constructor.reopenDelay);
            }
          } else {
            return this.open();
          }
        };

        Connection.prototype.getProtocol = function () {
          const ref1 = this.webSocket;
          // eslint-disable-next-line no-void
          return ref1 != null ? ref1.protocol : void 0;
        };

        Connection.prototype.isOpen = function () {
          return this.isState('open');
        };

        Connection.prototype.isActive = function () {
          return this.isState('open', 'connecting');
        };

        Connection.prototype.isProtocolSupported = function () {
          const ref1 = this.getProtocol();
          return indexOf.call(supportedProtocols, ref1) >= 0;
        };

        Connection.prototype.isState = function () {
          const ref1 = this.getState();
          // eslint-disable-next-line prefer-rest-params
          const states = arguments.length >= 1 ? slice.call(arguments, 0) : [];
          return indexOf.call(states, ref1) >= 0;
        };

        Connection.prototype.getState = function () {
          let ref1; let state; let
            value;
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (state in WebSocket) {
            value = WebSocket[state];
            // eslint-disable-next-line react/no-this-in-sfc
            ref1 = this.webSocket;
            // eslint-disable-next-line no-void
            if (value === (ref1 != null ? ref1.readyState : void 0)) {
              return state.toLowerCase();
            }
          }
          return null;
        };

        Connection.prototype.installEventHandlers = function () {
          let eventName; let
            handler;
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (eventName in this.events) {
            handler = this.events[eventName].bind(this);
            this.webSocket[`on${eventName}`] = handler;
          }
        };

        Connection.prototype.uninstallEventHandlers = function () {
          let eventName;
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (eventName in this.events) {
            this.webSocket[`on${eventName}`] = function () {};
          }
        };

        Connection.prototype.events = {
          message(event) {
            if (!this.isProtocolSupported()) {
              return undefined;
            }
            const ref1 = JSON.parse(event.data);
            const { identifier } = ref1;
            const { message } = ref1;
            const { type } = ref1;
            switch (type) {
              case message_types.welcome:
                this.monitor.recordConnect();
                return this.subscriptions.reload();
              case message_types.ping:
                return this.monitor.recordPing();
              case message_types.confirmation:
                return this.subscriptions.notify(identifier, 'connected');
              case message_types.rejection:
                return this.subscriptions.reject(identifier);
              default:
                return this.subscriptions.notify(identifier, 'received', message);
            }
          },
          open() {
            ActionCable.log(`WebSocket onopen event, using '${this.getProtocol()}' subprotocol`);
            this.disconnected = false;
            if (!this.isProtocolSupported()) {
              ActionCable.log('Protocol is unsupported. Stopping monitor and disconnecting.');
              return this.close({
                allowReconnect: false,
              });
            }
            return undefined;
          },
          close() {
            ActionCable.log('WebSocket onclose event');
            if (this.disconnected) {
              return undefined;
            }
            this.disconnected = true;
            this.monitor.recordDisconnect();
            return this.subscriptions.notifyAll('disconnected', {
              willAttemptReconnect: this.monitor.isRunning(),
            });
          },
          error() {
            return ActionCable.log('WebSocket onerror event');
          },
        };

        return Connection;
      })();
    }).call(this);
    (() => {
      const { slice } = [];

      ActionCable.Subscriptions = (() => {
        function Subscriptions(consumer) {
          this.consumer = consumer;
          this.subscriptions = [];
        }

        Subscriptions.prototype.create = function (channelName, mixin) {
          const channel = channelName;
          const params = typeof channel === 'object' ? channel : {
            channel,
          };
          const subscription = new ActionCable.Subscription(this.consumer, params, mixin);
          return this.add(subscription);
        };

        Subscriptions.prototype.add = function (subscription) {
          this.subscriptions.push(subscription);
          this.consumer.ensureActiveConnection();
          this.notify(subscription, 'initialized');
          this.sendCommand(subscription, 'subscribe');
          return subscription;
        };

        Subscriptions.prototype.remove = function (subscription) {
          this.forget(subscription);
          if (!this.findAll(subscription.identifier).length) {
            this.sendCommand(subscription, 'unsubscribe');
          }
          return subscription;
        };

        Subscriptions.prototype.reject = function (identifier) {
          let i; let len; let subscription;
          const ref = this.findAll(identifier);
          const results = [];
          for (i = 0, len = ref.length; i < len; i += 1) {
            subscription = ref[i];
            this.forget(subscription);
            this.notify(subscription, 'rejected');
            results.push(subscription);
          }
          return results;
        };

        Subscriptions.prototype.forget = function (subscription) {
          let s;
          this.subscriptions = (function () {
            let i; let len;
            const ref = this.subscriptions;
            const results = [];
            for (i = 0, len = ref.length; i < len; i += 1) {
              s = ref[i];
              if (s !== subscription) {
                results.push(s);
              }
            }
            return results;
          }).call(this);
          return subscription;
        };

        Subscriptions.prototype.findAll = function (identifier) {
          let i; let len; let s;
          const ref = this.subscriptions;
          const results = [];
          for (i = 0, len = ref.length; i < len; i += 1) {
            s = ref[i];
            if (s.identifier === identifier) {
              results.push(s);
            }
          }
          return results;
        };

        Subscriptions.prototype.reload = function () {
          let i; let len; let subscription;
          const ref = this.subscriptions;
          const results = [];
          for (i = 0, len = ref.length; i < len; i += 1) {
            subscription = ref[i];
            results.push(this.sendCommand(subscription, 'subscribe'));
          }
          return results;
        };

        Subscriptions.prototype.notifyAll = function () {
          let i; let len; let subscription;
          // eslint-disable-next-line prefer-rest-params
          const callbackName = arguments[0];
          // eslint-disable-next-line prefer-rest-params
          const args = arguments.length >= 2 ? slice.call(arguments, 1) : [];
          const ref = this.subscriptions;
          const results = [];
          for (i = 0, len = ref.length; i < len; i += 1) {
            subscription = ref[i];
            // eslint-disable-next-line prefer-spread
            results.push(this.notify.apply(this, [subscription, callbackName]
              .concat(slice.call(args))));
          }
          return results;
        };

        Subscriptions.prototype.notify = function () {
          let i; let len; let subscriptions;
          // eslint-disable-next-line prefer-rest-params
          let subscription = arguments[0];
          // eslint-disable-next-line prefer-rest-params
          const callbackName = arguments[1];
          // eslint-disable-next-line prefer-rest-params
          const args = arguments.length >= 3 ? slice.call(arguments, 2) : [];
          if (typeof subscription === 'string') {
            subscriptions = this.findAll(subscription);
          } else {
            subscriptions = [subscription];
          }
          const results = [];
          for (i = 0, len = subscriptions.length; i < len; i += 1) {
            subscription = subscriptions[i];
            results.push(typeof subscription[callbackName] === 'function'
              // eslint-disable-next-line no-void,prefer-spread
              ? subscription[callbackName].apply(subscription, args) : void 0);
          }
          return results;
        };

        Subscriptions.prototype.sendCommand = function (subscription, command) {
          const { identifier } = subscription;
          return this.consumer.send({
            command,
            identifier,
          });
        };

        return Subscriptions;
      })();
    }).call(this);
    (() => {
      ActionCable.Subscription = (() => {
        let extend;

        function Subscription(consumer, params, mixin) {
          this.consumer = consumer;
          if (params == null) {
            // eslint-disable-next-line no-param-reassign
            params = {};
          }
          this.identifier = JSON.stringify(params);
          extend(this, mixin);
        }

        Subscription.prototype.perform = function (action, data) {
          if (data == null) {
            // eslint-disable-next-line no-param-reassign
            data = {};
          }
          // eslint-disable-next-line no-param-reassign
          data.action = action;
          return this.send(data);
        };

        Subscription.prototype.send = function (data) {
          return this.consumer.send({
            command: 'message',
            identifier: this.identifier,
            data: JSON.stringify(data),
          });
        };

        Subscription.prototype.unsubscribe = function () {
          return this.consumer.subscriptions.remove(this);
        };

        extend = function (object, properties) {
          let key; let
            value;
          if (properties != null) {
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (key in properties) {
              value = properties[key];
              // eslint-disable-next-line no-param-reassign
              object[key] = value;
            }
          }
          return object;
        };

        return Subscription;
      })();
    }).call(this);
    (() => {
      ActionCable.Consumer = (() => {
        function Consumer(url) {
          this.url = url;
          this.subscriptions = new ActionCable.Subscriptions(this);
          this.connection = new ActionCable.Connection(this);
        }

        Consumer.prototype.send = function (data) {
          return this.connection.send(data);
        };

        Consumer.prototype.connect = function () {
          return this.connection.open();
        };

        Consumer.prototype.disconnect = function () {
          return this.connection.close({
            allowReconnect: false,
          });
        };

        Consumer.prototype.ensureActiveConnection = function () {
          if (!this.connection.isActive()) {
            return this.connection.open();
          }
          return undefined;
        };

        return Consumer;
      })();
    }).call(this);
  }).call(this);

  // if (typeof module === 'object' && module.exports) {
  //   module.exports = ActionCable;
  // }
  // else if (typeof define === "function" && define.amd) {
  //   define(ActionCable);
  // }
}

const ac = new ActionCableType();
export default ac.ActionCable;
