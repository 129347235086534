import React from 'react';
import { Layout } from 'antd';
import App from '@routes/index';
import Sidebar from '@containers/Sidebar/index';
import CameraControls from '@containers/CameraControls';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { logout } from '@actions/iObserverActions/GlobalActions';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from '@constants/ThemeSetting';
import CustomModalNotifications from '@components/modals/CustomModalNotifications';
import { refreshSessionToken, setSessionExpirationWarning, getProfile } from 'src/appRedux/actions';
import {
  getSessionExpirationTime,
  getSessionExpirationWarningFlag,
} from '@selectors/iObserverSelectors';
import loaderSvg from '@assets/images/loader.svg';
import { useIntl, FormattedMessage } from 'react-intl';
import Countdown from '@components/Countdown';

const { Content, Footer } = Layout;

const MainApp = () => {
  const intl = useIntl();
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const sessionExpirationWarningFlag = useSelector(getSessionExpirationWarningFlag);
  const sessionExpirationTime = useSelector(getSessionExpirationTime);

  const username = useSelector(({ profile }) => profile?.profile?.username);
  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  const onLogout = () => {
    dispatch(logout());
  };

  const refreshToken = () => {
    dispatch(setSessionExpirationWarning(false));
    dispatch(refreshSessionToken());
  };

  const getContainerClass = () => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
      // falls through
      case NAV_STYLE_DEFAULT_HORIZONTAL:
      // falls through
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      // falls through
      case NAV_STYLE_BELOW_HEADER:
      // falls through
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };

  const getSidebar = () => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }

    switch (navStyle) {
      case NAV_STYLE_FIXED:
      // falls through
      case NAV_STYLE_DRAWER:
      // falls through
      case NAV_STYLE_MINI_SIDEBAR:
      // falls through
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      // falls through
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  if (!username) {
    return (
      <div className="loader loading">
        <img src={loaderSvg} alt="loader" />
      </div>
    );
  }

  return (
    <Layout className="gx-app-layout">
      {getSidebar()}
      <Layout>
        <Content className={`gx-layout-content ${getContainerClass()}`}>
          <App match={match} />
          <Footer className="video_control_line">
            {sessionExpirationWarningFlag && (
              <CustomModalNotifications
                visible={sessionExpirationWarningFlag}
                title={<FormattedMessage id="sessionExpire.title" />}
                message={(
                  <p>
                    <FormattedMessage id="sessionExpire.message" />
                    <strong>
                      <Countdown timeLeft={sessionExpirationTime} onTimePassed={onLogout} />
                    </strong>
                    <FormattedMessage id="sessionExpire.minutes" />
                  </p>
                )}
                onOk={() => refreshToken()}
                onCancel={() => onLogout()}
                okBtnText={intl.formatMessage({ id: 'sessionExpire.refresh' })}
                cancelBtnText={intl.formatMessage({ id: 'app.userAuth.logout' })}
                modalClass="session-expiration-modal"
              />
            )}
            <CameraControls />
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainApp;
