import {
  START_OBSERVATION_SESSION_REQUEST,
  START_OBSERVATION_SESSION_SUCCESS,
  START_ROOM_OBSERVATION_REQUEST,
  START_ROOM_OBSERVATION_SUCCESS,
  UPDATE_ROOM_OBSERVATION_REQUEST,
  UPDATE_ROOM_OBSERVATION_SUCCESS,
  END_ROOM_OBSERVATION_REQUEST,
  END_ROOM_OBSERVATION_SUCCESS,
  SEND_COMMAND_BY_MACHINE_NAME,
  SELECT_OBSERVATION_ENDPOINT,
  UPDATE_NIGHT_VIEW,
  START_OBSERVATION_ACTIVITY,
  END_OBSERVATION_ACTIVITY,
  SET_LISTEN,
  SET_TALK,
  SET_NOTIFY,
  FETCH_LISTEN_REASONS_REQUEST,
  FETCH_LISTEN_REASONS_SUCCESS,
  FETCH_TALK_REASONS_REQUEST,
  FETCH_TALK_REASONS_SUCCESS,
  FETCH_ALERT_REASONS_REQUEST,
  FETCH_ALERT_REASONS_SUCCESS,
  FETCH_ALL_REASONS_REQUEST,
  START_NOTIFY,
  END_OBSERVATION_SESSION_REQUEST,
  CLEAN_UP_SESSION,
  REFRESH_CAMERA_ACTION,
  START_OBSERVATIONS,
  GET_AUDIO_VIDEO_REQUEST,
  GET_AUDIO_VIDEO_SUCCESS,
  SET_AUDIO_VIDEO_REQUEST,
  SET_AUDIO_VIDEO_SUCCESS,
  CHECK_ENDPOINT_ACTION,
  CHECK_ENDPOINT_RESULT_ACTION, UPDATE_ENDPOINT_MEDIA_TYPE, SET_AUDIO_VIDEO_BLOCKED,
} from '@constants/iObserverActionTypes';

import {
  CONTROL_COMMANDS,
  PARAMS_PER_COMMAND,
  OBSERVATION_ACTIVITY,
} from '@constants/Settings';

/**
 * @param  {Object} params
 * @return {Object}
 */
export const startObservationSession = (params) => ({
  type: START_OBSERVATION_SESSION_REQUEST,
  payload: params,
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const startObservationSessionSuccess = (params) => ({
  type: START_OBSERVATION_SESSION_SUCCESS,
  payload: params,
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const startRoomObservation = (params) => ({
  type: START_ROOM_OBSERVATION_REQUEST,
  payload: params,
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const startRoomObservationSuccess = (response, endpointId) => ({
  type: START_ROOM_OBSERVATION_SUCCESS,
  payload: { response, id: endpointId },
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const updateRoomObservation = (params) => ({
  type: UPDATE_ROOM_OBSERVATION_REQUEST,
  payload: params,
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const updateRoomObservationSuccess = (response, endpointId) => ({
  type: UPDATE_ROOM_OBSERVATION_SUCCESS,
  payload: { response, id: endpointId },
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const endRoomObservation = (params) => ({
  type: END_ROOM_OBSERVATION_REQUEST,
  payload: params,
});

/**
 * @param  {Object} params
 * @return {Object}
 */
export const endRoomObservationSuccess = (endpointId) => ({
  type: END_ROOM_OBSERVATION_SUCCESS,
  payload: { id: endpointId },
});

export const goToBookmark = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.GO_TO_BOOKMARK,
    params_p: PARAMS_PER_COMMAND.go_to_bookmark.bookmark,
  },
});
export const goToHomeBookmark = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.GO_TO_BOOKMARK,
    params_p: PARAMS_PER_COMMAND.go_to_bookmark.home,
  },
});

export const stopPlayAlert = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.STOP_PLAY_ALERT,
    params_p: PARAMS_PER_COMMAND.stop_play_alert,
  },
});

export const startObserver = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.START_OBSERVER,
  },
});
export const endObserver = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.END_OBSERVER,
  },
});
export const pan = (machine_name = null, direction) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.PAN,
    params_p: PARAMS_PER_COMMAND.pan.dir[direction],
  },
});
export const stop = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.STOP,
    params_p: null,
  },
});
export const setNightView = (machine_name = null, toSet) => {
  const toSetOrNot = toSet ? PARAMS_PER_COMMAND.night_view.on : PARAMS_PER_COMMAND.night_view.off;
  return {
    type: SEND_COMMAND_BY_MACHINE_NAME,
    payload: {
      machine_name,
      command: CONTROL_COMMANDS.NIGHT_VIEW,
      params_p: toSetOrNot,
    },
  };
};
export const pinHomePosition = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.SET_AS_BOOKMARK,
    params_p: PARAMS_PER_COMMAND.set_as_bookmark,
  },
});
export const zoomStop = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.ZOOM,
    params_p: PARAMS_PER_COMMAND.zoom.stop,
  },
});
export const zoomIn = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.ZOOM,
    params_p: PARAMS_PER_COMMAND.zoom.tele,
  },
});
export const zoomOut = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.ZOOM,
    params_p: PARAMS_PER_COMMAND.zoom.wide,
  },
});
export const goToPoint = (machine_name = null, x_val, y_val, withZoom) => {
  const params_p = withZoom
    ? { ...PARAMS_PER_COMMAND.go_to_point.zoom_val_true }
    : { ...PARAMS_PER_COMMAND.go_to_point.zoom_val_false };

  params_p.x_val = x_val;
  params_p.y_val = y_val;

  return {
    type: SEND_COMMAND_BY_MACHINE_NAME,
    payload: {
      machine_name,
      command: CONTROL_COMMANDS.GO_TO_POINT,
      params_p,
    },
  };
};

export const setSelectedObservation = (machine_name = null) => ({
  type: SELECT_OBSERVATION_ENDPOINT,
  payload: {
    machine_name,
  },
});

export const updateNightView = (machine_name = null, toSet) => ({
  type: UPDATE_NIGHT_VIEW,
  payload: {
    machine_name,
    toSet,
  },
});

export const startListen = (machine_name = null, reason = '', toSet) => {
  const params = {};
  params.machine_name = machine_name;
  params.a_type = OBSERVATION_ACTIVITY.TYPES.LISTEN;
  params.reason = reason;
  return {
    type: START_OBSERVATION_ACTIVITY,
    payload: { params, toSet },
  };
};
export const stopListen = (machine_name = null, reason = '', toSet, setOnly = false) => {
  const params = {};
  params.machine_name = machine_name;
  params.a_type = OBSERVATION_ACTIVITY.TYPES.LISTEN;
  params.reason = reason;

  return {
    type: END_OBSERVATION_ACTIVITY,
    payload: { params, toSet, setOnly },
  };
};
export const startTalk = (machine_name = null, reason = '', toSet) => {
  const params = {};

  params.machine_name = machine_name;
  params.a_type = OBSERVATION_ACTIVITY.TYPES.TALK;
  params.reason = reason;
  return {
    type: START_OBSERVATION_ACTIVITY,
    payload: { params, toSet },
  };
};
export const stopTalk = (machine_name = null, reason = '', toSet, setOnly = false) => {
  const params = {};
  params.machine_name = machine_name;
  params.a_type = OBSERVATION_ACTIVITY.TYPES.TALK;
  params.reason = reason;
  return {
    type: END_OBSERVATION_ACTIVITY,
    payload: { params, toSet, setOnly },
  };
};
export const startNotify = (machine_name = null, reason = '', toSet) => {
  const playAlertParams = {
    machine_name,
    command: CONTROL_COMMANDS.START_PLAY_ALERT,
    params_p: {},
  };
  const startActivityParams = {
    machine_name,
    a_type: OBSERVATION_ACTIVITY.TYPES.ALERT,
    reason,
    toSet,
  };

  return {
    type: START_NOTIFY,
    payload: { playAlertParams, startActivityParams },
  };
};
export const stopNotifyPlayAlert = (machine_name = null) => ({
  type: SEND_COMMAND_BY_MACHINE_NAME,
  payload: {
    machine_name,
    command: CONTROL_COMMANDS.STOP_PLAY_ALERT,
    params_p: PARAMS_PER_COMMAND.stop_play_alert,
  },
});

export const startVideoCallAction = (machine_name = null, endCall = false) => {
  const params = {
    machine_name,
    a_type: OBSERVATION_ACTIVITY.TYPES.VIDEO_CALL,
    reason: '-',
  };
  return {
    type: endCall ? END_OBSERVATION_ACTIVITY : START_OBSERVATION_ACTIVITY,
    payload: { params },
  };
};

export const refreshCameraAction = (machine_name = null, toSet) => ({
  type: REFRESH_CAMERA_ACTION,
  payload: {
    machine_name,
    toSet,
  },
});

export const endNotifyActivity = (machine_name = null, reason = '', toSet, setOnly = false) => {
  const params = {};
  params.machine_name = machine_name;
  params.a_type = OBSERVATION_ACTIVITY.TYPES.ALERT;
  params.reason = reason;
  return {
    type: END_OBSERVATION_ACTIVITY,
    payload: { params, toSet, setOnly },
  };
};

export const setListen = (machine_name = null, toSet) => ({
  type: SET_LISTEN,
  payload: {
    machine_name,
    toSet,
  },
});
export const setTalk = (machine_name = null, toSet) => ({
  type: SET_TALK,
  payload: {
    machine_name,
    toSet,
  },
});
export const setNotify = (machine_name = null, toSet) => ({
  type: SET_NOTIFY,
  payload: {
    machine_name,
    toSet,
  },
});

export const fetchListenReasons = () => ({
  type: FETCH_LISTEN_REASONS_REQUEST,
});

export const fetchListenReasonsSuccess = (data) => ({
  type: FETCH_LISTEN_REASONS_SUCCESS,
  payload: data,
});

export const fetchTalkReasons = () => ({
  type: FETCH_TALK_REASONS_REQUEST,
});

export const fetchTalkReasonsSuccess = (data) => ({
  type: FETCH_TALK_REASONS_SUCCESS,
  payload: data,
});

export const fetchNotifyReasons = () => ({
  type: FETCH_ALERT_REASONS_REQUEST,
});

export const fetchNotifyReasonsSuccess = (data) => ({
  type: FETCH_ALERT_REASONS_SUCCESS,
  payload: data,
});

export const fetchAllReasons = () => ({
  type: FETCH_ALL_REASONS_REQUEST,
});

export const endSession = () => ({
  type: END_OBSERVATION_SESSION_REQUEST,
});

export const cleanUpSession = () => ({
  type: CLEAN_UP_SESSION,
});

export const startObservations = () => ({
  type: START_OBSERVATIONS,
});

export const getAudioVideoRequest = () => ({
  type: GET_AUDIO_VIDEO_REQUEST,
});

export const getAudioVideoSuccess = (payload) => ({
  type: GET_AUDIO_VIDEO_SUCCESS,
  payload,
});

export const setAudioVideoBlocked = (payload) => ({
  type: SET_AUDIO_VIDEO_BLOCKED,
  payload,
});

export const setAudioVideoRequest = (payload) => ({
  type: SET_AUDIO_VIDEO_REQUEST,
  payload,
});

export const setAudioVideoSuccess = (payload) => ({
  type: SET_AUDIO_VIDEO_SUCCESS,
  payload,
});

export const checkEndpointAction = (machine_name = null) => ({
  type: CHECK_ENDPOINT_ACTION,
  payload: {
    machine_name,
  },
});

export const checkEndpointResultAction = (machine_name, availabilityStatus) => ({
  type: CHECK_ENDPOINT_RESULT_ACTION,
  payload: {
    machine_name,
    availabilityStatus,
  },
});

export const updateEndpointMediaType = (endpointId, mediaType) => ({
  type: UPDATE_ENDPOINT_MEDIA_TYPE,
  payload: {
    endpointId,
    mediaType,
  },
});
