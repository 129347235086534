import React from 'react';
import {
  Row, Col, Button, Radio,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { changeLayout } from '@actions/iObserverActions/LayoutSettingsActions';
import { layoutNames } from '@constants/LayoutSettings';
import { ENDPOINT_STATUSES } from '@constants/Settings';
import IntlMessages from '../util/IntlMessages';

export class InnerLayoutWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selectedLayout,
      layoutName: props.selectedLayout,
    };
    this.onChangeLayout = this.onChangeLayout.bind(this);
    this.onChangeRooms = this.onChangeRooms.bind(this);
    this.applyBtn = React.createRef();
  }

  onChangeLayout(layoutName) {
    const { changeLayoutProp } = this.props;
    changeLayoutProp(layoutName);
  }

  onChangeRooms(event, layoutName) {
    this.setState({
      value: layoutName,
      layoutName,
    });
  }

  applyLayout = () => {
    const { layoutName } = this.state;
    this.onChangeLayout(layoutName);
  }

  applyBtnHandler = () => {
    if (this.applyBtn.current) {
      this.applyBtn.current.disabled = true;
      setTimeout(() => {
        if (this.applyBtn.current) {
          this.applyBtn.current.disabled = false;
        }
      }, 2000);
    }
  }

  render() {
    const { value } = this.state;
    const { chosenEndpoints, selectedLayout } = this.props;
    const endpointsCounter = Object.keys(chosenEndpoints).length;

    return (
      <div className="inner-layout-wrapper view-wrapper-layouts">
        <div className="inner-wrapper-layouts scroll-container">
          <div className="scroll-content">
            <h1 className="title-layout"><IntlMessages id="layouts.titleLayoutPage" /></h1>
            <Radio.Group style={layoutNames.DEFAULT_LAYOUT && { width: '72%' }} className={selectedLayout === layoutNames.DEFAULT_LAYOUT ? null : 'layout-position'} value={value}>
              <Row gutter={[16, 10]}>
                {/* DEFAULT_LAYOUT */}
                <Col className="layout-cell" sm={12} md={12} lg={12} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.DEFAULT_LAYOUT}
                    checked={selectedLayout === layoutNames.DEFAULT_LAYOUT}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.DEFAULT_LAYOUT)}
                  >
                    <IntlMessages id="layouts.txtDefault" />
                  </Radio>
                  {value === layoutNames.DEFAULT_LAYOUT
                    ? <img className="unselected" onClick={() => this.onChangeRooms(1, layoutNames.DEFAULT_LAYOUT)} src={require('assets/images/layouts/layout-form-page/layout-default-violet.svg')} />
                    : <img className="selected" onClick={() => this.onChangeRooms(1, layoutNames.DEFAULT_LAYOUT)} src={require('assets/images/layouts/layout-form-page/layout-default-gray.svg')} />}
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={12}
                  xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 0 : 12}
                />
                {/* LAYOUT_3_ROOMS_LEFT */}
                <Col
                  sm={endpointsCounter > 3 ? 0 : 12}
                  md={endpointsCounter > 3 ? 0 : 12}
                  lg={endpointsCounter > 3 ? 0 : 12}
                  xl={endpointsCounter > 3 ? 0 : selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}
                >
                  <Radio
                    value={layoutNames.LAYOUT_3_ROOMS_LEFT}
                    checked={selectedLayout === layoutNames.LAYOUT_3_ROOMS_LEFT}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_3_ROOMS_LEFT)}
                  >
                    <IntlMessages id="layouts.txtTreeRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_3_ROOMS_LEFT
                    ? <img className="selected" onClick={() => this.onChangeRooms(2, layoutNames.LAYOUT_3_ROOMS_LEFT)} src={require('assets/images/layouts/layout-form-page/layout-3rooms-1-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(2, layoutNames.LAYOUT_3_ROOMS_LEFT)} src={require('assets/images/layouts/layout-form-page/layout-3rooms-1-gray.svg')} />}
                </Col>
                {/* LAYOUT_3_ROOMS_RIGHT */}
                <Col sm={endpointsCounter > 3 ? 0 : 12} md={endpointsCounter > 3 ? 0 : 12} lg={endpointsCounter > 3 ? 0 : 12} xl={endpointsCounter > 3 ? 0 : selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_3_ROOMS_RIGHT}
                    checked={selectedLayout === layoutNames.LAYOUT_3_ROOMS_RIGHT}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_3_ROOMS_RIGHT)}
                  >
                    <IntlMessages id="layouts.txtTreeRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_3_ROOMS_RIGHT
                    ? <img className="selected" onClick={() => this.onChangeRooms(3, layoutNames.LAYOUT_3_ROOMS_RIGHT)} src={require('assets/images/layouts/layout-form-page/layout-3rooms-2-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(3, layoutNames.LAYOUT_3_ROOMS_RIGHT)} src={require('assets/images/layouts/layout-form-page/layout-3rooms-2-gray.svg')} />}
                </Col>
                <Col sm={12} md={12} lg={12} xl={6} />
              </Row>
              <Row gutter={[16, 10]}>
                {/* LAYOUT_6_ROOMS_LEFT */}
                <Col sm={endpointsCounter > 6 ? 0 : 12} md={endpointsCounter > 6 ? 0 : 12} lg={endpointsCounter > 6 ? 0 : 12} xl={endpointsCounter > 6 ? 0 : selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_6_ROOMS_LEFT}
                    checked={selectedLayout === layoutNames.LAYOUT_6_ROOMS_LEFT}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_6_ROOMS_LEFT)}
                  >
                    <IntlMessages id="layouts.txtSixRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_6_ROOMS_LEFT
                    ? <img className="selected" onClick={() => this.onChangeRooms(4, layoutNames.LAYOUT_6_ROOMS_LEFT)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-1-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(4, layoutNames.LAYOUT_6_ROOMS_LEFT)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-1-gray.svg')} />}
                </Col>
                {/* LAYOUT_6_ROOMS_RIGHT */}
                <Col sm={endpointsCounter > 6 ? 0 : 12} md={endpointsCounter > 6 ? 0 : 12} lg={endpointsCounter > 6 ? 0 : 12} xl={endpointsCounter > 6 ? 0 : selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_6_ROOMS_RIGHT}
                    checked={selectedLayout === layoutNames.LAYOUT_6_ROOMS_RIGHT}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_6_ROOMS_RIGHT)}
                  >
                    <IntlMessages id="layouts.txtSixRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_6_ROOMS_RIGHT
                    ? <img className="selected" onClick={() => this.onChangeRooms(5, layoutNames.LAYOUT_6_ROOMS_RIGHT)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-2-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(5, layoutNames.LAYOUT_6_ROOMS_RIGHT)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-2-gray.svg')} />}
                </Col>
                {/* LAYOUT_6_ROOMS_SYMMETRIC */}
                <Col sm={endpointsCounter > 6 ? 0 : 12} md={endpointsCounter > 6 ? 0 : 12} lg={endpointsCounter > 6 ? 0 : 12} xl={endpointsCounter > 6 ? 0 : selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_6_ROOMS_SYMMETRIC}
                    checked={selectedLayout === layoutNames.LAYOUT_6_ROOMS_SYMMETRIC}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_6_ROOMS_SYMMETRIC)}
                  >
                    <IntlMessages id="layouts.txtSixRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_6_ROOMS_SYMMETRIC
                    ? <img className="selected" onClick={() => this.onChangeRooms(6, layoutNames.LAYOUT_6_ROOMS_SYMMETRIC)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-3-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(6, layoutNames.LAYOUT_6_ROOMS_SYMMETRIC)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-3-gray.svg')} />}
                </Col>
                {/* LAYOUT_6_ROOMS_EQUAL */}
                <Col sm={endpointsCounter > 6 ? 0 : 12} md={endpointsCounter > 6 ? 0 : 12} lg={endpointsCounter > 6 ? 0 : 12} xl={endpointsCounter > 6 ? 0 : selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_6_ROOMS_EQUAL}
                    checked={selectedLayout === layoutNames.LAYOUT_6_ROOMS_EQUAL}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_6_ROOMS_EQUAL)}
                  >
                    <IntlMessages id="layouts.txtSixRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_6_ROOMS_EQUAL
                    ? <img className="selected" onClick={() => this.onChangeRooms(7, layoutNames.LAYOUT_6_ROOMS_EQUAL)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-4-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(7, layoutNames.LAYOUT_6_ROOMS_EQUAL)} src={require('assets/images/layouts/layout-form-page/layout-6rooms-4-gray.svg')} />}
                </Col>
              </Row>
              <Row gutter={[16, 10]}>
                {/* LAYOUT_12_ROOMS_LEFT */}
                <Col className="layout-cell" sm={12} md={12} lg={12} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_12_ROOMS_LEFT}
                    checked={selectedLayout === layoutNames.LAYOUT_12_ROOMS_LEFT}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_12_ROOMS_LEFT)}
                  >
                    <IntlMessages id="layouts.txtTwelveRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_12_ROOMS_LEFT
                    ? <img className="selected" onClick={() => this.onChangeRooms(8, layoutNames.LAYOUT_12_ROOMS_LEFT)} src={require('assets/images/layouts/layout-form-page/layout-12rooms-1-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(8, layoutNames.LAYOUT_12_ROOMS_LEFT)} src={require('assets/images/layouts/layout-form-page/layout-12rooms-1-gray.svg')} />}
                </Col>
                {/* LAYOUT_12_ROOMS_EQUAL */}
                <Col className="layout-cell" sm={12} md={12} lg={12} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 6 : 12}>
                  <Radio
                    value={layoutNames.LAYOUT_12_ROOMS_EQUAL}
                    checked={selectedLayout === layoutNames.LAYOUT_12_ROOMS_EQUAL}
                    onChange={(e) => this.onChangeRooms(e, layoutNames.LAYOUT_12_ROOMS_EQUAL)}
                  >
                    <IntlMessages id="layouts.txtTwelveRooms" />
                  </Radio>
                  {value === layoutNames.LAYOUT_12_ROOMS_EQUAL
                    ? <img className="selected" onClick={() => this.onChangeRooms(9, layoutNames.LAYOUT_12_ROOMS_EQUAL)} src={require('assets/images/layouts/layout-form-page/layout-12rooms-2-violet.svg')} />
                    : <img className="unselected" onClick={() => this.onChangeRooms(9, layoutNames.LAYOUT_12_ROOMS_EQUAL)} src={require('assets/images/layouts/layout-form-page/layout-12rooms-2-gray.svg')} />}
                </Col>
                <Col sm={12} md={12} lg={12} xl={6} />
                <Col sm={12} md={12} lg={12} xl={6} />
              </Row>
            </Radio.Group>
          </div>
        </div>
        <div className="gx-d-flex gx-justify-content-end btn-customer-panel-layoyts">
          <Button className="btn-layout-apply" onClick={() => { this.applyLayout(); this.applyBtnHandler(); }} ref={this.applyBtn} id="applyBtn">
            <IntlMessages id="layouts.txtBtnApply" />
          </Button>
        </div>
      </div>
    );
  }
}

InnerLayoutWrapper.defaultProps = {

};

InnerLayoutWrapper.propTypes = {
  changeLayoutProp: PropTypes.func.isRequired,
  selectedLayout: PropTypes.string.isRequired,
  chosenEndpoints: PropTypes.shape().isRequired,
};

export const mapStateToProps = ({ monitoringLayout, endpoints }) => {
  const { selectedEndpoints = {} } = endpoints;
  const chosenEndpoints = {};

  Object.keys(selectedEndpoints).forEach((key) => {
    if (selectedEndpoints[key].status === ENDPOINT_STATUSES.ADMITTED
      || selectedEndpoints[key].status === ENDPOINT_STATUSES.APPLIED) {
      chosenEndpoints[selectedEndpoints[key].machine_name] = selectedEndpoints[key];
    }
  });

  return {
    chosenEndpoints,
    selectedLayout: monitoringLayout.selectedLayout,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  changeLayoutProp: (layout) => dispatch(changeLayout(layout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(withRouter(InnerLayoutWrapper)),
);
