import { cloneDeep } from 'lodash';
import {
  START_OBSERVATION_SESSION_SUCCESS,
  FETCH_LISTEN_REASONS_SUCCESS,
  FETCH_TALK_REASONS_SUCCESS,
  FETCH_ALERT_REASONS_SUCCESS,
  CLEAN_UP_SESSION,
  TRANSFER_SESSION_SUCCESS,
  CANCEL_TRANSFER_SESSION_SUCCESS,
  GET_TRANSFERS_REQUEST,
  GET_TRANSFERS_SUCCESS,
  SET_SESSION_POPUP_MESSAGE,
  GET_AUDIO_VIDEO_REQUEST,
  GET_AUDIO_VIDEO_SUCCESS,
  SET_AUDIO_VIDEO_SUCCESS, SET_AUDIO_VIDEO_BLOCKED,
} from '@constants/iObserverActionTypes';

const initialSettings = {
  session: {
    observation_session_id: null,
  },
  transfer_id: -1,
  listenReasons: [],
  talkReasons: [],
  notifyReasons: [],
  transfers: [],
  loading: false,
  popUpMessage: '',
  audioVideo: {
    speakerId: '',
  },
  loadingAudioVideo: true,
};

const tests = {
  observation: null,
};

const observation = (state = cloneDeep(initialSettings), action) => {
  let isVideoBlocked;
  let isMicBlocked;
  switch (action.type) {
    case START_OBSERVATION_SESSION_SUCCESS:
      return {
        ...state,
        session: { ...action.payload },
      };
    case FETCH_LISTEN_REASONS_SUCCESS:
      return {
        ...state,
        listenReasons: [...action.payload],
      };
    case FETCH_TALK_REASONS_SUCCESS:
      return {
        ...state,
        talkReasons: [...action.payload],
      };
    case FETCH_ALERT_REASONS_SUCCESS:
      return {
        ...state,
        notifyReasons: [...action.payload],
      };
    case CLEAN_UP_SESSION:
      return {
        ...state,
        session: {
          observation_session_id: null,
        },
        transfer_id: -1,
      };
    case TRANSFER_SESSION_SUCCESS:
      return {
        ...state,
        transfer_id: action.payload.transfer_id,
      };
    case CANCEL_TRANSFER_SESSION_SUCCESS:
      return {
        ...state,
        transfer_id: -1,
      };
    case GET_TRANSFERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSFERS_SUCCESS:
      return {
        ...state,
        transfers: [...action.payload.list],
        loading: false,
      };
    case SET_SESSION_POPUP_MESSAGE:
      return {
        ...state,

        popUpMessage: action.payload.popUpMessage,
      };
    case GET_AUDIO_VIDEO_REQUEST:
      return {
        ...state,
        loadingAudioVideo: true,
      };
    case GET_AUDIO_VIDEO_SUCCESS:
      return {
        ...state,
        audioVideo: { ...state.audioVideo, ...action.payload },
        loadingAudioVideo: false,
      };
    case SET_AUDIO_VIDEO_BLOCKED:
      console.log(`${action.payload.isMicBlocked} action`);
      if (action.payload.isMicBlocked !== null) {
        isMicBlocked = action.payload.isMicBlocked;
      } else if (state.audioVideo.isMicBlocked === undefined) {
        isMicBlocked = true;
      } else {
        isMicBlocked = state.audioVideo.isMicBlocked;
      }
      if (action.payload.isVideoBlocked !== null) {
        isVideoBlocked = action.payload.isVideoBlocked;
      } else {
        isVideoBlocked = state.audioVideo.isVideoBlocked;
      }
      return {
        ...state,
        audioVideo: { ...state.audioVideo, isMicBlocked, isVideoBlocked },
      };
    case SET_AUDIO_VIDEO_SUCCESS:
      return {
        ...state,
        audioVideo: { ...state.audioVideo, ...action.payload },
        // loadingAudioVideo: false,
      };

    default:
      return state;
  }
};

export default observation;

if (process.env.NODE_ENV === 'test') {
  tests.observation = observation;
}

export { tests }
