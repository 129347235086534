import React from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import EndpointVideo from '../EndpointVideo';

const Layout12RoomsLeft = (props) => {
  const {
    endpointsData, selectEndpoint, selectedEndpoint, refreshCamera, makeCall, callbacks,
  } = props;

  return (
    <Row className="video-height video-thumbs-holder">
      <Col span={14} className="video-height">
        <Row className="row-height-large">
          <Col span={16} className="video-thumb video-height padding-right">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[0] ? endpointsData[0].stream : undefined}
                mediaId={endpointsData[0] ? endpointsData[0].mediaId : undefined}
                endpointData={endpointsData[0] ? endpointsData[0].data : undefined}
                endpointStats={endpointsData[0] && endpointsData[0].stats}
                hasCall={!!(endpointsData[0] && endpointsData[0].dialedInPopup)}
                index={0}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
          <Col span={8} className="video-height">
            <Row className="video-height">
              <Col span={24} className="video-thumb video-secon-right-box padding-right-col">
                <div className="video-placeholder-six-rooms">
                  <EndpointVideo
                    selectedEndpoint={selectedEndpoint}
                    selectEndpoint={selectEndpoint}
                    stream={endpointsData[1] ? endpointsData[1].stream : undefined}
                    mediaId={endpointsData[1] ? endpointsData[1].mediaId : undefined}
                    endpointData={endpointsData[1] ? endpointsData[1].data : undefined}
                    endpointStats={endpointsData[1] && endpointsData[1].stats}
                    hasCall={!!(endpointsData[1] && endpointsData[1].dialedInPopup)}
                    index={1}
                    refreshCamera={refreshCamera}
                    makeCall={makeCall}
                    callbacks={callbacks}
                  />
                </div>
              </Col>
              <Col span={24} className="video-thumb video-secon-right-box padding-right-col">
                <div className="video-placeholder-six-rooms">
                  <EndpointVideo
                    selectedEndpoint={selectedEndpoint}
                    selectEndpoint={selectEndpoint}
                    stream={endpointsData[2] ? endpointsData[2].stream : undefined}
                    mediaId={endpointsData[2] ? endpointsData[2].mediaId : undefined}
                    endpointData={endpointsData[2] ? endpointsData[2].data : undefined}
                    endpointStats={endpointsData[2] && endpointsData[2].stats}
                    hasCall={!!(endpointsData[2] && !!endpointsData[2].dialedInPopup)}
                    index={2}
                    refreshCamera={refreshCamera}
                    makeCall={makeCall}
                    callbacks={callbacks}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="row-small-video-s">
          <Col span={8} className="video-thumb padding-right-col">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[3] ? endpointsData[3].stream : undefined}
                mediaId={endpointsData[3] ? endpointsData[3].mediaId : undefined}
                endpointData={endpointsData[3] ? endpointsData[3].data : undefined}
                endpointStats={endpointsData[3] && endpointsData[3].stats}
                hasCall={!!(endpointsData[3] && endpointsData[3].dialedInPopup)}
                index={3}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
          <Col span={8} className="video-thumb padding-right-col">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[4] ? endpointsData[4].stream : undefined}
                mediaId={endpointsData[4] ? endpointsData[4].mediaId : undefined}
                endpointData={endpointsData[4] ? endpointsData[4].data : undefined}
                endpointStats={endpointsData[4] && endpointsData[4].stats}
                hasCall={!!(endpointsData[4] && endpointsData[4].dialedInPopup)}
                index={4}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
          <Col span={8} className="video-thumb padding-right-col">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[5] ? endpointsData[5].stream : undefined}
                mediaId={endpointsData[5] ? endpointsData[5].mediaId : undefined}
                endpointData={endpointsData[5] ? endpointsData[5].data : undefined}
                endpointStats={endpointsData[5] && endpointsData[5].stats}
                hasCall={!!(endpointsData[5] && endpointsData[5].dialedInPopup)}
                index={5}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={10} className="video-height">
        <Row className="video-small-box-height">
          <Col span={12} className="video-thumb padding-right">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[6] ? endpointsData[6].stream : undefined}
                mediaId={endpointsData[6] ? endpointsData[6].mediaId : undefined}
                endpointData={endpointsData[6] ? endpointsData[6].data : undefined}
                endpointStats={endpointsData[6] && endpointsData[6].stats}
                hasCall={!!(endpointsData[6] && endpointsData[6].dialedInPopup)}
                index={6}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
          <Col span={12} className="video-thumb padding-left">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[7] ? endpointsData[7].stream : undefined}
                mediaId={endpointsData[7] ? endpointsData[7].mediaId : undefined}
                endpointData={endpointsData[7] ? endpointsData[7].data : undefined}
                endpointStats={endpointsData[7] && endpointsData[7].stats}
                hasCall={!!(endpointsData[7] && endpointsData[7].dialedInPopup)}
                index={7}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
        </Row>
        <Row className="video-small-box-height">
          <Col span={12} className="video-thumb padding-right">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[8] ? endpointsData[8].stream : undefined}
                mediaId={endpointsData[8] ? endpointsData[8].mediaId : undefined}
                endpointData={endpointsData[8] ? endpointsData[8].data : undefined}
                endpointStats={endpointsData[8] && endpointsData[8].stats}
                hasCall={!!(endpointsData[8] && endpointsData[8].dialedInPopup)}
                index={8}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
          <Col span={12} className="video-thumb padding-left">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[9] ? endpointsData[9].stream : undefined}
                mediaId={endpointsData[9] ? endpointsData[9].mediaId : undefined}
                endpointData={endpointsData[9] ? endpointsData[9].data : undefined}
                endpointStats={endpointsData[9] && endpointsData[9].stats}
                hasCall={!!(endpointsData[9] && endpointsData[9].dialedInPopup)}
                index={9}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
        </Row>
        <Row className="video-small-box-height">
          <Col span={12} className="video-thumb padding-right">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[10] ? endpointsData[10].stream : undefined}
                mediaId={endpointsData[10] ? endpointsData[10].mediaId : undefined}
                endpointData={endpointsData[10] ? endpointsData[10].data : undefined}
                endpointStats={endpointsData[10] && endpointsData[10].stats}
                hasCall={!!(endpointsData[10] && endpointsData[10].dialedInPopup)}
                index={10}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
          <Col span={12} className="video-thumb padding-left">
            <div className="video-placeholder-six-rooms">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[11] ? endpointsData[11].stream : undefined}
                mediaId={endpointsData[11] ? endpointsData[11].mediaId : undefined}
                endpointData={endpointsData[11] ? endpointsData[11].data : undefined}
                endpointStats={endpointsData[11] && endpointsData[11].stats}
                hasCall={!!(endpointsData[11] && !!endpointsData[11].dialedInPopup)}
                index={11}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Layout12RoomsLeft.defaultProps = {
  endpointsData: [],
  selectedEndpoint: null,
  selectEndpoint: null,
  refreshCamera: null,
  makeCall: null,
  callbacks: null,
};

Layout12RoomsLeft.propTypes = {
  selectEndpoint: PropTypes.func,
  selectedEndpoint: PropTypes.shape(),
  endpointsData: PropTypes.arrayOf(PropTypes.shape()),
  refreshCamera: PropTypes.func,
  makeCall: PropTypes.func,
  callbacks: PropTypes.shape(),
};

export { Layout12RoomsLeft as Layout12RoomsLeftTest };
export default injectIntl(withRouter(Layout12RoomsLeft));
