import {
  all, call, fork, put, takeEvery, select, take,
} from 'redux-saga/effects';
import {
  endpointsFetchDataSuccess,
  fetchEndpointSuccess,
  fetchPatientRisksListSuccess,
  fetchObservationRisksListSuccess,
  updateEndpointDetails,
  getEndpointStatusUpdate,
  fetchPatientRoomStatusListSuccess,
} from '@actions/iObserverActions/EndpointActions';
import {
  ENDPOINTS_FETCH_DATA,
  FETCH_ENDPOINT_REQUEST,
  FETCH_PATIENT_RISKS_LIST_REQUEST,
  FETCH_OBSERVATION_RISKS_LIST_REQUEST,
  FETCH_ENDPOINT_DETAILS,
  GET_ENDPOINT_STATUS_REQUEST,
  FETCH_PATIENT_ROOM_STATUS_LIST,
} from '@constants/iObserverActionTypes';

import { rest } from '@api/iObserverApi';
import { fetchError } from '@actions/Common';
import { eventChannel } from 'redux-saga';
import WebRTCManager from '@util/WebRTC/WebRTCManager';
import {
  ENDPOINT_STATUS_OFFLINE,
  ENDPOINT_STATUS_ONLINE,
} from '@constants/EndpointAvailabilityStatusTypes';
import {
  checkEndpointAction,
  checkEndpointResultAction,
  updateEndpointMediaType,
} from '@actions/iObserverActions/ObservationActions';
import { getEndpointByMachineName } from '../../selectors/iObserverSelectors';

const tests = {
  fetchEndpoints: null,
  fetchEndpointById: null,
  fetchEndpointRisksList: null,
  fetchObservationRisksList: null,
  fetchEndpointDetailsHandler: null,
  fetchEndpointRoomStatusList: null,
  getEndpointStatus: null,
  listenForEndpointOnlineEvents: null,
};

/**
 *
 * @param {number} page
 * @param {Array} sorting
 * @param filter {Array}
 */
function* fetchEndpoints({ page, sorting, filter }) {
  const params = {
    page,
    sorting,
    filter,
  };

  try {
    const fetchedData = yield call(rest.getEndpoints, params);
    yield put(endpointsFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @param {object} - includes id
 */
function* fetchEndpointById({ id }) {
  try {
    const fetchedEndpoint = yield call(rest.getEndpointById, id);
    if (fetchedEndpoint) {
      yield put(fetchEndpointSuccess(fetchedEndpoint));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchEndpointRisksList() {
  try {
    const fetchedRisksList = yield call(rest.getEndpointRisksList);
    if (fetchedRisksList) {
      yield put(fetchPatientRisksListSuccess(fetchedRisksList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchObservationRisksList() {
  try {
    const fetchedRisksList = yield call(rest.getObservationRisksList);
    if (fetchedRisksList) {
      yield put(fetchObservationRisksListSuccess(fetchedRisksList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchEndpointDetailsHandler({ payload }) {
  const { machine_name } = payload;
  try {
    // clean up the previous details
    yield put(updateEndpointDetails(machine_name));
    const endpointDetails = yield call(rest.fetchEndpointDetails, machine_name);
    if (endpointDetails) {
      yield put(updateEndpointDetails(machine_name, endpointDetails));
    }
  } catch (err) {
    console.error(err);
  }
}

function* fetchEndpointRoomStatusList() {
  try {
    const fetchedRoomStatusList = yield call(rest.fetchRoomStatusList);
    if (fetchedRoomStatusList) {
      yield put(fetchPatientRoomStatusListSuccess(fetchedRoomStatusList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getEndpointStatus({ payload }) {
  const { id } = payload;
  const endpoint = yield select(getEndpointByMachineName, id);
  try {
    if (endpoint) {
      yield put(getEndpointStatusUpdate(id, { ...endpoint.activityStatus, loading: true }));
    }

    const endpointStatusResponse = yield call(rest.getEndpointStatus, id);
    if (endpointStatusResponse) {
      yield put(getEndpointStatusUpdate(id, endpointStatusResponse));
    }
  } catch (err) {
    if (endpoint) {
      yield put(getEndpointStatusUpdate(id, { ...endpoint.activityStatus, loading: true }));
    }
    console.error(err);
  }
}

function* listenForEndpointOnlineEvents() {
  const eventChannelNodesStatus = eventChannel((emit) => {
    const onNodeOnline = (nodeId, mediaType) => {
      emit({ nodeId, mediaType, connected: true });
    };
    const onNodeOffline = (nodeId, mediaType) => {
      emit({ nodeId, mediaType, connected: false });
    };

    WebRTCManager.onNodeOnline = onNodeOnline;
    WebRTCManager.onNodeOffline = onNodeOffline;

    return () => {
      if (WebRTCManager.onNodeOnline === onNodeOnline) {
        WebRTCManager.onNodeOnline = null;
      }
      if (WebRTCManager.onNodeOffline === onNodeOffline) {
        WebRTCManager.onNodeOffline = null;
      }
    };
  });
  while (true) {
    const nodeData = yield take(eventChannelNodesStatus);

    const machineName = nodeData.nodeId.replace('camera_', '');
    const endpoint = yield select(getEndpointByMachineName, machineName);
    if (endpoint) {
      if (!nodeData.connected && endpoint.availabilityStatus === ENDPOINT_STATUS_ONLINE) {
        yield put(checkEndpointResultAction(endpoint.machine_name, ENDPOINT_STATUS_OFFLINE));
      } else if (nodeData.connected && endpoint.availabilityStatus === ENDPOINT_STATUS_OFFLINE) {
        yield put(checkEndpointAction(endpoint.machine_name));
      }

      yield put(updateEndpointMediaType(endpoint.id, nodeData.mediaType));
    }
  }
}

export function* actionsWatcher() {
  yield takeEvery(ENDPOINTS_FETCH_DATA, fetchEndpoints);
  yield takeEvery(FETCH_ENDPOINT_REQUEST, fetchEndpointById);
  yield takeEvery(FETCH_PATIENT_RISKS_LIST_REQUEST, fetchEndpointRisksList);
  yield takeEvery(FETCH_OBSERVATION_RISKS_LIST_REQUEST, fetchObservationRisksList);
  yield takeEvery(FETCH_ENDPOINT_DETAILS, fetchEndpointDetailsHandler);
  yield takeEvery(FETCH_PATIENT_ROOM_STATUS_LIST, fetchEndpointRoomStatusList);
  yield takeEvery(GET_ENDPOINT_STATUS_REQUEST, getEndpointStatus);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
    fork(listenForEndpointOnlineEvents),
  ]);
}

if (process.env.NODE_ENV === 'test') {
  tests.fetchEndpointById = fetchEndpointById;
  tests.fetchEndpoints = fetchEndpoints;
  tests.fetchEndpointRisksList = fetchEndpointRisksList;
  tests.fetchEndpointDetailsHandler = fetchEndpointDetailsHandler;
  tests.fetchObservationRisksList = fetchObservationRisksList;
  tests.getEndpointStatus = getEndpointStatus;
  tests.listenForEndpointOnlineEvents = listenForEndpointOnlineEvents;
  tests.fetchEndpointRoomStatusList = fetchEndpointRoomStatusList;
}

export { tests };
