/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { onGetOptions, resetFilters } from '@actions/iObserverActions/filters/ListingsFilterActions';

import {
  getSelectedEndpoints,
  getLastDropdownSelection,
} from '@selectors/iObserverSelectors';

const { Option } = Select;

export class FilterDropdowns extends React.Component {
  constructor(props) {
    super(props);
    this.selectedFilterVals = {};
    this.facilityFilterValue = null;
    this.unitFilterValue = null;
    this.endpointFilterValue = null;

    this.setInitFilter = this.setInitFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updateValuesByFilters = this.updateValuesByFilters.bind(this);
    this.getLastFilteredEndpoints = this.getLastFilteredEndpoints.bind(this);
    this.getOnlySelectedItemsFromStore = this.getOnlySelectedItemsFromStore.bind(this);
    this.fetchFilterOptions = this.fetchFilterOptions.bind(this);

    const lastSel = this.getOnlySelectedItemsFromStore();

    this.state = {
      selectedFilterVals: lastSel,
      selectedEndpointOption: [],
    };

    this.setInitFilter(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (Object.keys(prevProps.selectedEndpoints).length !== Object.keys(this.props.selectedEndpoints).length) {
      this.getLastFilteredEndpoints();
    }

    // If the endpoint is admitted or applied remove it from selected state, and select the next one
    if (prevState.selectedFilterVals.endpoint) {
      this.state.selectedEndpointOption.forEach((endpoint) => {
        const prevSelEndp = this.props.optionsList.endpoint.find((el) => el.id === endpoint);
        if (!prevSelEndp) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            selectedFilterVals: { ...this.state.selectedFilterVals, endpoint: this.props.optionsList.endpoint.length ? [this.props.optionsList.endpoint[0]?.id] : null },
            selectedEndpointOption: [],
          });
        }
      });
    }
  }

  getOnlySelectedItemsFromStore() {
    const lastSel = {};

    if (this.props.lastSelection) {
      for (const i in this.props.lastSelection) {
        const curr = this.props.lastSelection[i];
        if (curr) {
          lastSel[i] = curr;
        }
      }
    }
    return lastSel;
  }

  getLastFilteredEndpoints() {
    const lastUnitFilter = this.state.selectedFilterVals.unit;
    const { onGetOptions } = this.props;
    if (lastUnitFilter) {
      onGetOptions('endpoint', lastUnitFilter);
    }
  }

  /**
   * @description Set initial filter
   *
   * @return {void}
   */
  setInitFilter(keep) {
    const { filters, lastSelection } = this.props;
    if (lastSelection.customer === '' && lastSelection.facility === '' && lastSelection.unit === '') {
      this.props.onGetOptions(filters[0].key, null, keep);
    }
  }

  /**
   * Triggered when input is cleared or option is selected
   *
   * @param  {Array<string>} value
   * @param  {number} filterIndex
   * @return {void}
   */
  // eslint-disable-next-line react/sort-comp
  updateValuesByFilters(value, filterIndex) {
    const valueToSearch = filterIndex === 3 ? value[0][value[0].length - 1] : value[0];
    const { filters, onGetOptions } = this.props;
    const selectedFilter = filters[filterIndex];

    const selectedValue = this.props.optionsList[selectedFilter.key].find((el) => el.id === valueToSearch);
    if (filterIndex === 3) {
      this.props.onSelectEndpoint(selectedValue);
    }

    const filter = filters[filterIndex + 1] || null;
    if (filter && selectedValue && filterIndex <= 3) {
      onGetOptions(filter.key, value[0]);
    }
  }

  /**
   * Renders the "Clear Filter" button if enabled
   *
   * @return {ReactElement|null}
   */
  // eslint-disable-next-line react/sort-comp
  renderClearFilterButton() {
    const { showClearFiltersButton } = this.props;

    if (!showClearFiltersButton) {
      return null;
    }

    const statusFilter = this.isFilterActive();

    return (
      <Row type="flex" className="clearBtn">
        <Col lg={24} md={24} sm={24} align="center">
          <button alt="Reset Button" type="reset" className="ant-btn reset-btn" onClick={this.clearFilter} disabled={!statusFilter}>
            <FormattedMessage id="clearFiltersButtonLbl" />
          </button>
        </Col>
      </Row>
    );
  }

  /**
   * Returns true if any of the widgets filters are active
   *
   * @return {Boolean}
   */
  isFilterActive() {
    const activeFilters = Object.values(this.state.selectedFilterVals).reduce((active, filter) => {
      if (filter) {
        active += 1;
      }
      return active;
    }, 0);

    return activeFilters > 0;
  }

  /**
   * @description Cleans up the whole filter and reset both local and common state
   *
   * @return {void}
   */
  clearFilter() {
    const { filters } = this.props;
    const parentIndex = filters.length - 1;
    this.setState({ selectedFilterVals: {} }, () => {
      this.props.resetFilters();
      this.setInitFilter();
      this.onFilterChange('', parentIndex);
    });
  }

  fetchFilterOptions(key) {
    const { onGetOptions } = this.props;
    if (this.facilityFilterValue && key === 'facility') {
      onGetOptions(key, this.facilityFilterValue[0], true);
      return;
    }
    if (this.unitFilterValue && key === 'unit') {
      onGetOptions(key, this.unitFilterValue[0], true);
      return;
    }
    if (this.endpointFilterValue && key === 'endpoint') {
      onGetOptions(key, this.endpointFilterValue[0], true);
      return;
    }
    onGetOptions(key, null, true);
  }

  /**
   * @description Handle onChange event
   *
   * @param  {Array<string>} value
   * @param  {number} index
   * @return {void}
   */
  // eslint-disable-next-line react/sort-comp
  onFilterChange(value, index) {
    const { filters } = this.props;
    const { key } = filters[index];

    const newVal = [value];
    const selectedFilters = { ...this.state.selectedFilterVals };
    if (!newVal.length) {
      delete selectedFilters[key];
      // this.setState({ selectedFilterVals: { ...selectedFilters } });
    }

    let updatedFilters = {};
    switch (key) {
      case 'customer':
        updatedFilters[key] = newVal;
        this.facilityFilterValue = newVal;
        break;
      case 'facility':
        updatedFilters = { customer: selectedFilters.customer };
        updatedFilters[key] = newVal;
        this.unitFilterValue = newVal;
        break;
      case 'unit':
        updatedFilters.customer = selectedFilters.customer;
        updatedFilters.facility = selectedFilters.facility;
        updatedFilters[key] = newVal;
        this.endpointFilterValue = newVal;
        break;
      case 'endpoint':
        updatedFilters.customer = selectedFilters.customer;
        updatedFilters.facility = selectedFilters.facility;
        updatedFilters.unit = selectedFilters.unit;
        updatedFilters[key] = newVal;
        break;
      default:
        break;
    }
    this.setState({ selectedFilterVals: { ...updatedFilters } },
      () => {
        // console.log('SELECTED FILTER VALS: ', this.state.selectedFilterVals);
      });

    this.updateValuesByFilters(newVal, index);
  }

  onChange = (value) => {
    const endpointsLength = this.props.optionsList.endpoint.length;
    this.setState({ selectedEndpointOption: endpointsLength >= 1 ? value : [] },
      () => {
        // console.log('SELECTED FILTER VALS: ', this.state.selectedFilterVals);
      });
  }

  onDeselect = (endpointToRemove) => {
    this.props.onDeselectEndpoint(endpointToRemove);
  }

  /**
   * Renders filter inputs
   *
   * @return {Array<ReactElement>|null}
   */
  renderFilterDropdowns() {
    const { filters, optionsList, intl } = this.props;

    if (!filters || !Array.isArray(filters)) {
      return null;
    }

    const inputs = [];

    filters.forEach((config, index) => {
      const prevItem = index ? filters[index - 1] : null;
      const prevSelected = !!(prevItem && this.state.selectedFilterVals[prevItem.key]);

      const disabled = !prevSelected && index;
      const options = optionsList[config.key].sort((a, b) => a.name.localeCompare(b.name)).map((e) => <Option key={e.id} value={e.id}>{e.name}</Option>);

      const optionValue = this.state.selectedFilterVals[config.key];
      const endpointsLength = this.props.optionsList.endpoint.length;

      if (filters[index].key !== 'endpoint') {
        inputs.push(
          <Row type="flex" key={config.key}>
            <Col className="option-selector">
              <Select
                id={config.key}
                key={config.key}
                onChange={(value) => this.onFilterChange(value, index)}
                placeholder={intl.formatMessage({ id: config.placeholder })}
                showSearch={config.showSearch}
                onDropdownVisibleChange={() => { this.forceUpdate(); }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={!!disabled}
                value={optionValue || null}
                onFocus={() => this.fetchFilterOptions(config.key)}
                getPopupContainer={() => document.getElementById('default-inner-wrapper')}
              >
                {options}
              </Select>
            </Col>
          </Row>,
        );
      } else {
        inputs.push(
          <Row type="flex" key={config.key}>
            <Col className="option-selector">
              <Select
                key={config.key}
                mode="multiple"
                onChange={(value) => this.onChange(value)}
                onSelect={(value) => this.onFilterChange([value], index)}
                onDeselect={(value) => this.onDeselect(value)}
                placeholder={intl.formatMessage({ id: config.placeholder })}
                showSearch={config.showSearch}
                onDropdownVisibleChange={() => { this.forceUpdate(); }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={!!disabled}
                onFocus={() => this.fetchFilterOptions(config.key)}
                value={endpointsLength > 0 ? this.state.selectedEndpointOption : []}
                getPopupContainer={() => document.getElementById('default-inner-wrapper')}
              >
                {options}
              </Select>
            </Col>
          </Row>,
        );
      }
    });

    return inputs;
  }

  /**
   * Renders the component
   *
   * @return {ReactElement}
   */
  render() {
    return (
      <div className="filterDropdowns">
        {this.renderFilterDropdowns()}
        {/* this.renderClearFilterButton() */}
      </div>
    );
  }
}

FilterDropdowns.defaultProps = {
  showClearFiltersButton: true,
  cssClass: '',
};

FilterDropdowns.propTypes = {
  fieldNames: PropTypes.shape(),
  lastSelection: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  filters: PropTypes.array.isRequired,
  cssClass: PropTypes.string,
  showClearFiltersButton: PropTypes.bool,
  onGetOptions: PropTypes.func.isRequired,
  onSelectEndpoint: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const selectedEndpoints = getSelectedEndpoints(state);
  const lastSelection = getLastDropdownSelection(state);
  const optionsList = state.listingsFilter;
  const endpointsOptions = optionsList.endpoint;

  let filteredEndpoints = [];
  if (endpointsOptions) {
    filteredEndpoints = endpointsOptions.filter((e) => !selectedEndpoints[e.id]);
  }
  return {
    optionsList: { ...optionsList, endpoint: [...filteredEndpoints] },
    selectedEndpoints,
    lastSelection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetOptions: (key, filter, keep) => dispatch(onGetOptions(key, filter, keep)),
  resetFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(FilterDropdowns)));
