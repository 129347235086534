import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  customersFetchDataSuccess,
  fetchCustomerSuccess,
} from '@actions/iObserverActions/CustomerActions';
import {
  CUSTOMERS_FETCH_DATA,
  FETCH_CUSTOMER_REQUEST,
} from '@constants/iObserverActionTypes';

import { rest } from '@api/iObserverApi';
import { fetchError } from '@actions/Common';

const tests = {
  fetchCustomers: null,
  fetchCustomerById: null,
};
/**
 *
 * @param {number} page
 * @param {Array} sorting
 * @param filter {Array}
 */
function* fetchCustomers({ page, sorting, filter }) {
  const params = {
    page,
    sorting,
    filter,
  };

  try {
    const fetchedData = yield call(rest.getCustomers, params);
    yield put(customersFetchDataSuccess(fetchedData));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @param {object} - includes id
 */
function* fetchCustomerById({ id }) {
  try {
    const fetchedCustomer = yield call(rest.getCustomerById, id);
    if (fetchedCustomer) {
      yield put(fetchCustomerSuccess(fetchedCustomer));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(CUSTOMERS_FETCH_DATA, fetchCustomers);
  yield takeEvery(FETCH_CUSTOMER_REQUEST, fetchCustomerById);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
  ]);
}

if (process.env.NODE_ENV === 'test') {
  tests.fetchCustomers = fetchCustomers;
  tests.fetchCustomerById = fetchCustomerById;
}

export { tests };
