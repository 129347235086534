import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Select,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ReloadOutlined } from '@ant-design/icons';
import {
  endSession,
} from '@actions/iObserverActions/ObservationActions';
import {
  transferSessionRequest,
  cancelTransferSessionRequest,
  initTransferSessionWs,
  getTransfersRequest,
  startImportSession,
  setPopUpMessage,
} from '@actions/iObserverActions/SessionActions';
import { layoutNames } from '@constants/LayoutSettings';
import { changeLayout } from '@actions/iObserverActions/LayoutSettingsActions';
import {
  getTransferId,
  getProfile,
  getTransfers,
  getObservationLoading,
  getObservationSession,
  getSessionPopUpMessage,
} from '@selectors/iObserverSelectors';

import {
  SESSION_MESSAGES,
} from '@constants/Settings';
import IntlMessages from '../util/IntlMessages';

const { Option } = Select;

export class Session extends React.PureComponent {
  constructor(props) {
    super(props);
    this.openEndSessionModal = this.openEndSessionModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEndSession = this.handleEndSession.bind(this);
    this.openTransferSessionModal = this.openTransferSessionModal.bind(this);
    this.handleCancelTransfer = this.handleCancelTransfer.bind(this);
    this.handleTransferSession = this.handleTransferSession.bind(this);
    this.openCancelTransferSessionModal = this.openCancelTransferSessionModal.bind(this);
    this.handleCancelTransferSession = this.handleCancelTransferSession.bind(this);
    this.cancelCancelTransferSession = this.cancelCancelTransferSession.bind(this);
    this.renderTransfersSelect = this.renderTransfersSelect.bind(this);
    this.importSession = this.importSession.bind(this);
    this.openEndTransferSessionModal = this.openEndTransferSessionModal.bind(this);
    this.handleCloseEndTransferSessionModal = this.handleCloseEndTransferSessionModal.bind(this);
    this.openImportSessionModal = this.openImportSessionModal.bind(this);
    this.handleCancelImportTransfer = this.handleCancelImportTransfer.bind(this);
    this.handleImportSession = this.handleImportSession.bind(this);
    this.hasSelectedImportSession = this.hasSelectedImportSession.bind(this);

    this.state = {
      showEndSessionModal: false,
      showTransferSessionModal: false,
      showCancelTransferSessionModal: false,
      showEndTransferSessionModal: false,
      showImportSessionModal: false,
      showEndImportSessionModal: false,
      selectedTransferToImport: '',
    };
    this.connectingWs = false;
    this.initiated = false;
    const { getTransfersRequestProp } = this.props;
    getTransfersRequestProp();
  }

  componentDidUpdate(prevProps) {
    const {
      profileData, initTransferSessionWsProp, sessionPopUpMessage, currentSession,
    } = this.props;
    const { showEndTransferSessionModal } = this.state;

    if (Object.keys(profileData.profile).length > 0) {
      if (!this.connectingWs) {
        this.connectingWs = true;
        console.log('INITIATE WS');
        initTransferSessionWsProp();
      }
    }

    if (sessionPopUpMessage === SESSION_MESSAGES.SESSION_END
      && prevProps.sessionPopUpMessage !== sessionPopUpMessage) {
      if (showEndTransferSessionModal === false) {
        this.openEndTransferSessionModal();
      }
    }

    if (sessionPopUpMessage === SESSION_MESSAGES.SESSION_IMPORTED
      && prevProps.sessionPopUpMessage !== sessionPopUpMessage) {
      // Temporarily removed for IOB-564
      // this.openEndImportSessionModal();
    }

    const hasObservationSession = !!currentSession.observation_session_id;
    const prevHasObservationSession = prevProps
      && !!prevProps.currentSession.observation_session_id;
    if (!hasObservationSession && prevHasObservationSession) {
      this.fetchTransferRequests();
    }
  }

  onChangeTransferToImport(value) {
    this.setState({ selectedTransferToImport: value });
  }

  openEndImportSessionModal = () => {
    this.setState({ showEndImportSessionModal: true });
  }

  handleCloseEndImportSessionModal = () => {
    const { setPopUpMessageProp } = this.props;
    this.setState({ showEndImportSessionModal: false });
    setPopUpMessageProp('');
  }

  fetchTransferRequests() {
    const { getTransfersRequestProp } = this.props;
    getTransfersRequestProp();
    this.setState({ selectedTransferToImport: '' });
  }

  openEndTransferSessionModal() {
    this.setState({ showEndTransferSessionModal: true });
    // this.showEndTransferSessionModal = true;
  }

  handleCloseEndTransferSessionModal() {
    const { setPopUpMessageProp } = this.props;
    this.setState({ showEndTransferSessionModal: false });
    // this.showEndTransferSessionModal = false;
    setPopUpMessageProp('');
  }

  openImportSessionModal() {
    if (this.hasSelectedImportSession()) {
      this.setState({ showImportSessionModal: true });
    }
  }

  hasSelectedImportSession() {
    return this.state.selectedTransferToImport && !isNaN(this.state.selectedTransferToImport);
  }

  handleCancelImportTransfer() {
    this.setState({ showImportSessionModal: false });
  }

  handleImportSession() {
    this.setState({ showImportSessionModal: false });
    this.importSession();
  }

  openTransferSessionModal() {
    this.setState({ showTransferSessionModal: true });
  }

  handleCancelTransfer() {
    this.setState({ showTransferSessionModal: false });
  }

  handleTransferSession() {
    this.props.transferSessionRequest();
    this.setState({ showTransferSessionModal: false });
  }

  handleCancelTransferSession() {
    this.props.cancelTransferSessionRequest();
    this.setState({ showCancelTransferSessionModal: false });
  }

  openCancelTransferSessionModal() {
    this.setState({ showCancelTransferSessionModal: true });
  }

  cancelCancelTransferSession() {
    this.setState({ showCancelTransferSessionModal: false });
  }

  handleEndSession() {
    const { endSession, changeLayoutProp } = this.props;
    endSession();
    this.setState({ showEndSessionModal: false });
    changeLayoutProp(layoutNames.DEFAULT_LAYOUT);
  }

  handleCancel() {
    this.setState({ showEndSessionModal: false });
  }

  openEndSessionModal() {
    this.setState({ showEndSessionModal: true });
  }

  importSession() {
    if (this.hasSelectedImportSession()) {
      const { startImportSessionProp } = this.props;
      const { selectedTransferToImport } = this.state;
      startImportSessionProp(selectedTransferToImport);
    }
  }

  renderTransfersSelect() {
    const { selectedTransferToImport } = this.state;
    const {
      transfers, transferId, loadingTransfers, currentSession, intl,
    } = this.props;
    const hasObservationSession = !!currentSession.observation_session_id;

    const options = [];
    if (transfers.length) {
      transfers.forEach((transfer) => {
        if (transfer.transfer_id !== transferId) {
          options.push(
            <Option
              key={transfer.transfer_id}
              value={transfer.transfer_id}
            >
              {transfer.email}
            </Option>,
          );
        }
      });
    }

    return (
      <Select
        placeholder={intl.formatMessage({ id: 'sessoin.importSelect' })}
        className="select-session"
        onChange={(option) => { this.onChangeTransferToImport(option); }}
        loading={!!loadingTransfers}
        disabled={hasObservationSession}
        value={selectedTransferToImport || undefined}
        getPopupContainer={() => document.getElementById('inner-wrapper-session')}
      >
        {options}
      </Select>
    );
  }

  render() {
    const {
      transferId, loadingTransfers, currentSession, selectedLayout, intl, getTransfersRequestProp,
      selectedEndpoints,
    } = this.props;
    const {
      showCancelTransferSessionModal, showEndSessionModal, showEndTransferSessionModal,
      showImportSessionModal, showTransferSessionModal, showEndImportSessionModal,
    } = this.state;
    const hasObservationSession = !!currentSession.observation_session_id;
    return (
      <div className="gx-m-3 session-position view-wrapper-session">
        <div id="inner-wrapper-session" className="inner-wrapper">
          <h1 className="inner-heading">
            <IntlMessages id="sessoin.titleSessionPage" />
          </h1>
          <Row gutter={[16, 36]}>
            {/* TRANSFER SESSION */}
            <Col
              sm={24}
              md={24}
              lg={24}
              xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 8 : 24}
            >
              <div className="gx-d-flex gx-justify-content-center">
                {
                  transferId !== null && transferId > -1
                    ? (
                      <div>
                        <div><IntlMessages id="sessoin.waiting_for_transfer" /></div>
                        <Button
                          type="link"
                          block
                          onClick={this.openCancelTransferSessionModal}
                        >
                          <IntlMessages id="sessoin.cancel" />
                        </Button>
                      </div>

                    )
                    : (
                      <Button
                        className="gx-d-flex gx-align-items-center gx-justify-content-center btn-transfer btn-size"
                        onClick={this.openTransferSessionModal}
                        disabled={!hasObservationSession || Object.keys(selectedEndpoints).length === 0}
                      >
                        <IntlMessages id="sessoin.btnTtransfer" />
                      </Button>
                    )
                }
              </div>
              <p className="gx-d-flex gx-justify-content-center text-center">
                <IntlMessages id="sessoin.titleTransfer" />
              </p>
              <p className="text-center">
                <IntlMessages id="sessoin.txtTransfer" />
              </p>
            </Col>
            {/* IMPORT SESSION */}
            <Col
              md={24}
              lg={24}
              xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 8 : 24}
            >
              <div className="gx-d-flex gx-justify-content-center">
                <Button
                  className="gx-d-flex gx-align-items-center gx-justify-content-center btn-import btn-size"
                  onClick={this.openImportSessionModal}
                  disabled={hasObservationSession || !this.hasSelectedImportSession()}
                >
                  <IntlMessages id="sessoin.btnImport" />
                </Button>
              </div>
              <p className="gx-d-flex gx-justify-content-center text-center">
                <IntlMessages id="sessoin.titleImport" />
              </p>
              <p className="text-center">
                <IntlMessages id="sessoin.txtImport" />
              </p>
              <div>
                <Row gutter={8} className="session-import-select">
                  <Col sm={24} md={16} lg={20} xl={20} style={{ flex: 1, maxWidth: '100%' }}>
                    {this.renderTransfersSelect()}
                  </Col>
                  <Col sm={24} md={8} lg={4} xl={4} className="session-import-btn-container" style={{ position: 'relative', flex: '0' }}>
                    <Button
                      title={intl.formatMessage({ id: 'sessoin.refresh' })}
                      className="gx-d-flex gx-align-items-center gx-justify-content-center btn-import "
                      onClick={getTransfersRequestProp}
                      disabled={!!loadingTransfers || hasObservationSession}
                      loading={!!loadingTransfers}
                      icon={<ReloadOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            {/* END SESSION */}
            <Col
              sm={24}
              md={24}
              lg={24}
              xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 8 : 24}
            >
              <div className="gx-d-flex gx-justify-content-center">
                <Button
                  className="gx-d-flex gx-align-items-center gx-justify-content-center btn-size"
                  type="danger"
                  onClick={this.openEndSessionModal}
                  disabled={!hasObservationSession || transferId > -1}
                >
                  <IntlMessages id="sessoin.btnEnd" />
                </Button>
              </div>
              <p className="gx-d-flex gx-justify-content-center">
                <IntlMessages id="sessoin.titleEnd" />
              </p>
              <p className="gx-d-flex gx-justify-content-center">
                <IntlMessages id="sessoin.txtEnd" />
              </p>
            </Col>
          </Row>
        </div>
        {/* END SESSION MODAL */}
        <Modal
          visible={showEndSessionModal}
          title="End All Session?"
          onOk={this.handleEndSession}
          onCancel={this.handleCancel}
          getContainer={false}
          footer={[
            <Button className="buttons-modal" key="back" onClick={this.handleCancel}>
              <IntlMessages id="sessoin.cancel" />
            </Button>,
            <Button
              className="buttons-modal"
              key="submit"
              type="primary"
              loading={false}
              onClick={this.handleEndSession}
            >
              End Now
            </Button>,
          ]}
        >
          <div>Are you sure you want to end all sessions?</div>
        </Modal>
        {/* TRANSFER SESSION MODAL */}
        <Modal
          visible={showTransferSessionModal}
          title="Transfer your session?"
          onOk={this.handleTransferSession}
          onCancel={this.handleCancelTransfer}
          getContainer={false}
          footer={[
            <Button className="buttons-modal" key="back" onClick={this.handleCancelTransfer}>
              <IntlMessages id="sessoin.cancel" />
            </Button>,
            <Button
              className="buttons-modal"
              key="submit"
              type="primary"
              loading={false}
              onClick={this.handleTransferSession}
            >
              <IntlMessages id="sessoin.transfer_now" />
            </Button>,
          ]}
        >
          <div><IntlMessages id="sessoin.confirm_session_transfer" /></div>
        </Modal>
        {/* CANCEL TRANSFER SESSION MODAL */}
        <Modal
          visible={showCancelTransferSessionModal}
          onOk={this.handleCancelTransferSession}
          onCancel={this.cancelCancelTransferSession}
          getContainer={false}
          footer={[
            <Button className="buttons-modal" key="back" onClick={this.cancelCancelTransferSession}>
              <IntlMessages id="sessoin.cancel" />
            </Button>,
            <Button
              className="buttons-modal"
              key="submit"
              type="primary"
              loading={false}
              onClick={this.handleCancelTransferSession}
            >
              <IntlMessages id="sessoin.cancel_now" />
            </Button>,
          ]}
        >
          <div>{intl.formatMessage({ id: 'sessoin.cancel_session_transfer' })}</div>
        </Modal>
        {/* END TRANSFER SESSION MODAL */}
        <Modal
          visible={showEndTransferSessionModal}
          onOk={this.handleCloseEndTransferSessionModal}
          onCancel={this.handleCloseEndTransferSessionModal}
          getContainer={false}
          footer={[
            <Button
              className="buttons-modal"
              key="submit"
              type="primary"
              loading={false}
              onClick={this.handleCloseEndTransferSessionModal}
            >
              <IntlMessages id="sessoin.ok" />
            </Button>,
          ]}
        >
          <div>{intl.formatMessage({ id: 'sessoin.end_session' })}</div>
        </Modal>
        {/* IMPORT SESSION MODAL */}
        <Modal
          visible={showImportSessionModal}
          onOk={this.handleImportSession}
          onCancel={this.handleCancelImportTransfer}
          title={intl.formatMessage({ id: 'sessoin.begin_transfer' })}
          getContainer={false}
          footer={[
            <Button className="buttons-modal" key="back" onClick={this.handleCancelImportTransfer}>
              <IntlMessages id="sessoin.cancel" />
            </Button>,
            <Button
              className="buttons-modal"
              key="submit"
              type="primary"
              loading={false}
              onClick={this.handleImportSession}
            >
              <IntlMessages id="sessoin.start_now" />
            </Button>,
          ]}
        >
          <div>{intl.formatMessage({ id: 'sessoin.start_import_now' })}</div>
        </Modal>
        {/* IMPORT END SESSION MODAL */}
        {!!showEndImportSessionModal && (
          <Modal
            centered={selectedLayout !== layoutNames.DEFAULT_LAYOUT}
            style={selectedLayout === layoutNames.DEFAULT_LAYOUT ? { top: 100, marginRight: '50%' } : {}}
            visible={showEndImportSessionModal}
            onOk={this.handleCloseEndImportSessionModal}
            onCancel={this.handleCloseEndImportSessionModal}
            getContainer={false}
            footer={[
              <Button
                className="buttons-modal"
                key="submit"
                type="primary"
                loading={false}
                onClick={this.handleCloseEndImportSessionModal}
              >
                <IntlMessages id="sessoin.ok" />
              </Button>,
            ]}
          >
            <div>{intl.formatMessage({ id: 'sessoin.end_import_session' })}</div>
          </Modal>
        )}
      </div>
    );
  }
}

Session.propTypes = {
  intl: PropTypes.shape().isRequired,
  profileData: PropTypes.shape().isRequired,
  endSession: PropTypes.func.isRequired,
  transferSessionRequest: PropTypes.func.isRequired,
  cancelTransferSessionRequest: PropTypes.func.isRequired,
  transferId: PropTypes.number.isRequired,
  initTransferSessionWsProp: PropTypes.func.isRequired,
  getTransfersRequestProp: PropTypes.func.isRequired,
  startImportSessionProp: PropTypes.func.isRequired,
  transfers: PropTypes.array.isRequired,
  loadingTransfers: PropTypes.bool.isRequired,
  currentSession: PropTypes.shape().isRequired,
  sessionPopUpMessage: PropTypes.string.isRequired,
  setPopUpMessageProp: PropTypes.func.isRequired,
  selectedLayout: PropTypes.string.isRequired,
  selectedEndpoints: PropTypes.shape().isRequired,
  changeLayoutProp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedLayout } = state.monitoringLayout;

  return {
    transferId: getTransferId(state),
    profileData: getProfile(state),
    transfers: getTransfers(state),
    loadingTransfers: getObservationLoading(state),
    currentSession: getObservationSession(state),
    sessionPopUpMessage: getSessionPopUpMessage(state),
    selectedLayout,
    selectedEndpoints: state.endpoints.selectedEndpoints,
  };
};

const mapDispatchToProps = (dispatch) => ({
  endSession: () => dispatch(endSession()),
  transferSessionRequest: () => dispatch(transferSessionRequest()),
  cancelTransferSessionRequest: () => dispatch(cancelTransferSessionRequest()),
  initTransferSessionWsProp: () => dispatch(initTransferSessionWs()),
  getTransfersRequestProp: () => dispatch(getTransfersRequest()),
  startImportSessionProp: (transferId) => dispatch(startImportSession(transferId)),
  setPopUpMessageProp: (msg) => dispatch(setPopUpMessage(msg)),
  changeLayoutProp: (layoutName) => dispatch(changeLayout(layoutName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Session)));
