/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'antd';
import { setAudioOutput } from '@util/commonFunctions';

export default class VideoContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.video = null;
  }

  componentDidUpdate(prevPros) {
    const { mediaStream, muted, speakerId } = this.props;

    if (this.video && prevPros && prevPros.speakerId !== speakerId) {
      setAudioOutput(this.video, speakerId);
    }
    if (this.video && prevPros && muted !== prevPros.muted) {
      this.video.muted = !!muted;
      try {
        this.video.load();
      } catch (e) {
        console.log('[LOG] video preview', e);
      }
    }
    if (this.video && this.video.srcObject !== mediaStream) {
      this.video.srcObject = mediaStream;
    }
  }

  setVideo = (el) => {
    this.video = el;

    if (el) {
      const { muted, mediaStream, speakerId } = this.props;
      this.video.srcObject = mediaStream;
      this.video.muted = !!muted;
      setAudioOutput(this.video, speakerId);
      try {
        this.video.load();
      } catch (e) {
        console.log('[LOG] video preview', e);
      }
    }
  };

  render() {
    const {
      mediaStream, loading,
    } = this.props;

    if (!mediaStream) {
      return null;
    }

    return (
      <Card className="camera-container" id="cameraContainer" loading={loading}>
        <video className="video-display" autoPlay ref={this.setVideo} />
      </Card>
    );
  }
}
