// ListingsTopFilterSaga
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { fetchOptionsSuccess } from '@actions/iObserverActions/filters/ListingsFilterActions';
import {
  LISTINGS_FILTER_GET_OPTIONS,
} from '@constants/iObserverActionTypes';

import { rest } from '@api/iObserverApi';
import { fetchError } from '@actions/Common';

const tests = {
  fetchFilterOptions: null,
};
/**
 * @param {string} type
 * @param {string} key
 * @param {string} filter
 */
function* fetchFilterOptions(params) {
  const { key, filter, keep } = params;
  try {
    if (filter || key === 'customer') {
      const fetchedOptions = yield call(rest.getFilterOptions, key, filter);
      yield put(fetchOptionsSuccess(fetchedOptions, key, keep, filter));
    }
  } catch (error) {
    console.error('ERROR: ', error);
    yield put(fetchError(error));
  }
}

export function* fetchOptions() {
  yield takeEvery(LISTINGS_FILTER_GET_OPTIONS, fetchFilterOptions);
}

export default function* rootSaga() {
  yield all(
    [
      fork(fetchOptions),
    ],
  );
}

if (process.env.NODE_ENV) {
  tests.fetchFilterOptions = fetchFilterOptions;
}

export { tests };
