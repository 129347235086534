import {
  LISTINGS_FILTER_GET_OPTIONS,
  LISTINGS_FILTER_FETCH_SUCCESS,
  RESET_LISTING_FILTERS,
} from '@constants/iObserverActionTypes';

/**
 * @param  {string} key
 * @param  {string} filter
 * @param  {Boolean} keep
 * @return {Object}
 */
export const onGetOptions = (key, filter, keep) => ({
  type: LISTINGS_FILTER_GET_OPTIONS,
  key,
  filter,
  keep,
});

/**
 * @param  {Object} payload
 * @param  {[string} key
 * @param  {Boolean} keep
 * @return {Object}
 */
export const fetchOptionsSuccess = (payload, key, keep, filter) => ({
  type: LISTINGS_FILTER_FETCH_SUCCESS,
  payload,
  key,
  keep,
  filter,
});
/**
 * @return {Object}
 */
export const resetFilters = () => ({
  type: RESET_LISTING_FILTERS,
});
