import {
  all, call, delay, fork, put, takeEvery, cancel,
} from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import {
  FETCH_OWN_PROFILE_REQUEST,
  SESSION_EXPIRATION_TIME_SETUP,
  SESSION_REFRESH,
} from '@constants/iObserverActionTypes';

import {
  getProfileSuccess,
  refreshSessionTokenData,
  setSessionExpirationWarning,
} from '@actions/iObserverActions/ProfileActions';

import { auth, rest } from '@api/iObserverApi';
import RestManager from '@util/RestManager';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  SESSION_EXPIRATION_TIME_ADVANCE,
} from '@constants/Settings';

const tests = {
  fetchOwnProfile: null,
  handleSessionRefresh: null,
  setupSessionData: null,
  delaySessionExpirationWarning: null,
};

function* fetchOwnProfile() {
  try {
    let adminId = '';
    const authToken = RestManager.getAuthToken();
    if (authToken) {
      const decodedToken = jwt_decode(authToken);
      adminId = decodedToken.admin_id;
    }

    const fetchedProfile = yield call(rest.getProfileInfo);
    if (fetchedProfile) {
      yield put(getProfileSuccess({ ...fetchedProfile, adminId }));
    }
  } catch (error) {
    console.error('Fetch profile error: ', error);
  }
}

let sessionExpirationWarningTask = null;
function* delaySessionExpirationWarning(timeToWait) {
  try {
    yield delay(timeToWait);

    yield put(setSessionExpirationWarning(true));
  } catch (err) {
    console.warn('[error] delay session warning', err);
  }
}

function* setupSessionData() {
  try {
    const authToken = RestManager.getAuthToken();
    if (authToken) {
      const decodedToken = jwt_decode(authToken);
      const expirationTime = decodedToken.exp * 1000;

      yield put(refreshSessionTokenData({ expirationTime }));

      if (sessionExpirationWarningTask) {
        yield cancel(sessionExpirationWarningTask);
        sessionExpirationWarningTask = null;
      }
      let timeToWait = (expirationTime - SESSION_EXPIRATION_TIME_ADVANCE) - Date.now();
      if (timeToWait < 0) {
        timeToWait = 0;
      }
      sessionExpirationWarningTask = yield fork(delaySessionExpirationWarning, timeToWait);
    }
  } catch (e) {
    console.warn('[error] setup session data', e);
  }
}

function* handleSessionRefresh() {
  try {
    const refToken = RestManager.getRefreshToken();
    const authToken = RestManager.getAuthToken();

    const renewData = yield call(auth.refreshAccessToken, authToken, refToken);
    if (renewData) {
      const { accessToken, refreshToken } = renewData;
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      yield call(setupSessionData);
    }
  } catch (error) {
    console.error('[error] Fetch profile error: ', error);
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_OWN_PROFILE_REQUEST, fetchOwnProfile);
  yield takeEvery(SESSION_REFRESH, handleSessionRefresh);
  yield takeEvery(SESSION_EXPIRATION_TIME_SETUP, setupSessionData);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher), call(setupSessionData)]);
}

if (process.env.NODE_ENV === 'test') {
  tests.fetchOwnProfile = fetchOwnProfile;
  tests.handleSessionRefresh = handleSessionRefresh;
  tests.setupSessionData = setupSessionData;
  tests.delaySessionExpirationWarning = delaySessionExpirationWarning;
}

export { tests };
