import React from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import EndpointVideo from '../EndpointVideo';

const Layout6RoomsEqual = (props) => {
  const {
    endpointsData, selectEndpoint, selectedEndpoint, refreshCamera, makeCall, callbacks,
  } = props;

  const renderThumbs = (startIndex) => {
    const cols = [];
    for (let i = startIndex; i < startIndex + 3; i += 1) {
      cols.push((
        <Col span={8} key={`thumb-${i}`} className="video-thumb video-height">
          <div className="video-placeholder">
            <EndpointVideo
              selectedEndpoint={selectedEndpoint}
              selectEndpoint={selectEndpoint}
              stream={endpointsData[i] && endpointsData[i].stream}
              mediaId={endpointsData[i] && endpointsData[i].mediaId}
              endpointData={endpointsData[i] && endpointsData[i].data}
              endpointStats={endpointsData[i] && endpointsData[i].stats}
              hasCall={!!(endpointsData[i] && endpointsData[i].dialedInPopup)}
              index={i}
              refreshCamera={refreshCamera}
              makeCall={makeCall}
              callbacks={callbacks}
            />
          </div>
        </Col>
      ));
    }

    return cols;
  };

  return (
    <>
      <Row className="fisrt-row-equal-height video-thumbs-holder">
        {renderThumbs(0)}
      </Row>
      <Row className="video-secon-right-box video-thumbs-holder">
        {renderThumbs(3)}
      </Row>
    </>
  );
};

Layout6RoomsEqual.defaultProps = {
  endpointsData: [],
  selectedEndpoint: null,
  selectEndpoint: null,
  refreshCamera: null,
  makeCall: null,
  callbacks: null,
};

Layout6RoomsEqual.propTypes = {
  selectEndpoint: PropTypes.func,
  selectedEndpoint: PropTypes.shape(),
  endpointsData: PropTypes.arrayOf(PropTypes.shape()),
  refreshCamera: PropTypes.func,
  makeCall: PropTypes.func,
  callbacks: PropTypes.shape(),
};

export { Layout6RoomsEqual as Layout6RoomsEqualTest };
export default injectIntl(withRouter(Layout6RoomsEqual));
