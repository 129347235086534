import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Countdown = (props) => {
  const { timeLeft, onTimePassed } = props;
  const [timeToDisplay, setTimeToDisplay] = useState('00:00');

  let hours;
  let mins;
  let msLeft;
  let time;
  let timer;

  function twoDigits(n = 0) {
    return (n <= 9 ? `0${n}` : n);
  }

  function updateTimer() {
    msLeft = timeLeft - Date.now();
    if (msLeft < 1000) {
      if (onTimePassed) {
        onTimePassed();
      }
    } else {
      time = new Date(msLeft);
      hours = time.getUTCHours();
      mins = time.getUTCMinutes();
      setTimeToDisplay(`${hours ? `${hours}:${twoDigits(mins)}` : twoDigits(mins)}:${twoDigits(time.getUTCSeconds())} `);
      timer = setTimeout(updateTimer, 1000);
    }
  }

  React.useEffect(() => {
    updateTimer();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <span>{timeToDisplay}</span>
  );
};
Countdown.defaultProps = {
  onTimePassed: null,
};

Countdown.propTypes = {
  timeLeft: PropTypes.number.isRequired,
  onTimePassed: PropTypes.func,
};

export default Countdown;
