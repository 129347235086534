import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import EpicMonitorHandler from '@util/EpicMonitorHandler';
import { v4 as uuidv4 } from 'uuid';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ACCESS_TOKEN_KEY } from '@constants/Settings';
import EndpointVideoElement from '@components/EndpointVideo/EndpointVideoElement/EndpointVideoElement';
import WebRTCManager from '../util/WebRTC/WebRTCManager';
import {
  goToPoint,
  zoomIn,
  zoomOut,
  zoomStop,
} from '../appRedux/actions';

// eslint-disable-next-line max-len
let currentController = 'uhehaobsdev1.univago.com:3443';
if (process && process.env && process.env.NODE_ENV) {
  if (process.env.NODE_ENV === 'staging') {
    currentController = 'uhehaobsstag1.univago.com:3443,uhehaobsstag2.univago.com:3443,uhehaobsstag3.univago.com:3443';
  }
}
const AutoMonitorRoom = (props) => {
  const [streamVideo, setStreamVideo] = useState();
  const videoEl = useRef(null);
  const { nodeId } = useParams();
  const { pid } = useParams();
  const username = `${pid}-${uuidv4()}`;
  let epicMonitorHandler;
  let loginTimeout;

  useEffect(() => {
    // TEMP !!!!
    if (localStorage && !localStorage.getItem(ACCESS_TOKEN_KEY)) {
      localStorage.setItem(ACCESS_TOKEN_KEY, 'eyJhbGciOiJSUzI1NiJ9.eyJhZG1pbl9pZCI6MTQyLCJlbWFpbCI6InNtb25naWFAY2FyZWdpbGl0eS5jb20iLCJhdWQiOiJtb2JpbGVfYXBpIiwiYXV0aF90eXBlIjoibG9jYWwiLCJ1c2VyX3R5cGUiOiJjbGluaWNpYW4iLCJleHAiOjE2MjEzNDcxNjh9.W5FnHw3MByZlJC_VtD2_TLR45UwK04GjZk8W7K_sCELfT23fp6p15Uf6uBO8HjkAiDDFrvOEH-vrwzWRp-rHfjvhfSn0sZJO-6ER2aFbbIeZFKF31WOuwJAZGbgNTMoBn3ngSU_Kl7sJV6stxU-0sy5zgNwM-FvYAlwKxOZ1O8nmyiZF2u7zr0sozhpJiEpqMl2MDMVRQO9I-K3_WQTVlzTpQ6CbWXfaLddTJtNYMw4amAsQeySaoaCcDRCZjRu8zcaSxcn83TO8qJez2J2ieHGjqMrHdDGS_SZEW7TeBEDfXcFNGMG_XsQV_mTNPBvAP8e39YZFDiYF6OrKH51Z9A');
    }

    WebRTCManager.connect(currentController);
    WebRTCManager.onConnect = () => {
      WebRTCManager.directLink(`camera_${nodeId}`, username, { calibrateMotion: false });

      loginTimeout = setTimeout(() => {
        WebRTCManager.login(username);
      }, 300);
    };

    WebRTCManager.onRemoteStreamStart = (mediaId, stream) => {
      setStreamVideo(stream);
      if (videoEl && videoEl.current && mediaId === `camera_${nodeId}`) {
        videoEl.current.srcObject = stream;

        if (epicMonitorHandler) {
          epicMonitorHandler.reportCurrentStateUpdate();
        }
      } else if (mediaId !== `camera_${nodeId}`) {
        console.log('[auto-monitor onRemoteStreamStart: unlink', mediaId, `camera_${nodeId}`);
        WebRTCManager.directUnlink(mediaId, username);
      }
    };
    WebRTCManager.onRemoteStreamStop = (mediaId) => {
      if (videoEl && videoEl.current && mediaId === `camera_${nodeId}`) {
        videoEl.current.srcObject = null;

        if (epicMonitorHandler) {
          epicMonitorHandler.reportCurrentStateUpdate(true);
        }
      }
    };

    if (!epicMonitorHandler) {
      epicMonitorHandler = new EpicMonitorHandler();
      epicMonitorHandler.initHandler(videoEl, `camera_${nodeId}`);
    }

    return () => {
      // Clean up
      clearTimeout(loginTimeout);
      WebRTCManager.directUnlink(`camera_${nodeId}`, pid);
      WebRTCManager.closeUserSession();

      if (epicMonitorHandler) {
        epicMonitorHandler.cleanup();
        epicMonitorHandler = null;
      }
    };
  }, []);

  // const startVideo = () => {
  //   if (videoEl && videoEl.current) {
  //     videoEl.current.play();
  //   }
  // };

  const handleZoom = (stop, toZoomIn) => {
    if (stop) {
      const { zoomStopAction } = props;
      zoomStopAction(nodeId);
    } else if (toZoomIn) {
      const { zoomInAction } = props;
      zoomInAction(nodeId);
    } else {
      const { zoomOutAction } = props;
      zoomOutAction(nodeId);
    }
  };

  const goTo = (x, y, zoom) => {
    props.goToPointAction(nodeId, x, y, zoom);
  };

  const handleVideoRefCallback = (videoRef) => {
    videoEl.current = videoRef;
  };

  return (
    <div className="endpoint-video" style={{ cursor: 'pointer' }}>
      {streamVideo && (
        <EndpointVideoElement
          stream={streamVideo}
          goToPoint={goTo}
          zoom={handleZoom}
          muted
          setVideoRefCallback={handleVideoRefCallback}
        />
      )}
    </div>
  );
};

AutoMonitorRoom.propTypes = {
  goToPointAction: PropTypes.func.isRequired,
  zoomInAction: PropTypes.func.isRequired,
  zoomStopAction: PropTypes.func.isRequired,
  zoomOutAction: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  goToPointAction: (machineName, x, y, zoom) => dispatch(goToPoint(machineName, x, y, zoom)),
  zoomInAction: (machineName) => dispatch(zoomIn(machineName)),
  zoomOutAction: (machineName) => dispatch(zoomOut(machineName)),
  zoomStopAction: (machineName) => dispatch(zoomStop(machineName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AutoMonitorRoom));
