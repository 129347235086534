import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  injectIntl,
} from 'react-intl';
import {
  ENDPOINT_STATUSES,
} from '@constants/Settings';
import {
  onGetOptions,
} from '@actions/iObserverActions/filters/ListingsFilterActions';

import {
  admitEndpoint,
  removeEndpoint,
} from '@actions/iObserverActions/EndpointActions';
import { changeLayout } from '@actions/iObserverActions/LayoutSettingsActions';
import { layoutNames } from '@constants/LayoutSettings';

import {
  getAdmittedEndpoints,
  getAppliedEndpoints,
} from '@selectors/iObserverSelectors';

import FilterDropdowns from '@components/filters/FilterDropdowns';

import {
  Row,
  Col,
  Button,
  Form,
  Modal,
} from 'antd';

import { applyChanges } from '@actions/iObserverActions/GlobalActions';

import { checkEndpointAction } from '@actions/iObserverActions/ObservationActions';
import {
  ENDPOINT_STATUS_CHECKING, ENDPOINT_STATUS_OFFLINE,
} from '@constants/EndpointAvailabilityStatusTypes';
import WebRTCManager from '@util/WebRTC/WebRTCManager';
import IntlMessages from '../util/IntlMessages';
import SelectedCustomer from '../components/SelectedCustomer';

export class Customers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSelectEndpoint = this.onSelectEndpoint.bind(this);
    this.onAdmitEndpoint = this.onAdmitEndpoint.bind(this);
    this.sortEndpoints = this.sortEndpoints.bind(this);
    this.selectedEndpoint = [];
    this.applyBtnCustomers = React.createRef();
    this.filters = [
      {
        placeholder: 'select_dropdown.customer',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'customer',
      },
      {
        placeholder: 'select_dropdown.facility',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'facility',
      },
      {
        placeholder: 'select_dropdown.unit',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'unit',
      },
      {
        placeholder: 'select_dropdown.endpoint',
        fieldNames: { label: 'name', value: 'id' },
        showSearch: true,
        key: 'endpoint',
      },
    ];

    this.state = {
      modalIsVisible: false,
    };

    this.applyChanges = this.applyChanges.bind(this);
  }

  onSelectEndpoint(endpoint) {
    this.selectedEndpoint = [...this.selectedEndpoint, endpoint];
  }

  onDeselectEndpoint = (endpointToRemove) => {
    this.selectedEndpoint = this.selectedEndpoint
      .filter((endpoint) => endpoint.id !== endpointToRemove);
  }

  onAdmitEndpoint() {
    const {
      selectedLayout, checkEndpointAvailable, admitEndpointProp, admittedEndpoints,
      appliedEndpoints,
    } = this.props;
    const avEndpoints = { ...admittedEndpoints, ...appliedEndpoints };
    const selectedCameras = Object.keys(avEndpoints).length;
    const endpointsLength = Object.keys(admittedEndpoints).length + this.selectedEndpoint.length;

    if (endpointsLength > 12) {
      this.showModal();
      return;
    }
    if (this.selectedEndpoint.length > 0) {
      if (selectedCameras < 12) {
        if (selectedLayout !== layoutNames.DEFAULT_LAYOUT) {
          if (selectedCameras === 3) {
            switch (selectedLayout) {
              case layoutNames.LAYOUT_3_ROOMS_LEFT:
              case layoutNames.LAYOUT_3_ROOMS_RIGHT:
                this.applyLayout(layoutNames.LAYOUT_6_ROOMS_EQUAL);
                break;
              default:
                break;
            }
          } else if (selectedCameras === 6) {
            switch (selectedLayout) {
              case layoutNames.LAYOUT_6_ROOMS_LEFT:
              case layoutNames.LAYOUT_6_ROOMS_RIGHT:
              case layoutNames.LAYOUT_6_ROOMS_SYMMETRIC:
              case layoutNames.LAYOUT_6_ROOMS_EQUAL:
                this.applyLayout(layoutNames.LAYOUT_12_ROOMS_EQUAL);
                break;
              default:
                break;
            }
          }
        }
        this.selectedEndpoint.forEach((endpoint) => {
          endpoint.mediaType = WebRTCManager.lookupNodeInfo(
            `camera_${endpoint.machine_name}`,
          ).mediaType;
          checkEndpointAvailable(this.selectedEndpoint.machine_name);
        });

        admitEndpointProp(this.selectedEndpoint);

        this.selectedEndpoint = [];
      } else {
        this.showModal();
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  removeEndpoint(endpoint) {
    if (endpoint) {
      const { removeEndpointProp } = this.props;
      removeEndpointProp(endpoint);
    }
  }

  onChangeLayout(layoutName) {
    const { changeLayoutProp } = this.props;
    changeLayoutProp(layoutName);
  }

  applyLayout = (layoutName) => {
    this.onChangeLayout(layoutName);
  }

  applyChanges() {
    // eslint-disable-next-line no-console
    console.log('CLICK ON APPLY BUTTON');
    const { applyGlobalChanges } = this.props;
    applyGlobalChanges();
  }

  // eslint-disable-next-line react/sort-comp
  showModal = () => {
    this.setState({
      modalIsVisible: true,
    });
  };

  handleOk = () => {
    this.setState({ modalIsVisible: false });
  };

  handleCancel = () => {
    this.setState({ modalIsVisible: false });
  };

  applyBtnHandler = () => {
    if (this.applyBtnCustomers.current) {
      this.applyBtnCustomers.current.disabled = true;
      setTimeout(() => {
        if (this.applyBtnCustomers.current) {
          this.applyBtnCustomers.current.disabled = false;
        }
      }, 2000);
    }
  }

  sortEndpoints = (endpoints) => {
    const arr = Object.values(endpoints);
    if (arr.length > 1) {
      arr.sort((a, b) => (a.ordering_idx > b.ordering_idx ? 1 : -1));
    }
    return arr;
  }

  renderSelectedEndpoints() {
    const endpoints = [];
    const {
      checkEndpointAvailable, intl, admittedEndpoints, appliedEndpoints, selectedEndpoints,
    } = this.props;
    const avEndpoints = { ...admittedEndpoints, ...appliedEndpoints };
    let sortedEndpointsByAddedTime = [];
    if (Object.keys(selectedEndpoints).length > 0) {
      sortedEndpointsByAddedTime = this.sortEndpoints(avEndpoints);

      sortedEndpointsByAddedTime.forEach((endpoint) => {
        if (endpoint.status !== ENDPOINT_STATUSES.REMOVED) {
          endpoints.push(
            <SelectedCustomer
              isLoading={endpoint.availabilityStatus === ENDPOINT_STATUS_CHECKING}
              isOffline={endpoint.availabilityStatus === ENDPOINT_STATUS_OFFLINE}
              isStreamDisconnected={!endpoint.mediaType || endpoint.mediaType !== 'audioVideo'}
              value={endpoint.name}
              key={endpoint.id}
              onClick={() => this.removeEndpoint(endpoint)}
              onRefreshClick={() => checkEndpointAvailable(endpoint.machine_name)}
              intl={intl}
            />,
          );
        }
      });
    }

    return endpoints;
  }

  render() {
    const { modalIsVisible } = this.state;
    const { selectedLayout } = this.props;

    return (
      <div className="gx-mx-0 view-wrapper gx-m-2">
        <div className={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 'inner-wrapper' : 'customer-inner-wrapper'}>
          <h1 className="inner-heading  title-text gx-m-2"><IntlMessages id="customers.titleCustomer" /></h1>
          <Row className="customer-dropdowns">
            {/* SELECTORS */}
            <Col className="col-customers" sm={24} md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 11 : 24}>
              <FilterDropdowns
                filters={this.filters}
                onSelectEndpoint={this.onSelectEndpoint}
                onDeselectEndpoint={this.onDeselectEndpoint}
              />
              <Form.Item className="customer-form-select gx-mb-2">
                <div className="gx-d-flex gx-justify-content-end">
                  <Button onClick={this.onAdmitEndpoint} className="btn-customer btn-margin-customer"><IntlMessages id="customers.btnAdmit" /></Button>
                </div>
              </Form.Item>
            </Col>
            {/* <Col sm={0} md={0} lg={0} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 1 : 0} /> */}
            {/* ADMITTED / APPLIED */}
            <Col className={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 'col-customers2' : 'col-customers'} sm={24} md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 13 : 24}>
              <h2 className="text-title-list"><IntlMessages id="customers.titleAdmittedList" /></h2>
              <hr className="line" />
              <div className={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 'word-breaker customers-list' : 'word-breaker long-customers-list'}>
                {this.renderSelectedEndpoints()}
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          visible={modalIsVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button className="modal-btn" type="primary" onClick={this.handleOk}><IntlMessages id="customers.btnModalOK" /></Button>,
          ]}
        >
          <h1><IntlMessages id="customers.textInsideModal" /></h1>
          <h1><IntlMessages id="customers.txtnsideModal" /></h1>
        </Modal>
        <div className="gx-d-flex gx-justify-content-end btn-customer-panel">
          <Button className="btn-customer apply-btnl" onClick={() => { this.applyChanges(); this.applyBtnHandler(); }} ref={this.applyBtnCustomers} id="applyBtnCustomers">
            <IntlMessages id="customers.btnApply" />
          </Button>
        </div>
      </div>
    );
  }
}

Customers.propTypes = {
  intl: PropTypes.shape().isRequired,
  selectedEndpoints: PropTypes.shape().isRequired,
  admittedEndpoints: PropTypes.shape().isRequired,
  appliedEndpoints: PropTypes.shape().isRequired,
  admitEndpointProp: PropTypes.func.isRequired,
  removeEndpointProp: PropTypes.func.isRequired,
  applyGlobalChanges: PropTypes.func.isRequired,
  changeLayoutProp: PropTypes.func.isRequired,
  selectedLayout: PropTypes.string.isRequired,
  checkEndpointAvailable: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedEndpoints: state.endpoints.selectedEndpoints,
  admittedEndpoints: getAdmittedEndpoints(state),
  appliedEndpoints: getAppliedEndpoints(state),
  selectedLayout: state.monitoringLayout.selectedLayout,
});

const mapDispatchToProps = (dispatch) => ({
  onGetOptions: (key, filter) => dispatch(onGetOptions(key, filter)),
  // resetFilters,
  admitEndpointProp: (endpoint) => dispatch(admitEndpoint(endpoint)),
  removeEndpointProp: (endpoint) => dispatch(removeEndpoint(endpoint)),
  applyGlobalChanges: () => dispatch(applyChanges()),
  changeLayoutProp: (layout) => dispatch(changeLayout(layout)),
  checkEndpointAvailable: (machineName) => dispatch(checkEndpointAction(machineName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Customers)));
