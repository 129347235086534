import {
  APPLY_CHANGES,
  APPLY_CHANGES_SUCCESS,
  LOGOUT,
  CLEAN_UP_DATA_ON_LOGOUT,
} from '@constants/iObserverActionTypes';

/**
 * @return {Object}
 */
export const applyChanges = () => {
  return {
    type: APPLY_CHANGES,
  };
};

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const applyChangesSuccess = (payload) => {
  return {
    type: APPLY_CHANGES_SUCCESS,
    payload,
  };
};

/**
 * @return {Object}
 */
export const logout = () => {
  return {
    type: LOGOUT,
  };
};

/**
 * @return {Object}
 */
export const cleanUpDataOnLogout = () => {
  console.log('CLEAN_UP_DATA_ON_LOGOUT ACTION');
  return {
    type: CLEAN_UP_DATA_ON_LOGOUT,
  };
};
