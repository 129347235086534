import { cloneDeep } from 'lodash';
import {
  CLEAN_UP_DATA_ON_LOGOUT,
  FETCH_OWN_PROFILE_SUCCESS,
  SESSION_EXPIRATION_WARNING_FLAG,
  SESSION_REFRESH_DATA,
} from '@constants/iObserverActionTypes';

const initialSettings = {
  adminId: -1,
  profile: {},
  session: {
    expirationTime: null,
    expirationWarningFlag: false,
  },
};

const profile = (state = cloneDeep(initialSettings), action) => {
  switch (action.type) {
    case FETCH_OWN_PROFILE_SUCCESS:
      return {
        ...state,
        profile: { ...action.payload },
        adminId: action.payload.adminId || '',
      };
    case CLEAN_UP_DATA_ON_LOGOUT:
      return {
        ...cloneDeep(initialSettings),
      };
    case SESSION_REFRESH_DATA:
      return {
        ...state,
        session: {
          ...state.session,
          ...action.payload,
        },
      };
    case SESSION_EXPIRATION_WARNING_FLAG:
      return {
        ...state,
        session: {
          ...state.session,
          expirationWarningFlag: action.payload.expirationWarningFlag,
        },
      };
    default:
      return state;
  }
};

export default profile;
