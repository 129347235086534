import React from 'react';
import PropTypes from 'prop-types';
import { setAudioOutput } from '@util/commonFunctions';

const PTZ_TYPE_NONE = 0;
const PTZ_TYPE_MOVE = 1;
const PTZ_TYPE_ZOOM = 2;

class EndpointVideoElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.clickTimeout = 0;
    this.ptzEffectTimeout = 0;
    this.setVideoRef = (element) => {
      this.videoRef = element;
      const { setVideoRefCallback, speakerId } = this.props;
      if (this.videoRef && speakerId) {
        setAudioOutput(this.videoRef, speakerId);
      }
      if (setVideoRefCallback) {
        setVideoRefCallback(element);
      }
    };
    this.state = {
      ptzEffect: {
        type: PTZ_TYPE_NONE,
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    this.setupStream();
  }

  componentDidUpdate(prevProps) {
    const { speakerId } = this.props;
    if (this.videoRef && prevProps && prevProps.speakerId !== speakerId) {
      setAudioOutput(this.videoRef, speakerId);
    }
    this.setupStream();
  }

  componentWillUnmount() {
    clearTimeout(this.clickTimeout);
    clearTimeout(this.ptzEffectTimeout);
  }

  setupStream = () => {
    const { stream } = this.props;
    if (this.videoRef && this.videoRef.srcObject !== stream) {
      this.videoRef.srcObject = stream;
    }
  }

  goToPointHandler = (offsetX, offsetY, target, zoom) => {
    const { goToPoint } = this.props;
    if (goToPoint) {
      let x = offsetX;
      let y = offsetY;

      // runAnime(event, toZoom);

      const frameInfo = target.getBoundingClientRect();
      const headerHeight = 0;
      // y -= (1.0 * headerHeight);

      const xOrigin = ((frameInfo.width - 1) / 2);
      const yOrigin = ((frameInfo.height - 1 - headerHeight) / 2);
      x = (x + 1 - xOrigin) / xOrigin;
      // x = -(xOrigin - x) / xOrigin;
      // y = (yOrigin - y) / xOrigin;
      y = (yOrigin - y) / yOrigin;

      goToPoint(x, y, zoom);
    }
  };

  setupResetPtxEffectTimer = () => {
    if (this.ptzEffectTimeout) {
      clearTimeout(this.ptzEffectTimeout);
    }
    this.ptzEffectTimeout = window.setTimeout(() => {
      this.setState({ ptzEffect: { type: PTZ_TYPE_NONE, x: 0, y: 0 } });
    }, 1000);
  };

  doubleClicked = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    this.setState({ ptzEffect: { type: PTZ_TYPE_MOVE, x: offsetX, y: offsetY } });
    this.setupResetPtxEffectTimer();
    this.goToPointHandler(offsetX, offsetY, event.target, false);
  };

  onMouseUp = () => {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
      this.clickTimeout = 0;
    } else {
      const { zoom } = this.props;
      if (zoom) {
        zoom(true, false);
      }
    }

    return false;
  };

  onMouseDown = (event) => {
    const { target, button, nativeEvent } = event;
    const { offsetX, offsetY } = nativeEvent;
    this.clickTimeout = window.setTimeout(() => {
      this.setState({ ptzEffect: { type: PTZ_TYPE_ZOOM, x: offsetX, y: offsetY } });
      this.setupResetPtxEffectTimer();

      if (button < 1) {
        this.goToPointHandler(offsetX, offsetY, target, true);
      } else {
        const { zoom } = this.props;
        if (zoom) {
          zoom(false, false);
        }
      }
      this.clickTimeout = 0;
    }, 1000);

    return false;
  };

  render() {
    const { ptzEffect } = this.state;
    const { muted } = this.props;

    let moverStyle = null;
    if (ptzEffect.type) {
      moverStyle = `ptz-effect-container ${ptzEffect.type === PTZ_TYPE_MOVE ? 'move' : 'zoom'}`;
    }
    return (
      <div className="root-ep-video">
        {ptzEffect.type !== PTZ_TYPE_NONE && (
          <div className={moverStyle} style={{ left: ptzEffect.x - 27, top: ptzEffect.y - 27 }} />
        )}
        <video
          ref={this.setVideoRef}
          width="100%"
          height="100%"
          autoPlay
          muted={muted}
          onDoubleClick={this.doubleClicked}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    );
  }
}
EndpointVideoElement.defaultProps = {
  goToPoint: null,
  zoom: null,
  stream: null,
  setVideoRefCallback: null,
  speakerId: '',
};

EndpointVideoElement.propTypes = {
  stream: PropTypes.shape({ id: PropTypes.string }),
  goToPoint: PropTypes.func,
  zoom: PropTypes.func,
  muted: PropTypes.bool.isRequired,
  setVideoRefCallback: PropTypes.func,
  speakerId: PropTypes.string,
};

export default EndpointVideoElement;
