import WebRTCUtils from '@util/WebRTC/WebRTCUtils';
import io from 'socket.io-client';

const MAX_RECONNECT_ATTEMPTS_COUNT = 5;

/**
 * Callbacks:
 * onLocalStreamStart(stream)
 *
 * onLocalStreamStop(stream)
 *
 * onLocalStreamSending(sending)
 *
 * onLocalStreamDegraded(mediaType)
 *
 * onLocalStreamReacquiring()
 *
 * onLocalStreamOverconstrained()
 *
 * onConnect(id)
 *
 * onDisconnect()
 *
 * onRemoteStreamChange(mediaId, state, stream)
 *
 * onRemoteStreamStart(mediaId, stream, callType, calibrateMotion)
 * // callType: 'monitor', 'audioCall', 'videoCall', 'relay'
 * // calibrateMotion: flag indicating whether the stream is for motion calibration
 *
 * onRemoteStreamStop(mediaId, stream, calibrateMotion)
 *
 * onStats(mediaId, audioStats, videoStats)
 *
 * onNodeOnline(nodeId, mediaType)
 *
 * onNodeOffline(nodeId)
 *
 * onOnlineUsers(onlineUsers)
 *
 * onUserOnline(userName, mediaType)
 *
 * onUserOffline(userName)
 *
 * onMotionDetected(mediaId, moving, movingRatio)
 * // Indicates whether motion is detected from a media node.
 *
 * onMotionVisualization(needed)
 * // Indicates whether motion visualization is needed for streaming.
 */
class WebRTCManager {
  constructor() {
    this.controllerInit = false;
    this.localNodeId = null;
    this.userName = null;

    this.currentMediaRelayGroup = 'default';
    this.rtcBaseConfiguration = { }; // { offerExtmapAllowMixed: false };
    this.rtcConfiguration = this.rtcBaseConfiguration;

    this.streamHoldTime = 0;

    this.localStream = null;
    this.mediaState = 'off';
    this.localCallType = 'none';
    this.mediaConstraints = null;
    this.maxMediaType = null;

    this.localCanvasStream = null;
    this.initLocalCanvas = false;
    this.canvasSinks = new Set();

    this.controllers = new Map();
    this.masterUrl = null;
    this.masterSocket = null;

    this.localSenders = new Set();

    this.pollStatsCounter = 0;

    // Distributed system state ////////////////////////////////////////////////////

    // node ID -> {connected, up, mediaType, streamId, canvasStreamId, callType}
    this.remoteNodes = new Map();

    // TODO: max-bundle IceRestart

    // Peer connections ////////////////////////////////////////////////////////////

    // peer node ID -> {
    //   conn: RTCPeerConnection
    //   preparing: flag
    //   negotiating: flag
    //   renegotiate: flag
    //   closing: flag
    //   candidates: Array of queued ICE candidates
    //   receivingMedia: Set of receiving mediaId values
    //   inboundStreams: MediaStreamTrack ID -> MediaStream}
    //   outboundMedia: MediaStreamTrack ID -> media ID
    //   stats: RTCStats ID -> stats
    //   ? inboundStats: MediaStreamTrack ID -> RTCStats ID
    this.peers = new Map();

    // Inbound streams /////////////////////////////////////////////////////////////

    // media node ID -> {closing, requested, sourceId, state}
    this.mediaSource = new Map();

    // MediaStreamTrack -> [stream, sink ID]
    // const trackClones = new Map();

    // Outbound streams ////////////////////////////////////////////////////////////

    // Set of mediaId:sinkId
    this.mediaSinks = new Set();

    this.pollStats = this.pollStats.bind(this);
    this.connect = this.connect.bind(this);
    this.authenticateNode = this.authenticateNode.bind(this);

    WebRTCUtils.setSocketLogMethod(this.socketEmit);

    this.nodeCanvasId = (nodeId) => `${nodeId}*`;
    this.isNodeCanvasId = (id) => id[id.length - 1] === '*';
    this.isLocalNodeCanvasId = (id) => id === `${this.localNodeId}*`;
    this.nodeFromCanvasId = (id) => id.substring(0, id.length - 1);
    this.realNodeId = (id) => (this.isNodeCanvasId(id) ? this.nodeFromCanvasId(id) : id);

    this.defaultNodeInfo = () => ({ connected: false, up: false, mediaType: 'none' });

    this.getWithDefault = (map, key, defaultConstructor) => {
      let value = map.get(key);
      if (!value) {
        value = defaultConstructor();
        map.set(key, value);
      }
      return value;
    };
    this.lookupNodeInfo = (nodeId) => this.getWithDefault(
      this.remoteNodes, nodeId, this.defaultNodeInfo,
    );

    this.isNodeConnected = (nodeId) => this.lookupNodeInfo(nodeId).connected;

    setInterval(this.pollStats, 2000);
  }

  /**
   * Creates an array of peers with states
   *
   * @returns {Array}
   */
  peerStates() {
    return Array.from(this.peers, ([peerId, { conn }]) => ({
      peerId,
      iceState: conn.iceConnectionState,
      signalingState: conn.signalingState,
    }));
  }

  /**
   * Socket Emit
   *
   * @param  {...[*]} args
   *
   * @returns {void}
   */
  socketEmit = (...args) => {
    if (this.masterSocket) {
      this.masterSocket.emit(...args);
    }
  }

  /**
   * Generator function used as itterator.
   * Proabbly better to be refactored as
   * a normal method returning an array
   *
   * @returns {string}
   */
  * connectedNodes() {
    // eslint-disable-next-line no-restricted-syntax
    for (const [nodeId, { connected }] of this.remoteNodes) {
      if (connected) {
        yield nodeId;
      }
    }
  }

  /**
   * Node is connected
   *
   * @param  {string} nodeId
   * @param  {object} nodeInfo
   *
   * @returns {void}
   */
  nodeIsConnected(nodeId, nodeInfo) {
    // Get any existing media info for the node
    const prevInfo = this.remoteNodes.get(nodeId);

    // Determine whether to notify the UI of a change in node status
    const change = !prevInfo || !prevInfo.connected || prevInfo.mediaType !== nodeInfo.mediaType;

    // Set the new node status and media info
    this.remoteNodes.set(nodeId, {
      connected: true,
      up: true,
      mediaType: nodeInfo.mediaType,
      streamId: nodeInfo.streamId,
      canvasStreamId: nodeInfo.canvasStreamId,
      callType: nodeInfo.callType,
    });

    // Notify the UI of any change in node status
    if (change && this.onNodeOnline) {
      this.onNodeOnline(nodeId, nodeInfo.mediaType);
    }

    // TODO identify outstanding streams by media ID
  }

  /**
   * Node is disconnected
   *
   * @param  {string} nodeId
   *
   * @returns {void}
   */
  nodeIsDisconnected(nodeId) {
    const nodeInfo = this.remoteNodes.get(nodeId);

    if (nodeInfo && nodeInfo.connected) {
      nodeInfo.connected = false;

      if (this.onNodeOffline) {
        this.onNodeOffline(nodeId);
      }
    }

    // TODO close down streams by media ID
  }

  /**
   * Get Stream by Media Id
   *
   * @param  {string} mediaId
   *
   * @returns {object|null}
   */
  getStreamByMediaId(mediaId) {
    if (mediaId === this.localNodeId) {
      return this.localStream;
    }

    if (this.isLocalNodeCanvasId(mediaId)) {
      return this.localCanvasStream;
    }

    const sourceInfo = this.mediaSource.get(mediaId);
    if (!sourceInfo || !sourceInfo.sourceId) {
      return null;
    }

    const nodeInfo = this.remoteNodes.get(this.realNodeId(mediaId));
    if (!nodeInfo || !nodeInfo.streamId) {
      return null;
    }

    const peer = this.peers.get(sourceInfo.sourceId);
    if (!peer) {
      return null;
    }

    const findStreamId = this.isNodeCanvasId(mediaId) ? nodeInfo.canvasStreamId : nodeInfo.streamId;
    // eslint-disable-next-line no-restricted-syntax
    for (const [, stream] of peer.inboundStreams) {
      if (stream.id === findStreamId) {
        return stream;
      }
    }

    return null;
  }

  /**
   * Get Stream by Track Id
   *
   * @param  {string} trackId
   *
   * @returns {object|null}
   */
  getStreamByTrackId(trackId) {
    if (this.localStream) {
      // eslint-disable-next-line no-restricted-syntax
      for (const track of this.localStream.getTracks()) {
        if (track.id === trackId) {
          return this.localStream;
        }
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [, { inboundStreams }] of this.peers) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [checkTrackId, stream] of inboundStreams) {
        if (checkTrackId === trackId) {
          return stream;
        }
      }
    }

    return null;
  }

  /**
   * Get Media Info by Stream Id
   *
   * @param  {string} streamId
   *
   * @returns {array}
   */
  getMediaInfoByStreamId(streamId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [nodeId, nodeInfo] of this.remoteNodes) {
      if (nodeInfo.streamId === streamId) {
        return [nodeId, nodeInfo];
      }

      if (nodeInfo.canvasStreamId === streamId) {
        return [this.nodeCanvasId(nodeId), nodeInfo];
      }
    }

    return [null, null];
  }

  /**
   * Get Media Info by Track Id
   *
   * @param  {string} trackId
   *
   * @returns {array}
   */
  getMediaInfoByTrackId(trackId) {
    const stream = this.getStreamByTrackId(trackId);

    if (!stream) {
      return [null, null];
    }

    return this.getMediaInfoByStreamId(stream.id);
  }

  /**
   * Acquire Local Stream
   *
   * @returns {void}
   */
  async acquireLocalStream() {
    // Explictly end any existing local stream
    const prevLocalStream = this.localStream;

    if (this.localStream) {
      this.mediaState = 'turning off';
      this.localStream.getTracks().forEach((track) => track.stop());
    }

    const usingDefaultConstraints = !this.mediaConstraints;
    if (usingDefaultConstraints) {
      this.mediaConstraints = WebRTCUtils.formatMediaConstraints('', '');
    }

    this.mediaState = 'turning on';
    let stream;
    try {
      WebRTCUtils.logMessage(`getUserMedia with constraints ${JSON.stringify(this.mediaConstraints)}`);
      stream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
    } catch (err) {
      this.localStream = null;
      this.mediaState = 'off';
      this.socketEmit('node info', this.getLocalNodeInfo());
      if (prevLocalStream && this.onLocalStreamStop) {
        this.onLocalStreamStop(prevLocalStream);
      }

      if (err.name === 'OverconstrainedError') {
        if (this.onLocalStreamOverconstrained) {
          if (usingDefaultConstraints) {
            WebRTCUtils.logMessage('Failed local stream: OverConstrainedError using default constraints');
          } else {
            WebRTCUtils.logMessage('OverConstrainedError from getUserMedia; calling handler before retrying with default constraints');
            this.onLocalStreamOverconstrained();
            this.mediaConstraints = null;
            await this.acquireLocalStream();
          }
        } else {
          WebRTCUtils.logMessage('Failed local stream: OverConstrainedError from getUserMedia and no onLocalStreamOverconstrained handler available');
        }
      } else if (usingDefaultConstraints) {
        WebRTCUtils.logMessage(`Failed local stream with default constraints: getUserMedia error: ${err}`);
      } else {
        WebRTCUtils.logMessage(`Failed local stream with ${err}; retrying with default constraints`);
        this.mediaConstraints = null;
        await this.acquireLocalStream();
      }

      return;
    }

    if (!stream || !stream.getTracks) {
      WebRTCUtils.logMessage(`acquired local media is defunct + ${stream ? ' - object missing getTracks' : ''}`);
      return;
    }

    let initAudio = false;
    let initVideo = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const track of stream.getTracks()) {
      if (track.getSettings) {
        WebRTCUtils.logMessage(`acquired local ${track.kind} track ${track.id} with settings: ${JSON.stringify(track.getSettings())}`);
      } else {
        WebRTCUtils.logMessage(`acquired local ${track.kind} track ${track.id}`);
      }

      if (track.kind === 'audio') {
        initAudio = true;
      } else if (track.kind === 'video') {
        initVideo = true;
      }
    }

    // Assign the new local stream and register 'onended' handlers
    this.mediaState = 'on';
    this.localStream = stream;
    WebRTCUtils.logMessage(`local stream assigned to stream ${stream ? stream.id : 'null'}`);
    stream.getTracks().forEach((track) => {
      // eslint-disable-next-line no-param-reassign
      track.onended = () => {
        if (this.mediaState !== 'turning off') {
          this.lostLocalTrack(track, stream);
        }
      };
    });

    const localNodeInfo = this.getLocalNodeInfo();
    if (localNodeInfo.mediaType === 'none' && (initAudio || initVideo)) {
      let mode = 'videoOnly';

      if (initAudio && initVideo) {
        mode = 'audioVideo';
      } else if (initAudio) {
        mode = 'audioOnly';
      }

      WebRTCUtils.logMessage(`*** acquired local ${mode} media immediately degraded to none`);
      this.localStream = null;
      return;
    }

    const prevMaxMediaType = this.maxMediaType;
    if (!this.maxMediaType) {
      if (localNodeInfo.mediaType !== 'none') {
        this.maxMediaType = localNodeInfo.mediaType;
      }
    } else if (localNodeInfo.mediaType !== 'none' && this.maxMediaType !== 'audioVideo') {
      this.maxMediaType = localNodeInfo.mediaType;
    }

    if (this.maxMediaType !== prevMaxMediaType) {
      WebRTCUtils.logMessage(`new local max media type is ${this.maxMediaType}`);
    } else if (localNodeInfo.mediaType !== this.maxMediaType) {
      WebRTCUtils.logMessage(`got local media type ${localNodeInfo.mediaType} but max is ${this.maxMediaType}`);
    } else {
      WebRTCUtils.logMessage(`got max media type ${this.maxMediaType}`);
    }

    this.socketEmit('node info', localNodeInfo);

    if (this.onLocalStreamStart) {
      this.onLocalStreamStart(this.localStream);
    }
  }

  /**
   * Get Local Node Info
   *
   * @returns {object}
   */
  getLocalNodeInfo() {
    const nodeInfo = {
      callType: this.localCallType,
      mediaRelayGroup: this.currentMediaRelayGroup,
    };

    if (this.localStream) {
      nodeInfo.mediaType = WebRTCUtils.getMediaType(this.localStream);
      nodeInfo.streamId = this.localStream.id;

      if (this.localCanvasStream) {
        nodeInfo.canvasStreamId = this.localCanvasStream.id;
      }
    } else {
      WebRTCUtils.logMessage('*** missing localStream for getLocalNodeInfo');
      nodeInfo.mediaType = 'none';
    }

    return nodeInfo;
  }

  /**
   * Close Peer
   *
   * @param  {string} peerId
   *
   * @returns {void}
   */
  closePeer(peerId) {
    const peer = this.peers.get(peerId);
    if (peer && peer.conn) {
      if (peer.conn.signalingState === 'closed') {
        WebRTCUtils.logMessage(`peer connection with ${peerId} is already closed`);
        return;
      }
      WebRTCUtils.logMessage(`closing peer connection with ${peerId}`);
      peer.closing = true;
      peer.conn.close();
      peer.negotiating = false;

      if (this.canvasSinks.has(peerId)) {
        this.canvasSinks.delete(peerId);
        if (this.canvasSinks.size === 0 && this.onMotionVisualization) {
          this.onMotionVisualization(false);
        }
      }

      // socketEmit('peer closed', peerId);
    }
  }

  /**
   * Close All Peers
   *
   * @returns {void}
   */
  closeAllPeers() {
    // eslint-disable-next-line no-restricted-syntax
    for (const peerId of this.peers.keys()) {
      this.closePeer(peerId);
    }
  }

  /**
   * Start Outbound Stream
   *
   * @param  {string} mediaId
   * @param  {MediaStream} stream
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  async startOutboundStream(mediaId, stream, sinkId) {
    this.socketEmit('started stream', mediaId, sinkId, WebRTCUtils.getMediaType(stream));
    WebRTCUtils.logMessage(`starting ${mediaId} stream ${stream.id} to ${sinkId}`);
    const peer = this.peerConnection(sinkId);

    if (this.isLocalNodeCanvasId(mediaId)) {
      this.canvasSinks.add(sinkId);
      if (this.canvasSinks.size === 1 && this.onMotionVisualization) {
        this.onMotionVisualization(true);
      }
    }

    peer.preparing = true;
    await Promise.all(stream.getTracks().map(async (track) => {
      // let clone = track.clone();
      // stream.addTrack(clone);
      // trackClones.set(clone, [stream, sinkId]);
      if (this.localCallType === 'audioCall' && track.kind === 'video') {
        console.debug(`[iObserverController] ${new Date().toString()} making audioCall call - ignoring a video track`, track.id);
        return;
      }
      let transceiverFound = null;
      if (peer.conn.getTransceivers) {
        transceiverFound = peer.conn.getTransceivers().find((transceiver) => {
          const { sender, receiver } = transceiver;
          return (!sender.track
            || ((sender.track.muted || sender.track.readyState === 'ended') && sender.track.kind === track.kind))
            && (!receiver.track || receiver.track.kind === track.kind);
        });
      }
      if (transceiverFound) {
        transceiverFound.sender.setStreams(stream);
        try {
          await transceiverFound.sender.replaceTrack(track);
          console.log('[iObserverController] replaceTrack', transceiverFound.mid,
            transceiverFound.direction, transceiverFound.currentDirection);
          if (transceiverFound.direction === 'inactive') {
            transceiverFound.direction = 'sendonly';
          } else {
            transceiverFound.direction = 'sendrecv';
          }
        } catch (e) {
          console.warn('[iObserverController] replaceTrack', e);
          peer.conn.addTrack(track, stream);
        }
      } else {
        peer.conn.addTrack(track, stream); // peer.conn.addTrack(clone, stream);
      }
      peer.outboundMedia.set(track.id, mediaId); // peer.outboundMedia.set(clone.id, mediaId);
    }));
    peer.preparing = false;
    this.negotiatePeer(sinkId);

    if (mediaId === this.localNodeId) {
      const isFirstLocalSender = (this.localSenders.size === 0);
      this.localSenders.add(sinkId);
      if (isFirstLocalSender && this.onLocalStreamSending) {
        this.onLocalStreamSending(true);
      }
    }
  }

  /**
   * Acquire Stream By Media Id
   *
   * @param  {string} mediaId
   *
   * @returns {object|null}
   */
  async acquireStreamByMediaId(mediaId) {
    const stream = this.getStreamByMediaId(mediaId);

    if (stream) {
      return stream;
    }

    if (mediaId === this.localNodeId) {
      WebRTCUtils.logMessage(`requested local stream has not started; attempting to acquire stream with ${this.mediaConstraints ? JSON.stringify(this.mediaConstraints) : 'no constraints'}`);

      if (this.onLocalStreamReacquiring) {
        this.onLocalStreamReacquiring();
      }

      await this.acquireLocalStream();

      if (!this.localStream) {
        WebRTCUtils.logMessage('waiting to acquire requested local stream before sending');
        return null;
      }

      let mediaType = WebRTCUtils.getMediaType(this.localStream);
      if (mediaType !== this.maxMediaType) {
        WebRTCUtils.logMessage(`requested local stream is degraded to ${mediaType}; attempting to reacquire local ${this.maxMediaType ? this.maxMediaType : 'unknown max type'} stream`);

        if (this.onLocalStreamReacquiring) {
          this.onLocalStreamReacquiring();
        }

        await this.acquireLocalStream();

        if (!this.localStream) {
          WebRTCUtils.logMessage('cannot send unavailable local stream');
          return null;
        }

        mediaType = WebRTCUtils.getMediaType(this.localStream);
        if (mediaType !== this.maxMediaType) {
          WebRTCUtils.logMessage(`re-acquired local stream is still degraded to ${mediaType}`);
        }
      }
      return this.localStream;
    }

    WebRTCUtils.logMessage(`waiting to receive requested ${mediaId} stream before sending`);

    return null;
  }

  /**
   * Add Media Sink
   *
   * @param {string} mediaId
   * @param {string} sinkId
   *
   * @returns {void}
   */
  addMediaSink(mediaId, sinkId) {
    this.mediaSinks.add(`${mediaId}:${sinkId}`);
  }

  /**
   * Remove Media Sink
   *
   * @param {string} mediaId
   * @param {string} sinkId
   *
   * @returns {void}
   */
  removeMediaSink(mediaId, sinkId) {
    this.mediaSinks.delete(`${mediaId}:${sinkId}`);
  }

  /**
   * Get Media Sink
   *
   * @param {string} mediaId
   *
   * @returns {string}
   */
  * getMediaSinks(mediaId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const mediaSink of this.mediaSinks) {
      const [checkMediaId, sinkId] = mediaSink.split(':', 2);

      if (checkMediaId === mediaId) {
        yield sinkId;
      }
    }
  }

  /**
   * Get Sinks
   *
   * @returns {object}
   */
  * getSinks() {
    // eslint-disable-next-line no-restricted-syntax
    for (const mediaSink of this.mediaSinks) {
      const [mediaId, sinkId] = mediaSink.split(':', 2);
      yield { mediaId, sinkId };
    }
  }

  /**
   * Add Sink
   *
   * @param {string} mediaId
   * @param {string} sinkId
   *
   * @returns {void}
   */
  async addSink(mediaId, sinkId) {
    WebRTCUtils.logMessage(`adding sink ${sinkId} for ${mediaId} media`);

    if (WebRTCUtils.hasMediaSink(mediaId, sinkId, this.mediaSinks)) {
      WebRTCUtils.logMessage(`duplicate request to stream ${mediaId} to ${sinkId}`);
      const peer = this.peers.get(sinkId);

      if (peer) {
        let alreadySending = false;
        const senders = peer.conn.getSenders();

        // eslint-disable-next-line no-restricted-syntax
        for (const sender of senders) {
          if (sender.track && peer.outboundMedia.get(sender.track.id) === mediaId) {
            alreadySending = true;
            WebRTCUtils.logMessage(`${mediaId} ${sender.track.kind} already streaming to ${sinkId} as track ${sender.track.id}`);
          }
        }
        if (alreadySending) {
          return;
        }
      }
    }

    this.addMediaSink(mediaId, sinkId);

    const stream = await this.acquireStreamByMediaId(mediaId);

    if (!stream) {
      WebRTCUtils.logMessage(`waiting to acquire ${mediaId} stream before sending to ${sinkId}`);
      return;
    }

    if (!this.isNodeConnected(sinkId)) {
      WebRTCUtils.logMessage(`waiting for ${sinkId} to go online before sending ${mediaId}`);
      return;
    }

    this.startOutboundStream(mediaId, stream, sinkId);
  }

  /**
   * Start Sinks
   *
   * @param  {string} mediaId
   * @param  {object} stream
   *
   * @returns {void}
   */
  startSinks(mediaId, stream) {
    // eslint-disable-next-line no-restricted-syntax
    for (const sinkId of this.getMediaSinks(mediaId)) {
      if (!this.isNodeConnected(sinkId)) {
        WebRTCUtils.logMessage(`waiting for ${sinkId} to go online before sending ${mediaId}`);
      } else {
        this.startOutboundStream(mediaId, stream, sinkId);
      }
    }
  }

  /**
   * Remove Local Sender
   *
   * @param  {string} mediaId
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  removeLocalSender(mediaId, sinkId) {
    if (mediaId === this.localNodeId && this.localSenders.has(sinkId)) {
      this.localSenders.delete(sinkId);
      if (this.localSenders.size === 0 && this.onLocalStreamSending) {
        this.onLocalStreamSending(false);
      }
    }
  }

  /**
   * Remove Sink
   *
   * @param  {string} mediaId
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  removeSink(mediaId, sinkId) {
    try {
      WebRTCUtils.logMessage(`removing ${mediaId} sink ${sinkId}`);
      this.removeMediaSink(mediaId, sinkId);
      const peer = this.peers.get(sinkId);
      const stream = this.getStreamByMediaId(mediaId);

      if (peer && stream) {
        const senders = peer.conn.getSenders();
        const numSenders = senders.length;
        const removedSenders = new Set();
        let removed = false;

        // eslint-disable-next-line no-restricted-syntax
        for (const sender of senders) {
          if (sender.track && peer.outboundMedia.get(sender.track.id) === mediaId) {
            WebRTCUtils.logMessage(`  removing sender of ${sender.track.kind} track ${sender.track.id} in stream ${stream.id} to ${sinkId}`);
            removedSenders.add(sender);
          }
        }

        if (peer.conn.getReceivers) {
          const numReceivers = peer.conn.getReceivers().filter(
            (receiver) => receiver.track && !receiver.track.muted,
          ).length;
          const numRemovedSenders = removedSenders.size;
          WebRTCUtils.logMessage(`removing ${numRemovedSenders} of ${numSenders} senders to ${sinkId}; ${numReceivers} receivers`);
          if (numRemovedSenders === numSenders && numReceivers === 0) {
            peer.closing = true;
          }
        }

        peer.preparing = true;
        // eslint-disable-next-line no-restricted-syntax
        for (const sender of removedSenders) {
          peer.outboundMedia.delete(sender.track.id);
          peer.conn.removeTrack(sender);
          removed = true;
        }

        peer.preparing = false;

        if (peer.closing) {
          this.closePeer(sinkId);
        } else {
          this.negotiatePeer(sinkId);
        }

        if (removed) {
          this.socketEmit('stopped stream', mediaId, sinkId);
        }

        if (this.isLocalNodeCanvasId(mediaId)) {
          this.canvasSinks.delete(sinkId);
          if (this.canvasSinks.size === 0 && this.onMotionVisualization) {
            this.onMotionVisualization(false);
          }
        }

        this.removeLocalSender(mediaId, sinkId);
      }
    } catch (err) {
      WebRTCUtils.logMessage(err, `error removing sink ${sinkId} for ${mediaId} media`);
    }
  }

  /**
   * Stop Sinks
   *
   * @param  {string} mediaId
   *
   * @returns {void}
   */
  stopSinks(mediaId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const sinkId of this.getMediaSinks(mediaId)) {
      const peer = this.peers.get(sinkId);

      if (peer) {
        let removed = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const sender of peer.conn.getSenders()) {
          if (sender.track && peer.outboundMedia.get(sender.track.id) === mediaId) {
            // let stream = getStreamByTrackId(sender.track.id);
            // eslint-disable-next-line max-len
            // WebRTCUtils.logMessage(`removing sender of ${sender.track.kind} track ${sender.track.id} stream ${stream.id}`);
            // if (stream) {
            //  trackClones.delete(sender.track);
            //  stream.removeTrack(sender.track);
            // }
            WebRTCUtils.logMessage(`  removing sender of ${sender.track.kind} track ${sender.track.id} to ${sinkId}`);
            peer.conn.removeTrack(sender);
            peer.outboundMedia.delete(sender.track.id);
            removed = true;
          }
        }
        if (removed) {
          this.socketEmit('stopped stream', mediaId, sinkId);
        }
        this.removeLocalSender(mediaId, sinkId);
      }
    }

    if (this.isLocalNodeCanvasId(mediaId)) {
      this.canvasSinks.clear();
      if (this.onMotionVisualization) {
        this.onMotionVisualization(false);
      }
    }
  }

  /**
   * Lost Local Track
   *
   * @param  {object} track
   * @param  {object} stream
   *
   * @returns {void}
   */
  // eslint-disable-next-line no-unused-vars
  lostLocalTrack(track, stream) {
    const mediaType = WebRTCUtils.getMediaType(this.localStream);
    WebRTCUtils.logMessage(`unexpectedly lost local ${track.kind} track ${track.id}, now ${mediaType}`);

    if (mediaType === 'none') {
      // Notify the controller of the lost local media
      this.socketEmit('node info', { mediaType: 'none', callType: this.localCallType });

      // Close the sinks for the local media
      if (this.controllerInit) {
        this.stopSinks(this.localNodeId);
      }

      // Notify the UI of the lost local media
      if (this.onLocalStreamStop) {
        this.onLocalStreamStop(this.localStream);
      }
    } else {
      // Notify the controller of the new degraded media type
      if (this.controllerInit) {
        const nodeInfo = {
          mediaType,
          streamId: this.localStream.id,
          callType: this.localCallType,
        };
        if (this.localCanvasStream) {
          nodeInfo.canvasStreamId = this.localCanvasStream.id;
        }
        this.socketEmit('node info', nodeInfo);
      }

      // Notify the UI of the new degraded media type
      if (this.onLocalStreamDegraded) {
        this.onLocalStreamDegraded(mediaType);
      }
    }
  }

  /**
   * Start Local Stream
   *
   * @param  {object} requestedMediaConstraints
   *
   * @returns {void}
   */
  async startLocalStream(requestedMediaConstraints) {
    this.mediaConstraints = requestedMediaConstraints;
    await this.acquireLocalStream();

    if (this.controllerInit && this.localStream) {
      this.startSinks(this.localNodeId, this.localStream);
    }
  }

  /**
   * Stop Local Stream
   *
   * @returns {void}
   */
  stopLocalStream() {
    if (this.localStream) {
      this.mediaState = 'turning off';

      if (this.controllerInit) {
        // Notify the controller of the stopped local media
        this.socketEmit('node info', { mediaType: 'none', callType: this.localCallType });

        // Stop outbound streams of the local media
        this.stopSinks(this.localNodeId);
      }

      // Stop the local media
      const endedLocalStream = this.localStream;
      this.localStream.getTracks().forEach((track) => track.stop());
      this.localStream = null;
      this.mediaState = 'off';

      // Notify the UI of the stopped local media
      if (this.onLocalStreamStop) {
        this.onLocalStreamStop(endedLocalStream);
      }
    }
  }

  /**
   * Motion Detection
   *
   * @param  {object} canvas
   *
   * @returns {void}
   */
  motionDetection(canvas) {
    if (this.localCanvasStream && this.controllerInit) {
      this.stopSinks(this.nodeCanvasId(this.localNodeId));
    }

    this.localCanvasStream = canvas.captureStream();
    if (this.controllerInit) {
      this.socketEmit('node info', this.getLocalNodeInfo());
      this.startSinks(this.nodeCanvasId(this.localNodeId), this.localCanvasStream);
      this.initLocalCanvas = true;
    }
  }

  /**
   * Get And Save Media Source State
   *
   * @param  {string} mediaId
   *
   * @returns {object}
   */
  getAndSaveMediaSourceState(mediaId) {
    const sourceInfo = this.mediaSource.get(mediaId);
    const requested = sourceInfo && sourceInfo.requested === true;
    const sourceId = sourceInfo ? sourceInfo.sourceId || mediaId : mediaId;
    const mediaNode = this.remoteNodes.get(mediaId);
    const sourceNode = sourceId === mediaId ? mediaNode : this.remoteNodes.get(sourceId);
    const mediaState = mediaNode && mediaNode.mediaType && mediaNode.connected && sourceNode && sourceNode.connected ? mediaNode.mediaType : 'not available';
    let iceState;
    let signalingState;

    if (sourceInfo && sourceInfo.sourceId) {
      const peer = this.peers.get(sourceId);
      if (peer && peer.conn) {
        iceState = peer.conn.iceConnectionState;
        signalingState = peer.conn.signalingState;
      } else {
        iceState = 'pending';
        signalingState = 'pending';
      }
    } else {
      iceState = 'inactive';
      signalingState = 'inactive';
    }

    let state;
    if (requested) {
      if (mediaNode && mediaNode.connected && mediaNode.mediaType !== 'none'
        && sourceNode && sourceNode.connected
        && (iceState === 'connected' || iceState === 'completed')
        && signalingState === 'stable') {
        state = 'on';
      } else {
        state = 'turning on';
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if ((iceState === 'inactive' || iceState === 'closed')
          && (signalingState === 'inactive' || signalingState === 'closed')) {
        state = 'off';
      } else {
        state = 'turning off';
      }
    }

    const prevState = sourceInfo ? sourceInfo.state || 'off' : 'off';
    if (sourceInfo) {
      sourceInfo.state = state;
    }

    return {
      state,
      prevState,
      requested,
      mediaState,
      sourceId,
      iceState,
      signalingState,
    };
  }

  /**
   * Check Media Source State
   *
   * @param  {string} mediaId
   *
   * @returns {void}
   */
  checkMediaSourceState(mediaId) {
    const s = this.getAndSaveMediaSourceState(mediaId);
    if (s.state !== s.prevState) {
      WebRTCUtils.logMessage(`remote stream state change: ${mediaId}: ${JSON.stringify(s)}`);
      if (this.onRemoteStreamChange) {
        this.onRemoteStreamChange(mediaId, s.state);
      }
      if (this.onRemoteStreamStart) {
        if (s.state === 'on') {
          const realMediaId = this.realNodeId(mediaId);
          const stream = this.getStreamByMediaId(mediaId);
          const calibrateMotion = this.isNodeCanvasId(mediaId);
          if (!stream) {
            WebRTCUtils.logMessage(`should trigger onRemoteStreamStart for ${mediaId} but missing stream`);
          } else {
            this.onRemoteStreamStart(realMediaId, stream, 'none', calibrateMotion);
          }
        }
      }
      /*
      if (s.state === 'off') {
        // TODO delete peer connection if all tracks are 'off'
        WebRTCUtils.logMessage(`deleting peer ${mediaId}`);
        let peer = peers.get(mediaId);
        if (peer) {
          peer.deleted = true;
        }
      }
      */
    } else {
      WebRTCUtils.logMessage(`remote stream state unchanged: ${mediaId}: ${JSON.stringify(s)}`);
    }
  }

  getMediaOfSource(sourceId) {
    const mediaIds = new Set();

    // eslint-disable-next-line no-restricted-syntax
    for (const [mediaId, sourceInfo] of this.mediaSource) {
      if (sourceInfo.sourceId && sourceInfo.sourceId === sourceId) {
        mediaIds.add(mediaId);
      }
    }
    return mediaIds;
  }

  /**
   * Check Media Of Source
   *
   * @param  {string} sourceId
   *
   * @returns {void}
   */
  checkMediaOfSource(sourceId) {
    Array.from(this.getMediaOfSource(sourceId)).forEach(this.checkMediaSourceState);
  }

  /**
   * Negotiate Peer
   *
   * @param  {string} peerId
   *
   * @returns {void}
   */
  async negotiatePeer(peerId) {
    try {
      const peer = this.peerConnection(peerId);
      if (peer.negotiating) {
        peer.renegotiate = true;
        WebRTCUtils.logMessage('blocking negotiation in progress, flagging re-negotiation');
        return;
      }
      peer.negotiating = true;
      WebRTCUtils.logMessage(`negotiating media with ${peerId}, signaling state is ${peer.conn.signalingState}`);

      const description = await peer.conn.createOffer();
      if (peer.planB) {
        description.sdp = description.sdp.replace('a=extmap-allow-mixed\r\n', '');
      }
      await peer.conn.setLocalDescription(description);

      this.socketEmit('send', peerId, 'offer', peer.conn.localDescription);
      WebRTCUtils.logMessage(`set local description; sent offer to ${peerId}`);
    } catch (err) {
      WebRTCUtils.logMessage(err);
    }
  }

  /**
   * Peer Connection
   *
   * @param  {string} peerId
   *
   * @param planB
   * @returns {RTCPeerConnection}
   */
  peerConnection(peerId, planB = false) {
    let peer = this.peers.get(peerId);
    if (peer) {
      if (peer.conn.signalingState === 'closed') {
        WebRTCUtils.logMessage(`renewing previously closed peer connection to ${peerId}`);
      } else {
        // WebRTCUtils.logMessage(`using existing peer connection to ${peerId}:
        // ICE ${peer.conn.iceConnectionState}, signaling ${peer.conn.signalingState}`);
        return peer;
      }
    }

    const pcConstraints = WebRTCUtils.getPeerConnectionConstraints();

    WebRTCUtils.logMessage(`new peer connection with configuration ${JSON.stringify(this.rtcConfiguration)} ${JSON.stringify(pcConstraints)}`);

    const config = planB ? { ...this.rtcConfiguration, sdpSemantics: 'plan-b' } : this.rtcConfiguration;
    const conn = new RTCPeerConnection(config, pcConstraints);
    const receivingMedia = new Set();
    const inboundStreams = new Map();
    const outboundMedia = new Map();
    peer = {
      conn,
      negotiating: false,
      renegotiate: false,
      candidates: [],
      receivingMedia,
      inboundStreams,
      outboundMedia,
      stats: new Map(),
      planB,
      // inboundStats: new Map()
    };
    this.peers.set(peerId, peer);

    // socketEmit('peer init', peerId);

    conn.onnegotiationneeded = async () => {
      if (peer.closing) {
        WebRTCUtils.logMessage(`onnegotiationneeded flagged by browser for ${peerId} while closing connection; ignoring`);
      } else if (peer.preparing) {
        WebRTCUtils.logMessage(`onnegotiationneeded flagged by browser for ${peerId} while preparing local description; ignoring to sequence explicitly instead`);
      } else if (peer.negotiating) {
        WebRTCUtils.logMessage(`onnegotiationneeded flagged by browser for ${peerId} during negotiation; ignoring to prevent loops`);
      } else {
        WebRTCUtils.logMessage(`onnegotiationneeded flagged by browser for ${peerId}`);
        this.negotiatePeer(peerId);
      }
    };

    conn.onicecandidate = ({ candidate }) => {
      if (candidate) {
        this.socketEmit('send', peerId, 'ice', candidate);
      }
    };

    const closeOnTerminalStates = (connection) => {
      const { signalingState, iceConnectionState: iceState } = connection;

      if (iceState === 'disconnected' || iceState === 'failed') {
        if (signalingState === 'stable') {
          WebRTCUtils.logMessage(`closing stable peer connection with ${peerId}`);
          this.closePeer(peerId);
        } else {
          WebRTCUtils.logMessage(`allowing ICE state "${iceState}" while signaling state is "${signalingState}"`);
        }
      }
    };

    conn.oniceconnectionstatechange = () => {
      const iceState = conn.iceConnectionState;
      WebRTCUtils.logMessage(`${peerId} ICE state: ${iceState}`);
      this.socketEmit('peer ice state', peerId, iceState, conn.signalingState);
      // checkMediaOfSource(peerId);
      if (this.onPeerIceState) {
        this.onPeerIceState(peerId, iceState);
      }

      const sourceInfo = this.mediaSource.get(peerId);
      if (conn.signalingState === 'stable'
        && (iceState === 'disconnected' || iceState === 'failed')
        && sourceInfo && sourceInfo.requested) {
        sourceInfo.reconnectAttempts = !sourceInfo.reconnectAttempts
          ? 1 : sourceInfo.reconnectAttempts + 1;
        if (sourceInfo.reconnectAttempts > MAX_RECONNECT_ATTEMPTS_COUNT) {
          WebRTCUtils.logMessage(`reconnecting stable peer connection with ${peerId} CANCELED`,
            sourceInfo.reconnectAttempts, MAX_RECONNECT_ATTEMPTS_COUNT);
        }
        WebRTCUtils.logMessage(`reconnecting stable peer connection with ${peerId}`,
          sourceInfo.reconnectAttempts);

        const nodeInfo = this.remoteNodes.get(this.realNodeId(peerId));
        if (nodeInfo?.connected) {
          this.directUnlink(peerId, this.userName);
          setTimeout(() => {
            this.directLink(peerId, this.userName);
          }, 300);
        } else {
          this.directLink(peerId, this.userName);
        }
      } else if (sourceInfo && (iceState === 'connected' || iceState === 'completed')) {
        sourceInfo.reconnectAttempts = 0;
      }
      closeOnTerminalStates(conn);
    };

    conn.onsignalingstatechange = () => {
      const { signalingState } = conn;
      WebRTCUtils.logMessage(`${peerId} signaling state: ${signalingState}`);
      this.socketEmit('peer signaling state', peerId, signalingState);
      // checkMediaOfSource(peerId);
      if (this.onPeerSignalingState) {
        this.onPeerSignalingState(
          peerId, signalingState, conn.localDescription, conn.remoteDescription,
        );
      }

      if (signalingState === 'closed') {
        WebRTCUtils.logMessage(`removing closed peer connection with ${peerId}`);

        // eslint-disable-next-line no-restricted-syntax
        for (const mediaId of this.getMediaOfSource(peerId)) {
          WebRTCUtils.logMessage(`removing ${peerId} as source of ${mediaId} media`);
          this.mediaSource.delete(mediaId);
        }

        this.peers.delete(peerId);
      } else {
        closeOnTerminalStates(conn);
      }
    };

    conn.ontrack = ({ track, streams: [stream] }) => {
      WebRTCUtils.logMessage('conn.ontrack', stream.id, track.id, track.kind, track.readyState,
        track.muted, track.enabled);
      inboundStreams.set(track.id, stream);
      let [mediaId, mediaInfo] = this.getMediaInfoByStreamId(stream.id);

      if (!mediaId) {
        WebRTCUtils.logMessage(`received ${track.kind} track ${track.id} of unidentified stream ${stream.id} from ${peerId}`);
        // mediaId = peerId; mediaInfo = remoteNodes.get(peerId)
        // || {mediaType: 'none', callType: 'none'};
        mediaId = peerId;
        mediaInfo = WebRTCUtils.inferStreamByPeerId(stream, peerId, this.remoteNodes);
      }

      const realMediaId = this.realNodeId(mediaId);
      const calibrateMotion = this.isNodeCanvasId(mediaId);
      const newlyReceiving = !receivingMedia.has(realMediaId);
      receivingMedia.add(realMediaId);
      let sourceInfo = this.mediaSource.get(mediaId);
      // if (sourceInfo) {
      //  WebRTCUtils.logMessage(`received ${mediaId} ${track.kind} track from ${peerId} already
      //  sourced from ${sourceInfo.sourceId}`);
      // }

      if (!sourceInfo) {
        sourceInfo = { sourceId: peerId, reconnectAttempts: 0 };
        this.mediaSource.set(mediaId, sourceInfo);
      }

      // TODO reconcile sourceInfo.closing conflict
      this.startSinks(mediaId, stream);
      this.socketEmit('receiving media', peerId, mediaId, mediaInfo.mediaType);
      WebRTCUtils.logMessage(`receiving ${mediaId} ${mediaInfo.mediaType} from ${peerId}`);

      // Alternative: instead of triggering onRemoteStream start when the stream is available,
      // wait for all signaling transitions to complete.
      if (newlyReceiving && this.onRemoteStreamStart) {
        console.log('[iObserverController] onRemoteStreamStart', stream.id);
        this.onRemoteStreamStart(realMediaId, stream, mediaInfo.callType, calibrateMotion);
      }

      const numTracks = stream.getTracks().length;
      if (numTracks > 2) {
        WebRTCUtils.logMessage(`suspicious stale clones: stream ${stream.id} has ${numTracks} tracks`);
      }

      // eslint-disable-next-line no-shadow
      const handleTrackRemove = (track, skipDrop = false) => {
        console.log('[iob] handleTrackRemove', skipDrop, stream.id, track.id, track.kind,
          track.readyState, track.muted, track.enabled);

        const trackIds = new Set();
        // eslint-disable-next-line no-restricted-syntax
        for (const [trackId, checkStream] of inboundStreams) {
          if (checkStream.id === stream.id) {
            trackIds.add(trackId);
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const trackId of trackIds) {
          inboundStreams.delete(trackId);
        }

        // eslint-disable-next-line no-shadow
        let [mediaId] = this.getMediaInfoByStreamId(stream.id);
        if (!mediaId) {
          WebRTCUtils.logMessage(`lost ${track.kind} track ${track.id} on unidentified stream ${stream.id} from ${peerId}`);
          mediaId = peerId; // TODO resolve unknown track ID
        }
        if (!skipDrop || !sourceInfo.requested) {
          this.socketEmit('dropped media', peerId, mediaId);
          // eslint-disable-next-line no-shadow
          const sourceInfo = this.mediaSource.get(mediaId);
          if (sourceInfo && !sourceInfo.closing) {
            sourceInfo.closing = true;
            WebRTCUtils.logMessage(`closing remote stream ${stream.id}`);
            this.stopSinks(mediaId);
          } else {
            WebRTCUtils.logMessage(`already closing remote stream ${stream.id}`);
          }
        }

        if (receivingMedia.has(realMediaId)) {
          receivingMedia.delete(realMediaId);
          if (this.onRemoteStreamStop) {
            console.log('[iObserverController] onRemoteStreamStop', stream.id);
            this.onRemoteStreamStop(mediaId, stream);
          }
        }
      };
      // eslint-disable-next-line no-param-reassign,no-shadow
      stream.onremovetrack = ({ track }) => {
        WebRTCUtils.logMessage('stream.onremovetrack', stream.id, track.id, track.kind, track.readyState,
          track.muted, track.enabled);
        if (!planB) {
          handleTrackRemove(track, true);
        }
      };
      //
      // eslint-disable-next-line no-param-reassign
      track.onended = () => {
        WebRTCUtils.logMessage(`track.onended ${track.kind} track ${track.id} of stream ${stream.id}
        from ${peerId}`);
        handleTrackRemove(track);
      };

      // eslint-disable-next-line no-param-reassign
      track.onmute = () => {
        WebRTCUtils.logMessage(`track.onmute ${track.kind} track ${track.id} of stream ${stream.id}
        from ${peerId}`);
        // handleTrackRemove(track);
      };

      // eslint-disable-next-line no-param-reassign
      track.onunmute = () => {
        WebRTCUtils.logMessage(`track.onunmute ${track.kind} track ${track.id} of stream ${stream.id}
        from ${peerId}`);

        const streamToUse = this.getStreamByMediaId(peerId);
        if (streamToUse && !streamToUse.getTracks().find((tr) => tr.id === track.id)) {
          console.log('[iObserverController] add track to stream', stream.id);
          streamToUse.addTrack(track);
        }
        // handleTrackRemove(track);
      };
    };

    if (this.onPeerConnected) {
      this.onPeerConnected(peerId);
    }

    return peer;
  }

  /**
   * Login
   *
   * @param  {string} tryUserName
   *
   * @returns {Promise}
   */
  login(tryUserName) {
    return new Promise((resolve, reject) => {
      if (!this.controllerInit) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('must be connected to log in');
      } else {
        this.socketEmit('login user', tryUserName, (result) => {
          if (result === 'yes') {
            this.userName = tryUserName;
            resolve();
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('user login failed');
          }
        });
      }
    });
  }

  /**
   * Logout
   *
   * @returns {Promise}
   */
  logout() {
    return new Promise((resolve, reject) => {
      if (this.controllerInit) {
        this.socketEmit('logout user', (result) => {
          if (result === 'yes') {
            this.userName = null;
            resolve();
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('user logout failed');
          }
        });
      }
    });
  }

  /**
   * New User Session
   *
   * @param  {string} userName
   *
   * @returns {void}
   */
  newUserSession(userName) {
    this.socketEmit('new user session', userName);
  }

  /**
   * Close User Session
   *
   * @returns {void}
   */
  closeUserSession() {
    this.socketEmit('close user session');
  }

  /**
   * Get Current User
   *
   * @returns {Promise}
   */
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      try {
        this.socketEmit('get current user', resolve);
      } catch (err) {
        WebRTCUtils.logMessage(err);
        reject(err);
      }
    });
  }

  /**
   * Controller Authenticated
   *
   * @param  {string} nodeId
   *
   * @returns {void}
   */
  // eslint-disable-next-line no-unused-vars
  controllerAuthenticated(nodeId) {
    this.controllerInit = true;
    WebRTCUtils.logMessage(`connected to controller as ${this.localNodeId} on ${this.masterSocket.id}`);

    if (this.onConnect) {
      this.onConnect(this.localNodeId);
    }

    if (!this.initLocalCanvas && this.localCanvasStream) {
      this.socketEmit('node info', this.getLocalNodeInfo());
      this.startSinks(this.nodeCanvasId(this.localNodeId), this.localCanvasStream);
      this.initLocalCanvas = true;
    }
  }

  /**
   * Authenticate Node
   *
   * @param  {string} nodeId
   *
   * @returns {void}
   */
  async authenticateNode(nodeId) {
    try {
      this.localNodeId = nodeId;
      this.socketEmit('login node', nodeId, this.getLocalNodeInfo(), this.peerStates(), Array.from(this.getSinks()));
      WebRTCUtils.logMessage(`set local node ID; awaiting node login response for ${nodeId}`);
    } catch (err) {
      WebRTCUtils.logMessage(err);
    }
  }

  /**
   * Init With Master
   *
   * @param  {object} socket
   * @param  {string} connectAsNodeId
   *
   * @returns {void}
   */
  async initWithMaster(socket, connectAsNodeId) {
    this.masterSocket = socket;
    window.socket = socket;

    if (this.controllerInit) {
      return;
    }

    if (connectAsNodeId) {
      await this.authenticateNode(connectAsNodeId);
    } else {
      this.socketEmit('get node identity');
    }
  }

  /**
   * Connect
   *
   * @param  {string} controllersText
   * @param  {string} connectAsNodeId
   *
   * @returns {void}
   */
  connect(controllersText, connectAsNodeId = null) {
    WebRTCUtils.logMessage(`controller URLs: ${controllersText}`);
    const controllerUrls = controllersText.split(',').map((s) => s.trim().toLowerCase());

    const initSocket = (controllerUrl) => {
      let thisConnection = this.controllers.get(controllerUrl);

      if (thisConnection) {
        WebRTCUtils.logMessage(`reconnecting to ${thisConnection.state || 'uninitialized'} controller ${controllerUrl}`);
        thisConnection.state = 'connecting';
        delete thisConnection.socket;
      } else {
        WebRTCUtils.logMessage(`connecting to controller ${controllerUrl}`);
        thisConnection = { state: 'connecting' };
        this.controllers.set(controllerUrl, thisConnection);
      }

      const socket = io(controllerUrl, {
        transports: ['websocket'],
        reconnection: false,
      });
      thisConnection.socket = socket;

      socket.on('node identity', this.authenticateNode);

      socket.on('connect', () => {
        thisConnection.state = 'connected';

        socket.emit('get master', (url) => {
          this.masterUrl = url.replace('wss://', 'https://').replace('ws://', 'http://');

          if (this.masterUrl === controllerUrl) {
            WebRTCUtils.logMessage(`connected to master ${this.masterUrl}`);
            this.initWithMaster(socket, connectAsNodeId);
          } else if (controllerUrls.length === 1) {
            this.masterUrl = controllerUrl;
            WebRTCUtils.logMessage(`connected to single controller ${this.masterUrl}`);
            this.initWithMaster(socket, connectAsNodeId);
          } else {
            WebRTCUtils.logMessage(`disconnecting from ${controllerUrl} - master is ${this.masterUrl}`);
            socket.close();
          }
        });
      });

      socket.on('disconnect', (reason) => {
        WebRTCUtils.logMessage(`disconnected from ${controllerUrl}: ${reason}`);
        thisConnection.state = 'disconnected';
        delete thisConnection.socket;

        if (this.masterSocket === socket) {
          this.masterSocket = null;
        }

        if (!this.masterUrl || this.masterUrl === controllerUrl) {
          if (!connectAsNodeId) {
            this.localNodeId = null;
          }
          this.controllerInit = false;

          if (this.onDisconnect) {
            this.onDisconnect();
          }

          if (this.controllers.size > 1) {
            WebRTCUtils.logMessage('reconnect with other controllers');

            // eslint-disable-next-line no-restricted-syntax
            for (const [url, connection] of this.controllers) {
              if (url !== controllerUrl) {
                if (connection.socket) {
                  WebRTCUtils.logMessage(`other controller ${url} is already ${connection.state}`);
                } else {
                  initSocket(url);
                }
              }
            }
          }
        }
      });

      socket.on('connect_error', (error) => {
        WebRTCUtils.logMessage(`connect error to ${controllerUrl} - ${error}`);
        thisConnection.state = 'disconnected';
      });

      // socket.on('reconnect_attempt', (attemptNumber) => {
      //  WebRTCUtils.logMessage(`reconnect attempt ${attemptNumber} to ${controllerUrl}`);
      // });

      // socket.on('connect_timeout', (timeout) => {
      //  WebRTCUtils.logMessage(`connect timeout ${timeout}ms to ${controllerUrl}`);
      //  socket.close();
      // });

      socket.on('master change', (url) => {
        const newMasterUrl = url.replace('wss://', 'https://').replace('ws://', 'http://');

        if (this.controllers.size > 1) {
          if (newMasterUrl === this.masterUrl) {
            WebRTCUtils.logMessage(`ignoring duplicate master change notification to ${this.masterUrl}`);
          } else {
            this.masterUrl = newMasterUrl;

            if (newMasterUrl === controllerUrl) {
              WebRTCUtils.logMessage(`ignoring master change to current controller ${controllerUrl}`);
            } else {
              const masterConnection = this.controllers.get(newMasterUrl);
              if (!masterConnection) {
                WebRTCUtils.logMessage(`ignoring master change to unknown controller ${newMasterUrl}`);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (masterConnection.socket) {
                  WebRTCUtils.logMessage(`disconnecting from ${controllerUrl} - master changed to ${newMasterUrl} and is already ${masterConnection.state}`);
                  socket.close();
                } else {
                  WebRTCUtils.logMessage(`disconnecting from ${controllerUrl} - master changed to ${newMasterUrl} and will start to reconnect`);
                  socket.close();
                  this.controllerInit = false; // required to start authentication with new master
                  initSocket(this.masterUrl);
                }
              }
            }
          }
        } else {
          WebRTCUtils.logMessage(`ignoring master change to ${newMasterUrl} - connected to only configured controller ${controllerUrl}`);
        }
      });

      socket.on('node login success', async (nodeId) => {
        WebRTCUtils.logMessage(`node login successful for ${nodeId}`);
        this.controllerAuthenticated(nodeId);

        if (this.userName) {
          await this.login(this.userName);
          WebRTCUtils.logMessage(`node logged back in as user ${this.userName}`);
        }
      });

      socket.on('node login failure', (nodeId, err) => {
        WebRTCUtils.logMessage(`node login failure for ${nodeId}: ${err}`);
      });

      socket.on('iceservers', (newIceServers) => {
        this.rtcConfiguration = Object.assign(newIceServers, this.rtcBaseConfiguration);
        WebRTCUtils.logMessage(`new RTC configuration: ${JSON.stringify(this.rtcConfiguration)}`);
      });

      socket.on('timers', (timers) => {
        if (timers) {
          if (timers.streamHold) {
            this.streamHoldTime = timers.streamHold;
            WebRTCUtils.logMessage(`stream hold time: ${this.streamHoldTime}ms`);
          }
        }
      });

      socket.on('add sink', async (mediaId, sinkId) => {
        await this.addSink(mediaId, sinkId);
      });

      socket.on('remove sink', (mediaId, sinkId) => {
        this.removeSink(mediaId, sinkId);
      });

      socket.on('restart sink', async (mediaId, sinkId) => {
        try {
          WebRTCUtils.logMessage(`restarting sink ${sinkId} for ${mediaId} media`);
          this.addMediaSink(mediaId, sinkId);
          const peer = this.peerConnection(sinkId);
          const senders = peer.conn.getSenders();

          // eslint-disable-next-line no-restricted-syntax
          for (const sender of senders) {
            if (sender.track && peer.outboundMedia.get(sender.track.id) === mediaId) {
              WebRTCUtils.logMessage(`removing old transceiver of ${mediaId} media to ${sinkId}`);
              peer.conn.removeTrack(sender);
            }
          }
          const stream = this.acquireStreamByMediaId(mediaId);
          if (!stream) {
            WebRTCUtils.logMessage(`waiting to acquire ${mediaId} stream before sending to ${sinkId}`);
          } else {
            this.startOutboundStream(mediaId, stream, sinkId);
          }
        } catch (err) {
          WebRTCUtils.logMessage(`error restarting sink ${sinkId} for ${mediaId} media: ${err}`);
        }
      });

      socket.on('add source', (sourceId, mediaId) => {
        let sourceInfo = this.mediaSource.get(mediaId);
        if (sourceInfo) {
          // TODO resolve source conflict
          WebRTCUtils.logMessage(`expect ${mediaId} sourced from ${sourceId}, previously sourced from ${sourceInfo.sourceId}`);
          sourceInfo.sourceId = sourceId;
          sourceInfo.requested = true;
        } else {
          sourceInfo = { sourceId: mediaId, requested: true };
          this.mediaSource.set(mediaId, sourceInfo);
          WebRTCUtils.logMessage(`expect ${mediaId} sourced from ${sourceId}`);
        }
      });

      socket.on('remove source', (sourceId, mediaId) => {
        // TODO handle "turning off" race with track.onended handler
        WebRTCUtils.logMessage(`no longer expect ${mediaId} from ${sourceId}`);
        // const sourceInfo = this.mediaSource.get(mediaId);
        // if (sourceInfo) {
        //   sourceInfo.requested = false;
        // }
      });

      socket.on('offer', async (sourceId, { type, sdp }) => {
        try {
          WebRTCUtils.logMessage(`received offer from ${sourceId}`);
          const planB = sdp && (sdp.includes('mid:audio') || sdp.includes('mid:video'));
          WebRTCUtils.logMessage(`${planB ? 'plan-b' : 'unified plan'} detected for ${sourceId}`);

          const peer = this.peerConnection(sourceId, planB);
          await peer.conn.setRemoteDescription({ type, sdp });
          WebRTCUtils.dequeueCandidates(sourceId, peer);
          await peer.conn.setLocalDescription(await peer.conn.createAnswer());
          this.socketEmit('send', sourceId, 'answer', peer.conn.localDescription);
          WebRTCUtils.logMessage(`set remote and local description; sent answer to ${sourceId}`);
        } catch (err) {
          WebRTCUtils.logMessage(err);
        }
      });

      socket.on('answer', async (sinkId, { type, sdp }) => {
        try {
          WebRTCUtils.logMessage(`received answer from ${sinkId}`);
          const peer = this.peers.get(sinkId);

          if (!peer) {
            WebRTCUtils.logMessage(`unexpected answer from ${sinkId}`);
            return;
          }

          await peer.conn.setRemoteDescription(
            { type, sdp: WebRTCUtils.restrictBandwidth(sdp, this) },
          );
          WebRTCUtils.logMessage(`set remote description for ${sinkId}`);
          WebRTCUtils.dequeueCandidates(sinkId, peer);
          peer.negotiating = false;

          if (peer.closing) {
            WebRTCUtils.logMessage(`completing close of peer connection with ${sinkId}`);
            this.closePeer(sinkId);
          } else if (peer.renegotiate) {
            peer.renegotiate = false;
            WebRTCUtils.logMessage(`triggering re-negotiation with ${sinkId}`);
            peer.conn.onnegotiationneeded();
          }
        } catch (err) {
          WebRTCUtils.logMessage(err);
        }
      });

      socket.on('ice', async (peerId, candidate) => {
        try {
          const peer = this.peerConnection(peerId);

          if (!peer.conn.remoteDescription || !peer.conn.remoteDescription.type) {
            WebRTCUtils.logMessage(`queuing early ICE candidate from ${peerId}`);
            peer.candidates.push(candidate);
          } else {
            WebRTCUtils.logMessage(`received and applying ICE candidate from ${peerId}`);
            await peer.conn.addIceCandidate(new RTCIceCandidate(candidate));
          }
        } catch (err) {
          WebRTCUtils.logMessage(err);
        }
      });

      socket.on('online nodes', (nodes) => {
        this.onlineNodes = nodes;
        const nowConnected = new Set(nodes.map((n) => n.nodeId));

        // eslint-disable-next-line no-restricted-syntax
        for (const nodeId of this.connectedNodes()) {
          if (!nowConnected.has(nodeId)) {
            this.nodeIsDisconnected(nodeId);
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const nodeInfo of nodes) {
          this.nodeIsConnected(nodeInfo.nodeId, nodeInfo);
        }
      });

      socket.on('node online', (nodeId, nodeInfo) => {
        console.debug(`[iObserverController] ${new Date().toString()} node online`, nodeId,
          JSON.stringify(nodeInfo));
        this.nodeIsConnected(nodeId, nodeInfo);
      });

      socket.on('node offline', (nodeId) => {
        console.debug(`[iObserverController] ${new Date().toString()} node offline`, nodeId);

        this.nodeIsDisconnected(nodeId);
      });

      socket.on('close peer', (peerId) => {
        this.closePeer(peerId);
      });

      // eslint-disable-next-line no-unused-vars
      socket.on('online users', (newOnlineUsers) => {
        const onlineUsers = [];

        newOnlineUsers.forEach((userMedia) => {
          const [user, media] = userMedia.split(':', 2);

          if (!this.userName || this.userName !== user) {
            onlineUsers.push({ name: user, mediaType: media });
          }
        });
        WebRTCUtils.logMessage(`online users: ${JSON.stringify(onlineUsers)}`);

        if (this.onOnlineUsers) {
          this.onOnlineUsers(onlineUsers);
        }
      });

      socket.on('user online', (onlineUser, mediaType) => {
        if (!this.userName || this.userName !== onlineUser) {
          WebRTCUtils.logMessage(`user online: ${onlineUser}, media type ${mediaType}`);

          if (this.onUserOnline) {
            this.onUserOnline(onlineUser, mediaType);
          }
        }
      });

      socket.on('user offline', (offlineUser) => {
        if (!this.userName || this.userName !== offlineUser) {
          WebRTCUtils.logMessage(`user offline: ${offlineUser}`);

          if (this.onUserOffline) {
            this.onUserOffline(offlineUser);
          }
        }
      });

      socket.on('motion detected', (mediaId, moving, movingRatio) => {
        if (this.onMotionDetected) {
          this.onMotionDetected(mediaId, moving, movingRatio);
        }
      });

      socket.on('debug', (command) => {
        try {
          // eslint-disable-next-line no-eval
          const rv = eval(command);
          WebRTCUtils.logMessage(`debug: ${command} => ${JSON.stringify(rv)}`);
        } catch (err) {
          WebRTCUtils.logMessage(`debug: ${command} => error: ${err.message}`);
        }
      });

      return socket;
    };

    controllerUrls.forEach((controllerUrl, index) => {
      let url = controllerUrl;

      if (!url.startsWith('https://') && !url.startsWith('http://')
        && !url.startsWith('wss://') && !url.startsWith('ws://')) {
        url = `https://${controllerUrl}`;
        controllerUrls[index] = url;
      }

      initSocket(url);
    });

    if (this.pollControllerInterval) {
      clearInterval(this.pollControllerInterval);
    }

    if (this.controllers.size > 1) {
      this.pollControllerInterval = setInterval(() => {
        // for (let [url, connection] of controllers) {
        //  WebRTCUtils.logMessage(`controller connection state: ${connection.state} ${url}`);
        // }
        let notDisconnected = false;

        // eslint-disable-next-line no-restricted-syntax, no-unused-vars
        for (const [url, connection] of this.controllers) {
          if (connection.state !== 'disconnected') {
            notDisconnected = true;
            break;
          }
        }

        if (!notDisconnected) {
          WebRTCUtils.logMessage('disconnected from all controllers - reconnecting');
          // eslint-disable-next-line no-restricted-syntax, no-unused-vars
          for (const [url, connection] of this.controllers) {
            initSocket(url);
          }
        }
      }, 5000);
    } else {
      this.pollControllerInterval = setInterval(() => {
        const controllerUrl = controllerUrls[0];
        const connection = this.controllers.get(controllerUrl);
        // WebRTCUtils.logMessage(`single socket to ${controllerUrl}: ${connection.state}`);

        if (connection && connection.state === 'disconnected') {
          WebRTCUtils.logMessage('disconnected from single controller - reconnecting');
          initSocket(controllerUrl);
        }
      }, 5000);
    }
  }

  /**
   * Disconnect
   *
   * @returns {void}
   */
  disconnect() {
    this.masterSocket.close();
  }

  /**
   * Is Connected
   *
   * @returns {Boolean}
   */
  isConnected() {
    return this.controllerInit;
  }

  /**
   * Direct Link
   *
   * @param  {string} nodeId
   * @param  {string} userName
   * @param  {boolean} [options.calibrateMotion]
   *
   * @returns {void}                         [description]
   */
  directLink(nodeId, userName, { calibrateMotion = false } = {}) {
    const mediaId = calibrateMotion ? this.nodeCanvasId(nodeId) : nodeId;
    this.socketEmit('link user endpoint', nodeId, mediaId, userName);
  }

  /**
   * Unlink
   *
   * @param  {string} nodeId
   * @param  {string} userName
   * @param  {boolean} [options.calibrateMotion]
   *
   * @returns {void}                         [description]
   */
  directUnlink(nodeId, userName, { calibrateMotion = false } = {}) {
    const mediaId = calibrateMotion ? this.nodeCanvasId(nodeId) : nodeId;
    this.socketEmit('unlink user endpoint', nodeId, mediaId, userName);

    const sourceInfo = this.mediaSource.get(mediaId);
    if (sourceInfo) {
      sourceInfo.requested = false;
    }
  }

  /**
   * Indirect Link
   *
   * @param  {string} sourceId
   * @param  {string} mediaId
   * @param  {string} userName
   *
   * @returns {void}
   */
  indirectLink(sourceId, mediaId, userName) {
    this.socketEmit('link user endpoint', this.realNodeId(sourceId), mediaId, userName);
  }

  /**
   * Indirect Unlink
   *
   * @param  {string} sourceId
   * @param  {string} mediaId
   * @param  {string} userName
   *
   * @returns {void}
   */
  indirectUnlink(sourceId, mediaId, userName) {
    this.socketEmit('unlink user endpoint', this.realNodeId(sourceId), mediaId, userName);

    const sourceInfo = this.mediaSource.get(mediaId);
    if (sourceInfo) {
      sourceInfo.requested = false;
    }
  }

  /**
   * Unlink User
   *
   * @param  {string} userName
   *
   * @returns {void}
   */
  unlinkUser(userName) {
    this.socketEmit('unlink user', userName);
  }

  /**
   * Relay
   *
   * @param  {string} sourceId
   * @param  {string} mediaId
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  relay(sourceId, mediaId, sinkId) {
    // If only two parameters are given, interpret them as (sourceId, sinkId)
    if (!sinkId) {
      // eslint-disable-next-line no-param-reassign
      sinkId = mediaId;
      // eslint-disable-next-line no-param-reassign
      mediaId = sourceId;
    }

    this.socketEmit('stream on', sourceId, mediaId, sinkId, 'relay');
  }

  /**
   * Stop Relay
   *
   * @param  {string} sourceId
   * @param  {string} mediaId
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  stopRelay(sourceId, mediaId, sinkId) {
    // If only two parameters are given, interpret them as (sourceId, sinkId)
    if (!sinkId) {
      // eslint-disable-next-line no-param-reassign
      sinkId = mediaId;
      // eslint-disable-next-line no-param-reassign
      mediaId = sourceId;
    }
    this.socketEmit('stream off', sourceId, mediaId, sinkId);
  }

  /**
   * Stream
   *
   * @param  {string} sourceId
   * @param  {string} mediaId
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  stream(sourceId, mediaId, sinkId) {
    this.socketEmit('stream on', sourceId, mediaId, sinkId, 'monitor');
  }

  /**
   * StartStream description]
   *
   * @param  {string} sourceMediaId
   * @param  {string} sinkId
   * @param  {string} type
   *
   * @returns {void}
   */
  startStream(sourceMediaId, sinkId, type = 'monitor') {
    this.socketEmit('stream on', sourceMediaId, sourceMediaId, sinkId, type);
  }

  /**
   * Stop Monitor
   *
   * @param  {string} sourceMediaId
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  stopMonitor(sourceMediaId, sinkId) {
    this.socketEmit('stream off', sourceMediaId, sourceMediaId, sinkId);
  }

  /**
   * Start Call
   *
   * @param  {string} sinkId
   * @param  {string} callType
   *
   * @returns {void}
   */
  startCall(sinkId, callType) {
    if (!this.localStream) {
      WebRTCUtils.logMessage(`not starting call with ${sinkId}: no local media stream`);
      return;
    }
    if (!this.controllerInit) {
      WebRTCUtils.logMessage(`not starting call with ${sinkId}: not connected to controller`);
      return;
    }
    if (!this.mediaSource.has(sinkId)) {
      WebRTCUtils.logMessage(`not starting call with ${sinkId}: no existing stream`);
      return;
    }
    this.localCallType = callType;
    this.socketEmit('start call', this.localNodeId, sinkId, this.getLocalNodeInfo());
  }

  /**
   * End Call
   *
   * @param  {string} sinkId
   *
   * @returns {void}
   */
  endCall(sinkId) {
    this.localCallType = 'none';

    if (this.controllerInit) {
      this.socketEmit('end call', this.localNodeId, sinkId, this.getLocalNodeInfo());
    }
  }

  /**
   * Monitor Motion
   *
   * @param  {string} mediaId
   * @param  {string} userName
   *
   * @returns {void}
   */
  monitorMotion(mediaId, userName) {
    this.socketEmit('monitor motion', mediaId, userName);
  }

  /**
   * Unmonitor Motion
   *
   * @param  {string} mediaId
   * @param  {string} userName
   *
   * @returns {void}
   */
  unmonitorMotion(mediaId, userName) {
    this.socketEmit('unmonitor motion', mediaId, userName);
  }

  /**
   * Signal Motion
   *
   * @param  {string} moving
   * @param  {Number} movingRatio
   *
   * @returns {void}
   */
  signalMotion(moving, movingRatio = 0) {
    this.socketEmit('motion detected', moving, movingRatio);
  }

  /**
   * Start Local Dummy
   *
   * @param  {object} canvas
   *
   * @returns {void}
   */
  startLocalDummy(canvas) {
    // Explictly end any existing local stream
    if (this.localStream) {
      this.mediaState = 'turning off';
      this.localStream.getTracks().forEach((track) => track.stop());
    }

    // Call captureStream of dummy canvas element
    this.mediaState = 'turning on';
    const stream = canvas.captureStream();

    // eslint-disable-next-line no-restricted-syntax
    for (const track of stream.getTracks()) {
      if (track.getSettings) {
        WebRTCUtils.logMessage(`${track.kind} track ${track.id} settings: ${JSON.stringify(track.getSettings())}`);
      }
    }

    // Assign the new local stream and register 'onended' handlers
    this.mediaState = 'on';
    this.localStream = stream;
    WebRTCUtils.logMessage(`local stream assigned to stream ${stream.id}`);
    stream.getTracks().forEach((track) => {
      // eslint-disable-next-line no-param-reassign
      track.onended = () => {
        if (this.mediaState !== 'turning off') {
          this.lostLocalTrack(track, stream);
        }
      };
    });

    if (this.controllerInit) {
      this.socketEmit('node info', this.getLocalNodeInfo());
      this.startSinks(this.localNodeId, stream);
    }

    if (this.onLocalStreamStart) {
      this.onLocalStreamStart(this.localStream);
    }
  }

  /**
   * Get Media Relays
   *
   * @param  {String} mediaRelayGroup
   *
   * @returns {Promise}
   */
  getMediaRelays(mediaRelayGroup = 'default') {
    return new Promise((resolve, reject) => {
      try {
        this.socketEmit('get media relays', mediaRelayGroup, resolve);
      } catch (err) {
        WebRTCUtils.logMessage(err);
        reject(err);
      }
    });
  }

  /**
   * Set Media Relays
   *
   * @param {object} mediaRelays
   * @param {String} mediaRelayGroup
   *
   * @returns {void}
   */
  setMediaRelays(mediaRelays, mediaRelayGroup = 'default') {
    this.socketEmit('set media relays', mediaRelays, mediaRelayGroup);
  }

  /**
   * Get Media Relay Groups
   *
   * @returns {Promise}
   */
  getMediaRelayGroups() {
    return new Promise((resolve, reject) => {
      try {
        this.socketEmit('get media relay groups', resolve);
      } catch (err) {
        WebRTCUtils.logMessage(err);
        reject(err);
      }
    });
  }

  /**
   * Use Media Relay Group
   *
   * @param  {object} mediaRelayGroup
   *
   * @returns {void}
   */
  useMediaRelayGroup(mediaRelayGroup) {
    if (mediaRelayGroup === this.currentMediaRelayGroup) {
      return;
    }

    this.currentMediaRelayGroup = mediaRelayGroup;
    WebRTCUtils.logMessage(`using media relay group '${mediaRelayGroup}'`);
  }

  deleteMediaRelayGroup(mediaRelayGroup) {
    this.socketEmit('delete media relay group', mediaRelayGroup);
  }

  /**
   * Poll Stats
   *
   * @returns {void}
   */
  async pollStats() {
    this.pollStatsCounter += 1;

    // eslint-disable-next-line no-restricted-syntax
    for (const [peerId, peer] of this.peers) {
      if (peer && peer.conn) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const statsReport = await peer.conn.getStats(null);
          const mediaStats = new Map(); // media ID -> Set of stats
          // let types = new Set();

          // eslint-disable-next-line no-restricted-syntax
          for (const [, stats] of statsReport) {
            // types.add(stats.type);
            if (stats.type === 'inbound-rtp') {
              const s = {
                id: stats.id,
                ssrc: stats.ssrc,
                statsTimestamp: stats.timestamp,
                mediaType: stats.mediaType,
                packetsReceived: stats.packetsReceived,
                bytesReceived: stats.bytesReceived,
                packetsLost: stats.packetsLost,
                fractionLost: stats.fractionLost,
              };

              const track = statsReport.get(stats.trackId);

              if (!track || !track.trackIdentifier) {
                // eslint-disable-next-line no-continue
                continue;
              }

              s.kind = track.kind;
              s.trackTimestamp = track.timestamp;
              s.trackId = track.trackIdentifier;
              s.trackEnded = track.ended;

              const transport = statsReport.get(stats.transportId);
              if (transport) {
                s.transportTimestamp = transport.timestamp;
                s.dtlsState = transport.dtlsState;
                s.bytesSent = transport.bytesSent;
                s.transportBytesReceived = transport.bytesReceived;
              }

              if (stats.mediaType === 'video') {
                s.firCount = stats.firCount;
                s.pliCount = stats.pliCount;
                s.nackCount = stats.nackCount;
                s.qpSum = stats.qpSum;
                s.framesDecoded = stats.framesDecoded;
                s.framesPerSecond = stats.framesPerSecond;
                if (track) {
                  s.frameWidth = track.frameWidth;
                  s.frameHeight = track.frameHeight;
                  s.framesReceived = track.framesReceived;
                  s.trackFramesDecoded = track.framesDecoded;
                  s.framesDropped = track.framesDropped;
                }
              } else {
                s.jitter = stats.jitter;
                if (track) {
                  s.jitterBufferDelay = track.jitterBufferDelay;
                  s.audioLevel = track.audioLevel;
                  s.totalAudioEnergy = track.totalAudioEnergy;
                  s.totalSamplesReceived = track.totalSamplesReceived;
                  s.totalSamplesDuration = track.totalSamplesDuration;
                  s.concealedSamples = track.concealedSamples;
                  s.concealmentEvents = track.concealmentEvents;
                }
              }

              if (stats.codecId) {
                const codec = statsReport.get(stats.codecId);
                s.codecTimestamp = codec.timestamp;
                s.codec = codec.mimeType;
                s.payloadType = codec.payloadType;
                s.clockRate = codec.clockRate;
              }

              const prev = peer.stats.get(s.id);
              if (prev) {
                const statsIntervalMs = s.statsTimestamp - prev.statsTimestamp;
                s.statsIntervalMs = statsIntervalMs;
                const bitsReceived = (s.bytesReceived - prev.bytesReceived) * 8;
                s.kbps = Math.floor(bitsReceived / statsIntervalMs); // bits/ms = kbits/s
              }

              peer.stats.set(s.id, s);
              // peer.inboundStats.set(s.trackId, s.id);

              const [mediaId] = this.getMediaInfoByTrackId(s.trackId);
              if (mediaId) {
                if (mediaStats.has(mediaId)) {
                  mediaStats.get(mediaId).add(s);
                } else {
                  const newSet = new Set();
                  newSet.add(s);
                  mediaStats.set(mediaId, newSet);
                }
              } else {
                // WebRTCUtils
                // .logMessage(
                // `*** receiving inbound stats for unused track ${s.trackId} from ${peerId}`);
              }
            }
          }

          // WebRTCUtils.logMessage(`... types: ${JSON.stringify(Array.from(types))}`);
          const reportTime = (this.pollStatsCounter >= 5);

          if (reportTime) {
            this.pollStatsCounter = 0;
          }

          const report = (this.controllerInit && this.localNodeId && reportTime);
          if (report || this.onStats) {
            // eslint-disable-next-line no-restricted-syntax
            for (const [mediaId, statsSet] of mediaStats) {
              // WebRTCUtils.logMessage(
              // `${mediaId} stats: ${JSON.stringify(Array.from(statsSet).map(s => s.id))}`);
              const statsList = Array.from(statsSet);
              const audioStats = statsList.find((s) => s.mediaType === 'audio');
              const videoStats = statsList.find((s) => s.mediaType === 'video');
              if (report) {
                this.socketEmit('stats', peerId, mediaId, audioStats, videoStats);
              }
              if (this.onStats) {
                this.onStats(mediaId, audioStats, videoStats);
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  /**
   * Get Local Stream Id
   *
   * @returns {string}
   */
  localStreamId() {
    return this.localStream ? this.localStream.id : 'no stream';
  }

  /**
   * Get Local Stream
   *
   * @returns {object}
   */
  getLocalStream() {
    return this.localStream;
  }

  /**
   * Get Local Canvas Stream
   *
   * @returns {object}
   */
  getLocalCanvasStream() {
    return this.localCanvasStream;
  }

  /**
   * Abort Audio
   *
   * @returns {boolean}
   */
  abortAudio() {
    return this.localStream.getAudioTracks()[0].stop();
  }

  /**
   * Abort Video
   *
   * @returns {boolean}
   */
  abortVideo() {
    return this.localStream.getVideoTracks()[0].stop();
  }

  // NOT USED
  // /**
  //  * Sources
  //  *
  //  * @returns {void}
  //  */
  // sources() {
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const [mediaId] of this.mediaSource) {
  //     console.debug(`${mediaId}:${JSON.stringify(this.getAndSaveMediaSourceState(mediaId))}`);
  //   }
  // }

  // NOT USED
  // /**
  //  * Sinks
  //  *
  //  * @returns {void}
  //  */
  // sinks() {
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const [mediaId, sinkIds] of this.mediaSinks) {
  //     // console.debug(`${mediaId} sinks: ${JSON.stringify(Array.from(sinkIds))}`);
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const sinkId of sinkIds) {
  //       const mediaState = this.getStreamByMediaId(mediaId) ? 'available' : 'not available';
  //       const sinkOnline = this.isNodeConnected(sinkId) ? 'online' : 'offline';
  //       let sinkIceState = 'pending';
  //       let sinkSignalingState = 'pending';
  //       const peer = this.peers.get(sinkId);
  //
  //       if (peer && peer.conn) {
  //         sinkIceState = peer.conn.iceConnectionState;
  //         sinkSignalingState = peer.conn.signalingState;
  //       }
  //
  //       // eslint-disable-next-line no-console
  //       console.debug(`${mediaId}:${sinkId} media ${mediaState}, sink ${sinkOnline},
  //       sink ICE ${sinkIceState}, sink signaling ${sinkSignalingState}`);
  //     }
  //   }
  // }

  /**
   * Inspect Peers
   *
   * @returns {Array}
   */
  inspectPeers() {
    return Array
      .from(this.peers)
      .map(
        (kv) => `${kv[0]}: {signaling ${kv[1].conn.signalingState}, ICE ${kv[1].conn.iceConnectionState}}`,
      );
  }

  /**
   * Media Relay Group
   *
   * @returns {Object}
   */
  mediaRelayGroup() {
    return this.currentMediaRelayGroup;
  }

  /**
   * Ice Servers
   *
   * @returns {object}
   */
  iceServers() {
    return this.rtcConfiguration;
  }
}

export default new WebRTCManager();
export { WebRTCManager as WebRTCManagerClass };
