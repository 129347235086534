import {
  INIT_URL,
  SIGNOUT_USER,
} from '@constants/ActionTypes';

export const userSignOut = () => ({
  type: SIGNOUT_USER,
});

export const setInitUrl = (url) => ({
  type: INIT_URL,
  payload: url,
});
