import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { layoutNames } from '@constants/LayoutSettings';
import { Row, Col } from 'antd';
import { isEqual } from 'lodash';

import CustomTopbar from '@containers/Topbar/CustomTopbar';
import Customers from '@routes/Customers';
import Patient from '@routes/Patient';
import Alert from '@routes/Alert';
import Layouts from '@routes/Layouts';
import Session from '@routes/Session';
import MyCamera from '@routes/MyCamera';
import Reports from '@routes/Reports';
import { ENDPOINT_STATUSES } from '@constants/Settings';
import WebRTCManager from '@util/WebRTC/WebRTCManager';
import { ENDPOINT_STATUS_OFFLINE } from '@constants/EndpointAvailabilityStatusTypes';
import { getSelectedEndpointObservation } from '@selectors/iObserverSelectors';
import { changeLayout } from '@actions/iObserverActions/LayoutSettingsActions';
import DefaultLayout from './DefaultLayout';

import Layout3RoomsLeft from './Layout3RoomsLeft';
import Layout12RoomsLeft from './Layout12RoomsLeft';
import Layout3RoomsRight from './Layout3RoomsRight';
import Layout6RoomsLeft from './Layout6RoomsLeft';
import Layout6RoomsRight from './Layout6RoomsRight';
import Layout6RoomsEqual from './Layout6RoomsEqual';
import Layout6RoomsSymmetric from './Layout6RoomsSymmetric';
import Layout12RoomsEqual from './Layout12RoomsEqual';
import {
  setSelectedObservation,
  refreshCameraAction,
  fetchEndpointDetails,
  startVideoCallAction,
  goToPoint,
  zoomIn,
  zoomOut,
  zoomStop,
  getEndpointStatus,
  getEndpointStatusUpdate, checkEndpointAction,
} from '../../appRedux/actions';
import Version from '../../routes/Version';

const routePaths = {
  customers: '/app/customers',
  patient: '/app/patient',
  alert: '/app/alert',
  layouts: '/app/layouts',
  session: '/app/session',
  myCamera: '/app/my-camera',
  reports: '/app/reports',
  version: 'app/version',
};

const CHECK_STATUS_TIMEOUT = 60000; // 1 min
const WEBRTC_STATS_MIN_RES_HEIGHT = 240;
const WEBRTC_STATS_MIN_FPS = 1;

class InnerLayoutWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      endpointsData: [],
      bigVideoData: null,
    };

    this.bigVideo = React.createRef();

    this.onRemoteStreamStart = this.onRemoteStreamStart.bind(this);
    this.onRemoteStreamStop = this.onRemoteStreamStop.bind(this);
    this.onWebRtcStats = this.onWebRtcStats.bind(this);

    WebRTCManager.onRemoteStreamStart = this.onRemoteStreamStart;
    WebRTCManager.onRemoteStreamStop = this.onRemoteStreamStop;
    WebRTCManager.onStats = this.onWebRtcStats;

    // a map with machineName -> timer for the status checks for disconnected machines
    this.statusCheckTimers = {};
  }

  componentDidUpdate(prevProps) {
    const {
      chosenEndpoints, selectedLayout, selectedEndpoints, changeLayoutProp,
    } = this.props;

    if (prevProps?.selectedEndpoints
      && Object.keys(prevProps.selectedEndpoints).length !== Object.keys(selectedEndpoints).length
      && selectedLayout !== layoutNames.DEFAULT_LAYOUT) {
      if (Object.keys(selectedEndpoints).length > 3
        && Object.keys(selectedEndpoints).length <= 6) {
        switch (selectedLayout) {
          case layoutNames.LAYOUT_3_ROOMS_LEFT:
          case layoutNames.LAYOUT_3_ROOMS_RIGHT:
            changeLayoutProp(layoutNames.LAYOUT_6_ROOMS_EQUAL);
            break;
          default:
            break;
        }
      } else if (Object.keys(selectedEndpoints).length > 6) {
        switch (selectedLayout) {
          case layoutNames.LAYOUT_6_ROOMS_LEFT:
          case layoutNames.LAYOUT_6_ROOMS_RIGHT:
          case layoutNames.LAYOUT_6_ROOMS_SYMMETRIC:
          case layoutNames.LAYOUT_6_ROOMS_EQUAL:
          case layoutNames.LAYOUT_3_ROOMS_LEFT:
          case layoutNames.LAYOUT_3_ROOMS_RIGHT:
            changeLayoutProp(layoutNames.LAYOUT_12_ROOMS_EQUAL);
            break;
          default:
            break;
        }
      }
    }

    if (prevProps && prevProps.selectedLayout !== selectedLayout) {
      this.wrapEndpointData();
    }

    if ((!chosenEndpoints || Object.keys(prevProps.chosenEndpoints).length === 0)
      && (prevProps.chosenEndpoints && Object.keys(prevProps.chosenEndpoints).length > 0)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ endpointsData: [] });
    } else {
      const { endpointsData } = this.state;
      let updated = false;
      if (!isEqual(chosenEndpoints, prevProps.chosenEndpoints)) {
        const endpointsArr = Object.values(chosenEndpoints);
        endpointsArr
          .sort((a, b) => a.ordering_idx - b.ordering_idx)
          .forEach((endpoint) => {
            const endpointData = this.getEndpointDataByMachineName(endpoint.machine_name);
            if (!endpointData) {
              this.addEndpointData(endpointsData, {
                data: endpoint,
                stream: WebRTCManager.getStreamByMediaId(`camera_${endpoint.machine_name}`),
                mediaId: endpoint.machine_name,
              });
              updated = true;
            }
          });
      }

      const prevSelectedEndpointArr = prevProps.chosenEndpoints
        ? Object.keys(prevProps.chosenEndpoints) : [];
      prevSelectedEndpointArr.forEach((endpointName) => {
        if (!chosenEndpoints[endpointName]) {
          const { bigVideoData } = this.state;
          if (bigVideoData
            && (!bigVideoData.data || bigVideoData.data.machine_name === endpointName)) {
            this.setState({ bigVideoData: null });
          }
          updated = true;
          this.removeEndpointDataByMachineName(endpointsData, endpointName);
        } else {
          const endpointData = this.getEndpointDataByMachineName(endpointName);
          if (endpointData
            && !isEqual(endpointData.data, chosenEndpoints[endpointName])) {
            endpointData.data = chosenEndpoints[endpointName];
            updated = true;

            if (endpointData.dialedInPopup
              && chosenEndpoints[endpointName] && chosenEndpoints[endpointName].details
              && !prevProps.chosenEndpoints[endpointName].details) {
              this.setupPopupUrl(endpointData.dialedInPopup,
                chosenEndpoints[endpointName].details, endpointName);
            }
          }
        }
      });
      if (updated) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ endpointsData: [...endpointsData] });
      }
    }
  }

  componentWillUnmount() {
    this.turnStatusCheckTimer(false);
  }

  onRemoteStreamStart(mediaId, stream, callType) {
    console.log('onRemoteStreamStart', mediaId, !!stream, callType);
    const {
      selectedEndpoint, selectEndpoint, chosenEndpoints,
    } = this.props;
    const { endpointsData, bigVideoData } = this.state;

    const camMachineName = mediaId.replace('camera_', '');
    if (!chosenEndpoints[camMachineName]) {
      console.log('[iob] onRemoteStreamStart: camera not in list', camMachineName);
      // WebRTCManager.directUnlink(mediaId, adminId, { calibrateMotion: false });
      return;
    }
    if (chosenEndpoints[camMachineName].availabilityStatus === ENDPOINT_STATUS_OFFLINE) {
      const { resetEndpointActiveStatus, checkEndpointAvailable } = this.props;
      resetEndpointActiveStatus(chosenEndpoints[camMachineName].id);
      checkEndpointAvailable(camMachineName);
    }

    let endpointData = this.getEndpointDataByMachineName(camMachineName);
    if (!endpointData) {
      console.log('onRemoteStreamStart camMachineName', camMachineName);
      endpointData = { data: chosenEndpoints[camMachineName], stream, mediaId };
      this.addEndpointData(endpointsData, endpointData);
    } else {
      endpointData.stream = stream;
    }
    this.setState({ endpointsData: [...endpointsData] });
    if (!selectedEndpoint) {
      selectEndpoint(camMachineName);
    }

    if (!bigVideoData) {
      this.setState({ bigVideoData: endpointData });
    }
  }

  onRemoteStreamStop(mediaId, stream) {
    console.log('onRemoteStreamStop', mediaId, !!stream);

    const camMachineName = mediaId.replace('camera_', '');
    const endpointData = this.getEndpointDataByMachineName(camMachineName);
    if (endpointData) {
      const { resetEndpointActiveStatus, checkEndpointAvailable } = this.props;
      resetEndpointActiveStatus(endpointData.data.id);
      checkEndpointAvailable(camMachineName);

      endpointData.stream = null;
      const { endpointsData } = this.state;
      this.setState({ endpointsData: [...endpointsData] });

      this.turnStatusCheckTimer(true, camMachineName);
    }
  }

  onWebRtcStats(mediaId, audioStats, videoStats) {
    const camMachineName = mediaId.replace('camera_', '');
    const endpointData = this.getEndpointDataByMachineName(camMachineName);
    if (endpointData) {
      const { stats } = endpointData;
      // const prevStatsVideo = endpointData.stats.video;
      // if (prevStatsVideo) {
      //   // compare the stats
      // }

      const lowFps = videoStats && videoStats.framesPerSecond < WEBRTC_STATS_MIN_FPS;
      const lowFpsStartTime = lowFps
        ? (stats && stats.result && stats.result.lowFpsStartTime)
        || (videoStats.statsTimestamp && new Date(videoStats.statsTimestamp).getTime())
        || Date.now()
        : null;

      const lowRes = videoStats && videoStats.frameHeight
        && videoStats.frameHeight < WEBRTC_STATS_MIN_RES_HEIGHT;
      const lowResolutionStartTime = lowRes
        ? (stats && stats.result && stats.result.lowResolutionStartTime)
        || (videoStats.statsTimestamp && new Date(videoStats.statsTimestamp).getTime())
        || Date.now()
        : null;
      const update = !stats || !stats.result || stats.result.lowFpsStartTime !== lowFpsStartTime
        || stats.result.lowResolutionStartTime !== lowResolutionStartTime;

      endpointData.stats = {
        video: videoStats,
        result: {
          lowFpsStartTime,
          lowResolutionStartTime,
        },
      };
      if (update) {
        console.warn('[STATS] Low quality update (fps, minFps, resolution, minRes): ',
          !!lowFpsStartTime, WEBRTC_STATS_MIN_FPS, !!lowResolutionStartTime,
          WEBRTC_STATS_MIN_RES_HEIGHT);

        const { endpointsData } = this.state;
        this.setState({ endpointsData: [...endpointsData] });
      }
    }
  }

  turnStatusCheckTimer = (on, machineName = null) => {
    if (machineName) {
      const endpoint = this.getEndpointDataByMachineName(machineName);
      if (!endpoint) {
        console.log('[UI] turnStatusCheckTimer - no endpoint', machineName);
        return;
      }

      const timer = this.statusCheckTimers[machineName];
      if (timer) {
        clearTimeout(timer);
      }
      if (on) {
        const { getEndpointActiveStatus } = this.props;
        this.statusCheckTimers[machineName] = setTimeout(() => {
          getEndpointActiveStatus(endpoint.data.id);
        }, CHECK_STATUS_TIMEOUT);
      } else {
        delete this.statusCheckTimers[machineName];
      }
    } else if (!on) {
      // stop all
      Object.values(this.statusCheckTimers).forEach((timer) => {
        clearTimeout(timer);
      });
      this.statusCheckTimers = [];
    }
  };

  // cleans up the empty slots
  wrapEndpointData = () => {
    const { endpointsData } = this.state;

    const newData = endpointsData.filter((endpointData) => !!endpointData);

    this.setState({ endpointsData: newData });
  }

  addEndpointData = (endpointsData, endpointData) => {
    const index = endpointsData.findIndex((data) => !data);
    if (index >= 0) {
      // eslint-disable-next-line no-param-reassign
      endpointsData[index] = endpointData;
    } else {
      endpointsData.push(endpointData);
    }
  }

  removeEndpointDataByMachineName = (endpointsData, machineName) => {
    const index = endpointsData.findIndex(
      (endpointData) => endpointData && endpointData.data
        && endpointData.data.machine_name === machineName,
    );

    if (index >= 0) {
      // eslint-disable-next-line no-param-reassign
      endpointsData[index] = null;
      this.cleanupEmptyTrailingEndpoints(endpointsData);

      // remove the status timer if any
      this.turnStatusCheckTimer(false, machineName);
    }
  };

  cleanupEmptyTrailingEndpoints = (endpointsData) => {
    endpointsData.slice().reverse().some((endpointData) => {
      if (endpointData) {
        return true;
      }
      endpointsData.pop();
      return false;
    });
  }

  getEndpointDataByMachineName = (machineName) => {
    const { endpointsData } = this.state;
    return machineName
      && endpointsData.find(
        (endpointData) => endpointData && endpointData.data
          && endpointData.data.machine_name === machineName,
      );
  };

  setupPopupUrl = (popupWindow, endpointDetails, machineName) => {
    if (popupWindow) {
      // eslint-disable-next-line no-param-reassign
      popupWindow.location.href = `${process.env.REACT_APP_RUBI_BASE_URL}/api/encrypted_call?hl=true&api_username=${endpointDetails.api_username}&data=${endpointDetails.e_string}`;

      const { startVideoCall } = this.props;
      startVideoCall(machineName);
    }
  };

  setUpRemovePopupsInterval = () => {
    const { callPopupsCheckInterval } = this.state;
    if (!callPopupsCheckInterval) {
      const interval = setInterval(() => {
        const { endpointsData } = this.state;
        let updated = false;
        let count = 0;
        endpointsData.forEach((endpointData) => {
          if (endpointData && endpointData.dialedInPopup) {
            if (endpointData.dialedInPopup.closed) {
              updated = true;
              // eslint-disable-next-line no-param-reassign
              delete endpointData.dialedInPopup;

              const { startVideoCall } = this.props;
              startVideoCall(endpointData.mediaId, true);
            } else {
              count += 1;
            }
          }
        });
        if (updated) {
          this.setState({ endpointsData: [...endpointsData] });
        }
        if (count === 0) {
          console.log('[temp] setUpRemovePopupsInterval clean up', interval);
          clearInterval(interval);
          this.setState({ callPopupsCheckInterval: 0 });
        }
      }, 2000);
      this.setState({ callPopupsCheckInterval: interval });
    }
  };

  makeCall = (machineName) => {
    const { endpointsData } = this.state;
    const { getEndpointDetails } = this.props;
    const endpointData = this.getEndpointDataByMachineName(machineName);
    if (endpointData) {
      if (endpointData.dialedInPopup) {
        endpointData.dialedInPopup.focus();
      } else {
        endpointData.dialedInPopup = window.open('', endpointData.data.name,
          `width=${window.screen.width / 2},height=${window.screen.height / 2},left=${window.screen.width / 2 - 1}`);

        getEndpointDetails(machineName);
        this.setUpRemovePopupsInterval();

        this.setState({ endpointsData: [...endpointsData] });
      }
    }
  }

  goToPoint = (machineName, x, y, zoom) => {
    const { goToPointAction } = this.props;
    if (goToPointAction) {
      goToPointAction(machineName, x, y, zoom);
    }
  };

  zoom = (machineName, stop, toZoomIn) => {
    console.log('[temp] zoom', machineName, stop, toZoomIn);
    if (stop) {
      const { zoomStopAction } = this.props;
      zoomStopAction(machineName);
    } else if (toZoomIn) {
      const { zoomInAction } = this.props;
      zoomInAction(machineName);
    } else {
      const { zoomOutAction } = this.props;
      zoomOutAction(machineName);
    }
  };

  selectEndpoint = (machineName, justPin = false) => {
    const { selectEndpoint } = this.props;
    if (selectEndpoint) {
      if (justPin) {
        const { bigVideoData } = this.state;
        if (machineName && (!bigVideoData || bigVideoData.data.machine_name !== machineName)) {
          this.setState({ bigVideoData: this.getEndpointDataByMachineName(machineName) });
        }
      } else {
        selectEndpoint(machineName);
      }
    }
  };

  swapEndpointsData = (fromIndex, toIndex) => {
    console.log('[temp] swapEndpointsData', fromIndex, toIndex);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(fromIndex) || isNaN(toIndex)) {
      console.log('[temp] swapEndpointsData IGNORE');
    }

    const { endpointsData } = this.state;
    const newEndpointsData = endpointsData.slice();
    const from = newEndpointsData[fromIndex];
    newEndpointsData[fromIndex] = newEndpointsData[toIndex];
    newEndpointsData[toIndex] = from;

    this.cleanupEmptyTrailingEndpoints(newEndpointsData);
    this.setState({ endpointsData: newEndpointsData });
  };

  renderProperLayout() {
    const {
      selectedLayout, selectedEndpoint, refreshCamera,
    } = this.props;
    const { endpointsData } = this.state;

    const callbacks = {
      goToPoint: this.goToPoint,
      zoom: this.zoom,
      swapEndpointsData: this.swapEndpointsData,
    };

    switch (selectedLayout) {
      case layoutNames.LAYOUT_3_ROOMS_LEFT:
        return (
          <Layout3RoomsLeft
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_3_ROOMS_RIGHT:
        return (
          <Layout3RoomsRight
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_6_ROOMS_LEFT:
        return (
          <Layout6RoomsLeft
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_6_ROOMS_RIGHT:
        return (
          <Layout6RoomsRight
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_6_ROOMS_SYMMETRIC:
        return (
          <Layout6RoomsSymmetric
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_6_ROOMS_EQUAL:
        return (
          <Layout6RoomsEqual
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_12_ROOMS_LEFT:
        return (
          <Layout12RoomsLeft
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      case layoutNames.LAYOUT_12_ROOMS_EQUAL:
        return (
          <Layout12RoomsEqual
            selectEndpoint={this.selectEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpointsData={endpointsData}
            refreshCamera={refreshCamera}
            makeCall={this.makeCall}
            callbacks={callbacks}
          />
        );
      default:
        return null;
    }
  }

  renderProperRoute() {
    const { location } = this.props;
    const routePathName = location.pathname;
    switch (routePathName) {
      case routePaths.customers:
        return <Customers />;
      case routePaths.patient:
        return <Patient />;
      case routePaths.alert:
        return <Alert />;
      case routePaths.layouts:
        return <Layouts />;
      case routePaths.session:
        return <Session />;
      case routePaths.myCamera:
        return <MyCamera />;
      case routePaths.reports:
        return <Reports />;
      case routePaths.version:
        return <Version />;
      default:
        return <Customers />;
    }
  }

  render() {
    const {
      selectedLayout, profile, selectedEndpoint, navCollapsed, refreshCamera,
    } = this.props;
    const { username } = profile || {};
    const { bigVideoData, endpointsData } = this.state;

    if (selectedLayout === layoutNames.DEFAULT_LAYOUT) {
      return (
        <DefaultLayout
          selectEndpoint={this.selectEndpoint}
          selectedEndpoint={selectedEndpoint}
          bigVideoData={bigVideoData}
          endpointsData={endpointsData}
          refreshCamera={refreshCamera}
          makeCall={this.makeCall}
          callbacks={
            {
              goToPoint: this.goToPoint,
              zoom: this.zoom,
              swapEndpointsData: this.swapEndpointsData,
            }
          }
        />
      );
    }

    return (
      <>
        <Row gutter={6} className="central-view-wrapper">
          <Col span={!navCollapsed ? 7 : 0}>
            <CustomTopbar username={username} />
            {this.renderProperRoute()}
          </Col>
          <Col id="main-content" span={navCollapsed ? 24 : 17} className="main-video-holder">
            {this.renderProperLayout()}
          </Col>
        </Row>
      </>
    );
  }
}

InnerLayoutWrapper.defaultProps = {
  selectedEndpoint: null,
};

InnerLayoutWrapper.propTypes = {
  selectedLayout: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  profile: PropTypes.shape().isRequired,
  chosenEndpoints: PropTypes.shape().isRequired,
  selectEndpoint: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.shape(),
  navCollapsed: PropTypes.bool.isRequired,
  refreshCamera: PropTypes.func.isRequired,
  getEndpointDetails: PropTypes.func.isRequired,
  startVideoCall: PropTypes.func.isRequired,
  goToPointAction: PropTypes.func.isRequired,
  zoomInAction: PropTypes.func.isRequired,
  zoomOutAction: PropTypes.func.isRequired,
  zoomStopAction: PropTypes.func.isRequired,
  getEndpointActiveStatus: PropTypes.func.isRequired,
  resetEndpointActiveStatus: PropTypes.func.isRequired,
  checkEndpointAvailable: PropTypes.func.isRequired,
  selectedEndpoints: PropTypes.shape().isRequired,
  changeLayoutProp: PropTypes.func.isRequired,
};

export const mapStateToProps = (store) => {
  const {
    monitoringLayout, endpoints, profile, settings,
  } = store;
  const { selectedEndpoints = {} } = endpoints;
  const chosenEndpoints = {};

  Object.keys(selectedEndpoints).forEach((key) => {
    if (selectedEndpoints[key].status === ENDPOINT_STATUSES.APPLIED
      || selectedEndpoints[key].status === ENDPOINT_STATUSES.REMOVED) {
      chosenEndpoints[selectedEndpoints[key].machine_name] = selectedEndpoints[key];
    }
  });

  return {
    chosenEndpoints,
    selectedLayout: monitoringLayout.selectedLayout,
    profile: profile.profile,
    selectedEndpoint: getSelectedEndpointObservation(store),
    navCollapsed: settings.navCollapsed,
    selectedEndpoints,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  selectEndpoint: (machineName) => dispatch(setSelectedObservation(machineName)),
  refreshCamera: (machineName) => dispatch(refreshCameraAction(machineName, new Date().getTime())),
  getEndpointDetails: (machineName) => dispatch(fetchEndpointDetails(machineName)),
  startVideoCall: (machineName, endCall) => dispatch(startVideoCallAction(machineName, endCall)),
  goToPointAction: (machineName, x, y, zoom) => dispatch(goToPoint(machineName, x, y, zoom)),
  zoomInAction: (machineName) => dispatch(zoomIn(machineName)),
  zoomOutAction: (machineName) => dispatch(zoomOut(machineName)),
  zoomStopAction: (machineName) => dispatch(zoomStop(machineName)),
  getEndpointActiveStatus: (id) => dispatch(getEndpointStatus(id)),
  resetEndpointActiveStatus: (id) => dispatch(getEndpointStatusUpdate(id, null)),
  checkEndpointAvailable: (machineName) => dispatch(checkEndpointAction(machineName)),
  changeLayoutProp: (layout) => dispatch(changeLayout(layout)),
});

export { InnerLayoutWrapper as InnerLayoutWrapperTest };
export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(withRouter(InnerLayoutWrapper)),
);
