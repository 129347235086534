import WebRTCManager from '@util/WebRTC/WebRTCManager';

export default class EpicMonitorHandler {
  #refVideo;

  #cameraId;

  #isInitialized;

  initHandler = (ref, cameraId) => {
    this.#refVideo = ref;
    this.#cameraId = cameraId;

    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.addEventListener('message', this.#onMessageReceived);
    }
    this.#sendInitialize();
  }

  #sendInitialize = () => {
    const message = {
      correlationId: this.#generateCorrelationId(),
      type: 'epic.initialize',
    };

    this.#postMessage(message);
  }

  #postMessage = (message) => {
    console.log('[auto-monitor] [epic] sending message', window.chrome && window.chrome.webview, JSON.stringify(message));
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.postMessage(message);
    }
  }

  #generateCorrelationId = () => `${Date.now()}`

  #setAudioVolume = (volumeValue) => {
    if (this.#refVideo && this.#refVideo.current) {
      const volume = parseInt(volumeValue, 10);
      this.#refVideo.current.volume = Number.isNaN(volume) ? 1 : volume / 100;
    }
  }

  #setAudioMute = (value) => {
    if (this.#refVideo && this.#refVideo.current) {
      this.#refVideo.current.muted = value === 'MUTED';
    }
  }

  #setAudioInputState = async (value) => {
    if (value === 'UNMUTED') {
      if (WebRTCManager.localCallType === 'none') {
        if (!WebRTCManager.localStream()) {
          await WebRTCManager.startLocalStream({ video: false, audio: true });
        }

        WebRTCManager.startCall(this.#cameraId, 'audioCall');
      }
    } else if (WebRTCManager.localCallType !== 'none') {
      WebRTCManager.endCall(this.#cameraId);
    }
  }

  reportCurrentStateUpdate = (offline = false) => {
    if (!this.#isInitialized) {
      console.log('[auto-monitor] [epic] reportCurrentStateUpdate NOT INIT');
      return;
    }

    const message = {
      correlationId: this.#generateCorrelationId(),
      type: 'epic.current_state_update',
      data: [{
        property: 'AUDIO_INPUT_STATE',
        value: 'MUTED',
      }, {
        property: 'AUDIO_OUTPUT_STATE',
        value: 'MUTED',
      },
      {
        property: 'AUDIO_OUTPUT_VOLUME',
        value: '100',
      }],
    };
    if (offline) {
      message.integrationState = 'UNAVAILABLE';
    } else if (this.#refVideo && this.#refVideo.current && this.#refVideo.current.srcObject) {
      message.integrationState = 'INTEGRATED';
      message.data[0].value = this.#refVideo.current.muted ? 'MUTED' : 'UNMUTED';
      message.data[1].value = WebRTCManager.localCallType !== 'none' ? 'MUTED' : 'UNMUTED';
      message.data[2].value = `${this.#refVideo.current.volume}`;
    } else {
      message.integrationState = 'INITIALIZING';
    }

    this.#postMessage(message);
  }

  #handleInitializeResponse = ({ data }) => {
    if (data) {
      data.forEach((propData) => {
        switch (propData.property) {
          case 'AUDIO_INPUT_STATE':
            this.#setAudioInputState(propData.value);
            break;
          case 'AUDIO_OUTPUT_STATE':
            this.#setAudioMute(propData.value);
            break;
          case 'AUDIO_OUTPUT_VOLUME':
            this.#setAudioVolume(propData.value);
            break;
          default:
            // do nothing
            break;
        }
      });
    }
    this.#isInitialized = true;
    this.reportCurrentStateUpdate();
  }

  #handleCommand = (message) => {
    const { command, data, correlationId } = message;
    const value = data;
    let property;

    switch (command) {
      case 'SET_AUDIO_INPUT_STATE':
        this.#setAudioInputState(data);
        property = 'AUDIO_INPUT_STATE';
        break;
      case 'SET_AUDIO_OUTPUT_STATE':
        this.#setAudioMute(data);
        property = 'AUDIO_OUTPUT_STATE';
        break;
      case 'SET_AUDIO_OUTPUT_VOLUME':
        this.#setAudioVolume(data);
        property = 'AUDIO_OUTPUT_VOLUME';
        break;
      default:
        // do nothing
        break;
    }

    const response = {
      type: 'epic.command_response',
      property,
      value,
      correlationId,
    };
    this.#postMessage(response);
  }

  #onMessageReceived = (event) => {
    console.log('[auto-monitor] message received', event && event.data);
    if (!event || !event.data) {
      console.log('[auto-monitor] message received - NO DATA');
      return;
    }

    const { type } = event.data;
    switch (type) {
      case 'epic.initialize_response':
        this.#handleInitializeResponse(event.data);
        break;
      case 'epic.command':
        this.#handleCommand(event.data);
        break;
      case 'epic.unknown_property':
        console.log('[auto-monitor] message received - error', event.data);
        break;
      case 'epic.error_received':
        console.log('[auto-monitor] message received - error logged', event.data);
        break;
      default:
        console.log('[auto-monitor] message received - NO DATA');
        break;
    }
  };

  logError = (errorMessage, suggestedAction = 'NONE') => {
    const message = {
      type: 'epic.error_encountered',
      correlationId: this.#generateCorrelationId(),
      errorMessage,
      suggestedAction,
    };
    this.#postMessage(message);
  }

  cleanup = () => {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.removeEventListener('message', this.onMessageReceived);
    }
  }
}
