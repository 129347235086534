import React from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import EndpointVideo from '../EndpointVideo';

const Layout3RoomsLeft = (props) => {
  const {
    endpointsData, selectEndpoint, selectedEndpoint, refreshCamera, makeCall, callbacks,
  } = props;
  return (
    <Row className="video-container video-thumbs-holder">
      <Col span={16} className="col-padding video-height video-thumb">
        <div className="video-placeholder">
          <EndpointVideo
            selectedEndpoint={selectedEndpoint}
            selectEndpoint={selectEndpoint}
            stream={endpointsData[0] ? endpointsData[0].stream : undefined}
            mediaId={endpointsData[0] ? endpointsData[0].mediaId : undefined}
            endpointData={endpointsData[0] ? endpointsData[0].data : undefined}
            endpointStats={endpointsData[0] ? endpointsData[0].stats : undefined}
            hasCall={!!(endpointsData[0] && endpointsData[0].dialedInPopup)}
            index={0}
            refreshCamera={refreshCamera}
            makeCall={makeCall}
            callbacks={callbacks}
          />
        </div>
      </Col>
      <Col span={8} className="video-height">
        <Row className="small-video-thumb fisrt-row-equal-height">
          <Col span={24} className="video-thumb video-height video-thumb">
            <div className="video-placeholder">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[1] ? endpointsData[1].stream : undefined}
                mediaId={endpointsData[1] ? endpointsData[1].mediaId : undefined}
                endpointData={endpointsData[1] ? endpointsData[1].data : undefined}
                endpointStats={endpointsData[1] ? endpointsData[1].stats : undefined}
                hasCall={!!(endpointsData[1] && endpointsData[1].dialedInPopup)}
                index={1}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
        </Row>
        <Row className="small-video-thumb fisrt-row-equal-height">
          <Col span={24} className="video-thumb video-container">
            <div className="video-placeholder">
              <EndpointVideo
                selectedEndpoint={selectedEndpoint}
                selectEndpoint={selectEndpoint}
                stream={endpointsData[2] ? endpointsData[2].stream : undefined}
                mediaId={endpointsData[2] ? endpointsData[2].mediaId : undefined}
                endpointData={endpointsData[2] ? endpointsData[2].data : undefined}
                endpointStats={endpointsData[2] ? endpointsData[2].stats : undefined}
                hasCall={!!(endpointsData[2] && endpointsData[2].dialedInPopup)}
                index={2}
                refreshCamera={refreshCamera}
                makeCall={makeCall}
                callbacks={callbacks}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Layout3RoomsLeft.defaultProps = {
  endpointsData: [],
  selectedEndpoint: null,
  selectEndpoint: null,
  refreshCamera: null,
  makeCall: null,
  callbacks: null,
};

Layout3RoomsLeft.propTypes = {
  selectEndpoint: PropTypes.func,
  selectedEndpoint: PropTypes.shape(),
  endpointsData: PropTypes.arrayOf(PropTypes.shape()),
  refreshCamera: PropTypes.func,
  makeCall: PropTypes.func,
  callbacks: PropTypes.shape(),
};

export { Layout3RoomsLeft as Layout3RoomsLeftTest };
export default injectIntl(withRouter(Layout3RoomsLeft));
