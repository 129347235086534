import React from 'react';
import {
  Select, Row, Col, Button,
} from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getAudioVideo,
  getAudioVideoLoading,
} from '@selectors/iObserverSelectors';
import {
  setAudioVideoRequest,
} from '@actions/iObserverActions/ObservationActions';
import WebRTCManager from '@util/WebRTC/WebRTCManager';
import WebRTCUtils from '@util/WebRTC/WebRTCUtils';
import { layoutNames } from '@constants/LayoutSettings';
import { setAudioOutput } from '@util/commonFunctions';
import IntlMessages from '../util/IntlMessages';
import VideoContainer from '../components/VideoContainer';
import testAudioFile from '../assets/audio/sound_test.wav';

const { Option } = Select;
export class MyCamera extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleDevices = this.handleDevices.bind(this);
    this.loadDevices = this.loadDevices.bind(this);
    const { intl } = this.props;
    this.state = {
      mutedSpeaker: true,
      audioTestPressed: false,
      speakerTestPressed: false,
      audioDev: [],
      videoDev: [],
      speakersDev: [],
      videoConstraints: {
        deviceId: '',
        label: intl.formatMessage({ id: 'common.msg.noCamera' }),
      },
      audioConstraints: {
        deviceId: '',
        label: intl.formatMessage({ id: 'common.msg.noAudio' }),
      },
      speakerConstraints: {
        deviceId: '',
        label: intl.formatMessage({ id: 'common.msg.noSpeakers' }),
      },
    };
    this.forcedUpdate = false;
    this.deviceChanged = false;
    this.mediaStream = null;
  }

  componentDidMount() {
    const { audioVideo } = this.props;
    if (audioVideo.isMicBlocked || audioVideo.isVideoBlocked) {
      this.handleBlocked();
    }
    this.loadDevices(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.forcedUpdate) {
      const { audioVideo, audioVideoLoading } = this.props;
      const { audioConstraints, videoConstraints } = this.state;
      const {
        audio,
        video,
        speaker,
        deviceChanged,
      } = audioVideo;
      if (audioConstraints.label !== prevState.audioConstraints.label
        || videoConstraints.label !== prevState.videoConstraints.label) {
        this.handleStream(false);
      }

      if (audio !== prevProps.audioVideo.audio
        || video !== prevProps.audioVideo.video
        || speaker !== prevProps.audioVideo.speaker
        || deviceChanged !== prevProps.audioVideo.deviceChanged) {
        this.loadDevices();
      }
      if (!!audioVideo.isMicBlocked !== !!prevProps.audioVideo.isMicBlocked
        || !!audioVideo.isVideoBlocked !== !!prevProps.audioVideo.isVideoBlocked) {
        this.handleBlocked();
        this.loadDevices();
      }
      if (Object.keys(audioVideo).length && !Object.keys(prevProps.audioVideo).length) {
        // console.log('SET FOR FIRST TIME');
        if (audioVideoLoading) {
          // console.log('Still loading device settings 2 ...');
        } else {
          this.loadDevices();
        }
      }
    } else {
      this.forcedUpdate = false;
    }
  }

  componentWillUnmount() {
    if (this.mediaStream) {
      const tracks = this.mediaStream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
    this.mediaStream = null;
  }

  getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    await this.handleDevices(devices);
  }

  static setStreamToTheWebRTC(constraints) {
    let isSafari = false;
    if (navigator.userAgent.indexOf('Safari') !== -1
      && navigator.userAgent.indexOf('Chrome') === -1
      && navigator.userAgent.indexOf('Chromium') === -1) {
      isSafari = true;
    }
    WebRTCManager.startLocalStream(
      WebRTCUtils.formatMediaConstraints(
        constraints.audio.deviceId, constraints.video.deviceId, isSafari,
      ),
    );
  }

  handleBlocked = () => {
    const { intl, audioVideo } = this.props;
    if (audioVideo.isVideoBlocked) {
      this.setState({
        videoConstraints: {
          deviceId: 'default',
          label: intl.formatMessage({ id: 'blocked' }),
        },
      });
      this.video = [];
      this.mediaStream = null;
    }
    if (audioVideo.isMicBlocked) {
      this.setState({
        audioConstraints: {
          deviceId: 'default',
          label: intl.formatMessage({ id: 'blocked' }),
        },
      });
    }
  }

  handleDevices = (devices) => {
    const { audioVideo, intl, setAudioVideo } = this.props;
    const { audio, video, speaker } = audioVideo;
    const { speakersDev } = this.state;

    let { videoConstraints, audioConstraints } = this.state;
    let speakerConstraints;

    const audioDevices = devices.filter((x) => x.kind === 'audioinput');
    const videoDevices = devices.filter((x) => x.kind === 'videoinput');
    const speakersDevices = devices.filter((x) => x.kind === 'audiooutput');

    this.setState(() => ({
      audioDev: audioDevices,
      videoDev: videoDevices,
      speakersDev: speakersDevices,
    }));

    const speakerToSet = speakersDevices.find((x) => x.label === speaker) || speakersDevices[0];
    const audioToSet = audioDevices.find((x) => x.label === audio) || audioDevices[0];
    const videoToSet = videoDevices.find((x) => x.label === video) || videoDevices[0];

    if (!audioVideo.isVideoBlocked) {
      videoConstraints = videoToSet
        ? {
          deviceId: videoToSet.deviceId,
          label: videoToSet.label === '' ? intl.formatMessage({ id: 'default' }) : videoToSet.label,
        }
        : {
          deviceId: '',
          label: intl.formatMessage({ id: 'common.msg.noCamera' }),
        };
    }
    if (!audioVideo.isMicBlocked) {
      audioConstraints = audioToSet
        ? {
          deviceId: audioToSet.deviceId,
          label: audioToSet.label === '' ? intl.formatMessage({ id: 'default' }) : audioToSet.label,
        }
        : {
          deviceId: '',
          label: intl.formatMessage({ id: 'common.msg.noAudio' }),
        };
    }
    // speakerToSet
    if (!speakerToSet
      || (speakersDev[1] !== undefined && speakersDev[1].label === 'Dummy Output')) {
      speakerConstraints = {
        deviceId: '',
        label: intl.formatMessage({ id: 'common.msg.noSpeakers' }),
      };
    } else {
      speakerConstraints = {
        deviceId: speakerToSet.deviceId,
        label: speakerToSet.label === '' ? intl.formatMessage({ id: 'default' }) : speakerToSet.label,
      };
    }
    this.setState({
      videoConstraints,
      audioConstraints,
      speakerConstraints,
    });
    if (videoConstraints.label !== audioVideo.video
      || audioConstraints.label !== audioVideo.audio
      || speakerConstraints.label !== audioVideo.speaker) {
      setAudioVideo({
        audio: audioConstraints.label,
        video: videoConstraints.label,
        speaker: speakerConstraints.label,
      });
    }
  };

  handleAudio = () => {
    const { intl } = this.props;
    const { audioDev } = this.state;
    let audioDevices;
    if (audioDev.length > 0) {
      audioDevices = audioDev.sort((a, b) => a.label.localeCompare(b.label)).map((audio) => (
        <Option key={audio.deviceId} value={audio.label}>
          {audio.label}
        </Option>
      ));
    } else {
      audioDevices = (
        <Option value={intl.formatMessage({ id: 'common.msg.noAudio' })}>
          <IntlMessages id="common.msg.noAudio" />
        </Option>
      );
    }

    return audioDevices;
  };

  handleVideo = () => {
    const { intl } = this.props;
    const { videoDev } = this.state;
    let videoDevices;
    if (videoDev.length > 0) {
      videoDevices = videoDev.sort((a, b) => a.deviceId.localeCompare(b.deviceId))
        .map((video) => (
          <Option key={video.deviceId} value={video.label}>
            {video.label}
          </Option>
        ));
    } else {
      videoDevices = (
        <Option value={intl.formatMessage({ id: 'common.msg.noCamera' })}>
          <IntlMessages id="common.msg.noCamera" />
        </Option>
      );
    }
    return videoDevices;
  }

  handleSpeaker = () => {
    const { intl } = this.props;
    const { speakersDev } = this.state;
    let speakerDevices;
    if (speakersDev.length > 0) {
      speakerDevices = speakersDev.sort((a, b) => a.label.localeCompare(b.label)).map((speaker) => (
        <Option key={speaker.deviceId} value={speaker.label}>
          {speaker.label}
        </Option>
      ));
      if (speakersDev[1] !== undefined && speakersDev[1].label === 'Dummy Output') {
        speakerDevices = (
          <Option value={intl.formatMessage({ id: 'common.msg.noSpeakers' })}>
            <IntlMessages id="common.msg.noSpeakers" />
          </Option>
        );
      }
    } else {
      speakerDevices = (
        <Option value={intl.formatMessage({ id: 'common.msg.noSpeakers' })}>
          <IntlMessages id="common.msg.noSpeakers" />
        </Option>
      );
    }
    return speakerDevices;
  }

  changeVideo = (value, option) => {
    const { intl } = this.props;
    const { audioConstraints } = this.state;

    console.log('option: ', option);

    const videoDefConstraints = {
      deviceId: option.key,
      label: value,
    };
    const videoConstraintToUse = option.key === intl.formatMessage({ id: 'common.msg.noCamera' }) ? false : videoDefConstraints;
    const constraints = { audio: audioConstraints, video: videoConstraintToUse };
    this.setState({
      videoConstraints: videoDefConstraints,
    });

    this.initiateLocalStreaming(constraints);
    const { audioVideo, setAudioVideo } = this.props;
    setAudioVideo({
      video: value,
      isMicBlocked: audioVideo.isMicBlocked,
      isVideoBlocked: audioVideo.isVideoBlocked,
    });
  };

  changeAudio = (value, option) => {
    const { videoConstraints } = this.state;
    const audioConstraints = {
      deviceId: option.key,
      label: value,
    };

    const constraints = { audio: audioConstraints, video: videoConstraints };

    this.initiateLocalStreaming(constraints);
    const { audioVideo, setAudioVideo } = this.props;
    setAudioVideo({
      audio: value,
      isMicBlocked: audioVideo.isMicBlocked,
      isVideoBlocked: audioVideo.isVideoBlocked,
    });

    this.setState({
      audioConstraints,
    });
  };

  changeSpeaker = (value, option) => {
    const speakerConstraints = {
      deviceId: option.key,
      label: value,
    };

    const { audioVideo, setAudioVideo } = this.props;
    setAudioVideo({
      speaker: value,
      isMicBlocked: audioVideo.isMicBlocked,
      isVideoBlocked: audioVideo.isVideoBlocked,
      speakerId: option.key,
    });

    this.setState({
      speakerConstraints,
    });
  };

  playAudioTest = () => {
    const { audioVideo } = this.props;
    this.setState({ audioTestPressed: true });
    const audio = document.getElementById('myAudio');
    setAudioOutput(audio, audioVideo.speakerId);
    audio.play();
    audio.onended = () => {
      this.setState({ audioTestPressed: false });
    };
  };

  playSpeakerTest = () => {
    const { mutedSpeaker, speakerTestPressed } = this.state;
    const reverseMuted = !mutedSpeaker;
    this.setState({ mutedSpeaker: reverseMuted, speakerTestPressed: !speakerTestPressed });
  };

  handleStream = (fromMount) => {
    const { audioVideo, intl } = this.props;
    const { audioConstraints, videoConstraints } = this.state;
    let hasAudio = true;

    if (audioVideo.isMicBlocked || audioVideo.audio === intl.formatMessage({ id: 'common.msg.noAudio' })) {
      hasAudio = false;
    }
    if (!audioVideo.isVideoBlocked) {
      navigator.mediaDevices.getUserMedia({ audio: hasAudio, video: true })
        .then(async (stream) => {
          if (stream.getVideoTracks().length > 0 || stream.getAudioTracks().length > 0) {
            let constraints;
            if (hasAudio) {
              constraints = { audio: audioConstraints, video: videoConstraints };
            } else {
              constraints = { audio: '', video: videoConstraints };
            }
            this.initiateLocalStreaming(constraints, fromMount);
            stream.getTracks().forEach((track) => track.stop());
          } else {
            // console.log('DEVICES ARE NOT AVAILABLE');
          }
        }).catch(async (e) => {
          console.log('HAS NO PERMISSIONS');
          console.log('ERROR::: ', e);
        });
    } else {
      this.getDevices();
    }
  }

  initiateLocalStreaming(constraints, skipSend = false) {
    const { intl } = this.props;
    const constraintsToUse = { ...constraints };
    if (constraints.video && constraints.video.deviceId === intl.formatMessage({ id: 'common.msg.noCamera' })) {
      constraintsToUse.video = false;
    }
    if (!skipSend) {
      MyCamera.setStreamToTheWebRTC(constraints);
    }
    navigator.mediaDevices.getUserMedia(constraintsToUse)
      .then((mediaStream) => {
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach((track) => track.stop());
        }
        this.mediaStream = mediaStream;
        this.forcedUpdate = true;
        this.forceUpdate();
      })
      .catch((err) => {
        console.log(`${err.name} ${err.message}`);
      });
  }

  async loadDevices(fromMount) {
    const { audioVideo } = this.props;
    this.getDevices();
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
      return;
    }
    this.handleStream(fromMount);
    if (audioVideo.isVideoBlocked) {
      this.getDevices();
    }
  }

  render() {
    const { mediaStream } = this;
    const {
      mutedSpeaker, audioTestPressed, audioConstraints, videoConstraints, speakerConstraints,
    } = this.state;
    const {
      selectedLayout, audioVideoLoading, audioVideo, intl,
    } = this.props;
    const { innerWidth } = window;
    // const soundClassBtn = audioTestPressed
    //   ? 'camera-sound-btn-pressed gx-mx-2' : 'camera-sound-btn gx-mx-2 ';
    const soundClassBtn2 = audioTestPressed
      ? 'camera-sound-btn-pressed-custom camera-sound-btn-common gx-mx-2' : 'camera-sound-btn-custom camera-sound-btn-common gx-mx-2';
    const speakerClassBtn = mutedSpeaker
      ? 'camera-sound-btn-custom camera-sound-btn-common gx-mx-2' : 'camera-sound-btn-pressed-custom camera-sound-btn-common gx-mx-2';

    return (
      <div className="gx-mx-0 view-wrapper gx-m-2">
        <div id="inner-wrapper-mycamera" className="inner-wrapper">
          <h1 className="inner-heading gx-m-2">
            <IntlMessages id="common.heading.myCamera" />
          </h1>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="video-container-large">
              {(innerWidth <= 1280 || selectedLayout !== layoutNames.DEFAULT_LAYOUT) && (
                <VideoContainer
                  mediaStream={mediaStream}
                  cameraOps={videoConstraints}
                  loading={audioVideoLoading}
                  muted={mutedSpeaker}
                  speakerId={audioVideo.speakerId}
                />
              )}
            </Col>
            <Col md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 12 : 24} className="select-col">
              <Row gutter={[16, 20]} className="my-camera-dropdown">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="gx-mb-0">
                    <IntlMessages className="gx-mb-0 gx-ml-2" id="common.label.camera" />
                  </p>
                  <Select
                    value={videoConstraints.label}
                    className="camera-form-select"
                    id="camera"
                    onChange={this.changeVideo}
                    getPopupContainer={() => document.getElementById('inner-wrapper-mycamera')}
                    disabled={audioVideo.isVideoBlocked}
                  >
                    {this.handleVideo()}
                  </Select>
                </Col>
              </Row>
              {/* MICROPHONE */}
              <Row gutter={[16, 20]} className="my-camera-dropdown">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="gx-mb-0">
                    <IntlMessages className="gx-mb-0 gx-ml-2" id="common.label.microphone" />
                  </p>
                  <Select
                    value={audioConstraints.label}
                    className="camera-form-select"
                    id="microphone"
                    onChange={this.changeAudio}
                    getPopupContainer={() => document.getElementById('inner-wrapper-mycamera')}
                    disabled={audioVideo.isMicBlocked}
                  >
                    {this.handleAudio()}
                  </Select>
                </Col>
              </Row>
              {/* SPEAKER */}
              <Row gutter={[16, 20]} className="my-camera-dropdown">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="gx-mb-0">
                    <IntlMessages className="gx-mb-0 gx-ml-2" id="common.label.speaker" />
                  </p>
                  <Select
                    value={speakerConstraints.label}
                    className="camera-form-select"
                    id="speaker"
                    onChange={this.changeSpeaker}
                    getPopupContainer={() => document.getElementById('inner-wrapper-mycamera')}
                    disabled={audioVideo.isMicBlocked}
                  >
                    {this.handleSpeaker()}
                  </Select>
                </Col>
              </Row>
              {/* TEST SOUND BUTTON */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Button
                    className={soundClassBtn2}
                    onClick={this.playAudioTest}
                    disabled={audioVideo.speaker === intl.formatMessage({ id: 'common.msg.noSpeakers' })}
                  >
                    <IntlMessages className="gx-mb-0 gx-ml-2" id="btn.testSound" />
                    <audio id="myAudio">
                      <source src={testAudioFile} type="audio/mpeg" />
                    </audio>
                  </Button>

                  <Button
                    className={speakerClassBtn}
                    onClick={this.playSpeakerTest}
                    disabled={audioVideo.isMicBlocked || audioVideo.audio === intl.formatMessage({ id: 'common.msg.noAudio' })
                      || audioVideo.speaker === intl.formatMessage({ id: 'common.msg.noSpeakers' })}
                  >
                    <IntlMessages className="gx-mb-0 gx-ml-2" id="btn.testSpeaker" />
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={selectedLayout === layoutNames.DEFAULT_LAYOUT ? 12 : 24} className="video-col">
              {(selectedLayout === layoutNames.DEFAULT_LAYOUT && innerWidth > 1280) && (
                <VideoContainer
                  mediaStream={mediaStream}
                  cameraOps={videoConstraints}
                  loading={audioVideoLoading}
                  muted={mutedSpeaker}
                  speakerId={audioVideo.speakerId}
                />
              )}
            </Col>
          </Row>
          <p className="my-camera-app-version">
            v
            {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>
    );
  }
}

MyCamera.defaultProps = {
};

MyCamera.propTypes = {
  audioVideo: PropTypes.shape().isRequired,
  audioVideoLoading: PropTypes.bool.isRequired,
  selectedLayout: PropTypes.string.isRequired,
  setAudioVideo: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  audioVideo: getAudioVideo(state),
  audioVideoLoading: getAudioVideoLoading(state),
  selectedLayout: state.monitoringLayout.selectedLayout,
});

const mapDispatchToProps = (dispatch) => ({
  setAudioVideo: (params) => dispatch(setAudioVideoRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(MyCamera)));
