import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import CustomScrollbars from '@util/CustomScrollbars';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { THEME_TYPE_LITE } from '@constants/ThemeSetting';
import patientsIconBlue from '@assets/images/sidebarIcons/82x82-patients-blue.png';
import patientsIconViolet from '@assets/images/sidebarIcons/82x82-patients-violet.png';
import customerIconBlue from '@assets/images/sidebarIcons/82x82-customer-blue.png';
import customerIconViolet from '@assets/images/sidebarIcons/82x82-customer-violet.png';
import alertsIconBlue from '@assets/images/sidebarIcons/82x82-alerts-blue.png';
import alertsIconViolet from '@assets/images/sidebarIcons/82x82-alerts-violet.png';
import screensIconBlue from '@assets/images/sidebarIcons/82x82-screens-blue.png';
import screensIconViolet from '@assets/images/sidebarIcons/82x82-screens-violet.png';
import reportingIconBlue from '@assets/images/sidebarIcons/82x82-reporting-blue.png';
import reportingIconViolet from '@assets/images/sidebarIcons/82x82-reporting-violet.png';
import exitIconBlue from '@assets/images/sidebarIcons/82x82-exit-blue.png';
import exitIconViolet from '@assets/images/sidebarIcons/82x82-exit-violet.png';
import myCameraIconBlue from '@assets/images/sidebarIcons/82x82-mycamera-blue.png';
import myCameraIconViolet from '@assets/images/sidebarIcons/82x82-mycamera-violet.png';
import SidebarLogo from './SidebarLogo';

export const SidebarContent = (props) => {
  const { themeType, pathname } = useSelector(({ settings }) => settings);

  // const getNoHeaderClass = (navStyle) => {
  //   if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
  //     || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
  //     return 'gx-no-header-notifications';
  //   }
  //   return '';
  // };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const { intl, hideIcons } = props;
  return (
    <>
      <SidebarLogo intl={intl} />
      <div className="gx-sidebar-content">
        {!hideIcons && (
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              className="sidebar-item"
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="vertical"
            >
              <Menu.Item key="app/customers" title={intl.formatMessage({ id: 'customers.titleCustomer' })}>
                <Link to="/app/customers">
                  <img className="selected" alt="" src={patientsIconBlue} />
                  <img className="unselected" alt="" src={patientsIconViolet} />
                </Link>
              </Menu.Item>
              <Menu.Item key="app/patient" title={intl.formatMessage({ id: 'common.heading.patient' })}>
                <Link to="/app/patient">
                  <img className="selected" alt="" src={customerIconBlue} />
                  <img className="unselected" alt="" src={customerIconViolet} />
                </Link>
              </Menu.Item>
              <Menu.Item key="app/alert" title={intl.formatMessage({ id: 'alert.titleTxt' })}>
                <Link to="/app/alert">
                  <img className="selected" alt="" src={alertsIconBlue} />
                  <img className="unselected" alt="" src={alertsIconViolet} />
                </Link>
              </Menu.Item>
              <Menu.Item key="app/layouts" title={intl.formatMessage({ id: 'layouts.titleLayoutPage' })}>
                <Link to="/app/layouts">
                  <img className="selected" alt="" src={screensIconBlue} />
                  <img className="unselected" alt="" src={screensIconViolet} />
                </Link>
              </Menu.Item>

              <Menu.Item key="app/reports" title={intl.formatMessage({ id: 'iobReports.iobReport' })}>
                <Link to="/app/reports">
                  <img className="selected" alt="" src={reportingIconBlue} />
                  <img className="unselected" alt="" src={reportingIconViolet} />
                </Link>
              </Menu.Item>

              <Menu.Item key="app/session" title={intl.formatMessage({ id: 'sessoin.titleSessionPage' })}>
                <Link to="/app/session">
                  <img className="selected" alt="" src={exitIconBlue} />
                  <img className="unselected" alt="" src={exitIconViolet} />
                </Link>
              </Menu.Item>
              <Menu.Item key="app/my-camera" title={intl.formatMessage({ id: 'common.heading.myCamera' })}>
                <Link to="/app/my-camera">
                  <img className="selected" alt="" src={myCameraIconBlue} />
                  <img className="unselected" alt="" src={myCameraIconViolet} />
                </Link>
              </Menu.Item>
            </Menu>
          </CustomScrollbars>
        )}
      </div>
    </>
  );
};

SidebarContent.defaultProps = {
  hideIcons: false,
};
SidebarContent.propTypes = {
  intl: PropTypes.shape().isRequired,
  hideIcons: PropTypes.bool,
};
export default injectIntl(SidebarContent);
